import React from 'react';
import './App.css';
import Navigation from './Navigation';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';
import { AuthProvider } from './contexts/useAuth';
import { UsersProvider } from './contexts/useUsers';
import { AdvertisersProvider } from './contexts/useAdvertisers';
import { CampaignsProvider } from './contexts/useCampaigns';
import { ContractsProvider } from './contexts/useContracts';
import { PlacesProvider } from './contexts/usePlaces';
import { PlaceZonesProvider } from './contexts/usePlaceZones';
import { PointsProvider } from './contexts/usePoints';
import { PlaceResponsiblesProvider } from './contexts/usePlaceResponsibles';
import { PlaceTypesProvider } from './contexts/usePlaceTypes';
import { QrcodeSizesProvider } from './contexts/useQrcodeSizes';
import { TagsProvider } from './contexts/useTags';
import { PlaceGroupsProvider } from './contexts/usePlaceGroups';
import { IntlProvider } from 'react-intl';
import { CampaignMediasProvider } from './contexts/useCampaignMedias';
import { ExportReportsProvider } from './contexts/useExportReports';
import { BatchPaymentsProvider } from './contexts/useBatchPayments';
import { BatchPaymentDepositsProvider } from './contexts/useBatchPaymentDeposits';
import { UserLogsProvider } from './contexts/useUserLogs';
import { ScrapingDatasProvider } from './contexts/useScrapingDatas';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  ptBR,
);



function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <IntlProvider locale={'pt-BR'}>
            <AuthProvider>
              <UsersProvider>
                <ContractsProvider>
                  <AdvertisersProvider>
                    <CampaignMediasProvider>
                      <CampaignsProvider>
                        <PlaceGroupsProvider>
                          <PlacesProvider>
                            <PlaceZonesProvider>
                              <PointsProvider>
                                <PlaceResponsiblesProvider>
                                  <PlaceTypesProvider>
                                    <QrcodeSizesProvider>
                                      <ExportReportsProvider>
                                        <TagsProvider>
                                          <BatchPaymentsProvider>
                                            <BatchPaymentDepositsProvider>
                                              <UserLogsProvider>
                                                <ScrapingDatasProvider>
                                                  <Router>
                                                    <Navigation />
                                                  </Router>
                                                </ScrapingDatasProvider>
                                              </UserLogsProvider>
                                            </BatchPaymentDepositsProvider>
                                          </BatchPaymentsProvider>
                                        </TagsProvider>
                                      </ExportReportsProvider>
                                    </QrcodeSizesProvider>
                                  </PlaceTypesProvider>
                                </PlaceResponsiblesProvider>
                              </PointsProvider>
                            </PlaceZonesProvider>
                          </PlacesProvider>
                        </PlaceGroupsProvider>
                      </CampaignsProvider>
                    </CampaignMediasProvider>
                  </AdvertisersProvider>
                </ContractsProvider>
              </UsersProvider>
            </AuthProvider>
          </IntlProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
