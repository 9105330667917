import React, { SyntheticEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Layout from '../components/Layout';
import Table from '../components/Table';
import MenuBar from '../components/MenuBar';
import Navigate from '../components/Navigate';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useParams, Outlet, Link, useLocation } from 'react-router-dom';
import TitleTabs from '../components/TitleTabs';
import { useUsers } from '../contexts/useUsers';
import MapIcon from '@mui/icons-material/Map';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Grid, Avatar, IconButton, Chip, Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAdvertisers } from '../contexts/useAdvertisers';
import { useContracts } from '../contexts/useContracts';
import { useCampaigns } from '../contexts/useCampaigns';
import {
  AdvertiserFilter,
  BreadcrumbType,
  CampaignFilter,
  UserFilter,
} from '../components/common/types';
import { FormattedNumber } from 'react-intl';
import PhoneNumber from '../components/PhoneNumber';
import { 
  AttachMoneyOutlined, 
  EditOutlined, 
  MapOutlined, 
  RemoveRedEyeOutlined, 
  Description,
} from '@mui/icons-material';
import CampaignOutlined from '@mui/icons-material/CampaignOutlined';

import QR_CODE_CLIENT_URL from '../api/qrCodeClientUrl';

const initialAdvertiser: AdvertiserFilter = {
  id: null,
  name: null,
  urlImage: null,
  updatedAt: null,
};

const AdvertiserProfile = () => {
  const [value, setValue] = useState(0);
  const location = useLocation();

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    console.log('newValue', newValue)
    if (newValue === 0) {
      findContractsList();
    }
    if (newValue === 1) {
      findCampaignsList();
    }
    if (newValue === 2) {
      findUsersList();
    }
    setValue(newValue);
  };

  const { advertisers, getAdvertiser } = useAdvertisers();
  const [advertiser, setAdvertiser] =
    useState<AdvertiserFilter>(initialAdvertiser);
  const { advertiserId } = useParams();

  const getAdvertiserInformation = () => {
    if (!advertiser.id) {
      // console.log('getAdvertiserInformation');
      getAdvertiser(advertiserId);
    }
  };

  useEffect(() => {
    // console.log('useEffect getAdvertiserInformation', advertiserId);
    getAdvertiserInformation();
  }, [advertiserId]);

  useEffect(() => {
    const advertiserProfile = advertisers.find((adv: AdvertiserFilter) => adv.id === Number(advertiserId))

    if ((!advertiser.id && advertiserProfile?.id == advertiserId) || (advertiser.updatedAt != advertiserProfile?.updatedAt) ) {
      // console.log('executando useEffect setAdvertiser');
      setAdvertiser(advertiserProfile as AdvertiserFilter);
    }
  }, [advertisers]);

  const { contracts, findContracts } = useContracts();
  const { campaigns, findCampaigns } = useCampaigns();
  const { users, findUsers } = useUsers();
  const [queryUser, setQueryUser] = useState('');
  const [queryContract, setQueryContract] = useState('');
  const [queryCampaign, setQueryCampaign] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const filterDataUser = (query: string, data: any) => {
    if (!query) {
      return data;
    } else {
      return data.filter(({ name, email }: any) =>
        name.toLowerCase().includes(query.toLowerCase()) || email.toLowerCase().includes(query.toLowerCase())
      );
    }
  };

  const dataUsersFiltered = filterDataUser(queryUser, users);

  const filterDataContract = (query: string, data: any) => {
    if (!query) {
      return data;
    } else {
      return data.filter(({ campaign, place }: any) =>
        campaign?.name.toLowerCase().includes(query.toLowerCase()) || place?.name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };

  const dataContractsFiltered = filterDataContract(queryContract, contracts);

  const filterDataCampaign = (query: string, data: any) => {
    if (!query) {
      return data;
    } else {
      return data.filter(({ name }: any) =>
        name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };

  const dataCampaignsFiltered = filterDataCampaign(queryCampaign, campaigns);

  const findContractsList = () => {
    findContracts({
      query: {
        includeCampaignPlaces: true, 
        includeBigNumbers: true,
        advertiserId
      }
    });
  };

  const findCampaignsList = () => {
    findCampaigns({
      query: {
        includeBigNumbers: true,
        advertiserId
      }
    });
  };

  const findUsersList = () => {
    // if (!filteredUsers.length) {
      findUsers({
        query: {
          advertiserId
        }
      });
    // }
  };

  useEffect(() => {
    console.log('Use effect')
    findUsersList();
    findContractsList();
    findCampaignsList();
    console.log({ contracts });
  }, []);

  const contractColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Contrato',
      width: 90,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/advertisers/${advertiserId}/contracts/edit/${row?.id}`}
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
          state={{ background: location }}
        >
          <React.Fragment>#{row?.id}</React.Fragment>
        </Link>
      ),
    },
    {
      field: 'placeId',
      headerName: 'Estabelecimento',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <Link
            to={`/places/${row?.placeId}/profile`}
            target='_blank'
          >
            {row?.place?.name} ({row?.mediaType})
          </Link>
        </React.Fragment>
      ),
    },
    {
      field: 'campaignId',
      headerName: 'Campanha',
      width: 230,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <Link
            to={`/campaigns/${row?.campaign?.id}/profile`}
            target='_blank'
          >
            {row?.campaign?.name}
          </Link>
        </React.Fragment>
      ),
    },
    {
      field: 'contractIsActive',
      headerName: 'Status',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!!row?.contractIsActive ? (
            <Chip label="Ativo" color="success" />
          ) : (
            <Chip label="Inativo" color="warning" />
          )}
        </React.Fragment>
      ),
    },
    {
      field: 'estimateViews',
      headerName: 'Impressões',
      width: 100,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'viewCost',
      headerName: 'Valor Impressão (R$)',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <FormattedNumber minimumFractionDigits={4} value={row?.viewCost} style="decimal" currency="BRL" />
        </React.Fragment>
      ),
    },
    {
      field: 'nOfViews',
      headerName: 'Entrega',
      width: 120,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {Number(row?.nOfViews/row?.estimateViews * 100).toFixed(2)}%
           ({row?.nOfViews})
          
        </React.Fragment>
      ),
    },
    {
      field: 'viewsAvg',
      headerName: 'Exibição Média',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfCtas',
      headerName: 'Cliques',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'ctr',
      headerName: 'CTR',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
  ];

  const campaignColumns: GridColDef[] = [
    // columns field are different from Database Model contracts
    // {
    //   field: 'id',
    //   headerName: 'ID',
    //   width: 90,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'name',
      headerName: 'Nome',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/campaigns/${row?.id}/profile`}
          target='_blank'
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
        >
          {row?.name}
        </Link>
      ),
    },
    {
      field: 'nOfActivePlacesStatus',
      headerName: 'Status',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {(!!row?.nOfActiveContracts && row?.nOfActiveContracts > 0) ? (
            <Chip label="Rodando" color="success" />
          ) : (
            <Chip label="Parada" color="warning" />
          )}
        </React.Fragment>
      ),
    },
    {
      field: 'id',
      headerName: 'Preview',
      width: 80,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`${QR_CODE_CLIENT_URL}/preview/${row.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <RemoveRedEyeOutlined
            key={row?.id}
            id={row?.id}
            sx={{ color: '#FF4C51', width: '33px', height: '33px' }}
          />
        </Link>
      ),
    },
    {
      field: 'nOfPlaces',
      headerName: 'Locais',
      width: 100,
      // resizable: true,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfActivePlaces',
      headerName: 'Locais Ativos',
      width: 150,
      // resizable: true,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfContracts',
      headerName: 'Contratos',
      width: 100,
      // resizable: true,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfActiveContracts',
      headerName: 'Contratos Ativos',
      width: 150,
      // resizable: true,
      headerClassName: 'app-theme--header',
    },
    // {
    //   field: 'goal',
    //   headerName: 'Objetivo',
    //   width: 100,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'viewsGoal',
      headerName: 'Objetivo Impressões',
      width: 150,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfViews',
      headerName: 'Impressões',
      width: 120,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {
            row?.nOfViews && row?.viewsGoal 
              ? 
                <>
                  {Number(row?.nOfViews/row?.viewsGoal * 100).toFixed(2)}%
                  ({row?.nOfViews})
                </>
              : 
                '-'
          }
        </React.Fragment>
      ),
    },
    {
      field: 'viewsAvg',
      headerName: 'Exibição Média',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfCtas',
      headerName: 'Cliques',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'ctr',
      headerName: 'CTR',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
  ];

  const userColumns: GridColDef[] = [
    // {
    //   field: 'advertiserId',
    //   headerName: 'ID',
    //   width: 90,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'name',
      headerName: 'Nome',
      width: 230,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/advertisers/${advertiserId}/users/edit/${row?.id}`}
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
          state={{ background: location }}
        >
          {row?.name}
        </Link>
      ),
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 330,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <PhoneNumber value={row.phone} />
      ),
    },
    {
      field: 'isEnabled',
      headerName: 'Status',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!row?.isEnabled ? (
            <Chip label="Inativo" color="error"/>
          ) : (
            <Chip label="Ativo" color="success" />
          )}
        </React.Fragment>
      ),
    },
    // {
    //   field: 'profile',
    //   headerName: 'Profile',
    //   width: 130,
    //   headerClassName: 'app-theme--header',
    // },
    // {
    //   field: 'permission',
    //   headerName: 'Permission',
    //   width: 130,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
  ];

  const tabNames = ['Campanhas', 'Contratos', 'Usuarios'];

  const calculateTotalValue = () => {
    let total = 0;
    campaigns.forEach((cmp: CampaignFilter) => {
      const { viewsGoal = 0, viewCost = 0} = cmp;
      total += viewsGoal * viewCost;
    });
    return total
  }

  const sumPlaces = () => {
    let totalPlaces = 0;
    campaigns.forEach((cmp: CampaignFilter) => {
      const { nOfPlaces = 0 } = cmp;
      totalPlaces += Number(nOfPlaces);
    });

    return totalPlaces
  }

  const sumContracts = () => {
    let totalContracts = 0;
    campaigns.forEach((cmp: CampaignFilter) => {
      const { nOfContracts = 0 } = cmp;
      totalContracts += Number(nOfContracts);
    });

    return totalContracts
  }

  const advertiserInformation = [
    {
      title: 'Campanhas',
      quantity: campaigns.length,
      icon: <MapIcon sx={{ width: 36, height: 36, color: 'white' }} />,
      color: '#0288D1',
    },
    {
      title: 'Lugares',
      quantity: sumPlaces(),
      icon: (
        <InsertDriveFileIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#388E3C',
    },
    {
      title: 'Contratos',
      quantity: sumContracts(),
      icon: (
        <Description sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#388E3C',
    },
    {
      title: 'Valor Acumulado',
      quantity: calculateTotalValue(),
      icon: (
        <InsertDriveFileIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#F57C00',
    },
  ];

  const switchMenuBarAccordinTab = (() => {
    switch (value) {
      case 0:
        return (
          <MenuBar
            addUrl={`/advertisers/${advertiserId}/campaigns/create`}
            AddButtonText="Nova Campanha"
            query={queryCampaign}
            setQuery={setQueryCampaign}
          />
        );

      case 1:
        return (
          <MenuBar
            addUrl={`/advertisers/${advertiserId}/contracts/create`}
            AddButtonText="Novo Contrato"
            query={queryContract}
            setQuery={setQueryContract}
          />
        );

      case 2:
        return (
          <MenuBar
            addUrl={`/advertisers/${advertiserId}/users/create`}
            AddButtonText="Novo Usuario"
            query={queryUser}
            setQuery={setQueryUser}
          />
        );

      default:
        return (
          <MenuBar
            addUrl={`/advertisers/${advertiserId}/contracts/create`}
            AddButtonText="Novo Contrato"
            query={queryContract}
            setQuery={setQueryContract}
          />
        );
    }
  })();

  const switchTableAccordingTab = (() => {
    switch (value) {
      case 0:
        return (
          <>
            <Table height={550} rows={dataCampaignsFiltered} columns={campaignColumns} />
            <Typography
              variant='subtitle2' 
              style={{ color: 'gray' }}
            >
              * Dados atualizados em tempo real
            </Typography>
          </>
        );

      case 1:
        return (
          <>
            <Table height={550} rows={dataContractsFiltered} columns={contractColumns} />
            <Typography
              variant='subtitle2' 
              style={{ color: 'gray' }}
            >
              * Dados atualizados em tempo real
            </Typography>
          </>
        );

      case 2:
        return <Table height={550} rows={dataUsersFiltered} columns={userColumns} />;

      default:
        return <Table height={550} rows={dataContractsFiltered} columns={contractColumns} />;
    }
  })();

  const breadcrumbsList: BreadcrumbType[] = [
    {
      link: '/advertisers',
      label: 'Lista de Anunciantes'
    },
    {
      link: `/advertisers/${advertiserId}`,
      label: advertiser.name || '',
      disabled: true
    },
  ]

  return (
    <Layout>
      <Navigate 
        pageTitle="Perfil do Anunciante" 
        breadcrumbs={breadcrumbsList}
      />
      <TitleTabs value={value} handleChange={handleChange} tabNames={tabNames}>
        <Stack
          // container
          sx={{ p: 1, width: '100%'}}
          // columns={10}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack 
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            // spacing={3}  
          >
            <Avatar sx={{ width: 90, height: 90, mr: 1 }} src={advertiser.urlImage} />
            <Typography variant="h5"  sx={{ fontWeight: 'bold' }}>{advertiser.name}</Typography>
            <Link 
              state={{ background: location }} 
              to={`/advertisers/edit/${advertiserId}`}
            >
              <EditOutlined 
                fontSize="small" 
                sx={{ 
                  color: "#999999", 
                  marginLeft: 2, 
                  mt: '5px', 
                  borderColor: "#999999", 
                  borderStyle: 'solid', 
                  borderRadius: '2px', 
                  borderWidth: '1px',
                }} />
            </Link>
          </Stack>

          <Stack 
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            // spacing={3}  
          >
            <Link
              to={`/advertisercampaigns/${advertiserId}`}
              style={{ textDecoration: 'none' }}
            >
              <Button variant="outlined">Ver Dash</Button>
            </Link>
          </Stack>

          <Stack  flexDirection="row">
            <Stack flexDirection="row" sx={{ width: '160px'}}>
              <CampaignOutlined 
                sx={{ 
                  p: '10px', 
                  mr: 1,
                  width: 56, 
                  height: 56, 
                  color: 'white', 
                  backgroundColor: '#0288D1',
                  borderRadius: '60px'
                }} 
              />
              <Stack 
                justifyContent="center" 
                alignItems="center"
              >
                <Typography color="#999999" variant="subtitle2">Campanhas</Typography>
                <Typography variant="h6">{campaigns.length}</Typography>
              </Stack>
            </Stack>
            <Stack flexDirection="row" sx={{ width: '160px'}}>
              <MapOutlined 
                sx={{ 
                  p: '10px', 
                  mr: 1,
                  width: 56, 
                  height: 56, 
                  color: 'white', 
                  backgroundColor: '#388E3C',
                  borderRadius: '60px'
                }} 
              />
              <Stack 
                justifyContent="center" 
                alignItems="center"
              >
                <Typography color="#999999" variant="subtitle2">Lugares</Typography>
                <Typography variant="h6">{sumPlaces()}</Typography>
              </Stack>
            </Stack>
            <Stack flexDirection="row" sx={{ width: '160px'}}>
              <Description 
                sx={{ 
                  p: '10px', 
                  mr: 1,
                  width: 56, 
                  height: 56, 
                  color: 'white', 
                  backgroundColor: '#45388e',
                  borderRadius: '60px'
                }} 
              />
              <Stack 
                justifyContent="center" 
                alignItems="center"
              >
                <Typography color="#999999" variant="subtitle2">Contratos</Typography>
                <Typography variant="h6">{sumContracts()}</Typography>
              </Stack>
            </Stack>
            <Stack flexDirection="row" sx={{ width: '180px'}}>
              <AttachMoneyOutlined
                sx={{ 
                  p: '10px', 
                  mr: 1,
                  width: 56, 
                  height: 56, 
                  color: 'white', 
                  backgroundColor: '#F57C00',
                  borderRadius: '60px'
                }} 
              />
              <Stack 
                justifyContent="center" 
                alignItems="center"
              >
                <Typography color="#999999" variant="subtitle2">Valor Acumulado</Typography>
                <Typography variant="h6">R$ {calculateTotalValue()}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </TitleTabs>
      {switchMenuBarAccordinTab}
      {switchTableAccordingTab}
      <Outlet />
    </Layout>
  );
};

export default AdvertiserProfile;
