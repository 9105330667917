import React, { 
  ChangeEvent, 
  Fragment, 
  useEffect, 
  useRef, 
  useState,
} from 'react';
import {
  Autocomplete,
  Box,
  Button,
 
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { NumericFormat } from 'react-number-format';

import { useSnackbar } from 'notistack';

import { useCampaigns } from '../../contexts/useCampaigns';
import { useAdvertisers } from '../../contexts/useAdvertisers';
import { useCampaignMedias } from '../../contexts/useCampaignMedias';

import {
  AdvertiserFilter,
  CampaignModal,
  AddModalHandler,
  EventType,
  ValueChange,
  CampaignMediaInterface,
} from '../common/types';
import { v4 as uuidv4 } from 'uuid';
import { PhotoCamera, AttachFile } from '@mui/icons-material';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '90vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const initialCampaign: CampaignModal = {
  id: null,
  advertiserId: null,
  name: null,
  taxonomyName: null,
  codPi: null,
  urlCover: null,
  newFileCover: null,
  urlVideo: null,
  newFileVideo: null,
  prospectivePaymentDate: null,
  paidAt: null,
  approvedAt: null,
  viewCost: null,
  tagTrack: null,
  tagScript: null,
  viewsGoal: null,
  goal: null,
  ctaType: null,
  urlCta: null,
  textCta: null,
  startDate: null,
  endDate: null,
  colorStyle: '000000',
  backgroundStyle: 'ffffff',
  waitTime: 5,
  urlRedirectExample: 'https://piraja.com.br/cardapio',
  isTest: true,
  isFinished: false,
  urlFilePi: null,
  newFilePi: null,
  newFilePiName: null,
  status: null,
};

const goalsList = [
  {id: 1, label: "Divulgar evento"},
  {id: 2, label: "Divulgar uma bebida"},
  {id: 3, label: "Divulgar prato"},
  {id: 4, label: "Divulgar promoção"},
  {id: 5, label: "Divulgar redes sociais"},
  {id: 6, label: "Outro objetivo"}
]

const statusList = [
  { value: 'preparing', label: 'Nos Preparativos' },
  { value: 'active', label: 'Ativação' },
  { value: 'started', label: 'Em Andamento' },
  { value: 'stoped', label: 'pausada' },
  { value: 'inPayment', label: 'Em Pagamento' },
  { value: 'ended', label: 'Encerrada' },
];

const NumberFormatCustom = (props: any) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NumericFormat {...props} />;
};

const AddCampaignModal = React.forwardRef((props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { campaignId, advertiserId } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    editCampaign,
    setEditCampaign,
    getEditCampaign,
    getCampaign,
    createCampaigns,
    patchCampaigns,
    findCampaigns
  } = useCampaigns();

  const { advertisers, findAdvertisers } = useAdvertisers();
  const [campaign, setCampaign] = useState<CampaignModal>(initialCampaign);
  const [showAvancedSettings, setShowAvancedSettings] = useState<boolean>(false);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState('');
  const autocompleteComponent = useRef(null);
  const handleInputChange = (event: EventType) => {
    const { name, value } = event?.target || event;
    console.log('handleInputChange value:', value)
    setCampaign({
      ...campaign,
      [name]: value,
    });
  };

  const handleInputColorChange = (event: EventType) => {
    const { name, value } = event?.target || event;
    setCampaign({
      ...campaign,
      [name]: value.replace('#', ''),
    });
  };

  useEffect(() => {
    findAdvertisers();
  }, []);

  useEffect(() => {
    if (!campaign.advertiserId) {
      setCampaign({
        ...campaign,
        advertiserId: Number(advertiserId) 
      });
    }
  }, [advertiserId]);

  useEffect(() => {
    if (campaignId && !campaign?.id) {
      getEditCampaign(campaignId, { query: {
        includeAdvertisers: true,
      }});
    }
  }, [campaignId]);

  useEffect(() => {
    if (editCampaign.id && editCampaign?.id !== campaign?.id) {
      setCampaign({
        ...editCampaign,
        prospectivePaymentDate: editCampaign.prospectivePaymentDate
          ? dayjs(editCampaign.prospectivePaymentDate, 'YYYY-MM-DD')
          : null,
        paidAt: editCampaign.paidAt
          ? dayjs(editCampaign.paidAt, 'YYYY-MM-DD')
          : null,
        approvedAt: editCampaign.approvedAt
          ? dayjs(editCampaign.approvedAt, 'YYYY-MM-DD')
          : null,
        startDate: editCampaign.startDate
          ? dayjs(editCampaign.startDate)
          : null,
        endDate: editCampaign.endDate ? dayjs(editCampaign.endDate) : null,
      });
    }
  }, [editCampaign]);

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadFileCover = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    // console.log('base64: ', base64);
    setCampaign({
      ...campaign,
      newFileCover: base64 ? base64 : null,
    });
  };

  const uploadFilePi = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setCampaign({
      ...campaign,
      newFilePi: base64 ? base64 : null,
      newFilePiName: file?.name || null,
    });
  };

  const validateCampaign = (campaign: CampaignModal) => {
    console.log('validateCampaign campaign:', campaign);
    if (!advertiserId && !campaign?.advertiserId) {
      enqueueSnackbar('Informe o anunciante da campanha!', {
        variant: 'error',
      });
      return false;
    }

    if (!campaign?.name) {
      enqueueSnackbar('Informe o nome da campanha!', { variant: 'error' });
      return false;
    }

    if (!campaign?.isTest) {
      if (!campaign?.startDate) {
        enqueueSnackbar('Informe a data de início da campanha!', {  variant: 'error' });
      }
      if (!campaign?.endDate) {
        enqueueSnackbar('Informe a data de final da campanha!', {  variant: 'error' });
      }
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (validateCampaign(campaign)) {
      const newCampaign = {
        ...campaign,
        prospectivePaymentDate: campaign.prospectivePaymentDate
          ? campaign.prospectivePaymentDate.format('YYYY-MM-DD')
          : null,
        paidAt: campaign.paidAt ? campaign.paidAt.format('YYYY-MM-DD') : null,
        approvedAt: campaign.approvedAt
          ? campaign.approvedAt.format('YYYY-MM-DD')
          : null,
        startDate: campaign?.startDate
          ? campaign.startDate
          : null,
        endDate: campaign?.endDate
          ? campaign.endDate
          : null,
      };
      // console.log(newCampaign);
      if (campaign?.id) {
        console.log('newCampaign: ', newCampaign)
        patchCampaigns(campaign.id, newCampaign)
          .then(async () => {
            // console.log('Deu certo');
            enqueueSnackbar('Campanha alterada com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            console.log('Erro ao alterar', error);
            enqueueSnackbar('Erro ao alterar campanha!', { variant: 'error' });
            setLoading(false);
          });
      } else {
        createCampaigns(newCampaign)
          .then(async (result: any) => {
            enqueueSnackbar('Campanha criada com sucesso!', {
              variant: 'success',
            });
            closeModal(`/campaigns/${result?.id}/profile`);
          })
          .catch((error: any) => {
            console.log('Erro ao criar', error);
            enqueueSnackbar('Erro ao criar campanha!', { variant: 'error' });
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  };

  const closeModal = (goToAfterCloseModal?: string | null) => {

    if (advertiserId) {
      navigate(`/advertisers/${advertiserId}/contracts`);
    } else if (goToAfterCloseModal) {
      navigate(goToAfterCloseModal);
    } else {
      navigate(-1);
    }
    
    setLoading(false);
    reloadCampaigns();
    setTimeout(() => {
      setCampaign(initialCampaign);
      setEditCampaign(initialCampaign);
    }, 500);
  };

  const reloadCampaigns = () => {
    if (advertiserId) {
      findCampaigns({
        query: {
          includeBigNumbers: true,
          advertiserId,
        },
      });
    } else if (!advertiserId && campaignId) {
      getCampaign(campaignId, {
        query: { 
          includeAdvertisers: true,
          includeBigNumbers: true,
        }});
    } /*else {
      findCampaigns({
        query: {
          includeCampaignPlaces: true,
        },
      });
    }*/
  }

  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      style={{ overflow: 'scroll' }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box 
        sx={CenterModalStyle} 
        onClick={(e) => e.stopPropagation()}
      >
        <Stack 
          sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }} 
          direction="row" 
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {(!campaign?.id && 'Nova campanha') || 'Editar campanha'}
          </Typography>

          <IconButton className="closeIcon" onClick={() => closeModal()}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box
          component="form"
          sx={{
            display: 'flex', 
            flexWrap: 'wrap',
            '& .MuiTextField-root': { mt: 2, width: '48%' },
          }} 
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          {campaignId && (
            <Typography>Anunciante: {campaign?.advertiser?.name}</Typography>
          )}
          {(!advertiserId && !campaignId && advertisers?.length) && (
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ mt: 0, width: '100% !important' }}
            >
              <Autocomplete
                id="advertiserAutocomplete"
                ref={autocompleteComponent}
                style={{ width: '100% !important' }}
                options={advertisers.map((adv: any) => ({id: String(adv.id), name: adv.name}))}
                getOptionLabel={(option: any) => option?.name || ''}
                // value={String(campaign.advertiserId) || ''}
                // inputValue={selectedAdvertiser || ''}
                // onInputChange={(event, newInputValue) => setSelectedAdvertiser(newInputValue)}
                clearOnBlur={false}
                onChange={(event, value: any) => setCampaign({
                  ...campaign,
                  advertiserId: value?.id || null
                })}
                isOptionEqualToValue={(option: any, value: any) => String(option?.id) === String(value?.id) ? true : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={ref}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100% !important' }}
                    label="Anunciante"
                    id="advertiserId"
                    name="advertiserId"
                  />
                )}
              />
              {/* { advertisers?.length ?
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={advertisers.map((adv: any) => ({id: adv.id, name: adv.name}))}
                  getOptionLabel={(option: any) => option?.name || ''}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Movie" />}
                />
              : null } */}
            </FormControl>
          )}
          <FormControl variant="outlined" sx={{ width: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isTest"
                  id="isTest"
                  checked={!!campaign.isTest}
                  onChange={(event: any) =>
                    setCampaign({
                      ...campaign,
                      isTest: event.target.checked,
                    })
                  }
                />
              }
              label="Campanha de Teste"
            />
          </FormControl>
          <FormControl variant="outlined" sx={{ width: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isFinished"
                  id="isFinished"
                  checked={!!campaign.isFinished}
                  onChange={(event: any) =>
                    setCampaign({
                      ...campaign,
                      isFinished: event.target.checked,
                    })
                  }
                />
              }
              label="Campanha Finalizada"
            />
          </FormControl>
         
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ mt: 1, width: '100%'}}
          >
            <TextField
              label="Nome do Campanha"
              name="name"
              id="name"
              sx={{ width: '98% !important', ml: '1%' }}
              value={campaign.name || ''}
              onChange={handleInputChange}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          { !campaign?.isTest && (
            <>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ mt: 2, width: '100% !important' }}
              >
                <InputLabel 
                  shrink 
                  style={{ 
                    backgroundColor: '#fff', 
                    paddingLeft: 5, 
                    paddingRight: 5 
                  }} 
                  id="demo-simple-select-outlined-label"
                >
                  Status
                </InputLabel>
                  <Select
                  labelId="demo-simple-select-outlined-label"
                  sx={{ width: '98% !important', ml: '1%' }}
                  id="status"
                  name="status"
                  value={campaign.status || ''}
                  onChange={handleInputChange}
                  label="Objetivo"
                  input={<OutlinedInput />}
                  required
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {statusList.map((stt: any) => (
                    <MenuItem value={String(stt.value)}>
                      {stt.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ mt: 2, width: '100% !important' }}
              >
                <InputLabel 
                  shrink 
                  style={{ 
                    backgroundColor: '#fff', 
                    paddingLeft: 5, 
                    paddingRight: 5 
                  }} 
                  id="demo-simple-select-outlined-label"
                >
                  Objetivo
                </InputLabel>
                  <Select
                  labelId="demo-simple-select-outlined-label"
                  sx={{ width: '98% !important', ml: '1%' }}
                  id="goal"
                  name="goal"
                  value={campaign.goal || ''}
                  onChange={handleInputChange}
                  label="Objetivo"
                  input={<OutlinedInput />}
                  required
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {goalsList.map((goal: any) => (
                    <MenuItem value={String(goal.id)}>
                      {goal.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Nome Taxonômico"
                name="taxonomyName"
                id="taxonomyName"
                fullWidth
                sx={{ m: '1%' }}
                value={campaign.taxonomyName || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Código PI"
                name="codPi"
                id="codPi"
                sx={{ ml: '1%' }}
                value={campaign.codPi || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <DateTimePicker
                label="Data Início"
                inputFormat="DD/MM/YYYY HH:mm"
                value={campaign.startDate}
                onChange={(date) => setCampaign({ ...campaign, startDate: date })}
                renderInput={(params) => (
                  <TextField
                    name="startDate"
                    id="startDate"
                    sx={{ m: '1%' }}
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
              <DateTimePicker
                label="Data Final"
                inputFormat="DD/MM/YYYY HH:mm"
                value={campaign.endDate}
                onChange={(date) => setCampaign({ ...campaign, endDate: date })}
                renderInput={(params) => (
                  <TextField
                    name="endDate"
                    id="endDate"
                    sx={{ ml: '1%' }}
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
              <TextField
                label="Total de Views (Objetivo)"
                value={campaign.viewsGoal}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: {
                    thousandSeparator: '.',
                    decimalSeparator: ',',
                    decimalScale: 0,
                    variant: 'outlined',
                    onValueChange: (vals: ValueChange) =>
                      setCampaign({
                        ...campaign,
                        viewsGoal: vals.floatValue,
                      })
                  },
                }}
                sx={{ m: '1%' }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Custo por Impressão (R$)"
                value={campaign.viewCost}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: {
                    thousandSeparator: '.',
                    decimalSeparator: ',',
                    valueIsNumericString: true,
                    decimalScale: 4,
                    variant: 'outlined',
                    onValueChange: (vals: ValueChange) =>
                      setCampaign({
                        ...campaign,
                        viewCost: vals.floatValue,
                      })
                  },
                }}
                sx={{ ml: '1%' }}
                InputLabelProps={{ shrink: true }}
              />
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ mt: 2, width: '100% !important' }}
              >
                <InputLabel 
                  shrink 
                  style={{ 
                    backgroundColor: '#fff', 
                    paddingLeft: 5, 
                    paddingRight: 5 
                  }} 
                  id="demo-simple-select-outlined-label"
                >
                  Tipo de Clique
                </InputLabel>
                  <Select
                  labelId="demo-simple-select-outlined-label"
                  sx={{ width: '98% !important', ml: '1%' }}
                  id="ctaType"
                  name="ctaType"
                  value={campaign.ctaType || ''}
                  onChange={handleInputChange}
                  label="Tipo de Clique"
                  input={<OutlinedInput />}
                  required
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="internal"> Interno </MenuItem>
                  <MenuItem value="external"> Externo </MenuItem>
                </Select>
              </FormControl>
              {campaign?.ctaType === 'external' && (
                <TextField
                  label="URL Clique"
                  name="urlCta"
                  id="urlCta"
                  sx={{ m: '1%' }}
                  value={campaign.urlCta || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              <TextField
                label="Texto Clique"
                name="textCta"
                id="textCta"
                sx={{ m: '1%' }}
                value={campaign.textCta || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Cor da campanha"
                name="colorStyle"
                id="colorStyle"
                type="color"
                sx={{ m: '1%' }}
                value={campaign.colorStyle ? `#${campaign.colorStyle}` : ''}
                onChange={handleInputColorChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Cor do fundo"
                name="backgroundStyle"
                id="backgroundStyle"
                type="color"
                sx={{ m: '1%' }}
                value={campaign.backgroundStyle ? `#${campaign.backgroundStyle}` : ''}
                onChange={handleInputColorChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </>
          )}      
         
          <TextField
            label="URL Exemplo Redirect (Cardapio)"
            name="urlRedirectExample"
            id="urlRedirectExample"
            sx={{ m: '1%' }}
            value={campaign.urlRedirectExample || ''}
            onChange={handleInputChange}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />

          <Stack sx={{ width: '100%' }} direction="row" justifyContent="flex-end">
            <Button variant="text" onClick={() => setShowAvancedSettings(!showAvancedSettings)}>
              { showAvancedSettings ? 'Ocultar Opções Avançadas' : 'Mostrar Opções Avançadas'}
            </Button>   
          </Stack>
          <Box 
            sx={{
              '& .MuiTextField-root': { mt: 2, width: '48%' },
              display: showAvancedSettings ? 'block' : 'none'
            }}  
          >
            <DesktopDatePicker
              label="Aprovado em"
              inputFormat="DD/MM/YYYY"
              value={campaign.approvedAt}
              onChange={(date) =>
                setCampaign({ ...campaign, approvedAt: date })
              }
              renderInput={(params) => (
                <TextField
                  name="approvedAt"
                  id="approvedAt"
                  sx={{ m: '1%' }}
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
            <DesktopDatePicker
              label="Pagamento Previsto"
              inputFormat="DD/MM/YYYY"
              value={campaign.prospectivePaymentDate}
              onChange={(date) =>
                setCampaign({ ...campaign, prospectivePaymentDate: date })
              }
              renderInput={(params) => (
                <TextField
                  name="prospectivePaymentDate"
                  id="prospectivePaymentDate"
                  sx={{ m: '1%' }}
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
            <DesktopDatePicker
              label="Pago em"
              inputFormat="DD/MM/YYYY"
              value={campaign.paidAt}
              onChange={(date) => setCampaign({ ...campaign, paidAt: date })}
              renderInput={(params) => (
                <TextField
                  name="paidAt"
                  id="paidAt"
                  sx={{ m: '1%' }}
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
            <TextField
              label="Tempo de espera pro anuncio"
              name="waitTime"
              id="waitTime"
              sx={{ m: '1%' }}
              value={campaign.waitTime || ''}
              onChange={handleInputChange}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Tag Track"
              name="tagTrack"
              id="tagTrack"
              sx={{ m: '1%' }}
              value={campaign.tagTrack || ''}
              onChange={handleInputChange}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Tag Script"
              name="tagScript"
              id="tagScript"
              sx={{ m: '1%' }}
              value={campaign.tagScript || ''}
              onChange={handleInputChange}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Box>  
          <FormControl fullWidth sx={{ m: 1 }}>
            <Divider sx={{ width:'100%'}} />
          </FormControl>
          <FormControl variant="outlined" sx={{ m: 1, width: '100%' }}>
            <>
              {campaign?.newFileCover || campaign?.urlCover ? (
                <img
                  style={{ marginTop: 15}}
                  src={
                    campaign?.newFileCover
                      ? (campaign?.newFileCover as string)
                      : (campaign?.urlCover as string)
                  }
                  alt="Cover campanha"
                />
              ) : null}
              {!campaign?.newFileCover &&
                <Button
                  variant="contained"
                  component="label"
                  sx={{ mt: 2}}
                  startIcon={<PhotoCamera />}
                >
                  Cover da Campanha
                  <input
                    hidden
                    key={Math.random().toString(36)}
                    accept="image/*"
                    onChange={(e) => uploadFileCover(e)}
                    multiple
                    type="file"
                  />
                </Button>
              }
              {campaign?.newFileCover && (
                <Button
                  color="warning"
                  variant="contained"
                  sx={{ mt: 2}}
                  onClick={() =>
                    setCampaign({ ...campaign, newFileCover: null })
                  }
                >
                  Remover imagem
                </Button>
              )}
            </>
          </FormControl>
          <FormControl variant="outlined" sx={{ m: 1, width: '100%' }}>
            <>
              {campaign?.urlFilePi && !campaign.newFilePiName ? (
                <Link
                  to={campaign?.urlFilePi}
                  target='_blank'
                  rel="noreferrer"
                >
                  Download Arquivo PI
                </Link>
              ) : null}
              {!campaign?.newFilePi &&
                <Button
                  variant="contained"
                  component="label"
                  sx={{ mt: 2}}
                  startIcon={<AttachFile />}
                >
                  Novo Arquivo PI
                  <input
                    hidden
                    key={Math.random().toString(36)}
                    accept="application/pdf"
                    onChange={(e) => uploadFilePi(e)}
                    multiple
                    type="file"
                  />
                </Button>
              }
              {campaign?.newFilePiName && (
                <Fragment>
                  <Typography>Arquivo PI selecionado: {campaign?.newFilePiName}</Typography> 
                  <Button
                    color="warning"
                    variant="contained"
                    sx={{ mt: 2}}
                    onClick={() =>
                      setCampaign({ ...campaign, newFilePi: null, newFilePiName: null })
                    }
                  >
                    Remover Arquivo PI
                  </Button>
                </Fragment>
              )}
            </>
          </FormControl>
          <FormControl fullWidth sx={{ m: 1, mt: 3 }}>
            <LoadingButton type="submit" loading={loading} variant="contained">
              <span>
                {(!campaign?.id && 'Criar campanha') || 'Editar campanha'}
              </span>
            </LoadingButton>
          </FormControl>
        </Box>
      </Box>
    </Modal>
  );
});

export default AddCampaignModal;
