import prerollIcon from '../components/img/img-preroll.png';
import highlightIcon from '../components/img/img-highlight.png';
import listIcon from '../components/img/img-list.png';

const getMediaTypeIcon = (mediaType: string) => {
  if (mediaType.includes('listItemTop')) {
    return listIcon;
  }
  if (mediaType.includes('highlight')) {
    return highlightIcon;
  }
  if (mediaType.includes('preRoll')) {
    return prerollIcon;
  }
};

export { getMediaTypeIcon };

