import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../components/Layout';
import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Chip,
  Divider
} from '@mui/material';

import Search from '@mui/icons-material/Search';
import Clear from '@mui/icons-material/Clear';

import Download from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as XLSX from 'xlsx';
import sumBy from 'lodash/sumBy';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { usePlaces } from '../../contexts/usePlaces';
import { useScrapingDatas } from '../../contexts/useScrapingDatas';
import Map from '../../components/Map';
import Table from '../../components/Table';
import { useSnackbar } from 'notistack';

const SegmentPlacesDashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const autocompleteComponent = useRef(null);
  const autocompleteComponentPlaceTypes = useRef(null);
  const autocompleteComponentTags = useRef(null);

  const { places, findPlaces } = usePlaces();
  const { findScrapingDatas } = useScrapingDatas();

  const [ selectedCities, setSelectedCities ] = useState<any>([]);
  const [ citiesInputValue, setCitiesInputValue ] = useState<string>('');

  const [ selectedPlaceTypes, setSelectedPlaceTypes] = useState<any>([]);
  const [ placeTypesInputValue, setPlaceTypesInputValue ] = useState<string>('');

  const [ selectedTags, setSelectedTags] = useState<any>([]);
  const [ tagsInputValue, setTagsInputValue ] = useState<string>('');

  const [ itemName, setItemName ] = useState<string | null>(null);
  const [ scrapItensList, setScrapItensList ] = useState<string[]>([]);

  useEffect(() => {
    findPlaces({query: { includeSegmentPlaces: true }});
  }, []);

  const getUniqueCities = (places: any) => {
    const cities = places.map(({ addressCity }: any) => addressCity);
    const uniqueCities = Array.from(new Set(cities));
    return uniqueCities;
  }

  const getUniquePlaceTypes = (places: any) => {
    const placeTypes = places.map(({ placeType }: any) => placeType?.name);
    const uniquePlaceTypes = Array.from(new Set(placeTypes));
    return uniquePlaceTypes;
  }

  const getUniqueTags= (places: any) => {
    const tagPlaces = [] as any[]
    places.forEach(({ segmentTagPlaces  }: any) => {
      segmentTagPlaces?.forEach(({segmentTag}: any) => {
        tagPlaces.push({
          id: segmentTag?.id, 
          name: segmentTag?.name, 
          group: segmentTag?.segmentTagGroup?.name
        });
      });
    });
  
    const uniqueTagNames = Array.from(new Set(tagPlaces.map(({name}: any) => name)));
    const uniqueTags = uniqueTagNames.map(tag => tagPlaces.find(tagPlace => tagPlace.name === tag));
    return uniqueTags.sort((a, b) => {
      // Ordenar por groupName
      if (a.group !== b.group) {
        return a.group < b.group ? -1 : 1;
      }
    
      // Ordenar por name (apenas se os grupos forem iguais)
      return a.name < b.name ? -1 : 1;
    });;
  }

  const getUniqueItens= (scrapItensList: any) => {
    const itens = scrapItensList.map(({ itemName }: any) => itemName);
    const uniqueItens = Array.from(new Set(itens));
    return uniqueItens;
  }

  const uniqueCities = getUniqueCities(places);
  const uniquePlaceTypes = getUniquePlaceTypes(places);
  const uniqueTags = getUniqueTags(places);
  const uniqueItens = getUniqueItens(scrapItensList);

  const [gridView, setGridView] = useState(false);

  const changeGridView = () => {
    setGridView(true);
  };

  const changeMapView = () => {
    setGridView(false);
  };

  const handleRemoveScrapedItem = (item: any) => {
    const newScrapedItensList = scrapItensList.filter((scrapedItem: any) => scrapedItem.itemName !== item);
    setScrapItensList(newScrapedItensList);
  }
  const clearSegmentDataHandler = () => {
    setItemName(null);
    setScrapItensList([]);
  }

  const getSegmentDataHandler = async (filteredPlaces: any[]) => {
    if (!itemName) {
      enqueueSnackbar('Informe o nome do produto!', { variant: 'error' });
      return;
    }

    if (itemName.length < 3) {
      enqueueSnackbar('O nome do produto deve ter no mínimo 3 letras!', { variant: 'error' });
      return
    }
    const placeIdArray = filteredPlaces.map(({ id }: any) => id);
 
    const itensList = await findScrapingDatas({
      itemName, 
      placeIds: JSON.stringify(placeIdArray),
    });
    if (itensList.length === 0) {
      enqueueSnackbar(`Nenhuma ocorrência de ${itemName} encontrado!`, { variant: 'error' });
      return;
    }
    setScrapItensList(itensList);
  };

  const filterPlaces = (places: any) => {
    let filtredPlaces = places;

    if (selectedCities.length > 0) {
      filtredPlaces = filtredPlaces.filter((place: any) => selectedCities.includes(place.addressCity));
    }

    if (selectedPlaceTypes.length > 0) {
      filtredPlaces = filtredPlaces.filter((place: any) => selectedPlaceTypes.includes(place?.placeType?.name));
    }

    const placedWithScrapedItens = scrapItensList.map((item: any) => item.placeId);

    // if (placedWithScrapedItens.length > 0) {
    //   filtredPlaces = filtredPlaces.filter((place: any) => placedWithScrapedItens.includes(place?.id));
    // }

    filtredPlaces = filtredPlaces.map((place: any) => {
      return {
        ...place,
        markerColor: place.readMenuAt ? (placedWithScrapedItens.includes(place?.id) ? 'green' : 'blue') : 'red',
        haveItem: itemName && placedWithScrapedItens.includes(place?.id) ? true : false,
        itemList: scrapItensList.filter((item: any) => item.placeId === place.id).map((item: any) => item.itemName).join(', ')
      }
    });

    if (selectedTags.length > 0) {
      const selectedTagsIds  = selectedTags.map(({id}: any) => id);
      filtredPlaces = filtredPlaces.filter((place: any) => {
        const mapPlacesTagsId = place?.segmentTagPlaces?.map((sementTag: any) => sementTag.segmentTagId)
        if (selectedTagsIds.some((id: any)=> mapPlacesTagsId.includes(id))) {
          return place;
        }
      });
    }
    return filtredPlaces;
  }

  const exportSegmentTagsPlaces = async (places: any[]) => {
    const placesReport: any = [];
    places.forEach((place: any) => {
      console.log(place);
      const newPlace = {
        'id': place.id,
        'Nome': place.name,
        'Estado': place.addressState,
        'Cidade': place.addressCity,
        'ECD': place.placeGroup?.name,
        'Tipo': place.placeType?.name,
        'Views 30 dias': place.nOfViewsLast30Days || 0,
        'Menu Lido em': place?.readMenuAt ? dayjs(place?.readMenuAt).format('DD/MM/YYYY'): '',
        'Itens menu': place?.itemList,
        'Tags': place.segmentTagPlaces?.map((tagPlace: any) => tagPlace?.segmentTag?.name).join(', ')
      };
      placesReport.push(newPlace);
    })

    const worksheet = XLSX.utils.json_to_sheet(placesReport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Relatorio de Segmentação");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `Thirky - Segmentação Estabelecimentos ${dayjs().format('DD-MM-YYYY HHmm')}.xlsx`);
  };

  let filteredPlaces =  filterPlaces(places);
  const nOfScrapedMenus = filteredPlaces.filter((place: any) => place.readMenuAt).length;
  const nOfPlacesWithItem = filteredPlaces.filter((place: any) => place.haveItem).length;

  const renderAvatar = ({ row }: Partial<GridRowParams>) => (
    <React.Fragment>
      {/* <Link to={`/places/${row?.id}/profile`} style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}> */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 2 }}>
          <Avatar
            alt=""
            src={row.urlImage}
            style={{ marginRight: '1.5rem' }}
          />
          {row?.name}
        </Box>
      {/* </Link> */}
    </React.Fragment>
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 350,
      headerClassName: 'app-theme--header',
      renderCell: renderAvatar,
    },
    {
      field: 'placeGroup',
      headerName: 'ECD',
      width: 180,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row.placeGroup?.name}</span>
      ),
    },
    {
      field: 'addressState',
      headerName: 'Estado',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'addressCity',
      headerName: 'Cidade',
      width: 220,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'placeType',
      headerName: 'Tipo',
      width: 150,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row.placeType?.name}</span>
      ),
    },
    {
      field: 'nOfViewsLast30Days',
      headerName: 'Views 30 dias',
      width: 150,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row?.nOfViewsLast30Days || 0}</span>
      ),
    },
    {
      field: 'readMenuAt',
      headerName: 'Menu Lido em',
      width: 150,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      field: 'itemList',
      headerName: 'Itens menu',
      width: 250,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'tags',
      headerName: 'Tags',
      width: 600,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row.segmentTagPlaces?.map((tagPlace: any) => tagPlace?.segmentTag?.name).join(', ')}</span>
      ),
    },
  ];
  return (
    <Layout removeLeftBar>
      <Stack style={{ margin: '0 auto', width: '90%', maxWidth: '1600px' }}>
        <Typography 
          variant="subtitle2" 
          component={Link} 
          to={`/`}  
          style={{ 
            color: '#FF4C51', 
            textDecoration: 'inherit',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <ArrowBackIcon fontSize='small' />&nbsp;&nbsp;<span>Voltar</span>
        </Typography>

        <Box
          id="campaign-places"
          sx={{ backgroundColor: 'white', borderRadius: '5px', padding: '3rem' }}
        >
          <Stack 
            justifyContent='space-between'
          >
            <Typography
              variant="h6"
              sx={{
                paddingTop: '1.2rem',
                paddingBottom: 3,
              }}
            >
              Segmentação de Estabelecimentos
            </Typography>
            <Box sx={{ height: '500px', width: '100%', marginBottom: '1rem' }}>
              <div style={{ height: '500px' }} className="map">
                <Map markers={filteredPlaces.filter((pl: any) => pl.lat && pl.lng) || []} />
              </div>
            </Box>
          </Stack>
        </Box>
        <Box
          id="campaign-places"
          sx={{ backgroundColor: '#434347', color: 'white', borderRadius: '5px', padding: 10, marginTop: 2 }}
        >
          <Stack flexDirection='row' justifyContent="space-between" alignItems="center" style={{ margin: '1rem'}}>
            <Typography><b>{filteredPlaces.length}</b> estabelecimentos </Typography>
            <Typography><b>{nOfScrapedMenus}</b> menus verificados</Typography>
            {itemName ? <Typography><b>{nOfPlacesWithItem}</b> locais com {itemName}</Typography> : null}
            <Typography><b>{sumBy(filteredPlaces, (place: any) => Number(place.nOfViewsLast30Days))}</b>&nbsp;visualizações/mes: </Typography>
            <Button 
              style={{
                color: 'white',
                borderColor: 'white'
              }} 
              variant='outlined' 
              onClick={() => exportSegmentTagsPlaces(filteredPlaces)} 
              startIcon={<Download />}
            >
              Exportar
            </Button>
          </Stack>
        </Box>

        <Box
          id="campaign-places"
          sx={{ backgroundColor: 'white', borderRadius: '5px', padding: 10, marginTop: 2 }}
        >
          <Stack flexDirection='row' justifyContent="space-between" alignItems="center" style={{ margin: '1rem'}}>
            <FormControl sx={{width: '19%'}}>
              <Autocomplete
                multiple
                disabled={scrapItensList.length > 0}
                style={{ width: '100%', maxWidth: '500px'}}
                ref={autocompleteComponentPlaceTypes}
                id="placeTypeId"
                options={uniquePlaceTypes}
                getOptionLabel={(option: any) => `${option}`}
                onChange={(event, value) => setSelectedPlaceTypes(value)}
                value={[...selectedPlaceTypes].map(placeType => placeType)}
                onInputChange={(event, newInputValue) => setPlaceTypesInputValue(newInputValue)}
                clearOnBlur={false}
                inputValue={placeTypesInputValue || ''}
                isOptionEqualToValue={(option: any, value: any) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100% !important' }}
                    label="Tipos de Estabelecimentos"
                    id="placeTypes"
                    name="placeTypes"
                  />
                )}
              />
              <Typography variant="caption">Tipos: {selectedPlaceTypes.length}</Typography>
            </FormControl>
            <FormControl sx={{width: '19%'}}>
              <Autocomplete
                multiple
                disabled={scrapItensList.length > 0}
                style={{ width: '100%', maxWidth: '500px'}}
                ref={autocompleteComponent}
                id="placeId"
                options={uniqueCities}
                getOptionLabel={(option: any) => `${option}`}
                onChange={(event, value) => setSelectedCities(value)}
                value={[...selectedCities].map(city => city)}
                onInputChange={(event, newInputValue) => setCitiesInputValue(newInputValue)}
                clearOnBlur={false}
                inputValue={citiesInputValue || ''}
                isOptionEqualToValue={(option: any, value: any) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100% !important' }}
                    label="Cidades"
                    id="cities"
                    name="cities"
                  />
                )}
              />
              <Typography variant="caption">Cidades: {selectedCities.length}</Typography>
            </FormControl>
            <FormControl sx={{width: '29%'}}>
              <Autocomplete
                multiple
                disabled={scrapItensList.length > 0}
                style={{ width: '100%', maxWidth: '500px'}}
                ref={autocompleteComponentTags}
                id="tagId"
                groupBy={(option) => option.group}
                options={uniqueTags}
                getOptionLabel={(option: any) => option?.name}
                onChange={(event, value) => setSelectedTags(value)}
                value={[...selectedTags].map(tag => tag)}
                onInputChange={(event, newInputValue) => setTagsInputValue(newInputValue)}
                clearOnBlur={false}
                inputValue={tagsInputValue || ''}
                isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100% !important' }}
                    label="Tags"
                    id="tags"
                    name="tags"
                  />
                )}
              />
              <Typography variant="caption">Tags: {selectedTags.length}</Typography>
            </FormControl>
            {/* <FormControl sx={{width: '29%'}}>
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={{ width: '100% !important' }}
                label="Produto"
                id="itemName"
                name="itemName"
              />
              <Typography variant="caption">Produtos Econtrados: {selectedTags.length}</Typography>
              <div>

              </div>
            </FormControl> */}
            <FormControl sx={{width: '29%'}}>
              <InputLabel shrink={true} htmlFor="outlined-adornment-password">Produto</InputLabel>
              <OutlinedInput
                id="itemName"
                name="itemName"
                onChange={(event: any) => setItemName(event.target.value)}
                value={itemName|| ''}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Buscar produtos"
                      onClick={() => getSegmentDataHandler(filteredPlaces)}
                      edge="end"
                    >
                      <Search />
                    </IconButton>
                    <Divider sx={{ height: 28, ml: 3, mr: 1 }} orientation="vertical" />
                    <IconButton
                      aria-label="Buscar produtos"
                      onClick={() => clearSegmentDataHandler()}
                      edge="end"
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              <Typography variant="caption">Produtos Econtrados: {uniqueItens.length}</Typography>
              
            </FormControl>
          </Stack>
          {uniqueItens.length > 0 && (
            <Stack flexDirection='column' justifyContent="space-between" alignItems="start" style={{ margin: '1rem', overflow: 'auto' }}>
              <Typography sx={{ mb: 1 }}>Produtos Econtrados</Typography>
              <Box>
                {uniqueItens.map((item: any) => 
                  <Chip 
                    sx={{ mr: 1, mt: 1 }} 
                    label={item} 
                    variant="outlined" 
                    onDelete={() => handleRemoveScrapedItem(item)} 
                  />
                )}
              </Box>
            </Stack>
          )}
        </Box>
        <Box
          id="campaign-places"
          sx={{ backgroundColor: 'white', borderRadius: '5px', padding: 10, marginTop: 2 }}
        >
          <Table rows={filteredPlaces} columns={columns} />
        </Box>
      </Stack>
    </Layout>
  );
};

export default SegmentPlacesDashboard;
