import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const TagsContext = createContext();
const initialTag = {
  id: null,
  name: null,
};

export function TagsProvider({ children }) {
  const [tags, setTags] = useState([]);
  const [editTag, setEditTag] = useState(initialTag);

  function getTag(tagId) {
    services.tags.get(tagId).then((response) => {
      // console.log('getTag', response);
      setTags([response]);
    });
  }

  function getEditTag(tagId) {
    services.tags.get(tagId).then((response) => {
      // console.log('getTag', response);
      setEditTag(response);
    });
  }

  function findTags(query = {}) {
    services.tags.find(query).then((response) => {
      // console.log('findTags', response);
      setTags(response.data);
    });
  }

  async function createTags(tag) {
    return services.tags.create(tag).then(() => {
      findTags({ query: { placeId: tag.placeId}});
    });
  }

  async function patchTags(id, tag) {
    return services.tags.patch(id, tag).then(() => {
      setEditTag(initialTag);
      findTags({ query: { placeId: tag.placeId}});
    });
  }

  async function removeTags(tagId) {
    return services.tags.remove(tagId).then(() => {
      findTags();
    });
  }
  return (
    <TagsContext.Provider
      value={{
        tags,
        editTag,
        setEditTag,
        getEditTag,
        findTags,
        createTags,
        patchTags,
        getTag,
        removeTags,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
}

export function useTags() {
  const context = useContext(TagsContext);

  return context;
}
