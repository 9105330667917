import React, { useEffect, useState, useRef, Fragment, ChangeEvent } from 'react';
import {
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  InputLabel,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Stack,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardContent,
  CardActions,
  OutlinedInput,
  ListItemText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { useSnackbar } from 'notistack';
import { VideoCameraBack, PhotoCamera } from '@mui/icons-material';
import { useContracts } from '../../contexts/useContracts';
import { useCampaigns } from '../../contexts/useCampaigns';
import { usePlaces } from '../../contexts/usePlaces';
import { useCampaignMedias } from '../../contexts/useCampaignMedias';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  ContractModal,
  AddModalHandler,
  CampaignFilter,
  ValueChange,
  PlaceFilter,
  CampaignMediaInterface,
  PlaceGroupInterface,
  PlaceInterface
} from '../common/types';
import videoPlaceholder from '../img/video-placeholder.jpg';
import imagePlaceholder from '../img/image-placeholder.jpg';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '90vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const initialContract: ContractModal = {
  id: null,
  placeId: null,
  campaignId: null,
  estimateViews: null,
  viewCost: null,
  prospectivePaymentDate: null,
  approvedAt: null,
  endedAt: null,
  placePaidAt: null,
  startDate: null,
  endDate: null,
  urlCover: null,
  newFileCover: null,
  urlVideo: null,
  newFileVideo: null,
  urlCta: null,
  ctaType: null,
  waitTime: null,
  isPaused: false,
  isDisabled: false,
  mediaType: null,
  campaignMedias: [],
};

const initialCampaignMedia = {
  id: null,
  name: null,
  type: null,
  position: 'top',
  urlCover: null,
  urlVideo: null,
  videoDuration: null,
  newFileVideo: null,
  newFileCover: null,
};

const NumberFormatCustom = (props: any) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NumericFormat {...props} />;
};

const AddContractModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { 
    contractId, 
    advertiserId, 
    campaignId, 
    placeId,
  } = useParams();

  const [loading, setLoading] = useState(false);
  const {
    editContract,
    setEditContract,
    getEditContract,
    createContracts,
    patchContracts,
  } = useContracts();
  const {
    campaignMedias,
    findCampaignMedias,
    createCampaignMedias,
    patchCampaignMedias,
    removeCampaignMedias,
  } = useCampaignMedias();
  const autocompleteComponent = useRef(null);
  const { campaigns, findCampaigns } = useCampaigns();
  const { places, findPlaces } = usePlaces();
  const { findContracts } = useContracts();
  const [selectedPlaces, setSelectedPlaces] = useState<any[]>([]);
  const [ notAddSelectedPlaces, setNotAddSelectedPlaces] = useState<any[]>([]);
  const [enabledPlacesToCampaigMedia, setEnabledPlacesToCampaigMedia] = useState<any[]>([]);
  const [campaignMediaEnableds, setCampaignMediaEnableds] = useState<any[]>([]);
  const [changeMediaTypeDialog, setChangeMediaTypeDialog] = useState<string | null>();
  const [placesInputValue, setPlacesInputValue] = useState<string>('');
  const [showAvancedSettings, setShowAvancedSettings] = useState<boolean>(false);
  const [contract, setContract] = useState<ContractModal>(initialContract);
  const [contractCampaignMedia, setContractCampaignMedia] = useState<CampaignMediaInterface>(initialCampaignMedia);

  const handleInputChange = (event: any) => {
    const { name, value } = event?.target || event;
    setContract({
      ...contract,
      [name]: value,
    });
  };

  const handleInputChangeMediaType = (event: any) => {
    const { value } = event?.target || event;
    if (selectedPlaces.length > 0) {
      setChangeMediaTypeDialog(value);
    } else {
      changeMediaType(event);
    }
  };

  const changeMediaType = (event: any) => {
    const { value } = event?.target || event;
    setContract({
      ...contract,
      mediaType: value,
    });
    if (selectedPlaces.length > 0) {
      setSelectedPlaces([]);
      setChangeMediaTypeDialog(null);
    }
  };

  const handleAddSelectedPlacesByIds = (event: any) => {
    setNotAddSelectedPlaces([])
    const notSelectedPlaces: any[] = []
    if (event?.key === 'Enter') {
      const placesList: Array<string> = placesInputValue?.split(',') || [];
      console.log('placesList', placesList.length)

      const newSelectedPlaces = []
      for (const placeIndex in placesList) {
        const newPlaceId = placesList[placeIndex];
        const placesDuplicated = selectedPlaces.find(place => place?.id == newPlaceId)
        if (!placesDuplicated) {
          const newPlace = places?.find((place: any) => place?.id == newPlaceId);
          if (newPlace) {
            newSelectedPlaces.push(newPlace)
          } else {
            notSelectedPlaces.push({ id: newPlaceId, reason: 'not_active' })
          }
        } else {
          notSelectedPlaces.push({ id: newPlaceId, reason: 'duplicated' })
        }
      }
      setNotAddSelectedPlaces(notSelectedPlaces)
      setSelectedPlaces([
        ...selectedPlaces,
        ...newSelectedPlaces,
      ])
      setPlacesInputValue('');
    }
  };

  useEffect(() => {
    if (advertiserId) {
      findCampaigns({query: { advertiserId, includeCampaignMedias: true }});
    } else if (campaignId) {
      findCampaigns({
        query: {
          id: campaignId,
          advertiserId,
          includeCampaignMedias: true,
          includeAdvertisers: true,
        },
      });
    }
    
    findPlaces({
      query: {
        enabledCampaignsAt: {
          $gte: '2000-01-01',
        }
      }
    });
  }, []);

  useEffect(() => {
    if (contractId && !contract?.id) {
      getEditContract(contractId);
      findCampaignMedias({ query: { contractId, campaignId: 'null' } });
    }
  }, [contractId]);

  useEffect(() => {
    if (campaignMedias.length && contractId) {
      const campaignMedia = campaignMedias.find((campaignMedia: CampaignMediaInterface) => campaignMedia?.contractId === Number(contractId));
      setContractCampaignMedia(campaignMedia);
    }
  }, [contractId, campaignMedias]);

  useEffect(() => {
    if (campaignId) {
      setContract({
        ...contract,
        campaignId: Number(campaignId)
      });
    }
  }, [campaignId]);

  useEffect(() => {
    if (editContract.id && editContract?.id !== contract?.id) {
      setContract({
        ...editContract,
        prospectivePaymentDate: editContract.prospectivePaymentDate
          ? dayjs(editContract.prospectivePaymentDate, 'YYYY-MM-DD')
          : null,
        placePaidAt: editContract.placePaidAt
          ? dayjs(editContract.placePaidAt, 'YYYY-MM-DD')
          : null,
        approvedAt: editContract.approvedAt
          ? dayjs(editContract.approvedAt, 'YYYY-MM-DD')
          : null,
        startDate: editContract.startDate
          ? dayjs(editContract.startDate)
          : null,
        endDate: editContract.endDate ? dayjs(editContract.endDate) : null,
      });
    }
  }, [editContract]);

  useEffect(() => {
    const selectedCampaign = campaigns.find((cmp: any) => cmp.id == campaignId || cmp.id == contract.campaignId)
   
    const filtredCampaignMedias = selectedCampaign?.campaignMedias?.filter((cmp: any) => !cmp.contractId)
    const campaignMediasTypes: any = []
    if (filtredCampaignMedias) {
      filtredCampaignMedias.forEach((media: any) => {
        if (media.type === 'preRoll') {
          campaignMediasTypes.push({ value: 'preRoll', label: 'PréRoll', name: media.name })
        }
        if (media.type === 'highlight') {
          campaignMediasTypes.push({ value: 'highlight', label: 'Destaque', name: media.name  })
        }
        if (media.type === 'listItemTop') {
          campaignMediasTypes.push({ value: 'listItemTop', label: 'Lista Topo', name: media.name  })
        }
        if (media.type === 'listItemBottom') {
          campaignMediasTypes.push({ value: 'listItemBottom', label: 'Lista Rodapé', name: media.name  })
        }
      })
    }
    setCampaignMediaEnableds(campaignMediasTypes)
  }, [campaignId, contract?.campaignId, campaigns]);

  useEffect(() => {
    if (contract?.mediaType) {
      let placesEnableds = []
      if (contract?.mediaType === 'preRoll') {
        placesEnableds = places.filter((place: PlaceFilter) => !place?.placeGroup || place?.placeGroup?.acceptPreRoll === true)
      }
      if (contract?.mediaType === 'highlight') {
        placesEnableds = places.filter((place: PlaceFilter) => place?.placeGroup?.acceptHighlight === true)
      }
      if (contract?.mediaType === 'listItemTop') {
        placesEnableds = places.filter((place: PlaceFilter) => place?.placeGroup?.acceptListItemTop === true)
      }
      if (contract?.mediaType === 'listItemBottom') {
        placesEnableds = places.filter((place: PlaceFilter) => place?.placeGroup?.acceptListItemBottom === true)
      }
      
      setEnabledPlacesToCampaigMedia(placesEnableds)
    }
  }, [places, contract?.mediaType]);

  const validateContract = (contract: any) => {
    if (!contract?.campaignId) {
      enqueueSnackbar('Informe a campanha!', {
        variant: 'error',
      });
      return false;
    }

    if (!contract?.mediaType) {
      enqueueSnackbar('Informe o tipo de mídia!', {
        variant: 'error',
      });
      return false;
    }

    if (!contract?.startDate) {
      enqueueSnackbar('Informe a data de início!', {
        variant: 'error',
      });
      return false;
    }

    if (!contract?.endDate) {
      enqueueSnackbar('Informe a data de término!', {
        variant: 'error',
      });
      return false;
    }



    return true;
  };

  const uploadFileCover = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setContractCampaignMedia({
      ...contractCampaignMedia,
      newFileCover: base64 ? base64 as string : null,
    });
  };

  const uploadFileVideo = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setContractCampaignMedia({
      ...contractCampaignMedia,
      newFileVideo: base64 ? base64 as string : null,
    });
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const placeAcceptsMediaType = (place: PlaceInterface) => {
    if (!place?.placeGroupId) {
      return true
    }
    if (contract?.mediaType === 'preRoll') {
      return place?.acceptAllPreRoll
    }
    if (contract?.mediaType === 'highlight') {
      return place?.acceptAllHighlight
    }
    if (contract?.mediaType === 'listItemTop') {
      return place?.acceptAllListItemTop
    }
    if (contract?.mediaType === 'listItemBottom') {
      return place?.acceptAllListItemBottom
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (validateContract(contract)) {
      const newContract = {
        ...contract,
        prospectivePaymentDate: contract.prospectivePaymentDate
          ? contract.prospectivePaymentDate.format('YYYY-MM-DD')
          : null,
        placePaidAt: contract.placePaidAt ? contract.placePaidAt.format('YYYY-MM-DD') : null,
        // endedAt: contract.endedAt
        //   ? contract.endedAt.format('YYYY-MM-DD')
        //   : null,
        approvedAt: contract.approvedAt
          ? contract.approvedAt.format('YYYY-MM-DD')
          : null,
        startDate: contract.startDate
          ? contract.startDate
          : null,
        endDate: contract.endDate
          ? contract.endDate
          : null,
      };

      if (contract?.id) {
        patchContracts(contract.id, newContract)
          .then(() => {

            submitCampaignMedias()
            enqueueSnackbar('Contrato alterado com sucesso!', {
              variant: 'success',
            });
            reloadContracts();
            closeModal();
          })
          .catch((error: any) => {

            enqueueSnackbar('Erro ao alterar contrato!', { variant: 'error' });
          });
      } else {
        let newContracts: any[] = [];

        selectedPlaces.forEach((place) => {
          newContracts.push({
            ...newContract,
            placeId: place.id,
            placeGroupId: place.placeGroupId,
            approvedAt: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
            splitPlace: place?.splitPlace ? place?.splitPlace : place?.placeGroup?.splitPlace,
            splitPlaceGroup: place?.splitPlaceGroup ? place?.splitPlaceGroup : place?.placeGroup?.splitPlaceGroup,
          });
        });
        createContracts(newContracts)
          .then(() => {
            enqueueSnackbar('Contrato criado com sucesso!', {
              variant: 'success',
            });
            setLoading(false);
            reloadContracts();
            closeModal(); 
          })
          .catch((error: any) => {
            console.log()
            enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 10000 });
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  };

  const submitCampaignMedias = async () => {
    if (contractCampaignMedia?.id)  {
      if (
        !contractCampaignMedia.newFileCover && 
        !contractCampaignMedia.newFileVideo && 
        !contractCampaignMedia.urlCover && 
        !contractCampaignMedia.urlVideo
      ) {
        await removeCampaignMedias(contractCampaignMedia.id);
      } else {
        await patchCampaignMedias(contractCampaignMedia.id, contractCampaignMedia);
      }
    } else {
      if (contractCampaignMedia?.newFileCover || contractCampaignMedia?.newFileVideo) {
        await createCampaignMedias({
          ...contractCampaignMedia, 
          type: contract?.mediaType,
          campaignId: contract.campaignId, 
          contractId});
      }
    }
  };

  const reloadContracts = () => {
    if (advertiserId) {
      findContracts({
        query: {
          includeCampaignPlaces: true,
          includeBigNumbers: true,
          advertiserId,
        },
      });
    } else if (campaignId) {
      findContracts({
        query: {
          includeCampaignPlaces: true,
          includeBigNumbers: true,
          campaignId
        },
      });
    } else if (placeId) {
      findContracts({
        query: {
          includeCampaignPlaces: true, 
          includeBigNumbers: true,
          placeId
        },
      });
    } else {
      findContracts({
        query: {
          includeCampaignPlaces: true,
        },
      });
    }
  };

  const closeModal = () => {
    navigate(-1);
    setTimeout(() => {
      setContract(initialContract);
      setEditContract(initialContract);
      setContractCampaignMedia(initialCampaignMedia);
    }, 500);
  };

  const selectedCampaign = campaigns.find((cmp: any) => cmp.id == campaignId || cmp.id == contract.campaignId);
  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      style={{ overflow: 'scroll' }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CenterModalStyle} onClick={(e) => e.stopPropagation()}>
        <Stack 
          sx={{ width: '100%', paddingTop: 2, paddingBottom: 3 }} 
          direction="row" 
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {(!contract?.id && 'Novo contrato') || 'Editar contrato'}
          </Typography>

          <IconButton className="closeIcon" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Box
          component="form"
          sx={{
            display: 'flex', 
            flexWrap: 'wrap',
            '& .MuiTextField-root': { mt: 2, width: '49%' },
          }}  
          noValidate
          autoComplete="off"
          onSubmit={e => { e.preventDefault() }}
        >
          {!campaignId ? (
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ mt: 2, minWidth: 120 }}
            >
              <InputLabel>Campanha</InputLabel>
              <Select
                id="campaignId"
                name="campaignId"
                value={contract.campaignId || ''}
                onChange={handleInputChange}
                label="Selecione a campanha"
                required
                disabled={contractId ? true : false}
              >
                <MenuItem value="">Selecione</MenuItem>
                {campaigns.map((campaign: CampaignFilter) => (
                  <MenuItem value={String(campaign.id)} key={campaign.id}>
                    {campaign.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography>Campanha: {selectedCampaign?.name}</Typography>
          )}
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ mt: 2, minWidth: 120 }}
          >
            <InputLabel>Tipo de Midia</InputLabel>
            <Select
              id="mediaType"
              name="mediaType"
              value={contract.mediaType || ''}
              onChange={handleInputChangeMediaType}
              label="Selecione o formato da mídia"
              required
              disabled={contractId ? true : false}
            >
              <MenuItem value="">Selecione</MenuItem>
              {campaignMediaEnableds?.map((mediaType: any) => 
                <MenuItem value={mediaType.value} key={mediaType.value}>
                  <ListItemText primary={mediaType.name} secondary={mediaType.label} />
                </MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            variant="outlined"
            sx={{ mt: 2, width: '100%'}}
          >
            {!contract?.id ? (
              <Fragment>
                <Autocomplete
                  multiple
                  style={{ width: '100%' }}
                  ref={autocompleteComponent}
                  id="placeId"
                  options={enabledPlacesToCampaigMedia}
                  getOptionLabel={(option: any) => `${option?.name} cod: ${option?.id}`}
                  onChange={(event, value) => setSelectedPlaces(value)}
                  value={[...selectedPlaces].map(place => place)}
                  onInputChange={(event, newInputValue) => setPlacesInputValue(newInputValue)}
                  onKeyPress={(event) => handleAddSelectedPlacesByIds(event)}
                  clearOnBlur={false}
                  inputValue={placesInputValue || ''}
                  isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: '100% !important' }}
                      label="Estabelecimento"
                      id="placeId"
                      name="placeId"
                    />
                  )}
                />
                <Typography variant="caption">
                  Estabelecimentos selecionados: {selectedPlaces.length}
                </Typography>
              </Fragment>
            ) : (
              <React.Fragment>
                <InputLabel>Estabelecimento</InputLabel>
                <Select
                  id="placeId"
                  name="placeId"
                  value={contract.placeId || ''}
                  onChange={handleInputChange}
                  label="Selecione a estabelecimento"
                  required
                  disabled
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {places.map((place: PlaceFilter) => (
                    <MenuItem value={String(place.id)} key={place.id}>
                      {place.name}
                    </MenuItem>
                  ))}
                </Select>
              </React.Fragment>
            )}
          </FormControl>
          <Box sx={{ mt: 2, width: '100%' }}>
            {notAddSelectedPlaces.length > 0 && (
              <Typography variant="subtitle1" >
                Estabelecimentos não adicionados ({notAddSelectedPlaces.length})&nbsp;&nbsp;
                <IconButton style={{ width: 25, height: 25 }} className="closeIcon" onClick={() => setNotAddSelectedPlaces([])}>
                  <CloseIcon />
                </IconButton>
              </Typography>
            )}
            {notAddSelectedPlaces.map((notAddSelectedPlace: any, index: number) => (
              <div>
                <Typography key={index} variant="subtitle2">
                  Código <b>{notAddSelectedPlace.id}</b> - {notAddSelectedPlace.reason === 'duplicated' ? 'Duplicado' : 'Não ativado na ECD'}&nbsp;&nbsp;
                  <Link to={`/places/${notAddSelectedPlace.id}/profile`} style={{ textDecoration: 'none' }} target='_blank'>
                    Ver
                  </Link>
                </Typography>
              </div>
            ))}
          </Box>
          <TextField
            label="Quantidade estimada de Impressões"
            value={contract.estimateViews}
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                thousandSeparator: '.',
                decimalSeparator: ',',
                decimalScale: 0,
                variant: 'outlined',
                onValueChange: (vals: ValueChange) =>
                  handleInputChange({
                    name: 'estimateViews',
                    value: vals.floatValue,
                  }),
              },
              disableUnderline: true,
            }}
            sx={{ mr: '1%' }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Valor impressão (R$)"
            value={contract.viewCost}
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                thousandSeparator: '.',
                decimalSeparator: ',',
                valueIsNumericString: true,
                decimalScale: 4,
                variant: 'outlined',
                onValueChange: (vals: ValueChange) =>
                  handleInputChange({
                    name: 'viewCost',
                    value: vals.floatValue,
                  }),
              },
              disableUnderline: true,
            }}
            sx={{ ml: '1%' }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="% ECD"
            value={contract.splitPlaceGroup}
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                thousandSeparator: '.',
                decimalSeparator: ',',
                valueIsNumericString: true,
                decimalScale: 2,
                variant: 'outlined',
                onValueChange: (vals: ValueChange) =>
                  handleInputChange({
                    name: 'splitPlaceGroup',
                    value: vals.floatValue,
                  }),
              },
              disableUnderline: true,
            }}
            sx={{ mr: '1%' }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="% Estabelecimento"
            value={contract.splitPlace}
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                thousandSeparator: '.',
                decimalSeparator: ',',
                valueIsNumericString: true,
                decimalScale: 2,
                variant: 'outlined',
                onValueChange: (vals: ValueChange) =>
                  handleInputChange({
                    name: 'splitPlace',
                    value: vals.floatValue,
                  }),
              },
              disableUnderline: true,
            }}
            sx={{ ml: '1%' }}
            InputLabelProps={{ shrink: true }}
          />
          <DateTimePicker
            label="Data Início"
            inputFormat="DD/MM/YYYY HH:mm"
            value={contract.startDate}
            onChange={(date) => setContract({ ...contract, startDate: date })}
            renderInput={(params) => (
              <TextField
                name="startDate"
                id="startDate"
                sx={{ mr: '1%' }}
                InputLabelProps={{ shrink: true }}
                {...params}
              />
            )}
          />
          <DateTimePicker
            label="Data Final"
            inputFormat="DD/MM/YYYY HH:mm"
            value={contract.endDate}
            onChange={(date) => setContract({ ...contract, endDate: date })}
            renderInput={(params) => (
              <TextField
                name="endDate"
                id="endDate"
                sx={{ ml: '1%' }}
                InputLabelProps={{ shrink: true }}
                {...params}
              />
            )}
          />

          <div style={{ marginTop: 15, width: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isDisabled"
                  id="isDisabled"
                  checked={!!contract.isDisabled}
                  onChange={(event) =>
                    setContract({
                      ...contract,
                      isDisabled: event.target.checked,
                    })
                  }
                />
              }
              label="Pausado pela operação"
            />
          </div>
          <div style={{ width: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isPaused"
                  id="isPaused"
                  checked={!!contract.isPaused}
                  onChange={(event) =>
                    setContract({
                      ...contract,
                      isPaused: event.target.checked,
                    })
                  }
                />
              }
              label="Pausada pelo Estabelecimento"
            />
          </div>
          <Stack sx={{ width: '100%' }} direction="row" justifyContent="flex-end">
            <Button variant="text" onClick={() => setShowAvancedSettings(!showAvancedSettings)}>
              { showAvancedSettings ? 'Ocultar Opções Avançadas' : 'Mostrar Opções Avançadas'}
            </Button>   
          </Stack>
          <Box 
            sx={{
              '& .MuiTextField-root': { mt: 2, width: '49%' },
              display: showAvancedSettings ? 'block' : 'none'
            }}  
          >

            <DesktopDatePicker
              label="Pagamento Previsto"
              inputFormat="DD/MM/YYYY"
              value={contract.prospectivePaymentDate}
              onChange={(date) =>
                setContract({ ...contract, prospectivePaymentDate: date })
              }
              renderInput={(params) => (
                <TextField
                  name="prospectivePaymentDate"
                  id="prospectivePaymentDate"
                  sx={{ mr: '1%' }}
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
            <DesktopDatePicker
              label="Aprovado em"
              inputFormat="DD/MM/YYYY"
              value={contract.approvedAt}
              onChange={(date) =>
                setContract({ ...contract, approvedAt: date })
              }
              renderInput={(params) => (
                <TextField
                  name="approvedAt"
                  id="approvedAt"
                  sx={{ ml: '1%' }}
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
            <DesktopDatePicker
              label="Finalizado em"
              inputFormat="DD/MM/YYYY"
              value={contract.endedAt}
              onChange={(date) => setContract({ ...contract, endedAt: date })}
              renderInput={(params) => (
                <TextField
                  name="endedAt"
                  id="endedAt"
                  sx={{ mr: '1%' }}
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
            <DesktopDatePicker
              label="Pago em"
              inputFormat="DD/MM/YYYY"
              value={contract.placePaidAt}
              onChange={(date) => setContract({ ...contract, placePaidAt: date })}
              renderInput={(params) => (
                <TextField
                  name="placePaidAt"
                  id="placePaidAt"
                  sx={{ ml: '1%' }}
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ mt: 2, width: '100% !important' }}
              >
                <InputLabel 
                  shrink 
                  style={{ 
                    backgroundColor: '#fff', 
                    paddingLeft: 5, 
                    paddingRight: 5 
                  }} 
                  id="demo-simple-select-outlined-label"
                >
                  Tipo de Clique
                </InputLabel>
                  <Select
                  labelId="demo-simple-select-outlined-label"
                  sx={{ width: '100% !important' }}
                  id="ctaType"
                  name="ctaType"
                  value={contract.ctaType || ''}
                  onChange={handleInputChange}
                  label="Tipo de Clique"
                  input={<OutlinedInput />}
                  required
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="internal"> Interno </MenuItem>
                  <MenuItem value="external"> Externo </MenuItem>
                </Select>
              </FormControl>
            <TextField
              label="URL Clique"
              name="urlCta"
              id="urlCta"
              sx={{ mr: '1%' }}
              value={contract.urlCta || ''}
              onChange={handleInputChange}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Tempo de espera pro contrato"
              name="waitTime"
              id="waitTime"
              sx={{ ml: '1%' }}
              value={contract.waitTime || ''}
              onChange={handleInputChange}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            { contractId && (
              <Box sx={{ mt: 2, width: '100%' }}>
                <Typography variant="h6" component="h2">
                  Midia
                </Typography>
                <FormControl variant="outlined" sx={{ m: 1, width: '100%' }}>
                  <TextField
                    fullWidth
                    label="Nome da midia"
                    name="mediaName"
                    id="mediaName"
                    sx={{ mr: '1%', width: '100% !important' }}
                    value={contractCampaignMedia?.name || null}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => 
                      setContractCampaignMedia({...contractCampaignMedia, name: event.target.value}
                    )}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
                <Stack flexDirection='row' sx={{ width: '100%' }}>
                  <FormControl variant="outlined" sx={{ m: 1, width: '50%' }}>
                    <>
                      <Card sx={{  width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mr: 1, mt: 1}}>
                        {contractCampaignMedia?.newFileCover || contractCampaignMedia?.urlCover ? (
                          <Fragment>
                            <CardContent>
                              <img
                                style={{ width: '100%' }}
                                src={
                                  contractCampaignMedia?.newFileCover
                                    ? (contractCampaignMedia?.newFileCover ? contractCampaignMedia?.newFileCover as string : undefined)
                                    : (contractCampaignMedia?.urlCover ? contractCampaignMedia?.urlCover as string : undefined)
                                }
                                alt="Cover campanha"
                              />
                            </CardContent>
                            <CardActions>
                              <Button
                                sx={{ mt: 2, width: '100%' }}
                                onClick={() =>
                                  setContractCampaignMedia({ ...contractCampaignMedia, urlCover: null, newFileCover: null })
                                }
                              >
                                Excluir
                              </Button>
                            </CardActions>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <CardContent>
                              <img
                                style={{ width: '100%' }}
                                src={imagePlaceholder}
                                alt="Cover campanha"
                              />
                            </CardContent>
                            <CardActions>
                              <Button
                              variant="contained"
                              component="label"
                              sx={{ mt: 2, width: '100%' }}
                              startIcon={<PhotoCamera />}
                              >
                                Imagem
                                <input
                                  hidden
                                  key={Math.random().toString(36)}
                                  accept="image/*"
                                  onChange={(e) => uploadFileCover(e)}
                                  type="file"
                                />
                              </Button>
                            </CardActions>
                          </Fragment>
                        )}
                      </Card>
                    </>
                  </FormControl>
                  <FormControl variant="outlined" sx={{ m: 1, width: '50%' }}>
                    <Card sx={{  width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mr: 1, mt: 1}}>
                      {(contractCampaignMedia?.newFileVideo || contractCampaignMedia?.urlVideo) ? (
                        <Fragment>
                          <CardContent>
                            <video  style={{ marginTop: 15, width: '100%'}} controls>
                              <source
                                type="video/mp4"
                                src={
                                  contractCampaignMedia?.newFileVideo
                                    ? (contractCampaignMedia?.newFileVideo as string)
                                    : (contractCampaignMedia?.urlVideo as string)
                                }
                              />
                            </video>
                            <TextField
                              label="Duração do vídeo (segundos)"
                              name="preRollMediaViewDuration"
                              id="preRollMediaViewDuration"
                              sx={{ mr: '1%', width: '100% !important' }}
                              value={contractCampaignMedia?.videoDuration || null}
                              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                setContractCampaignMedia({...contractCampaignMedia, videoDuration: Number(event.target.value) || null }
                              )}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </CardContent>
                          <CardActions>
                            <Button
                              sx={{ mt: 2, width: '100%' }}
                              onClick={() =>
                                setContractCampaignMedia({ ...contractCampaignMedia, urlVideo: null, newFileVideo: null })
                              }
                            >
                              Excluir
                            </Button>
                          </CardActions>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <CardContent style={{ width: '100%' }}>
                            <img
                              style={{ width: '100%' }}
                              src={videoPlaceholder}
                              alt="Cover campanha"
                            />
                            <TextField
                              fullWidth
                              label="Duração do vídeo (segundos)"
                              name="listItemMediaViewDuration"
                              id="listItemMediaViewDuration"
                              sx={{ mr: '1%', width: '100% !important' }}
                              value={contractCampaignMedia?.videoDuration || null}
                              onChange={(event: ChangeEvent<HTMLInputElement>) => 
                                setContractCampaignMedia({...contractCampaignMedia, videoDuration: Number(event.target.value)}
                              )}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </CardContent>
                          <CardActions>
                            <Button
                            variant="contained"
                            component="label"
                            sx={{ mt: 2, width: '100%' }}
                            startIcon={<VideoCameraBack />}
                            >
                              Vídeo
                              <input
                                hidden
                                key={Math.random().toString(36)}
                                accept="video/*"
                                onChange={(e) => uploadFileVideo(e)}
                                multiple
                                type="file"
                              />
                            </Button>
                          </CardActions>
                        </Fragment>
                      )}
                    </Card> 
                  </FormControl>
                </Stack>
              </Box>
            )}
          </Box>
          <FormControl variant="outlined" sx={{ mt: 3 }} fullWidth>
            <LoadingButton onClick={handleSubmit} loading={loading} variant="contained">
              <span>
                {(!contract?.id && 'Criar contrato') || 'Editar contrato'}
              </span>
            </LoadingButton>
          </FormControl>
        </Box>
        <Dialog
          open={changeMediaTypeDialog ? true : false}
          onClose={() => setChangeMediaTypeDialog(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Alterar formato de mídia?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{color: 'red'}}>Ao alterar o formato de midia a lista de estabelecimentos do contrato será .</div>
              <div>Deseja alterar o formato de mídia?</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setChangeMediaTypeDialog(null)}>Cancelar</Button>
            <Button onClick={() => changeMediaType({ value: changeMediaTypeDialog })} autoFocus>
              Alterar Formato de Mídia
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
};

export default AddContractModal;