import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import Table from '../components/Table';
import MenuBar from '../components/MenuBar';
import Navigate from '../components/Navigate';
import { useUsers } from '../contexts/useUsers';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Link, Outlet, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import PhoneNumber from '../components/PhoneNumber';
import { Chip } from '@mui/material';
import { BreadcrumbType } from '../components/common/types';

const UserView = () => {
  const location = useLocation();
  const { users, findUsers } = useUsers();
  const [query, setQuery] = useState('');

  //Fix to not remove insertion point after typing a letter
  const filterData = (query: string, data: any) => {
    if (!query) {
      return data;
    } else {
      return data.filter(({ name }: any) =>
        name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };

  const dataFiltered = filterData(query, users);

  const findUsersList = () => {
    // console.log('find users');
    findUsers({query: {
      $sort: {
        name: 1,
      },
    }});
  };

  useEffect(() => {
    findUsersList();
  }, []);

  const columns: GridColDef[] = [
    // {
    //   field: 'id',
    //   headerName: 'ID',
    //   width: 70,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'name',
      headerName: 'Nome',
      width: 230,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link to={`/users/edit/${row?.id}`} state={{ background: location }}  style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}>
          {row?.name}
        </Link>
      ),
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 350,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <PhoneNumber value={row.phone} />
      ),
    },
    {
      field: 'isEnabled',
      headerName: 'Status',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!row?.isEnabled ? (
            <Chip label="Inativo" color="error"/>
          ) : (
            <Chip label="Ativo" color="success" />
          )}
        </React.Fragment>
      ),
    },
    // {
    //   field: 'permission',
    //   headerName: 'Permission',
    //   width: 130,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
  ];

  const breadcrumbsList: BreadcrumbType[] = [
    {
      link: '/users',
      label: 'Lista de Usuários'
    },
  ]

  return (
    <Layout>
      <Navigate pageTitle="Lista de Usuarios" breadcrumbs={breadcrumbsList} />
      <MenuBar
        addUrl="/users/create"
        AddButtonText="Adicionar"
        query={query}
        setQuery={setQuery}
      />
      <Table height={700} rows={dataFiltered} columns={columns} />
      <Outlet />
    </Layout>
  );
};

export default UserView;
