import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import * as XLSX from 'xlsx';
import Layout from '../components/Layout';
import Table from '../components/Table';
import MenuBar from '../components/MenuBar';
import Navigate from '../components/Navigate';
import { usePlaces } from '../contexts/usePlaces';
import { useExportReports } from '../contexts/useExportReports';
import { usePlaceGroups } from '../contexts/usePlaceGroups';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, InputAdornment, InputLabel, Menu, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { BreadcrumbType, PlaceGroupInterface } from '../components/common/types';
import { KeyboardArrowDown } from '@mui/icons-material';

const menuBarStyle = {
  // position: 'relative',
  // display: 'flex',
  // justifyContent: 'space-between',
  bgcolor: 'background.paper',
  height: '75px',
  zIndex: 4,
  alignItems: 'center',
  gridColumnEnd: 'auto',
  padding: '0 1rem',
};

const AddUserStyle = {
  width: '200px',
  gridTemplateColumns: '1fr 5fr',
  gridGap: '5px',
  mr: 1,
};

const PlaceView = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const { findExportReports } = useExportReports();
  const { places, findPlaces } = usePlaces();
  const { placeGroups, findPlaceGroups } = usePlaceGroups();
  const [ placeGroupId, setPlaceGroupId ] = useState("");
  const [ placeIsEnabled, setPlaceIsEnabled ] = useState<boolean>(true);
  const [ loadingPlaceAccessesResume, setLoadingPlaceAccessesResume ] = useState<boolean>(false);
  const [ loadingPlacesRequestsByPlaceGroupResume, setLoadingPlacesRequestsByPlaceGroupResume ] = useState<boolean>(false);
  const [ loadingPlacesData, setLoadingPlacesData ] = useState<boolean>(false);

  const findPlacesList = (isEnabled: boolean) => {
    findPlaces({
      query: {
        includePlacesBigNumbers: true,
        $sort: {
          name: 1,
        },
        isEnabled,
      }
    });
  };

  useEffect(() => {
    findPlacesList(placeIsEnabled);
    findPlaceGroups()
    // console.log({places})
  }, []);

  useEffect(() => {
    findPlacesList(placeIsEnabled);
  }, [placeIsEnabled]);

  const [query, setQuery] = useState('');

  const filterData = (query: string, data: any) => {
    const dataPlaceGroupFiltered = placeGroupId ? filterDataByPlaceGroup(placeGroupId, data) : data;
    const dataNameFiltered = query ? filterDataByName(query, dataPlaceGroupFiltered) : dataPlaceGroupFiltered;
    return dataNameFiltered;
  };

  const filterDataByPlaceGroup = (placeGroupId: string, data: any) => {
    return data.filter(({ placeGroup }: any) => String(placeGroup?.id) === String(placeGroupId))
  }

  const filterDataByName = (query: string, data: any) => {
    return data.filter(({ name }: any) => name.toLowerCase().includes(query.toLowerCase()));
  }

  const downloadExportPlaceAccessesResume = async () => {
    setLoadingPlaceAccessesResume(true);
    if(!loadingPlaceAccessesResume) {
      const places = await findExportReports({
        query: {
          reportType: 'place-accesses-resume',
        }
      });
      setLoadingPlaceAccessesResume(false);
      console.log('reportDatas', places);
      const placesReport: any = [];
      places.forEach((place: any) => {
        const newPlace = {
          'id': place.id,
          'CNPJ': place.cnpj,
          'Nome Fantasia': place.name,
          'Razão Social': place.legalName,
          'Bairro': place.addressNeighborhood,
          'Cidade': place.addressCity,
          'Estado': place.addressState,
          'ECD': place.placeGroupName,
          'Slug': place.placeGroupKey,
          'Url do Menu': place?.urlMenu,
          
          'Media Impr. 7 Dias': Number(place?.avg7Days || 0),
          'Total Impr. 7 Dias': place?.sum7Days || 0,
          'Media Impr. 30 Dias': Number(place?.avg30Days || 0),
          'Total Impr. 30 Dias': place?.sum30Days || 0,

          'Media Sessoes 7 Dias': Number(place?.avgSessions7Days || 0),
          'Total Sessoes 7 Dias': place?.sumSessions7Days || 0,
          'Media Sessoes 30 Dias': Number(place?.avgSessions30Days || 0),
          'Total Sessoes 30 Dias': place?.sumSessions30Days || 0,

          'Media Usuarios 7 Dias': Number(place?.avgUsers7Days || 0),
          'Total Usuarios 7 Dias': place?.sumUsers7Days || 0,
          'Media Usuarios 30 Dias': Number(place?.avgUsers30Days || 0),
          'Total Usuarios 30 Dias': place?.sumUsers30Days || 0,

          'Ultima leitura': place.lastAccess ? dayjs(place.lastAccess).format('DD/MM/YYYY') : '',
          'Data Cadastro': dayjs(place.createdAt).format('DD/MM/YYYY HH:mm'),
        };
        placesReport.push(newPlace);
      })
      console.log(placesReport)
      const worksheet = XLSX.utils.json_to_sheet(placesReport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Relatorio de Dimensionamento");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `Thirky - Dimensionamento Resumido ${dayjs().format('DD-MM-YYYY HHmm')}.xlsx`);
    }
  };

  const downloadExportPlacesRequestsByPlaceGroupResume = async () => {
    setLoadingPlacesRequestsByPlaceGroupResume(true);
    if(!loadingPlacesRequestsByPlaceGroupResume) {
      const placeGroups = await findExportReports({
        query: {
          reportType: 'places-requests-by-place-group-resume',
        }
      });
      setLoadingPlacesRequestsByPlaceGroupResume(false);
      console.log('reportDatas', places);
      const placeGroupsReport: any = [];
      placeGroups.forEach((placeGroup: any) => {
        const newPlaceGroup = {
          'ECD': placeGroup.ecdName,
          'Viws monetizadas': placeGroup.campaignViews,
          'Leituras 7 Dias': placeGroup.views7Days,
          'Locais com cadastro': placeGroup.placesExists,
          'Leituras com cadastro': placeGroup.placeExistsViews,
          'Locais sem cadastro': placeGroup.placesNotExists,
          'Leituras sem cadastro': placeGroup.placeNotExistsViews,
        };
        placeGroupsReport.push(newPlaceGroup);
      })
      console.log(placeGroupsReport)
      const worksheet = XLSX.utils.json_to_sheet(placeGroupsReport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Relatorio de Dimensionamento");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `Thirky - Indicadores Leituras Estabelecimentos EDC ${dayjs().format('DD-MM-YYYY HHmm')}.xlsx`);
    }
  };

  const downloadPlacesData = async () => {
    setLoadingPlacesData(true);
    if(!loadingPlacesData) {
      const placesData = await findExportReports({
        query: {
          reportType: 'places-data',
        }
      });
      setLoadingPlacesData(false);
      console.log('reportDatas', placesData);
      const placesDataReport: any = [];
      placesData.forEach((pl: any) => {
        const newPlace = {
          'Id': pl.id,
          'Nome': pl.name,
          'Razao Social': pl.legal_name,
          'Tipo':	pl.place_type,
          'Instagram': pl.instagram,	
          'Endereço': pl.address,	
          'CEP': pl.addres_cep,	
          'Bairro': pl.addres_neighborhood,	
          'Cidade': pl.addres_city,	
          'Estado': pl.addres_state,	
          'ECD': pl.ecd,	
          'Slug': pl.slug,	
          'URL Menu': pl.url_menu,	
          'URL Imagem': pl.url_image,	
          'PIX': pl.pix,	
          'Latitude': pl.lat,	
          'Longitude': pl.lng,
        };
        placesDataReport.push(newPlace);
      })
      console.log(placesDataReport)
      const worksheet = XLSX.utils.json_to_sheet(placesDataReport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Estabelecimentos");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `Thirky - Dados dos Estabelecimentos ${dayjs().format('DD-MM-YYYY HHmm')}.xlsx`);
    }
  };

  const dataFiltered = filterData(query, places);

  const renderAvatar = ({ row }: Partial<GridRowParams>) => (
    <React.Fragment>
      <Link to={`/places/${row?.id}/profile`} style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 2 }}>
          <Avatar
            alt=""
            src={row.urlImage}
            style={{ marginRight: '1.5rem' }}
          />
          {row?.name}
        </Box>
      </Link>
    </React.Fragment>
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 350,
      headerClassName: 'app-theme--header',
      renderCell: renderAvatar,
    },
    {
      field: 'placeGroup',
      headerName: 'ECD',
      width: 230,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row.placeGroup?.name}</span>
      ),
    },
    {
      field: 'enabledCampaignsAt',
      headerName: 'Autorização ADS',
      width: 330,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!row?.enabledCampaignsAt ? (
            <Chip label="Inativo" color="error" />
          ) : (
            <Chip label={`Autorizado em ${dayjs(row?.enabledCampaignsAt).format('DD/MM/YY')}`} color="success" />
          )}
        </React.Fragment>
      ),
    },
    {
      field: 'nOfViewsLast30Days',
      headerName: 'Leituras 30 Dias',
      width: 130,
      type: "number",
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{Number(row.nOfViewsLast30Days || 0)}</span>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
  ];

  const breadcrumbsList: BreadcrumbType[] = [
    {
      link: '/places',
      label: 'Lista de Estabelecimentos'
    },
  ]

  return (
    <Layout>
      <Navigate pageTitle="Lista de Estabelecimentos" breadcrumbs={breadcrumbsList} />
      {/* <MenuBar
        addUrl="/places/create"
        AddButtonText="Adicionar"
        query={query}
        setQuery={setQuery}
      /> */}
      <Stack sx={menuBarStyle} flexDirection='row' justifyContent='flex-end'>
        <FormControlLabel
          control={
            <Checkbox
              name="placeIsEnabled"
              id="placeIsEnabled"
              checked={!!placeIsEnabled}
              onChange={(event: any) =>
                setPlaceIsEnabled(event.target.checked)
              }
            />
          }
          label="Ativos"
        />
        <FormControl variant="outlined" sx={AddUserStyle} size= 'small'>
          <InputLabel 
            id="demo-simple-select-outlined-label"
            shrink
            style={{ 
              backgroundColor: '#fff', 
              paddingLeft: 5, 
              paddingRight: 5 
            }} 
          >
            ECD
          </InputLabel>

          <Select
            labelId="placeGroupId"
            id="placeGroupId"
            name="placeGroupId"
            value={String(placeGroupId || '')}
            onChange={ (event: any) => setPlaceGroupId(event.target.value)}
            label="ECD"
            input={<OutlinedInput />}
            required
          >
            <MenuItem selected value="">Todos</MenuItem>
            {placeGroups.map((placeGroup : PlaceGroupInterface) => (
              <MenuItem key={placeGroup.id} value={String(placeGroup.id)}>
                {placeGroup.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <OutlinedInput
          id="search"
          size= 'small'
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          placeholder= 'Pesquisar'
          autoFocus
          autoComplete='off'
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Link
          to="/places/create"
          state={{ background: location }}
          style={{ textDecoration: 'none', marginLeft: 10 }}
        >
          <Button variant="contained" sx={AddUserStyle}>
            <AddIcon />
            <Typography>Adicionar</Typography>
          </Button>
        </Link>
        <Button 
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{ marginLeft: '1rem' }}
          variant="outlined"
          onClick={handleClick}
          endIcon={<KeyboardArrowDown />}
        >
          {/* <PublishIcon
            style={{ fontSize: 'medium', marginRight: '0.5rem' }}
          /> */}
          Exportar
        </Button>
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          // anchorEl={anchorEl}
          // open={openExportMenu}
          // onClose={() => setOpenExportMenu(false)}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem 
            onClick={downloadExportPlaceAccessesResume} 
            disabled={loadingPlaceAccessesResume}
            disableRipple
          >
            { loadingPlaceAccessesResume ? 'Aguarde...' : 'Dimensionamento Resumido' }
          </MenuItem>
          <MenuItem
            onClick={downloadExportPlacesRequestsByPlaceGroupResume}
            disabled={loadingPlacesRequestsByPlaceGroupResume}
            disableRipple
          >
            Leituras Estabelecimentos EDC
          </MenuItem>
          <MenuItem
            onClick={downloadPlacesData}
            disabled={loadingPlacesData}
            disableRipple
          >
            Dados do Estabelecimento
          </MenuItem>
        </Menu>
        <Link
          to="/places/import"
          state={{ background: location }}
          style={{ textDecoration: 'none', marginLeft: 10 }}
        >
          <Button variant="outlined" sx={AddUserStyle}>
            <AddIcon />
            <Typography>Importar Lote</Typography>
          </Button>
        </Link>
      </Stack>
      <Table rows={dataFiltered} columns={columns} />
      <Typography 
        variant='subtitle2' 
        style={{ color: 'gray', paddingBottom: 10 }}
      >
        * Dados atualizados diariamente às 00:01
      </Typography>
      <Outlet />
    </Layout>
  );
};

export default PlaceView;
