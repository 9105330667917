import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const UserLogsContext = createContext();

export function UserLogsProvider({ children }) {
  const [userLogs, setUserLogs] = useState([]);
  function findUserLogs(query = {}) {
    return services.userLogs.find(query).then((response) => {
      setUserLogs(response);
    });
  }

  return (
    <UserLogsContext.Provider
      value={{
        findUserLogs,
        userLogs,
      }}
    >
      {children}
    </UserLogsContext.Provider>
  );
}

export function useUserLogs() {
  const context = useContext(UserLogsContext);

  return context;
}
