import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useCampaigns } from '../../contexts/useCampaigns';
import { ScheduleInterface } from '../common/types';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers';

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '90vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const initialSchedule: ScheduleInterface = {
  id: null,
  activeDays: [],
  startTime: null,
  endTime: null,
  isDeletad: false,
  isDisabled: false,
}

const AddPlaceZoneModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { campaignId, campaignScheduleId } = useParams();
  const { 
    getCampaign,
    getEditCampaign,
    setEditCampaign,
    editCampaign,
    patchCampaigns,
  } =  useCampaigns();
  const [schedule, setSchedule] = useState<ScheduleInterface>(initialSchedule);
  const [ campaign, setCampaign ] = useState<any>(null);

  const handleEnableAllDays = (event: any) => {
    if (event.target.checked) {
      setSchedule({
        ...schedule,
        activeDays: ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'],
      });
    } else {
      setSchedule({
        ...schedule,
        activeDays: [],
      });
    }
  }

  const handleChangeActiveDays = (event: any, day: string) => {
    const dayIsEnabled = event.target.checked;
    const { activeDays } = schedule;
    let newActiveDays = activeDays ? [...activeDays] : [];

    if (dayIsEnabled) {
      newActiveDays?.push(day);
    } else {
      newActiveDays = newActiveDays?.filter((item) => item !== day);
    }
    setSchedule({
      ...schedule,
      activeDays: newActiveDays,
    });
  }

  useEffect(() => {
    if (campaignId && !campaign?.id) {
      getEditCampaign(campaignId);
    }
  }, [campaignScheduleId]);

  useEffect(() => {
    if (editCampaign.id && editCampaign?.id !== campaign?.id) {
      setCampaign(editCampaign);
      const newSchedule = editCampaign?.schedules?.find((item: any) => item.id === campaignScheduleId)
      console.log('newSchedule: ', newSchedule);
      setSchedule(newSchedule ? newSchedule : initialSchedule);
    }
  }, [editCampaign]);

  const validateSchedule = (schedule: ScheduleInterface) => {
    if (!schedule.activeDays?.length && !schedule?.startTime && !schedule?.endTime) {
      enqueueSnackbar('Informe os dias ou horarios para o agendamento!', { variant: 'error' });
      return false;
    }
    if (schedule?.activeDays?.length === 0) {
      enqueueSnackbar('Informe os dias para o agendamento!', { variant: 'error' });
      return false;
    }


    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log('schedule: ', schedule);
    if (validateSchedule(schedule)) {
      const { schedules } = campaign;
      const newSchedules = schedules?.filter((item: any) => item.id !== schedule.id) || [];
      newSchedules.push({
        ...schedule,
        id: schedule.id ? schedule.id : uuidv4(),
      });
      patchCampaigns(campaignId, { schedules: newSchedules })
        .then(() => {
          // console.log('Deu certo');
          enqueueSnackbar('Agendamento alterado com sucesso!', {
            variant: 'success',
          });
          closeModal();
        })
        .catch((error: any) => {
          // console.log('Erro ao alterar', error);
          enqueueSnackbar('Erro ao alterar zona!', { variant: 'error' });
        });
    }
  };

  const handleDelete = async () => {
    const { schedules } = campaign;
      const newSchedules = schedules?.filter((item: any) => item.id !== schedule.id) || [];
      patchCampaigns(campaignId, { schedules: newSchedules })
      .then(() => {
        // console.log('Deu certo');
        enqueueSnackbar('Agendamento excluido com sucesso!', {
          variant: 'success',
        });
        closeModal();
      })
      .catch((error: any) => {
        // console.log('Erro ao alterar', error);
        enqueueSnackbar('Erro ao ecluir agendamento!', { variant: 'error' });
      });
  }

  const closeModal = () => {
    setSchedule(initialSchedule);
    setEditCampaign({});
    getCampaign(campaignId, {
      query: { 
        includeAdvertisers: true,
        includeBigNumbers: true,
      }});
    navigate(-1);
  };

  return (
    <Modal
      open={true}
      // onClose={() => setSchedule(initialSchedule)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CenterModalStyle} onClick={(e) => e.stopPropagation()}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Adicionar Agendamento
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton className="closeIcon" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <FormControl variant="outlined" sx={{ width: '100%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isFinished"
                  id="isFinished"
                  checked={schedule.activeDays?.length === 7}
                  onChange={(event: any) => handleEnableAllDays(event)}
                />
              }
              label="Todos os dias"
            />
          </FormControl>
          <FormControl variant="outlined" sx={{ width: '100% !important', mb: '35px !important' }}>
            <Box>
              <FormControlLabel
                sx={{ width: '110px' }}
                control={
                  <Checkbox
                    name="seg"
                    id="seg"
                    checked={schedule.activeDays?.includes('seg')}
                    onChange={(event: any) => handleChangeActiveDays(event, 'seg')}
                  />
                }
                label="Segunda"
              />
              <FormControlLabel
                sx={{ width: '110px' }}
                control={
                  <Checkbox
                    name="ter"
                    id="ter"
                    checked={schedule.activeDays?.includes('ter')}
                    onChange={(event: any) => handleChangeActiveDays(event, 'ter')}
                  />
                }
                label="Terça"
              />
            <FormControlLabel
              sx={{ width: '110px' }}
              control={
                <Checkbox
                  name="qua"
                  id="qua"
                  checked={schedule.activeDays?.includes('qua')}
                  onChange={(event: any) => handleChangeActiveDays(event, 'qua')}
                />
              }
              label="Quarta"
            />
            <FormControlLabel
              sx={{ width: '110px' }}
              control={
                <Checkbox
                  name="qui"
                  id="qui"
                  checked={schedule.activeDays?.includes('qui')}
                  onChange={(event: any) => handleChangeActiveDays(event, 'qui')}
                />
              }
              label="Quinta"
            />
            {/* </Box>  
            <Box> */}
              <FormControlLabel
                sx={{ width: '110px' }}
                control={
                  <Checkbox
                    name="sex"
                    id="sex"
                    checked={schedule.activeDays?.includes('sex')}
                    onChange={(event: any) => handleChangeActiveDays(event, 'sex')}
                  />
                }
                label="Sexta"
              />
              <FormControlLabel
                sx={{ width: '110px' }}
                control={
                  <Checkbox
                    name="sab"
                    id="sab"
                    checked={schedule.activeDays?.includes('sab')}
                    onChange={(event: any) => handleChangeActiveDays(event, 'sab')}
                  />
                }
                label="Sabado"
              />
              <FormControlLabel
                sx={{ width: '110px' }}
                control={
                  <Checkbox
                    name="dom"
                    id="dom"
                    checked={schedule.activeDays?.includes('dom')}
                    onChange={(event: any) => handleChangeActiveDays(event, 'dom')}
                  />
                }
                label="Domingo"
              />
            </Box>
          </FormControl>
          <TimePicker
            label="Hora Inicio"
            ampm={false}
            value={schedule.startTime || null}
            onChange={(newValue: any) => setSchedule({ ...schedule, startTime: newValue })}
            renderInput={(params) => <TextField sx={{ width: '45%' }} {...params} />}
          />
          <TimePicker
            label="Hora Final"
            ampm={false}
            value={schedule.endTime || null}
            onChange={(newValue: any) => setSchedule({ ...schedule, endTime: newValue })}
            renderInput={(params) => <TextField sx={{ width: '45%' }} {...params} />}
          />
          <FormControl variant="outlined" sx={{ mt: 3 }} fullWidth>
            <Button variant="contained" type="submit">
              <AddIcon style={{ marginRight: '1rem' }} />
              {(!schedule?.id && 'Adicionar agendamento') || 'Editar agendamento'}
            </Button>
          </FormControl>
          {schedule?.id && (
            <FormControl variant="outlined" sx={{ mt: 3 }} fullWidth>
              <Button onClick={handleDelete} color='error' variant="contained" type="button">
                <AddIcon style={{ marginRight: '1rem' }} />
                Excluir
              </Button>
            </FormControl>
          )}

          </Box>

      </Box>
    </Modal>
  );
};

export default AddPlaceZoneModal;
