import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Layout from '../../components/Layout';
import Table from '../../components/Table';
import MenuBar from '../../components/MenuBar';
import Navigate from '../../components/Navigate';
import { usePlaceGroups } from '../../contexts/usePlaceGroups';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Avatar, Box, Chip, Typography } from '@mui/material';
import { BreadcrumbType } from '../../components/common/types';
import numeral from 'numeral';

const PlaceGroupView = () => {
  const location = useLocation();
  const { placeGroups, findPlaceGroups } = usePlaceGroups();
  const [ formatedPlaceGroups, setFormatedPlaceGroups ] = useState([]);

  const findPlaceGroupsList = () => {
    findPlaceGroups({query: {
      includeBigNumbers: true,
      $sort: {
        name: 1,
      },
    }});
  };

  const transformPlaceGroupsNumbers = (placeGroups: any) => {
    const newPlaceGroups = placeGroups.map((placeGroup: any) => {
      return {
        ...placeGroup,
        nOfActivePlaces: Number(placeGroup.nOfActivePlaces || 0),
        nOfPlaces: Number(placeGroup.nOfPlaces || 0),
        nOfViewsLast30Days: Number(placeGroup.nOfViewsLast30Days || 0),
        nOfViewsLast7Days: Number(placeGroup.nOfViewsLast7Days || 0),
      }
    })
    setFormatedPlaceGroups(newPlaceGroups);
  }

  useEffect(() => {
    findPlaceGroupsList();
  }, []);

  useEffect(() => {
    transformPlaceGroupsNumbers(placeGroups);
  }, [placeGroups]);

  const [query, setQuery] = useState('');

  const filterData = (query: string, data: any) => {
    if (!query) {
      return data;
    } else {
      return data.filter(({ name }: any) =>
        name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };

  const dataFiltered = filterData(query, formatedPlaceGroups);

  const renderAvatar = ({ row }: Partial<GridRowParams>) => (
    <React.Fragment>
      <Link
        to={`/placeGroups/${row?.id}/profile`}
        style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 2 }}>
          <Avatar alt="" src={row?.urlImage} style={{ marginRight: '1.5rem' }} />
          {row?.name}
        </Box>
      </Link>
    </React.Fragment>
  );

  const renderOptionsButton = ({ row }: Partial<GridRowParams>) => (
    <React.Fragment>
      <Link state={{ background: location }} to={`/placeGroups/edit/${row?.id}`}>Editar ECD</Link>
    </React.Fragment>
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 250,
      // resizable: true,
      headerClassName: 'app-theme--header',
      renderCell: renderAvatar,
    },
    // {
    //   field: 'nOfActivePlaces',
    //   headerName: 'Estabelecimentos',
    //   width: 150,
    //   // resizable: true,
    //   headerClassName: 'app-theme--header',
    // },
    // {
    //   field: 'nOfPlaces',
    //   headerName: 'Estabelecimentos Ativos',
    //   width: 150,
    //   // resizable: true,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'isEnabled',
      headerName: 'Status',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!row?.isEnabled ? (
            <Chip label="Inativo" color="error" />
          ) : (
            <Chip label="Ativo" color="success" />
          )}
        </React.Fragment>
      ),
    },
    {
      field: 'nOfPlaces',
      headerName: 'Locais',
      width: 125,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfActivePlaces',
      headerName: 'Locais Ativos',
      width: 125,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfViewsLast30Days',
      headerName: '30 Dias',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{numeral(row.nOfViewsLast30Days).format('0,0')}</span>
      ),
    },
    {
      field: 'nOfViewsLast7Days',
      headerName: '7 Dias',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{numeral(row.nOfViewsLast7Days).format('0,0')}</span>
      ),
    },
    {
      field: 'avgViewsLast7Days',
      headerName: 'Media 7 Dias',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{numeral(row.nOfViewsLast7Days/7).format('0,0')}</span>
      ),
    },
    {
      field: 'groupKey',
      headerName: 'Key',
      width: 125,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 180,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
    // {
    //   field: 'options',
    //   headerName: '',
    //   width: 170,
    //   headerClassName: 'app-theme--header',
    //   renderCell: renderOptionsButton,
    // },
  ];

  const breadcrumbsList: BreadcrumbType[] = [
    {
      link: '/placeGroups',
      label: 'Lista de ECDs'
    },
  ]

  return (
    <Layout>
      <Navigate pageTitle="Lista de ECDs" breadcrumbs={breadcrumbsList} />
      <MenuBar
        addUrl="/placeGroups/create"
        AddButtonText="Adicionar"
        query={query}
        setQuery={setQuery}
      />
      <Table height={700} rows={dataFiltered} columns={columns} />
      <Typography
        variant='subtitle2' 
        style={{ color: 'gray' }}
      >
        * Dados atualizados diariamente às 00:01
      </Typography>
      <Outlet />
    </Layout>
  );
};

export default PlaceGroupView;
