import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const PlaceGroupsContext = createContext();
const initialPlaceGroup = {
  id: null,
  groupKey: null,
  acceptPreRoll: null,
  acceptHighlight: null,
  acceptCarousel: null,
};

export function PlaceGroupsProvider({ children }) {
  const [placeGroups, setPlaceGroups] = useState([]);
  const [dashPlaceGroup, setDashPlaceGroup] = useState(initialPlaceGroup);
  const [editPlaceGroup, setEditPlaceGroup] = useState(initialPlaceGroup);

  function getPlaceGroup(placeGroupId, query = {}) {
    services.placeGroups.get(placeGroupId, query).then((response) => {
      // console.log('getPlaceGroup', response);
      setPlaceGroups([response]);
    });
  }

  function getDashPlaceGroup(placeGroupId, query = {}) {
    services.placeGroups.get(placeGroupId, query).then((response) => {
      // console.log('getPlaceGroup', response);
      setDashPlaceGroup(response);
    });
  }

  function getEditPlaceGroup(placeGroupId) {
    services.placeGroups.get(placeGroupId).then((response) => {
      // console.log('getPlaceGroup', response);
      setEditPlaceGroup(response);
    });
  }

  function findPlaceGroups(query = {}) {
    services.placeGroups.find(query).then((response) => {
      // console.log('findPlaceGroups', response);
      setPlaceGroups(response.data);
    });
  }

  function findHashPlaceGroups(hash) {
    services.placeGroups.find({query: { hash }}).then((response) => {
      // console.log('findPlaceGroups', response);
      setPlaceGroups(response.data);
    });
  }


  async function createPlaceGroups(placeGroup) {
    return services.placeGroups.create(placeGroup).then((result) => {
      return result;
    });
  }

  async function patchPlaceGroups(id, placeGroup) {
    return services.placeGroups.patch(id, placeGroup).then(() => {
      setEditPlaceGroup(initialPlaceGroup);
      findPlaceGroups({ query: { placeId: placeGroup.placeId}});
    });
  }

  async function removePlaceGroups(placeGroupId) {
    return services.placeGroups.remove(placeGroupId).then(() => {
      findPlaceGroups();
    });
  }
  return (
    <PlaceGroupsContext.Provider
      value={{
        placeGroups,
        editPlaceGroup,
        dashPlaceGroup,
        findHashPlaceGroups,
        setEditPlaceGroup,
        getEditPlaceGroup,
        getDashPlaceGroup,
        findPlaceGroups,
        createPlaceGroups,
        patchPlaceGroups,
        getPlaceGroup,
        removePlaceGroups,
      }}
    >
      {children}
    </PlaceGroupsContext.Provider>
  );
}

export function usePlaceGroups() {
  const context = useContext(PlaceGroupsContext);

  return context;
}
