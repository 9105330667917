import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const ContractsContext = createContext();
const initialContract = {
  id: null,
  placeId: null,
  campaignId: null,
  viewCost: null,
  estimateViews: null,
  prospectivePaymentDate: null,
  approvedAt: null,
  endedAt: null,
  placePaidAt: null,
  startDate: null,
  endDate: null,
  urlCta: null,
  urlVideo: null,
  urlCover: null,
  waitTime: null,
  isPaused: null,
  isDisabled: null,
};

export function ContractsProvider({ children }) {
  const [contracts, setContracts] = useState([]);
  const [editContract, setEditContract] = useState(initialContract);

  function getContract(contractId) {
    services.contracts.get(contractId).then((response) => {
      // console.log('getContract', response);
      setContracts([response]);
    });
  }

  function getEditContract(contractId) {
    services.contracts.get(contractId).then((response) => {
      // console.log('getContract', response);
      setEditContract(response);
    });
  }

  function findContracts(query = {}) {
    services.contracts.find(query).then((response) => {
      // console.log('findContracts', response);
      setContracts(response.data);
    });
  }

  async function createContracts(contract) {
    return services.contracts.create(contract).then(() => {
      // findContracts({ query: { includeCampaignPlaces: true } });
    });
  }

  async function patchContracts(id, contract) {
    return services.contracts.patch(id, contract).then(() => {
      setEditContract(initialContract);
      // findContracts({ query: { includeCampaignPlaces: true } });
    });
  }

  async function removeContracts(contractId) {
    return services.contracts.remove(contractId).then(() => {
      findContracts({ query: { includeCampaignPlaces: true } });
    });
  }
  return (
    <ContractsContext.Provider
      value={{
        contracts,
        editContract,
        setEditContract,
        getEditContract,
        findContracts,
        createContracts,
        patchContracts,
        getContract,
        removeContracts,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
}

export function useContracts() {
  const context = useContext(ContractsContext);

  return context;
}
