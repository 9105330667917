import React, { SyntheticEvent, useEffect, useState, useRef } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import numeral from 'numeral';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useParams, Outlet, Link, useLocation } from 'react-router-dom';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SearchIcon from '@mui/icons-material/Search';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import dayjs from 'dayjs';

import * as XLSX from 'xlsx';

import { getMediaTypeIcon } from '../../utils/media-type-icons';
import TitleTabs from '../../components/TitleTabs';
import Layout from '../../components/Layout';
import Table from '../../components/Table';
import MenuBar from '../../components/MenuBar';
import Navigate from '../../components/Navigate';

import { useContracts } from '../../contexts/useContracts';
import { useCampaigns } from '../../contexts/useCampaigns';
import { useCampaignMedias } from '../../contexts/useCampaignMedias';

import videoPlaceholder from '../../components/img/video-placeholder.jpg';
import imagePlaceholder from '../../components/img/image-placeholder.jpg';

import {
  BreadcrumbType,
  CampaignFilter,
  CampaignMediaInterface,
  ContractFilter,
} from '../../components/common/types';
import { 
  AccessTimeOutlined, 
  AttachMoneyOutlined, 
  CampaignOutlined, 
  CheckCircleOutlineOutlined, 
  DoNotDisturbOutlined, 
  EditOutlined, 
  EmojiEventsOutlined, 
  FmdGoodOutlined, 
  MapOutlined, 
  RocketLaunchOutlined,
  CalendarMonthOutlined,
  Speed,
  CheckBox
} from '@mui/icons-material';
import QR_CODE_CLIENT_URL from '../../api/qrCodeClientUrl';
import { FormattedNumber } from 'react-intl';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useExportReports } from '../../contexts/useExportReports';
import { set } from 'lodash';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const initialCampaign: CampaignFilter = {
  id: null,
  name: null,
  urlCover: undefined,
  advertiserId: undefined,
  schedules: [],
};

const bignumbersCardsStyle = {
  p: 2, 
  width: '18%', 
  height: 120, 
  backgroundColor: '#f7f7f7', 
  borderRadius: 1 
}

const optionsChart = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false,
      // text: 'Visualização Semanal',
    },
  },
};

const menuBarStyle = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-end',
  bgcolor: 'background.paper',
  height: '75px',
  zIndex: 4,
  alignItems: 'center',
  gridColumnEnd: 'auto',
  padding: '0 2rem',
};

const stackFilterContractsStyle = {
  bgcolor: 'background.paper',
  height: '75px',
  zIndex: 4,
  padding: '0 2rem',
};

const CampaignProfile = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const exportIsOpen = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef();
  const [value, setValue] = useState(0);
  const location = useLocation();

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  const { 
    campaigns, 
    getCampaign,
    createCampaignProcessPayments,
  } = useCampaigns();
  const [ campaign, setCampaign ] = useState<CampaignFilter>(initialCampaign);
  const { findExportReports } = useExportReports();
  const [ campaignSchedules, setCampaignSchedules ] = useState<any[]>([]);
  const [ filtredMapaignMedias, setFiltredMapaignMedias ] = useState<CampaignMediaInterface[]>([]);
  const { campaignId } = useParams();
  const [ queryContracts, setQueryContracts ] = useState('');
  const [ openDialogProcessPayments, setOpenDialogProcessPayments ] = useState(false)
  const [ loadingCapaignDetailsGroupByDate, setLoadingCapaignDetailsGroupByDate ] = useState(false);
  const [ loadingCapaignDetailsGroupByDatePlaces, setLoadingCapaignDetailsGroupByDatePlaces ] = useState(false);
  const [ loadingCapaignDetailsGroupByPlaces, setLoadingCapaignDetailsGroupByPlaces ] = useState(false);
  const [ loadingCapaignSingleReport, setLoadingCapaignSingleReport ] = useState(false);
  const [ filterEnabled, setFilterEnabled ] = useState(true);
  const [ placeGroupsList, setPlaceGroupsList ] = useState<string[]>([]);
  const [ mediaTypesList, setMediaTypesList ] = useState<string[]>([]);

  const [ filterPlaceGroupSelected, setFilterPlaceGroupSelected ] = useState<string>('');
  const [ filterMediaTypeSelected, setFilterMediaTypeSelected ] = useState<string>('');
  const [ filterWithoutViews, setFilterWithoutViews ] = useState<boolean>(false);

  const { contracts, findContracts } = useContracts();
  const { campaignMedias, findCampaignMedias } = useCampaignMedias();

  const getCampaignInformation = () => {
    getCampaign(campaignId, {
      query: { 
        includeAdvertisers: true,
        includeBigNumbers: true,
      }});
  };

  const handleClickExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseExportMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const uniquePlaceGroups = new Set(contracts.map((contract: any) => contract?.place?.placeGroup?.name));
    setPlaceGroupsList(Array.from(uniquePlaceGroups).map(item => item as string).sort());

    const uniqueMediaTypes = new Set(contracts.map((contract: any) => contract?.mediaType));
    setMediaTypesList(Array.from(uniqueMediaTypes).map(item => item as string).sort());

  }, [contracts]);

  useEffect(() => {
    if (value === 0) {
      getCampaignInformation()
    }

    if (value === 1) {
      findContractsList()
    }
  }, [value]);

  useEffect(() => {
    getCampaignInformation();
  }, [campaignId]);

  useEffect(() => {
    if(campaignId == campaigns[0]?.id) {
      setCampaign(campaigns[0] as CampaignFilter);
      setCampaignSchedules(campaigns[0]?.schedules || []);
    }
  }, [campaigns]);

  const filteredContracts = contracts.filter(
    (contract: any) => (filterPlaceGroupSelected === '' || (filterPlaceGroupSelected !== '' && contract?.place?.placeGroup?.name == filterPlaceGroupSelected)) 
    && (filterMediaTypeSelected == '' || (filterMediaTypeSelected !== '' && contract?.mediaType == filterMediaTypeSelected))
    && (contract?.contractIsActive === filterEnabled) && ((!filterWithoutViews && contract?.nOfViews > 0) || (!!filterWithoutViews && !contract?.nOfViews))
  );

  const numberFormatK = (value: number)=> value >= 1000 ? numeral(value).format('0.00a').toUpperCase() : value;

  const filterContractsData = (query: string, data: any) => {
    return data.filter(({ place }: any) =>
      place?.name.toLowerCase().includes(query.toLowerCase())
    );
  };

  // const filteredPlaces = places.filter(place: PlaceFilter => contracts.include(place.id == campaignId));

  const findContractsList = () => {
    findContracts({
      query: {
        includeCampaignPlaces: true, 
        includeBigNumbers: true,
        campaignId
      }
    });
    findCampaignMedias({
      query: {
        campaignId
      }
    });
  };

  useEffect(() => {
    findContractsList();
    // findPlacesList();
  }, []);

  useEffect(() => {
    setFiltredMapaignMedias(campaignMedias.filter((campaignMedia: CampaignMediaInterface) => !campaignMedia.contractId));
  }, [campaignMedias]);

  const handleProcessPayments = async () => {
    createCampaignProcessPayments(campaignId).then(() => {
      enqueueSnackbar('Fechamento financeiro realizado com sucesso!', {
        variant: 'success',
      });
      getCampaign(campaignId);
    })
    .catch((error: any) => {
      enqueueSnackbar('Erro ao realizar fechamento financeiro!', { variant: 'error' });
    });
    setOpenDialogProcessPayments(false)
  }

  const downloadCapaignDetailsGroupByPlaces = async () => {
    setLoadingCapaignDetailsGroupByPlaces(true);
    
    try {
      const views = await findExportReports({
        query: {
          reportType: 'campaign-details-group-by-places',
          campaignId,
        }
      });
      const reportData: any = [];
      views.forEach((place: any) => {
        const placeData = {
          'Local': place.placeName,
          'Endereço': place.placeAddress,
          'Cidade': place.placeCity,
          'Estado': place.placeState,
          'Usuarios Unicos': place.nOfUsers,
          'Sessões': place.nOfSessions,
          'Impressões': place.nOfViews,
          'Cliques': place.nOfCtas,
          'CTR': place.ctr,
        };
        reportData.push(placeData);
      })
      const worksheet = XLSX.utils.json_to_sheet(reportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Dados Campanha por local");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `Thirky-Agrupado-Local-${campaign?.name}_${dayjs().format('DD-MM-YYYY HHmm')}.xlsx`);
      setLoadingCapaignDetailsGroupByPlaces(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  const downloadCapaignDetailsGroupByDate = async () => {
      setLoadingCapaignDetailsGroupByDate(true);
      try {
        const views = await findExportReports({
          query: {
            reportType: 'campaign-details-group-by-date',
            campaignId,
          }
        });
        const viewsReport: any = [];
        views.forEach((view: any) => {
          const newView = {
            'Campanha': view.campaignId,
            'Data': dayjs(view.date).format('DD/MM/YYYY'),
            'Usuarios Unicos': view.nOfUsers,
            'Sessões': view.nOfSessions,
            'Impressões': view.nOfViews,
            'Cliques': view.nOfCtas,
            'CTR': view.ctr,
            'CPM':  view.cpm,
            'Valor Gasto':  view.totalValue,
          };
          viewsReport.push(newView);
        })
        const worksheet = XLSX.utils.json_to_sheet(viewsReport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Dados Campanha por data");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, `Thirky-Agrupado-Data-${campaign?.name}_${dayjs().format('DD-MM-YYYY HHmm')}.xlsx`);
        setLoadingCapaignDetailsGroupByDate(false);
        setAnchorEl(null);
      } catch (error) {
        setLoadingCapaignDetailsGroupByDate(false);
        setAnchorEl(null);
        console.log('error', error);
      }
  }

  const downloadCapaignDetailsGroupByDatePlaces = async () => {
    setLoadingCapaignDetailsGroupByDatePlaces(true)
    try {
      const views = await findExportReports({
        query: {
          reportType: 'campaign-details-group-by-date-places',
          campaignId,
        }
      });
      console.log('reportDatas', views);
      const viewsReportByPlaceAndDate: any = [];
      views.forEach((view: any) => {
        const newView = {
          'Data': dayjs(view.date).format('DD/MM/YYYY'),
          'Nome Criativo': view.mediaName,
          'Tipo de Criativo': view.mediaType,
          'Id campanha': view.campaignId,
          'Nome da campanha': view.campaignName,
          'Nome Local':  view.placeName,
          'Endereço Local': view.placeAddress,
          'Cidade':  view.placeCity,
          'Estado':  view.placeState,
          'Impressões':  view.nOfViews,
          'Cliques':  view.nOfCtas,
          'CTR':  view.ctr,
          'CPM':  view.cpm,
          'Valor Gasto':  view.totalValue,
          'Sessões':  view.nOfSessions,
          'Usuarios Unicos':  view.nOfUsers
        };
        viewsReportByPlaceAndDate.push(newView);
      })
      const worksheetByPlaceAndDate = XLSX.utils.json_to_sheet(viewsReportByPlaceAndDate);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheetByPlaceAndDate, "Dados Campanha por data e local");
      setLoadingCapaignDetailsGroupByDatePlaces(false);
    } catch (error) {
      setLoadingCapaignDetailsGroupByDatePlaces(false);
      console.log('error', error);
    }
  }

  const downloadCapaignSingleReport = async () => {
    setLoadingCapaignSingleReport(true)
    try {
      const views = await findExportReports({
        query: {
          reportType: 'campaign-details-group-by-date-places',
          campaignId,
        }
      });
      console.log('reportDatas', views);
      const viewsReportByPlaceAndDate: any = [];
      views.forEach((view: any) => {
        const newView = {
          'Data': dayjs(view.date).format('DD/MM/YYYY'),
          'Nome Criativo': view.mediaName,
          'Tipo de Criativo': view.mediaType,
          'Id campanha': view.campaignId,
          'Nome da campanha': view.campaignName,
          'Nome Local':  view.placeName,
          'Endereço Local': view.placeAddress,
          'Cidade':  view.placeCity,
          'Estado':  view.placeState,
          'Impressões':  view.nOfViews,
          'Cliques':  view.nOfCtas,
          'CTR':  view.ctr,
          'CPM':  view.cpm,
          'Valor Gasto':  view.totalValue,
          'Sessões':  view.nOfSessions,
          'Usuarios Unicos':  view.nOfUsers
        };
        viewsReportByPlaceAndDate.push(newView);
      })
      const worksheetByPlaceAndDate = XLSX.utils.json_to_sheet(viewsReportByPlaceAndDate);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheetByPlaceAndDate, "Dados Campanha por data e local");

      const viewsByPlaces = await findExportReports({
        query: {
          reportType: 'campaign-details-group-by-places',
          campaignId,
        }
      });
      const reportData: any = [];
      viewsByPlaces.forEach((place: any) => {
        const placeData = {
          'Local': place.placeName,
          'Endereço': place.placeAddress,
          'Cidade': place.placeCity,
          'Estado': place.placeState,
          'Usuarios Unicos': place.nOfUsers,
          'Sessões': place.nOfSessions,
          'Impressões': place.nOfViews,
          'Cliques': place.nOfCtas,
          'CTR': place.ctr
        };
        reportData.push(placeData);
      })
      const worksheetByPlace = XLSX.utils.json_to_sheet(reportData);
      // const workbookByPlaces = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheetByPlace, "Dados Campanha por local");

      const viewsByDate = await findExportReports({
        query: {
          reportType: 'campaign-details-group-by-date',
          campaignId,
        }
      });
      const viewsByDates: any = [];
      viewsByDate.forEach((view: any) => {
        const newView = {
          'Campanha': view.campaignId,
          'Data': dayjs(view.date).format('DD/MM/YYYY'),
          'Usuarios Unicos': view.nOfUsers,
          'Sessões': view.nOfSessions,
          'Impressões': view.nOfViews,
          'Cliques': view.nOfCtas,
          'CTR': view.ctr,
          'CPM':  view.cpm,
          'Valor Gasto':  view.totalValue,
        };
        viewsByDates.push(newView);
      })
      const worksheetByDates = XLSX.utils.json_to_sheet(viewsByDates);
      // const workbookByDates = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheetByDates, "Dados Campanha por data");

      XLSX.writeFile(workbook, `Thirky-Agrupado-${campaign?.name}_${dayjs().format('DD-MM-YYYY HHmm')}.xlsx`);
      setLoadingCapaignSingleReport(false);
    } catch (error) {
      setLoadingCapaignSingleReport(false);
      console.log('error', error);
    }
  }

  const contractColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Contrato',
      width: 90,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/campaigns/${campaignId}/contract/edit/${row?.id}`}
          state={{ background: location }}
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
        >
          <React.Fragment>#{row?.id}</React.Fragment>
        </Link>
      ),
    },
    {
      field: 'placeId',
      headerName: 'Estabelecimento',
      width: 350,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <Link
            to={`/places/${row?.placeId}/profile`}
            target='_blank'
          >
            {row?.place?.name} ({row?.mediaType})
          </Link>
        </React.Fragment>
      ),
    },
    {
      field: 'ecd',
      headerName: 'ECD',
      width: 150,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.place?.placeGroup?.name}
        </React.Fragment>
      ),
    },
    {
      field: 'contractIsActive',
      headerName: 'Status',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!!row?.contractIsActive ? (
            <Chip label="Ativo" color="success" />
          ) : (
            <Chip label="Inativo" color="warning" />
          )}
        </React.Fragment>
      ),
    },
    {
      field: 'estimateViews',
      headerName: 'Objetivo Impressões',
      width: 100,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'viewCost',
      headerName: 'Valor',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <FormattedNumber minimumFractionDigits={4} value={row?.viewCost} style="decimal" currency="BRL" />
        </React.Fragment>
      ),
    },
    {
      field: 'nOfViews',
      headerName: 'Entrega',
      width: 220,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.viewCost && row?.estimateViews && row?.estimateViews > 0 ? 
            `${Number(row?.nOfViews/row?.estimateViews * 100).toFixed(2)}% (${row?.nOfViews || 0})`
            : '-'}
           
          
        </React.Fragment>
      ),
    },
    {
      field: 'totalCost',
      headerName: 'Total (R$)',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <FormattedNumber value={row?.nOfViews * row?.viewCost} style="currency" currency="BRL" />
        </React.Fragment>
      ),
    },
    {
      field: 'totalPaidPlaceGroup',
      headerName: 'ECD (R$)',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <FormattedNumber 
            value={row?.totalPaidPlaceGroup || row?.viewCost * row?.nOfViews * (row?.splitPlaceGroup / 100)} 
            style="currency" 
            currency="BRL" 
          />
        </React.Fragment>
      ),
    },
    {
      field: 'totalPaidPlace',
      headerName: 'Estabelecimento (R$)',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <FormattedNumber 
            value={row?.totalPaidPlace || row?.viewCost * row?.nOfViews * (row?.splitPlace / 100)} 
            style="currency" 
            currency="BRL" 
          />
        </React.Fragment>
      ),
    },
    {
      field: 'totalPaidThirky',
      headerName: 'Thirky (R$)',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <FormattedNumber 
            value={row?.totalPaidThirky || row?.viewCost * row?.nOfViews * ((100 - row?.splitPlaceGroup - row?.splitPlace) / 100) } 
            style="currency" 
            currency="BRL" 
          />
        </React.Fragment>
      ),
    },
    {
      field: 'placePaidAt',
      headerName: 'Pagemento Estabelecimento',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.placePaidAt ? row?.placePaidAt : null}
        </React.Fragment>
      ),
    },
    {
      field: 'placeGroupPaidAt',
      headerName: 'Pagamento ECD',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.placeGroupPaidAt ? row?.placeGroupPaidAt : null}
        </React.Fragment>
      ),
    },

    // {
    //   field: 'viewsAvg',
    //   headerName: 'Exibição Média',
    //   width: 120,
    //   headerClassName: 'app-theme--header',
    // },
    // {
    //   field: 'nOfCtas',
    //   headerName: 'CTA',
    //   width: 120,
    //   headerClassName: 'app-theme--header',
    // },
    // {
    //   field: 'ctr',
    //   headerName: 'CTR',
    //   width: 120,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },

  ];

  const schadulesColumns: GridColDef[] = [
    // {
    //   field: 'id',
    //   hidden: true,
    //   headerName: 'Contrato',
    //   width: 90,
    //   headerClassName: 'app-theme--header',
    //   renderCell: ({ row }: Partial<GridRowParams>) => (
    //     <Link
    //       to={`/campaigns/${campaignId}/schedules/edit/${row?.id}`}
    //       state={{ background: location }}
    //       style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
    //     >
    //       <React.Fragment>#{row?.id}</React.Fragment>
    //     </Link>
    //   ),
    // },
    {
      field: 'activeDays',
      headerName: 'Dias',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.activeDays?.map((day: string) => <span>{day}&nbsp;&nbsp;</span>)}
        </React.Fragment>
      ),
    },
    {
      field: 'startTime',
      headerName: 'Inicio',
      width: 100,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row.startTime ? dayjs(row.startTime).format('HH:mm') : ''}</span>
      ),
    },
    {
      field: 'endTime',
      headerName: 'Final',
      width: 100,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row.endTime ? dayjs(row.endTime).format('HH:mm') : ''}</span>
      ),
    },
    {
      field: 'id',
      headerName: '',
      width: 100,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/campaigns/${campaignId}/schedules/edit/${row?.id}`}
          state={{ background: location }}
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
        >
          {/* <React.Fragment>Editar</React.Fragment> */}
          <EditOutlined
            fontSize="small" 
            sx={{ 
              color: "#999999", 
              marginLeft: 2, 
              mt: '5px', 
              borderColor: "#999999", 
              borderStyle: 'solid', 
              borderRadius: '2px', 
              borderWidth: '1px',
            }} />
        </Link>
      ),
    },
  ];

  const tabNames = ['Dashboard', 'Mídias', 'Contratos', 'Agendamentos'];

  const endAllContractsProperties = {
    visible: true,
    contracts: filteredContracts,
  };
  const viewDays = campaign?.viewsOnLastDays?.map((view: any) => dayjs(view.register_date).format('DD/MM'));
  const nOfViewsDate = campaign?.viewsOnLastDays?.map((view: any) => view.n_of_views);
  const nOfSessionsDate = campaign?.viewsOnLastDays?.map((view: any) => view.n_of_sessions);
  const nOfUsersate = campaign?.viewsOnLastDays?.map((view: any) => view.n_of_users);

  const dataChart = {
    labels: viewDays?.reverse(),
    datasets: [
      {
        label: 'Impressões',
        data: nOfViewsDate?.reverse(),
        backgroundColor: '#FF9F9F',
      },
      {
        label: 'Sessões',
        data: nOfSessionsDate?.reverse(),
        backgroundColor: '#9fa9ff',
      },
      {
        label: 'Usuários',
        data: nOfUsersate?.reverse(),
        backgroundColor: '#9fff9f',
      },
    ],
  };

  const calculatePacing = () => {
    const endDate = dayjs(campaign.endDate).isAfter(dayjs()) ? dayjs() : dayjs(campaign.endDate);
    const daysPercent = Number(dayjs(endDate).diff(campaign.startDate, 'day')/dayjs(campaign.endDate).diff(campaign.startDate, 'day')).toFixed(4) || 0;
    const viewsPercent = campaign?.nOfViews && campaign?.viewsGoal ? Number(campaign?.nOfViews/campaign?.viewsGoal).toFixed(4) : 0;
    const difResutl = Number(daysPercent) - Number(viewsPercent);
    return Number(1 - difResutl).toFixed(2);
  }

  const switchMenuBarAccordinTab = (() => {
    const endDate = dayjs(campaign.endDate).isAfter(dayjs()) ? dayjs() : dayjs(campaign.endDate);
    const pacing: number | null = (calculatePacing() || 0) as number;
    const totalDays = dayjs(campaign.endDate).diff(campaign.startDate, 'day');
    const duringDays = dayjs(endDate).diff(campaign.startDate, 'day');
    const ctaPercent = campaign?.nOfCtas && campaign?.nOfViews ? Number(campaign?.nOfCtas/campaign?.nOfViews * 100).toFixed(2) : 0
    switch (value) {
      case 0: {
        if (campaign.endDate && campaign.startDate) {
          return (
            <Box sx={{ alignSelf: "stretch", width: '100%', height: '60vh', bgcolor: 'background.paper'}}>
              <Stack sx={{ p: 2 }} flexDirection="row" justifyContent="space-between">
                <Stack justifyContent="space-between" sx={bignumbersCardsStyle}>
                  <Box component="span" sx={{display: 'flex'}} >
                    <Speed />
                    <Typography sx={{ ml: 2, fontWeight: 'bold'}} component="span">Pacing</Typography>
                  </Box>
                  <Typography variant="h5">
                    {pacing || ''} 
                  </Typography>
                  <Typography variant="subtitle2">
                    {pacing > 1 ? 'Precisa desacelerar' : ''}
                    {pacing < 1 ? 'Precisa acelerar' : ''}
                  </Typography>
                </Stack>
                <Stack justifyContent="space-between" sx={bignumbersCardsStyle}>
                  <Box component="span" sx={{display: 'flex'}} >
                    <CalendarMonthOutlined />
                    <Typography sx={{ ml: 2, fontWeight: 'bold'}} component="span">Percorrido&nbsp;&bull;&nbsp;Duração</Typography>
                  </Box>
                  <Typography variant="h5">
                    {duringDays} de {totalDays} dias
                  </Typography>
                  <Typography variant="subtitle2">
                    {Number(duringDays/totalDays * 100).toFixed(2)}% já entregue
                  </Typography>
                </Stack>
                <Stack justifyContent="space-between" sx={bignumbersCardsStyle}>
                  <Box component="span" sx={{display: 'flex'}} >
                    <EmojiEventsOutlined />
                    <Typography sx={{ ml: 2, fontWeight: 'bold'}} component="span">Impressões&nbsp;&bull;&nbsp;Objetivo</Typography>
                  </Box>
                  <Typography variant="h5">
                    {numberFormatK(campaign?.nOfViews || 0)}&nbsp;&bull;&nbsp;
                    {campaign?.nOfViews && campaign?.viewsGoal ? `${Number(campaign?.nOfViews/campaign?.viewsGoal * 100).toFixed(2)}%` : 'Objetivo nao informado' }
                  </Typography>
                  <Typography variant="subtitle2">
                    {campaign?.nOfViews && campaign?.viewsGoal ? `${Number(campaign?.nOfViews/campaign?.viewsGoal * 100).toFixed(2)} % já entregue` : ''}
                  </Typography>
                </Stack>
                <Stack justifyContent="space-between" sx={bignumbersCardsStyle}>
                  <Box component="span" sx={{display: 'flex'}} >
                    <CampaignOutlined />
                    <Typography sx={{ ml: 2, fontWeight: 'bold'}} component="span">Cliques&nbsp;&bull;&nbsp;CTR</Typography>
                  </Box>
                  <Typography variant="h5">
                    {
                      campaign?.nOfViews && campaign?.nOfCtas 
                        ? 
                          <>
                            {campaign?.nOfCtas}&nbsp;&bull;&nbsp;
                            {ctaPercent}%
                            
                          </>
                        : 
                          '-'
                    }
                  </Typography>
                  <Typography variant="subtitle2">
                    {(ctaPercent && Number(ctaPercent || 0) > 1.2) ? 'CTR acima da média' : 'CTR abaixo da média'} (1,2%)
                  </Typography>
                </Stack>
                <Stack justifyContent="space-between" sx={bignumbersCardsStyle}>
                  <Box component="span" sx={{display: 'flex'}} >
                    <RocketLaunchOutlined />
                    <Typography sx={{ ml: 2, fontWeight: 'bold'}} component="span">Alcance&nbsp;&bull;&nbsp;Sessões</Typography>
                  </Box>
                  <Typography variant="h5">
                    {numberFormatK(campaign.nOfUniqueUsers || 0)}&nbsp;&bull;&nbsp;{numberFormatK(campaign.nOfSessions || 0)}
                  </Typography>
                  <Typography variant="subtitle2">
                    Frequencia de {campaign.nOfSessions && campaign.nOfUniqueUsers ? Number(campaign.nOfSessions/campaign.nOfUniqueUsers).toFixed(2) : '-'}x
                  </Typography>
                </Stack>
              </Stack>
              <Stack flexDirection="row" sx={{ p: 2, width: '100%' }} >
                <Stack flex={1}>
                    <List 
                      sx={{ 
                        width: '100%', 
                        maxWidth: 360, 
                        bgcolor: 'background.paper',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRadius: 2,
                        p: 0
                      }} 
                      aria-label="mailbox folders"
                    >
                    <ListItem sx={{ p: 3 }} divider>
                      <FmdGoodOutlined style={{ color: '#0288D1' }} />
                      <ListItemText sx={{ ml: 2, color: '#1E1E1E' }} primary="Total de Estabelecimentos" />
                      <Typography sx={{ fontWeight: 'bold' }}>{campaign?.nOfPlaces}</Typography>
                    </ListItem>
                    <ListItem  sx={{ p: 3 }} divider>
                      <CheckCircleOutlineOutlined style={{ color: '#66BB6A' }} />
                      <ListItemText sx={{ ml: 2, color: '#1E1E1E' }} primary="Estabelecimentos Ativos" />
                      <Typography sx={{ fontWeight: 'bold' }}>{campaign?.nOfActivePlaces}</Typography>
                    </ListItem>
                    <ListItem  sx={{ p: 3 }} divider>
                      <DoNotDisturbOutlined style={{ color: '#F44336' }} />
                      <ListItemText sx={{ ml: 2, color: '#1E1E1E' }}  primary="Estabelecimentos Inativos" />
                      <Typography sx={{ fontWeight: 'bold' }}>{Number(campaign?.nOfPlaces) - Number(campaign?.nOfActivePlaces)}</Typography>
                    </ListItem>
                    <ListItem  sx={{ p: 3 }}>
                      <AccessTimeOutlined style={{ color: '#F57C00' }} />
                      <ListItemText sx={{ ml: 2, color: '#1E1E1E' }}  primary="Duração" />
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {campaign.endDate && campaign.startDate ? 
                          dayjs(campaign.endDate).diff(campaign.startDate, 'day') : ''}
                      </Typography>
                    </ListItem>
                  </List>
                </Stack>
                <Stack flex={2} justifyContent="center" alignItems="center">
                  <Bar ref={ref} options={optionsChart} data={dataChart} />
                </Stack>
              </Stack>
              <Typography 
                variant='subtitle2' 
                style={{ color: 'gray', marginTop: 30, marginLeft: 15 }}
              >
                * Dados atualizados em tempo real
              </Typography>
            </Box>
          )
        } else {
          return (
            <Box sx={{ alignSelf: "stretch", width: '100%', height: '60vh', bgcolor: 'background.paper'}}>
              <Stack sx={{ p: 2 }} flexDirection="row" justifyContent="space-between">
                Campanha com dados pendentes para apresentação da dashboard
              </Stack>
            </Box>
          )
        }
      }

      case 1:
        return (
          <>
            <MenuBar
              addUrl={`/campaigns/${campaignId}/campaignMedia/create`}
              AddButtonText="Nova Mídia"
              disableSeach={true}
              // endAllContracts={endAllContractsProperties}
            />
            <Stack flexWrap="wrap" flexDirection='row' sx={{ mt: 5, width: '100' }}>
              {filtredMapaignMedias?.map((campaignMedia: CampaignMediaInterface) => (
                <Card sx={{ p: 3, width: '400px', backgroundColor: '#fff', m: 2 }}>
                  <CardActions sx={{ flex: 1, justifyContent: 'space-between' }}>
                    <Stack flexDirection="row">
                      <img style={{ width: 25, height: 25, marginRight: 10 }} src={getMediaTypeIcon(campaignMedia?.type || '')} alt={campaignMedia?.type || ''}/>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {campaignMedia?.name}
                      </Typography>
                    </ Stack>
                    <Link
                      to={`/campaigns/${campaignId}/campaignMedia/edit/${campaignMedia.id}`}
                      state={{ background: location }}
                      style={{ textDecoration: 'none', marginLeft: 10 }}
                    >
                      <Button variant="contained">
                        <Typography>Editar</Typography>
                      </Button>
                    </Link>
                  </CardActions>
                  <Stack flexDirection='row'>
                    <Card sx={{  width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mr: 1, mt: 1}}>
                      <CardContent>
                        <img
                          style={{ width: '100%' }}
                          src={campaignMedia?.urlCover || imagePlaceholder}
                          alt="Cover campanha"
                        />
                      </CardContent>
                    </Card>
                    <Card sx={{  width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mr: 1, mt: 1}}>
                        {campaignMedia?.urlVideo ? (
                          <CardContent>
                            <video  style={{ marginTop: 15, width: '100%'}} controls>
                              <source
                                type="video/mp4"
                                src={campaignMedia?.urlVideo}
                              />
                            </video>
                            <TextField
                              label="Duração do vídeo (segundos)"
                              name="campaignMediaViewDuration"
                              id="campaignMediaViewDuration"
                              sx={{ mr: '1%', width: '100% !important' }}
                              disabled={true}
                              value={campaignMedia.videoDuration || ''}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </CardContent>
                        ) : (
                          <CardContent>
                            <img
                              style={{ width: '100%' }}
                              src={videoPlaceholder}
                              alt="Cover campanha"
                            />
                          </CardContent>
                        )}
                    </Card> 
                  </Stack>
                </Card>
              ))}
            </Stack>
          </>
        );
      case 2:
        return (
          <>
            {!campaign?.isFinished ? (
              <Stack flexDirection='row' justifyContent="space-between" alignItems="center">
                <Stack sx={stackFilterContractsStyle}  flexDirection='row' justifyContent="start" alignItems="center" flex={1}>
                  <FormControl variant="outlined" style={{ width: 200}}>
                    <InputLabel 
                      shrink 
                      style={{ 
                        backgroundColor: '#fff', 
                        paddingLeft: 5, 
                        paddingRight: 5 
                      }} 
                      id="demo-simple-select-outlined-label"
                    >
                      ECD
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="placeGroupId"
                      name="placeGroupId"
                      size= 'small'
                      value={String(filterPlaceGroupSelected || '')}
                      onChange={(event) => setFilterPlaceGroupSelected(event?.target?.value as string)}
                      label="ECD"
                      input={<OutlinedInput />}
                      required
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      {placeGroupsList.map((placeGroup: any) => (
                        <MenuItem key={placeGroup} value={placeGroup}>
                          {placeGroup}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" style={{ width: 200, marginLeft: 20}}>
                    <InputLabel 
                      shrink 
                      style={{ 
                        backgroundColor: '#fff', 
                        paddingLeft: 5, 
                        paddingRight: 5 
                      }} 
                      id="demo-simple-select-outlined-label"
                    >
                      Tipo de Midia
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="mediaType"
                      name="mediaType"
                      size= 'small'
                      value={String(filterMediaTypeSelected || '')}
                      onChange={(event) => setFilterMediaTypeSelected(event?.target?.value as string)}
                      label="Tipo de Midia"
                      input={<OutlinedInput />}
                      required
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      {mediaTypesList.map((mediaType: any) => (
                        <MenuItem key={mediaType} value={mediaType}>
                          {mediaType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    style={{ width: 50, marginLeft: 20}}
                    control={
                      <Checkbox
                        name="filterEnabled"
                        id="filterEnabled"
                        checked={!!filterEnabled}
                        onChange={(event) => setFilterEnabled(event.target.checked)}
                      />
                    }
                    label="Ativo"
                  />
                  <FormControlLabel
                    style={{ width: 150, marginLeft: 20}}
                    control={
                      <Checkbox
                        name="filterWithoutViews"
                        id="filterWithoutViews"
                        checked={!!filterWithoutViews}
                        onChange={(event) => setFilterWithoutViews(event.target.checked)}
                      />
                    }
                    label="Sem Entregas"
                  />
                </Stack>
                <MenuBar
                  addUrl={`/campaigns/${campaignId}/contract/create`}
                  AddButtonText="Novo Contrato"
                  endAllContracts={endAllContractsProperties}
                  query={queryContracts}
                  setQuery={setQueryContracts}
                />
              </Stack>
            ) : (
              <Box sx={menuBarStyle}>
                <OutlinedInput
                  id="filled-adornment-password"
                  size= 'small'
                  sx={{ mr: 2 }}
                  onChange={(e) => setQueryContracts(e.target.value)}
                  value={queryContracts}
                  placeholder= 'Pesquisar'
                  autoFocus
                  autoComplete='off'
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
                { !campaign?.paymentProcessedAt ? (
                  <Button variant="contained" onClick={() => setOpenDialogProcessPayments(true)}>
                    <PriceCheckIcon
                      style={{ fontSize: 'medium', marginRight: '0.5rem' }}
                    />
                    <Typography>Fechamento Financeiro</Typography>
                  </Button>
                ) : `Pagamento processado em ${dayjs(campaign?.paymentProcessedAt).format('DD/MM/YYYY')}`}

                <Dialog
                  open={openDialogProcessPayments}
                  onClose={() => setOpenDialogProcessPayments(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Fechamento financeiro de todos os contratos?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <div style={{color: 'red'}}>Esta operação não poderá ser desfeita.</div>
                      <div>Deseja realizar o fechamento financeiro de todos os {filteredContracts?.length} contratos desta campanha? </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenDialogProcessPayments(false)}>Cancelar</Button>
                    <Button onClick={handleProcessPayments} autoFocus>
                      Finalizar Todos
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            )}
            <Table rows={filterContractsData(queryContracts, filteredContracts)} columns={contractColumns} />
            <Typography 
              variant='subtitle2' 
              style={{ color: 'gray' }}
            >
              * Dados atualizados em tempo real
            </Typography>
          </>
        );
      case 3:
        return (
          <>
            <MenuBar
              addUrl={`/campaigns/${campaignId}/schedules/create`}
              AddButtonText="Novo Agendamento"
              // endAllContracts={endAllContractsProperties}
              // query={queryContracts}
              // setQuery={setQueryContracts}
            />
            <Table rows={campaignSchedules} columns={schadulesColumns} />
          </>
        );
      default:
        return (
          <MenuBar
            addUrl={`/campaigns/${campaignId}/profile/create`}
            AddButtonText="Novo Contrato"
          />
        );
    }
  })();

  const breadcrumbsList: BreadcrumbType[] = [
    {
      link: '/campaigns',
      label: 'Lista de Campanhas'
    },
    {
      link: `/campaigns/${campaignId}`,
      label: campaign?.name || '',
      disabled: true
    },
  ]

  return (
    <Layout>
      <Navigate pageTitle="Perfil da Campanha" breadcrumbs={breadcrumbsList} />
      <TitleTabs value={value} handleChange={handleChange} tabNames={tabNames}>
        <Stack
          // container
          sx={{ p: 1, width: '100%'}}
          // columns={10}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack 
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignSelf="stretch"
            // spacing={3}  
          >
            <Avatar sx={{ width: 90, height: 90, mr: 1 }} src={campaign?.urlCover} variant="square"/>
            <Stack justifyContent="space-between" alignSelf="stretch">
              <Typography variant="h5"  sx={{ fontWeight: 'bold' }}>{campaign?.advertiser?.name} - {campaign?.name}</Typography>
              <Typography color="#929292">
                {campaign?.taxonomyName ? `#${campaign?.taxonomyName}` : ''}
              </Typography>
            </Stack>
            
            <Link 
              style={{ textDecoration: 'none' }}
              state={{ background: location }} 
              to={`/campaigns/edit/${campaignId}`}
            >
              <EditOutlined
                fontSize="small" 
                sx={{ 
                  color: "#999999", 
                  marginLeft: 2, 
                  mt: '5px', 
                  borderColor: "#999999", 
                  borderStyle: 'solid', 
                  borderRadius: '2px', 
                  borderWidth: '1px',
                }} />
            </Link>
          </Stack>
          <Stack 
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            // spacing={3}  
          >
            <Link
              to={`${QR_CODE_CLIENT_URL}/preview/API/?campaignId=${campaign?.id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button variant="outlined">Ver prévia Cardapio</Button>
            </Link>
            <Link
              to={`${QR_CODE_CLIENT_URL}/preview/${campaign?.id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button sx={{ ml: 2 }} variant="outlined">Ver prévia QrCode</Button>
            </Link>
              <Button 
              aria-controls={exportIsOpen ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={exportIsOpen ? 'true' : undefined}
              sx={{ marginLeft: '1rem' }}
              variant="outlined"
              onClick={handleClickExportMenu}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {/* <PublishIcon
                style={{ fontSize: 'medium', marginRight: '0.5rem' }}
              /> */}
              Exportar
            </Button>
            <Menu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              // anchorEl={anchorEl}
              // open={openExportMenu}
              // onClose={() => setOpenExportMenu(false)}
              anchorEl={anchorEl}
              open={exportIsOpen}
              onClose={handleCloseExportMenu}
            >
              <MenuItem disableRipple>
                {/* <EditIcon /> */}
                <LoadingButton loading={loadingCapaignDetailsGroupByDate} onClick={downloadCapaignDetailsGroupByDate}>Relatorio por Data </LoadingButton>
              </MenuItem>
              <MenuItem disableRipple>
                {/* <EditIcon /> */}
                <LoadingButton loading={loadingCapaignDetailsGroupByPlaces} onClick={downloadCapaignDetailsGroupByPlaces}>Relatorio Por Local</LoadingButton>
              </MenuItem>
                {/* <MenuItem  disableRipple>
                <LoadingButton loading={loadingExportViewsDetails} onClick={downloadExportViewsDetails}>Exportar Entregas </LoadingButton>
              </MenuItem> */}
              <MenuItem  disableRipple>
                {/* <FileCopyIcon /> */}
                <LoadingButton loading={loadingCapaignDetailsGroupByDatePlaces} onClick={downloadCapaignDetailsGroupByDatePlaces}>Relatorio Por Data e Local</LoadingButton>
              </MenuItem>

              <MenuItem  disableRipple>
                {/* <FileCopyIcon /> */}
                <LoadingButton loading={loadingCapaignSingleReport} onClick={downloadCapaignSingleReport}>Relatorio Unificado</LoadingButton>
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
      </TitleTabs>
      {switchMenuBarAccordinTab}
      <Outlet />
    </Layout>
  );
};

export default CampaignProfile;
