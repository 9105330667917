import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Layout from '../../components/Layout';
import Table from '../../components/Table';
import MenuBar from '../../components/MenuBar';
import Navigate from '../../components/Navigate';
import * as XLSX from 'xlsx';
import { useBatchPayments } from '../../contexts/useBatchPayments';
import { useBatchPaymentDeposits } from '../../contexts/useBatchPaymentDeposits';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField, Tooltip, Typography } from '@mui/material';
import { BreadcrumbType } from '../../components/common/types';
import numeral from 'numeral';
import { FormattedNumber } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'notistack';

const BatchPaymentView = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { batchPayments, findBatchPayments } = useBatchPayments();
  const { patchBatchPaymentDeposits, findBatchPaymentDeposits } = useBatchPaymentDeposits();
  const [ formatedBatchPayments, setFormatedBatchPayments ] = useState([]);
  const [ batchPaymentOpenId, setBatchPaymentOpen ] = useState<number | null>(null);
  const [ paymentDate, setPaymentDate ] = useState(null);

  const handleClickOpen = (batchPaymentId: number) => {
    setBatchPaymentOpen(batchPaymentId);
  };

  const handleClose = () => {
    setBatchPaymentOpen(null);
    setPaymentDate(null);
  };
  const findBatchPaymentsList = () => {
    findBatchPayments({
      query: {
        includeBigNumbers: true,
        $sort : { createdAt: -1 }
      }
    });
  };

  const transformBatchPaymentsNumbers = (batchPayments: any) => {
    // const newBatchPayments = batchPayments.map((batchPayment: any) => {
    //   return {
    //     ...batchPayment,
    //   }
    // })
    setFormatedBatchPayments(batchPayments);
  }

  useEffect(() => {
    findBatchPaymentsList();
  }, []);

  useEffect(() => {
    transformBatchPaymentsNumbers(batchPayments);
  }, [batchPayments]);

  const [query, setQuery] = useState('');

  const filterData = (query: string, data: any) => {
    if (!query) {
      return data;
    } else {
      console.log(data)
      const dataFiltred = data.filter((payment: any) => {
        const {
          placeGroups = [],
          places = [],
          campaigns = [],
        } = payment;

        const placeGroupsFiltered = placeGroups?.filter((pg: any) => (pg.toLowerCase()).includes(query.toLowerCase())) || [];
        const placesFiltered = places?.filter((pl: any) => (pl.toLowerCase()).includes(query.toLowerCase())) || [];
        const campaignsFiltered = campaigns?.filter((cp: any) => (cp.toLowerCase()).includes(query.toLowerCase())) || [];

        if (placeGroupsFiltered.length > 0 || placesFiltered.length > 0 || campaignsFiltered.length > 0) {
          return payment
        } else {
          return null
        }
      })

      console.log(dataFiltred)
      return dataFiltred
    }
  };

  const dataFiltered = filterData(query, formatedBatchPayments);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      // resizable: true,
      // headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/batchPayments/${row?.id}/details`}
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
        >
          #{row?.id}
        </Link>
      ),
    },   
    {
      field: 'recipientType',
      headerName: 'Tipo de Lote',
      width: 150,
      // resizable: true,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.recipientType === 'place' ? 'Estabelecimento' : 'ECD'}
        </React.Fragment>
      ),
    },
    {
      field: 'nOfDeposits',
      headerName: 'Depositos',
      width: 150,
      // resizable: true,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <>
          <Tooltip title={`${row?.placeGroups ? row?.placeGroups?.join(', ') : ''}${row?.places ? row?.places?.join(', ') : ''}`}>
            {row?.nOfDeposits}
          </Tooltip>
        </>
      ),
    },
    {
      field: 'nOfContracts',
      headerName: 'Contratos',
      width: 150,
      // resizable: true,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <>
          <Tooltip title={`${row?.campaigns ? row?.campaigns?.join(', ') : ''}`}>
            {row?.nOfContracts}
          </Tooltip>
        </>
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Total',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <FormattedNumber
            value={row?.totalAmount} 
            style="currency" 
            currency="BRL" 
          />
        </React.Fragment>
      ),
    },
    {
      field: 'totalPendingAmount',
      headerName: 'Total Pendente',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <FormattedNumber
            value={row?.totalPendingAmount} 
            style="currency" 
            currency="BRL" 
          />
        </React.Fragment>
      ),
    },
    {
      field: 'totalPayed',
      headerName: 'Total Pago',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <FormattedNumber
            value={row?.totalAmount - row?.totalPendingAmount} 
            style="currency" 
            currency="BRL" 
          />
        </React.Fragment>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 180,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
    {
      field: 'payedMark',
      headerName: 'Pago',
      width: 230,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <>
          {row.nOfPendingDeposits > 0 ?
            <Button variant="outlined" onClick={() => handleClickOpen(row.id)}>
              Informar Pagamento
            </Button>
            : 
            <Chip label="Pago" variant="outlined" color="success" />
          }
        </>
      ),
    },
    {
      field: 'download',
      headerName: 'Download',
      width: 180,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <>
        {row.nOfPendingDeposits > 0 ?
          <Button variant="outlined" onClick={() => handleDownloadBachFile(row.id)}>
            Download
          </Button>
          : null
        }
      </>
      ),
    },
  ];

  const breadcrumbsList: BreadcrumbType[] = [
    {
      link: '/batchPayments',
      label: 'Lista de Lotes de Pagamento'
    },
  ]

  const handleSubmitPaymentDate = async () => {
    // console.log('place: ', place);
    if (!paymentDate) {
      enqueueSnackbar('Informe a data do pagamento!', {
        variant: 'error',
      });
      return;
    }
    patchBatchPaymentDeposits(null, { paymentDate }, { query: {
      batchPaymentId: batchPaymentOpenId,
    }})
      .then(() => {
        // console.log('Deu certo');
        enqueueSnackbar('Pagamento informado com sucesso!', {
          variant: 'success',
        });
        handleClose()
        findBatchPaymentsList()
      })
      .catch((error: any) => {
        // console.log('Erro ao alterar', error);
        enqueueSnackbar(error.message ? error.message : 'Erro ao informar pagamento!', {
          variant: 'error',
        });
      });
  };

  const handleDownloadBachFile = async (batchPaymentId: number) => {
    const depositsList = await findBatchPaymentDeposits({
      query: {
        batchPaymentId
      }
    });

    const batchPaymentFile: any = [];
    depositsList.forEach((deposit: any) => {
      const pix = deposit.place ? deposit.place.pix : deposit.placeGroup.pix;
      const recipientType = deposit.place ? 'o Estabelecimento' : 'a ECD';
      const name = deposit.place ? deposit.place.name : deposit.placeGroup.name;
      // if (!pix) {
      //   enqueueSnackbar(`Não há pix cadastrado para ${recipientType} ${name}`, {
      //     variant: 'error',
      //   });
      //   return;
      // }
      const newDepositPix = {
        'pix': deposit.place ? deposit?.place?.pix || 'Não informado' : deposit.placeGroup.pix,
        'valor': String(Number(deposit.depositValue).toFixed(2)).replace('.', ','),
        'Descricao': String(`Lote ${batchPaymentId} - ${deposit.place ? deposit.place.name : deposit.placeGroup.name}`).slice(0, 50),
      };
      batchPaymentFile.push(newDepositPix);
    })
    console.log(batchPaymentFile)
    const worksheet = XLSX.utils.json_to_sheet(batchPaymentFile);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Lote Pagamento");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `Lote Pagamento - ${batchPaymentId}.xlsx`);
  };

  return (
    <Layout>
      <Navigate pageTitle="Financeiro" breadcrumbs={breadcrumbsList} />
      <MenuBar
        addUrl="/batchPayments/create"
        AddButtonText="Novo Lote"
        query={query}
        setQuery={setQuery}
      />
      <Table height={700} rows={dataFiltered} columns={columns} />
      <Outlet />
      
      <Dialog open={!!batchPaymentOpenId} onClose={handleClose} disableEnforceFocus={false}>
        <DialogTitle>Data Pagamento Lote #{batchPaymentOpenId}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Informe a data em que o pagamento foi realizado.
          </DialogContentText>
          <FormControl fullWidth>
            <DatePicker
              label="Basic example"
              value={paymentDate}
              onChange={(newValue: any) => setPaymentDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="DD/MM/YYYY"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmitPaymentDate}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default BatchPaymentView;
