import IMask from 'imask';

var phoneMask10Digits = IMask.createMask({
  mask: '(00) 0000-0000',
  lazy: false,  // make placeholder always visible
});

var phoneMask11Digits = IMask.createMask({
  mask: '(00) 0 0000-0000',
  lazy: false,  // make placeholder always visible
});

const gerMask = (value: string | null) => {
  if (!value?.length) {
    return ''
  }

  if (value?.length === 10) {
    return phoneMask10Digits.resolve(value)
  }

  if (value?.length === 10) {
    return phoneMask11Digits.resolve(value)
  }
}

const PhoneNumber = (props: any) => <>{gerMask(props.value)}</>

export default PhoneNumber;
