import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const BatchPaymentsContext = createContext();
const initialBatchPayment = {
  id: null,
};

export function BatchPaymentsProvider({ children }) {
  const [batchPayments, setBatchPayments] = useState([]);
  const [dashBatchPayment, setDashBatchPayment] = useState(initialBatchPayment);
  const [editBatchPayment, setEditBatchPayment] = useState(initialBatchPayment);

  function getBatchPayment(batchPaymentId, query = {}) {
    services.batchPayments.get(batchPaymentId, query).then((response) => {
      // console.log('getBatchPayment', response);
      setBatchPayments([response]);
    });
  }

  function getDashBatchPayment(batchPaymentId, query = {}) {
    services.batchPayments.get(batchPaymentId, query).then((response) => {
      // console.log('getBatchPayment', response);
      setDashBatchPayment(response);
    });
  }

  function getEditBatchPayment(batchPaymentId) {
    services.batchPayments.get(batchPaymentId).then((response) => {
      // console.log('getBatchPayment', response);
      setEditBatchPayment(response);
    });
  }

  function findBatchPayments(query = {}) {
    services.batchPayments.find(query).then((response) => {
      // console.log('findBatchPayments', response);
      setBatchPayments(response.data);
    });
  }

  async function createBatchPayments(batchPayment) {
    return services.batchPayments.create(batchPayment).then((result) => {
      return result;
    });
  }

  async function patchBatchPayments(id, batchPayment) {
    return services.batchPayments.patch(id, batchPayment).then(() => {
      setEditBatchPayment(initialBatchPayment);
      getBatchPayment(id);
    });
  }

  async function removeBatchPayments(batchPaymentId) {
    return services.batchPayments.remove(batchPaymentId).then(() => {
      findBatchPayments();
    });
  }
  return (
    <BatchPaymentsContext.Provider
      value={{
        batchPayments,
        dashBatchPayment,
        editBatchPayment,
        setEditBatchPayment,
        getEditBatchPayment,
        getDashBatchPayment,
        findBatchPayments,
        createBatchPayments,
        patchBatchPayments,
        getBatchPayment,
        removeBatchPayments,
      }}
    >
      {children}
    </BatchPaymentsContext.Provider>
  );
}

export function useBatchPayments() {
  const context = useContext(BatchPaymentsContext);

  return context;
}
