import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Layout from '../components/Layout';
import Table from '../components/Table';
import MenuBar from '../components/MenuBar';
import Navigate from '../components/Navigate';
import { useAdvertisers } from '../contexts/useAdvertisers';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Avatar, Box, Chip } from '@mui/material';
import { BreadcrumbType } from '../components/common/types';

const AdvertiserView = () => {
  const location = useLocation();
  const { advertisers, findAdvertisers } = useAdvertisers();

  const findAdvertisersList = () => {
    findAdvertisers({query: {
      includeAdvertiserNumberOfActivePlaces: true,
      $sort: {
        createdAt: -1,
      },
    }});
  };

  useEffect(() => {
    findAdvertisersList();
  }, []);

  const [query, setQuery] = useState('');

  const filterData = (query: string, data: any) => {
    if (!query) {
      return data;
    } else {
      return data.filter(({ name }: any) =>
        name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };

  const dataFiltered = filterData(query, advertisers);

  const renderAvatar = ({ row }: Partial<GridRowParams>) => (
    <React.Fragment>
      <Link
        to={`/advertisers/${row?.id}/contracts`}
        style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 2 }}>
          <Avatar alt="" src={row?.urlImage} style={{ marginRight: '1.5rem' }} />
          {row?.name}
        </Box>
      </Link>
    </React.Fragment>
  );

  const renderOptionsButton = ({ row }: Partial<GridRowParams>) => (
    <React.Fragment>
      <Link state={{ background: location }} to={`/advertisers/edit/${row?.id}`}>Editar Anunciante</Link>
    </React.Fragment>
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 250,
      // resizable: true,
      headerClassName: 'app-theme--header',
      renderCell: renderAvatar,
    },
    {
      field: 'nOfActiveContracts',
      headerName: 'Contratos Ativos',
      width: 150,
      // resizable: true,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'isEnabled',
      headerName: 'Status',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!row?.isEnabled ? (
            <Chip label="Inativo" color="error" />
          ) : (
            <Chip label="Ativo" color="success" />
          )}
        </React.Fragment>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 180,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
    // {
    //   field: 'options',
    //   headerName: '',
    //   width: 170,
    //   headerClassName: 'app-theme--header',
    //   renderCell: renderOptionsButton,
    // },
  ];

  const breadcrumbsList: BreadcrumbType[] = [
    {
      link: '/advertisers',
      label: 'Lista de Anunciantes'
    },
  ]

  return (
    <Layout>
      <Navigate pageTitle="Lista de Anunciantes" breadcrumbs={breadcrumbsList} />
      <MenuBar
        addUrl="/advertisers/create"
        AddButtonText="Adicionar"
        query={query}
        setQuery={setQuery}
      />
      <Table height={700} rows={dataFiltered} columns={columns} />
      <Outlet />
    </Layout>
  );
};

export default AdvertiserView;
