import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const UsersContext = createContext();
const initialUser = {
  id: null,
  advertiserId: null,
  name: null,
  email: null,
  password: null,
  phone: null,
  profile: null,
  permission: null,
}

export function UsersProvider({ children }) {
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(initialUser);

  function getUser(userId) {
    services.users.get(userId)
      .then((response) => {
        // console.log('getUser', response)
        setUsers([response])
      });
  }

  function getEditUser(userId) {
    services.users.get(userId)
      .then((response) => {
        // console.log('getUser', response)
        setEditUser(response)
      });
  }

  function findUsers(query = {}) {
    services.users.find(query)
    .then((response) => {
      // console.log('findUsers', response)
      setUsers(response.data)
    });
  }

  async function createUsers(user) {
    return services.users.create(user)
  }

  async function patchUsers(id, user) {
    return services.users.patch(id, user)
      .then(() => {
        setEditUser(initialUser);
      });
  }

  async function removeUsers(userId) {
    return services.users.remove(userId)
  }
  return (
    <UsersContext.Provider
      value={{
        users,
        editUser,
        setEditUser,
        getEditUser,
        findUsers,
        createUsers,
        patchUsers,
        getUser,
        removeUsers,
      }}
    >
      { children }
    </UsersContext.Provider>
  );
}

export function useUsers() {
  const context = useContext(UsersContext);

  return context;
}
