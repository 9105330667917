import React, { RefCallback, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
  FormControlLabel,
  Input,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { useSnackbar } from 'notistack';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useUsers } from '../../contexts/useUsers';
import { useUserLogs } from '../../contexts/useUserLogs';
import { useNavigate, useParams } from 'react-router-dom';
import { UserModal, AddModalHandler, EventType } from '../common/types';
import { IMaskInput } from 'react-imask';
import dayjs from 'dayjs';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '90vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const initialUser: UserModal = {
  id: null,
  advertiserId: null,
  placeGroupId: null,
  name: null,
  email: null,
  newPassword: null,
  phone: null,
  profile: null,
  permission: null,
  password: null,
  isEnabled: true,
};

const AddUserModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { userId, advertiserId, placeGroupId } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [totalLogs, setTotalLogs] = useState(0);
  const [lastLogs, setLastLogs] = useState([]);
  const { 
    editUser, 
    setEditUser, 
    getEditUser, 
    createUsers, 
    patchUsers,
    findUsers
  } =
    useUsers();
  const [user, setUser] = useState<UserModal>(initialUser);
  const { userLogs, findUserLogs } = useUserLogs();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const getProfileType = () => {
    if(advertiserId) {
      return 'advertiser';
    } else if (placeGroupId) {
      return 'ecd';
    } else {
      return 'admin';
    }
  }

  const handleInputChange = (event: EventType) => {
    const { name, value } = event.target;
    setUser({
      ...user,
      advertiserId: advertiserId ? Number(advertiserId) : null,
      placeGroupId: placeGroupId ? Number(placeGroupId) : null,
      profile: getProfileType(),
      [name]: value,
    });
  };

  useEffect(() => {
    if (userId && !user?.id) {
      getEditUser(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (editUser.id && editUser?.id !== user?.id) {
      setUser(editUser);
      findUserLogs({query: {
        userId,
        $sort: {
          createdAt: -1,
        },
        $limit: 10,
      }});
    }
  }, [editUser]);

  useEffect(() => {
    console.log(userLogs);
    if (userLogs?.data) {
      setLastLogs(userLogs?.data);
      setTotalLogs(userLogs?.total);
    }
  }, [userLogs])

  const validateUser = (user: UserModal) => {
    const { newPassword } = user;

    if (!user?.name) {
      enqueueSnackbar('Informe o nome do usuário!', { variant: 'error' });
      return false;
    }

    if (!user?.email) {
      enqueueSnackbar('Informe o email do usuário!', { variant: 'error' });
      return false;
    }

    if (!user.id) {
      if (!newPassword) {
        enqueueSnackbar('Informe a senha do usuário!', { variant: 'error' });
        return false;
      } else {
        if (newPassword.length < 6) {
          enqueueSnackbar(
            'Informe a senha do usuário com seis ou mais caracteres!',
            { variant: 'error' }
          );
          return false;
        }
      }
    } else {
      if (newPassword && newPassword.length < 6) {
        enqueueSnackbar(
          'Informe a senha do usuário com seis ou mais caracteres!',
          { variant: 'error' }
        );
        return false;
      }
    }

    // if (!user?.phone) {
    //   enqueueSnackbar('Informe o telefone do usuário!', { variant: 'error' });
    //   return false;
    // }

    // if (!user?.profile) {
    //   enqueueSnackbar('Informe o perfil do usuário!', { variant: 'error' });
    //   return false;
    // }

    // if (!user?.permission) {
    //   enqueueSnackbar('Informe a permissão do usuário!', { variant: 'error' });
    //   return false;
    // }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validateUser(user)) {
      const { newPassword } = user;

      if (newPassword) {
        delete user.newPassword;
        user.password = newPassword;
      }

      if (user.phone) {
        user.phone = user.phone.replace(/\D+/g, '');
      }

      if (user?.id) {
        patchUsers(user.id, user)
          .then(() => {
            enqueueSnackbar('Usuário alterado com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            const { message } = error;
            enqueueSnackbar(message ? message : 'Erro ao alterar usuário!', { variant: 'error' });
          });
      } else {
        createUsers(user)
          .then(() => {
            enqueueSnackbar('Usuário criado com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            const { message } = error;
            enqueueSnackbar(message ? message : 'Erro ao alterar usuário!', { variant: 'error' });
          });
      }
    }
  };

  const reloadUsers = () => {
    if (advertiserId) {
      findUsers({query: {
        advertiserId,
        $sort: {
          name: 1,
        },
      }});
    } else if (placeGroupId) {
      findUsers({query: {
        placeGroupId,
        $sort: {
          name: 1,
        },
      }});
    } else {
      findUsers({query: {
        $sort: {
          name: 1,
        },
      }});
    }
  }

  const closeModal = () => {
    reloadUsers();
    navigate(-1);
    setTimeout(() => {
      setUser(initialUser);
      setEditUser(initialUser);
    }, 1000);
  };

  const TextMaskCustom = useMemo(() => React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
      const { onChange, ...other } = props;
      return (
        <IMaskInput
          {...other}
          key="phone-mask"
          mask="(00) 0 0000-0000"
          definitions={{
            '#': /[1-9]/,
          }}
          inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
          onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
          overwrite
        />
      );
    },
  ), []);

  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CenterModalStyle} onClick={(e) => e.stopPropagation()}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {(!user?.id && 'Novo usuario') || 'Editar usuário'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton className="closeIcon" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
            <Typography>Perfil: 
              {(!advertiserId && !placeGroupId) && 'Administrador'}
              {placeGroupId && 'ECD'}
              {advertiserId && 'Anunciante'}
            </Typography>
          </FormControl>
          <TextField
            label="Nome completo"
            name="name"
            id="name"
            value={user.name || ''}
            onChange={handleInputChange}
            required
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="E-mail"
            name="email"
            id="email"
            value={user.email || ''}
            onChange={handleInputChange}
            required
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel shrink style={{ backgroundColor: '#fff', paddingLeft: 5, paddingRight: 5 }} htmlFor="newPassword">Senha</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              name="newPassword"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={user.newPassword || ''}
              fullWidth
              onChange={handleInputChange}
              label="Password"
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel shrink style={{ backgroundColor: '#fff', paddingLeft: 5, paddingRight: 5 }} htmlFor="phone">Telefone</InputLabel>
            <OutlinedInput
              key="phone"
              value={user.phone || ''}
              onChange={handleInputChange}
              name="phone"
              id="phone"              
              fullWidth
              inputComponent={TextMaskCustom as any}
            />
          </FormControl>
          {user.id &&   
            <FormControlLabel
              control={
                <Checkbox
                  name="isEnabled"
                  id="isEnabled"
                  checked={!!user.isEnabled}
                  onChange={(event: any) =>
                    setUser({
                      ...user,
                      isEnabled: event.target.checked,
                    })
                  }
                />
              }
              label="Ativo"
            />
          } 
          <Button variant="contained" type="submit" fullWidth>
            <AddIcon style={{ marginRight: '1rem' }} />
            {(!user?.id && 'Adicionar novo') || 'Editar usuário'}
          </Button>
        </Box>
        {user?.id && (
          <Box sx={{ m: 1 }}>
            <Typography variant="h6" component="h2">
              Logs
            </Typography>
            Total de Acessos: {totalLogs}
            {lastLogs.map((log: any) => (
              <Typography variant="body1" component="p">
                {dayjs(log?.createdAt).format('DD/MM/YYYY HH:mm')} - {log?.osName} {log?.osVerion} - {log?.browserName} {log?.browserVersion}
              </Typography>
            ))}
          </Box>
        )}

      </Box>
    </Modal>
  );
};

export default AddUserModal;
