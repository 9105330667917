import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import PublishIcon from '@mui/icons-material/Publish';
import PauseIcon from '@mui/icons-material/Pause';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FilledInput, Icon, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useContracts } from '../contexts/useContracts';
import { MenuBarHandler } from './common/types';

const menuBarStyle = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  bgcolor: 'background.paper',
  height: '75px',
  zIndex: 4,
  alignItems: 'center',
  gridColumnEnd: 'auto',
};



const MenuBar = ({
  endAllContracts,
  addUrl,
  AddButtonText,
  query,
  setQuery,
  disableSeach
}: MenuBarHandler) => {
  const location = useLocation();
  const { patchContracts } = useContracts();
  const [ openDialog, setOpenDialog ] = useState(false)

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const handleChangeSearch = (e: any) => {
    e.preventDefault();
    setQuery(e.target.value)
  }

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

  const AddUserStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 5fr',
    gridGap: '5px',
  };

  const AddButton = {
    display: 'grid',
    justifyContent: 'right',
    paddingRight: '1rem',
  };

  const handleClickEndAllContracts = () => {
    setOpenDialog(false);
    const filtredContracts = endAllContracts?.contracts?.filter(cnt => !!cnt.contractIsActive)
    filtredContracts?.forEach((contract: any) => {
      patchContracts(contract.id, {
        ...contract,
        isDisabled: true,
      });
    });
  };

  const filtredContracts = endAllContracts?.contracts?.filter(cnt => !!cnt.contractIsActive)

  return (
    <Box sx={menuBarStyle}>
      {/* <Grid item xs={endAllContracts ? 2 : 4}>
        <Button variant="outlined">
          <PublishIcon
            style={{ fontSize: 'medium', marginRight: '0.5rem' }}
          />
          Exportar
        </Button>
      </Grid> */}
      
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: 2 }}>
        {endAllContracts?.visible ? (
          <Button variant="outlined" onClick={() => setOpenDialog(true)} disabled={filtredContracts?.length === 0}>
            <PauseIcon
              style={{ fontSize: 'medium', marginRight: '0.5rem' }}
            />
            Encerrar todos
          </Button>
        ) : null}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 2 }}>
        {disableSeach ? null : (
          <Search>
            <OutlinedInput
              id="filled-adornment-password"
              size= 'small'
              onChange={(e) => handleChangeSearch(e)}
              value={query}
              placeholder= 'Pesquisar'
              autoFocus
              autoComplete='off'
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Search>
        )}
        {(addUrl && AddButtonText) ? (
          <Link
            to={addUrl}
            state={{ background: location }}
            style={{ textDecoration: 'none', marginLeft: 10 }}
          >
            <Button variant="contained" sx={AddUserStyle}>
              <AddIcon />
              <Typography>{AddButtonText}</Typography>
            </Button>
          </Link>
        ) : null}
      </Box>
 
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Encerrar todos os contratos?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{color: 'red'}}>Esta operação não poderá ser desfeita.</div>
              <div>Deseja finalizar todos os {filtredContracts?.length} contratos ativos desta campanha? </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
            <Button onClick={handleClickEndAllContracts} autoFocus>
              Finalizar Todos
            </Button>
          </DialogActions>
        </Dialog>
 
    </Box>
  );
};

export default MenuBar;
