import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
  Autocomplete,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import QRCode from 'qrcode.react';
import { Sonyflake } from 'sonyflake';
import QRCODE_BASE_URL from '../../api/qrcodeBaseUrl';

import { useSnackbar } from 'notistack';
import { Pinterest, Visibility, VisibilityOff } from '@mui/icons-material';
import { usePoints } from '../../contexts/usePoints';
import { useQrcodeSizes } from '../../contexts/useQrcodeSizes';
import { useTags } from '../../contexts/useTags';
import { usePlaceZones } from '../../contexts/usePlaceZones';
import { useNavigate, useParams } from 'react-router-dom';

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '90vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

interface PointInterface {
  id: number | null;
  hash: string | null;
  description: string | null;
  urlMenuTable: string | null;
  tags: Array<string> | null | unknown;
  qrcodeSize: string | null;
  placeZoneId?: number | null;
}

interface QrcodeSizeInterface {
  id: number | null;
  name: string | null;
}

interface TagInterface {
  id: number | null;
  name: string | null;
}

interface PlaceZoneInterface {
  id: number | null;
  name: string | null;
}

const initialPoint: PointInterface = {
  id: null,
  hash: null,
  description: null,
  urlMenuTable: null,
  tags: [] as string[],
  qrcodeSize: null,
  placeZoneId: null,
};

const AddPointModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { placeId, pointId } = useParams();
  const { editPoint, setEditPoint, getEditPoint, createPoints, patchPoints } =
    usePoints();
  const { qrcodeSizes, findQrcodeSizes } = useQrcodeSizes();
  const { tags, findTags } = useTags();
  const { placeZones, findPlaceZones } = usePlaceZones();
  const [point, setPoint] = useState<PointInterface>(initialPoint);

  const sonyflake = new Sonyflake({
    machineId: 1, // in range 2^16
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setPoint({
      ...point,
      [name]: value,
    });
  };

  const getNextHash = () => {
    if (!point.hash) {
      setPoint({ ...point, hash: String(sonyflake.nextId()) });
    }
  };

  useEffect(() => {
    findQrcodeSizes();
    findTags();
    getNextHash();
    findPlaceZones({ query: { placeId }})
  }, []);

  useEffect(() => {
    if (pointId && !point?.id) {
      getEditPoint(pointId);
    }
    findPlaceZones({ query: { placeId } });
  }, [pointId]);

  useEffect(() => {
    if (editPoint.id && editPoint?.id !== point?.id) {
      setPoint(editPoint);
    }
  }, [editPoint]);

  const validatePoint = (point: any) => {
    if (!point?.hash) {
      enqueueSnackbar('Informe a hash do QrCode!', { variant: 'error' });
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // console.log('point: ', point);
    if (validatePoint(point)) {
      if (point?.id) {
        patchPoints(point.id, point)
          .then(() => {
            // console.log('Deu certo');
            enqueueSnackbar('QrCode alterado com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            // console.log('Erro ao alterar', error);
            const msgError = error.message.includes('unique')
              ? 'QrCode já cadastrado no banco de dados'
              : 'Erro ao criar QrCode!';
            enqueueSnackbar(msgError, { variant: 'error' });
          });
      } else {
        createPoints({ ...point, placeId })
          .then(() => {
            // console.log('Deu certo');
            enqueueSnackbar('QrCode criado com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            // console.log('Erro ao criar', error.message);
            const msgError = error.message.includes('unique')
              ? 'QrCode já cadastrado no banco de dados'
              : 'Erro ao criar QrCode!';
            enqueueSnackbar(msgError, { variant: 'error' });
          });
      }
    }
  };

  const closeModal = () => {
    setPoint(initialPoint);
    setEditPoint(initialPoint);
    navigate(-1);
  };

  const downloadQR = () => {
    const canvas = document.getElementById('qrCodeLarge') as HTMLCanvasElement;
    const pngUrl = canvas
      ?.toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    const fileName = `place${placeId}-${String(point.qrcodeSize).replaceAll(
      ' ',
      '_'
    )}-${point.hash}.png`;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const tagsList = tags.map((tg: TagInterface) => tg.name);

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CenterModalStyle} onClick={(e) => e.stopPropagation()}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {pointId ? 'Editar QrCode' : 'Adicionar QrCode'}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton className="closeIcon" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Box
          component="form"
          sx={{ display: 'flex', flexWrap: 'wrap' }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ mt: 2, minWidth: 120 }}
          >
            <InputLabel 
              shrink 
              style={{ 
                backgroundColor: '#fff', 
                paddingLeft: 5, 
                paddingRight: 5 
              }} 
              id="demo-simple-select-outlined-label"
            >
              Zona
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="placeZoneId"
              name="placeZoneId"
              value={point.placeZoneId || ''}
              onChange={handleInputChange}
              label="Tamanho"
              input={<OutlinedInput />}
              required
            >
              <MenuItem value="">Selecione</MenuItem>
              {placeZones.map((zone: PlaceZoneInterface) => (
                <MenuItem key={zone.id} value={String(zone.id)}>
                  {zone.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ mt: 2, minWidth: 120 }}
          >
            <InputLabel 
              shrink 
              style={{ 
                backgroundColor: '#fff', 
                paddingLeft: 5, 
                paddingRight: 5 
              }} 
              id="demo-simple-select-outlined-label"
            >
              Tamanho
            </InputLabel>

            <Select
              labelId="demo-simple-select-outlined-label"
              id="qrcodeSize"
              name="qrcodeSize"
              value={point.qrcodeSize || ''}
              onChange={handleInputChange}
              label="Tamanho"
              input={<OutlinedInput />}
              required
            >
              <MenuItem value="">Selecione</MenuItem>
              {qrcodeSizes.map((size: QrcodeSizeInterface) => (
                <MenuItem key={size.id} value={String(size.name)}>
                  {size.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Hash"
            name="hash"
            id="hash"
            fullWidth
            disabled={!!pointId}
            sx={{ mt: 2 }}
            value={point.hash || ''}
            onChange={handleInputChange}
            required
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <Typography variant="subtitle2" sx={{ mt: 1, mb: 2 }}>
            <b>URL thirky:</b>
            <a href={`${QRCODE_BASE_URL}${point.hash}`} target='_blank' rel="noreferrer">{`${QRCODE_BASE_URL}${point.hash}`}</a>
          </Typography>
          <TextField
            label="Descrição"
            name="description"
            id="description"
            fullWidth
            sx={{ mt: 2 }}
            value={point.description || ''}
            onChange={handleInputChange}
            required
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Url Menu"
            name="urlMenuTable"
            id="urlMenuTable"
            fullWidth
            sx={{ mt: 2 }}
            value={point.urlMenuTable || ''}
            onChange={handleInputChange}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          {((!pointId && tagsList.length > 0) ||
            (pointId && tagsList.length > 0 && point.id)) && (
            <Autocomplete
              multiple
              id="size-small-filled-multi"
              // size="small"
              fullWidth
              sx={{ mt: 2 }}
              options={tagsList}
              getOptionLabel={(tag) => tag}
              onChange={(event, value) =>
                setPoint({ ...point, tags: value ? value : [] })
              }
              defaultValue={point.tags as string[]}
              // renderTags={(value, getTagProps) =>
              //   value.map((option, index) => (
              //     <Chip
              //       variant="outlined"
              //       label={tag}
              //       size="small"
              //       {...getTagProps({ index })}
              //     />
              //   ))
              // }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Tags"
                  // placeholder="Favorites"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          )}
          {pointId && (
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ mt: 2, minWidth: 120, alignItems: 'center' }}
            >
              <QRCode
                id="qrCode"
                value={`${QRCODE_BASE_URL}${point.hash}`}
                size={290}
                level={'H'}
                includeMargin={true}
              />

              <QRCode
                id="qrCodeLarge"
                style={{ display: 'none' }}
                value={`${QRCODE_BASE_URL}${point.hash}`}
                size={1200}
                level={'H'}
                includeMargin={true}
              />
              <Button onClick={downloadQR}> Download QR Code </Button>
            </FormControl>
          )}
          <Button variant="contained" type="submit" sx={{ mt: 2 }}>
            <AddIcon style={{ marginRight: '1rem' }} />
            {(!point?.id && 'Adicionar novo') || 'Editar QrCode'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPointModal;
