import React, { 
  ChangeEvent, 
  Fragment, 
  useEffect, 
  useRef, 
  useState,
} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { NumericFormat } from 'react-number-format';

import { useSnackbar } from 'notistack';

import { VideoCameraBack, PhotoCamera } from '@mui/icons-material';
import { useCampaigns } from '../../contexts/useCampaigns';
import { useAdvertisers } from '../../contexts/useAdvertisers';
import { useCampaignMedias } from '../../contexts/useCampaignMedias';
import videoPlaceholder from '../img/video-placeholder.jpg';
import imagePlaceholder from '../img/image-placeholder.jpg';

import {
  AdvertiserFilter,
  CampaignModal,
  AddModalHandler,
  EventType,
  ValueChange,
  CampaignMediaInterface,
} from '../common/types';
import { v4 as uuidv4 } from 'uuid';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '90vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const initialCampaignMedia = {
  id: null,
  name: null,
  type: null,
  campaignId: null,
  urlCover: null,
  urlVideo: null,
  videoDuration: null,
  newFileVideo: null,
  newFileCover: null,
  urlCta: null,
};

const AddCampaignMediaModal = React.forwardRef((props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { campaignId, campaignMediaId } = useParams();
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const {
    editCampaignMedia,
    getEditCampaignMedia,
    setEditCampaignMedia,
    findCampaignMedias,
    createCampaignMedias,
    patchCampaignMedias,
    removeCampaignMedias,
  } = useCampaignMedias();

  const [ campaignMedia, setCampaignMedia ] =
    useState<CampaignMediaInterface>({ ...initialCampaignMedia });

  const handleInputChange = (event: EventType) => {
    const { name, value } = event?.target || event;
    setCampaignMedia({
      ...campaignMedia,
      [name]: value,
    });
  };

  useEffect(() => {
    if (campaignMediaId && !editCampaignMedia?.id) {
      getEditCampaignMedia(campaignMediaId);
    }
  }, [campaignMediaId]);

  useEffect(() => {

    if (editCampaignMedia?.id == campaignMediaId) {
      setCampaignMedia(editCampaignMedia);
    }
  }, [editCampaignMedia]);

  const validateCampaignMedia = (campaignMedia: CampaignMediaInterface) => {
    if (!campaignMedia?.type) {
      enqueueSnackbar('Informe o tipo da mídia!', { variant: 'error' });
      return false;
    }

    if (!campaignMedia?.id && !campaignMedia?.newFileCover && !campaignMedia?.urlCover) {
      enqueueSnackbar('Selecione as mídias!', {  variant: 'error' });
      return false
    }

    if ((campaignMedia?.newFileVideo || campaignMedia?.urlVideo) && !campaignMedia?.videoDuration) {
      enqueueSnackbar('Informe o tempo de duração do vídeo!', {  variant: 'error' });
      return false;
    }

    return true;
  };

  const uploadFileCover = async (e: any, mediaType: string) => {
    const file = e.target.files[0];
    const base64: string | unknown = await convertBase64(file);
      setCampaignMedia({
        ...campaignMedia,
        newFileCover: base64 as string || null,
      });
  };

  const uploadFileVideo = async (e: any, mediaType: string) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setCampaignMedia({
      ...campaignMedia,
      newFileVideo: base64 ? base64 : null,
    });
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDelete = async () => {
    setLoading(true);
    removeCampaignMedias(campaignMedia.id).then(() => {
      enqueueSnackbar('Mídia da Campanha removida com sucesso!', {
        variant: 'success',
      });
      closeModal();
      setLoading(false);
    }).catch((error: any) => {
      enqueueSnackbar('Erro ao remover mídia da campanha!', { variant: 'error' });
      setLoading(false);
    })
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (validateCampaignMedia(campaignMedia)) {
      if (campaignMedia?.id) {
        patchCampaignMedias(campaignMedia.id, campaignMedia)
          .then(async () => {
            enqueueSnackbar('Mídia da Campanha alterada com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            // console.log('Erro ao alterar', error);
            enqueueSnackbar('Erro ao alterar mídia da campanha!', { variant: 'error' });
            setLoading(false);
          });
      } else {
        createCampaignMedias({ ...campaignMedia, campaignId: Number(campaignId) })
          .then(async (result: any) => {
            enqueueSnackbar('Mídia da Campanha criada com sucesso!', {
              variant: 'success',
            });
            closeModal(`/campaigns/${result?.id}/profile`);
          })
          .catch((error: any) => {
            // console.log('Erro ao criar', error);
            enqueueSnackbar('Erro ao criar mídia da campanha!', { variant: 'error' });
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  };

  const closeModal = (goToAfterCloseModal?: string | null) => {

    if (campaignId) {
      navigate(`/campaigns/${campaignId}/profile`);
    }
    
    setLoading(false);
    reloadCampaignMedias();
    setTimeout(() => {
      // setCampaign(initialCampaign);
      setEditCampaignMedia(initialCampaignMedia);
    }, 500);
  };

  const reloadCampaignMedias = () => {
    if (campaignId) {
      findCampaignMedias({
        query: {
          campaignId,
        },
      });
    }
  }

  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      style={{ overflow: 'scroll' }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box 
        sx={CenterModalStyle} 
        onClick={(e) => e.stopPropagation()}
      >
        <Stack 
          sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }} 
          direction="row" 
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {(!campaignMedia?.id && 'Nova Mídia') || 'Editar Mídia'}
          </Typography>

          <IconButton className="closeIcon" onClick={() => closeModal()}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box
          component="form"
          sx={{
            display: 'flex', 
            flexWrap: 'wrap',
            '& .MuiTextField-root': { mt: 2, width: '49%' },
          }}  
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ mt: 2, width: '100% !important' }}
          >
            <InputLabel 
              shrink 
              style={{ 
                backgroundColor: '#fff', 
                paddingLeft: 5, 
                paddingRight: 5 
              }} 
              id="demo-simple-select-outlined-label"
            >
              Tipo de Mídia
            </InputLabel>
              <Select
              labelId="demo-simple-select-outlined-label"
              sx={{ width: '98% !important', ml: '1%' }}
              id="type"
              name="type"
              value={campaignMedia.type || ''}
              onChange={handleInputChange}
              label="Tipo de Mídia"
              input={<OutlinedInput />}
              required
              disabled={!!campaignMedia?.id}
            >
              <MenuItem value="">Selecione</MenuItem>
              <MenuItem value="preRoll"> PreRoll </MenuItem>
              <MenuItem value="highlight"> Highlight </MenuItem>
              <MenuItem value="listItemTop"> Item Lista Topo </MenuItem>
              <MenuItem value="listItemBottom"> Item Lista Bottom </MenuItem>
            </Select>     
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ mt: 2, width: '100% !important' }}
          >
            <TextField
              label="Nome da Mídia"
              name="name"
              id="name"
              sx={{ width: '98% !important', ml: '1%' }}
              value={campaignMedia.name || ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setCampaignMedia({...campaignMedia, name: event.target.value})}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />  
          </FormControl>
          <Box sx={{ mt: 2, width: '100%' }}>
            <Stack flexDirection='row' sx={{ width: '100%' }}>
              <FormControl variant="outlined" sx={{ m: 1, width: '50%' }}>
                <>
                  <Card sx={{  width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mr: 1, mt: 1}}>
                    {campaignMedia?.newFileCover || campaignMedia?.urlCover ? (
                      <Fragment>
                        <CardContent>
                          <img
                            style={{ width: '100%' }}
                            src={
                              campaignMedia?.newFileCover
                                ? (campaignMedia?.newFileCover ? campaignMedia?.newFileCover as string : undefined)
                                : (campaignMedia?.urlCover ? campaignMedia?.urlCover as string : undefined)
                            }
                            alt="Cover campanha"
                          />
                        </CardContent>
                        <CardActions>
                          <Button
                            sx={{ mt: 2, width: '100%' }}
                            onClick={() =>
                              setCampaignMedia({ ...campaignMedia, urlCover: null, newFileCover: null })
                            }
                          >
                            Excluir
                          </Button>
                        </CardActions>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <CardContent>
                          <img
                            style={{ width: '100%' }}
                            src={imagePlaceholder}
                            alt="Cover campanha"
                          />
                        </CardContent>
                        <CardActions>
                          <Button
                          variant="contained"
                          component="label"
                          sx={{ mt: 2, width: '100%' }}
                          startIcon={<PhotoCamera />}
                          >
                            Imagem
                            <input
                              hidden
                              key={Math.random().toString(36)}
                              accept="image/*"
                              onChange={(e) => uploadFileCover(e, 'preRoll')}
                              type="file"
                            />
                          </Button>
                        </CardActions>
                      </Fragment>
                    )}
                  </Card>
                </>
              </FormControl>
              <FormControl variant="outlined" sx={{ m: 1, width: '50%' }}>
                <Card sx={{  width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mr: 1, mt: 1}}>
                  {(campaignMedia?.newFileVideo || campaignMedia?.urlVideo) ? (
                    <Fragment>
                      <CardContent>
                        <video  style={{ marginTop: 15, width: '100%'}} controls>
                          <source
                            type="video/mp4"
                            src={
                              campaignMedia?.newFileVideo
                                ? (campaignMedia?.newFileVideo as string)
                                : (campaignMedia?.urlVideo as string)
                            }
                          />
                        </video>
                        <TextField
                          label="Duração do vídeo (segundos)"
                          name="campaignMediaViewDuration"
                          id="campaignMediaViewDuration"
                          sx={{ mr: '1%', width: '100% !important' }}
                          value={campaignMedia.videoDuration || ''}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => setCampaignMedia({...campaignMedia, videoDuration: Number(event.target.value)})}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />                      
                      </CardContent>
                      <CardActions>
                        <Button
                          sx={{ mt: 2, width: '100%' }}
                          onClick={() =>
                            setCampaignMedia({ ...campaignMedia, urlVideo: null, newFileVideo: null })
                          }
                        >
                          Excluir
                        </Button>
                      </CardActions>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <CardContent style={{ width: '100%' }}>
                        <img
                          style={{ width: '100%' }}
                          src={videoPlaceholder}
                          alt="Cover campanha"
                        />
                        <TextField
                          fullWidth
                          label="Duração do vídeo (segundos)"
                          name="campaignMediaViewDuration"
                          id="campaignMediaViewDuration"
                          sx={{ mr: '1%', width: '100% !important' }}
                          value={campaignMedia.videoDuration || ''}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => setCampaignMedia({...campaignMedia, videoDuration: Number(event.target.value)})}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </CardContent>
                      <CardActions>
                        <Button
                        variant="contained"
                        component="label"
                        sx={{ mt: 2, width: '100%' }}
                        startIcon={<VideoCameraBack />}
                        >
                          Vídeo
                          <input
                            hidden
                            key={Math.random().toString(36)}
                            accept="video/*"
                            onChange={(e) => uploadFileVideo(e, 'preRoll')}
                            multiple
                            type="file"
                          />
                        </Button>
                      </CardActions>
                    </Fragment>
                  )}
                </Card> 
              </FormControl>
            </Stack>
          </Box>   
          <FormControl fullWidth sx={{ m: 1 }}>
            <Divider sx={{ width:'100%'}} />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              label="URL Clique Midia"
              name="urlCta"
              id="urlCta"
              sx={{ mr: '1%', width: '100% !important' }}
              value={campaignMedia.urlCta || ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setCampaignMedia({...campaignMedia, urlCta: String(event.target.value)})}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1, mt: 3 }}>
            <LoadingButton type="submit" loading={loading} variant="contained">
              <span>
                {(!campaignMedia?.id && 'Criar mídia') || 'Editar mídia'}
              </span>
            </LoadingButton>
          </FormControl>
          {campaignMedia?.id && (
            <FormControl fullWidth sx={{ m: 1, mt: 3 }}>
              <LoadingButton sx={{ backgroundColor: 'red' }} onClick={() => setOpenDeleteDialog(true)} loading={loading} variant="contained">
                Excluir
              </LoadingButton>
            </FormControl>
          )}
        </Box>
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Alterar formato de mídia?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>Deseja excluir mídia?</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)}>Cancelar</Button>
            <Button onClick={() => handleDelete()} autoFocus>
              Excluir Mídia
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
});

export default AddCampaignMediaModal;
