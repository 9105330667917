import React, { SyntheticEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import Layout from '../../components/Layout';
import Table from '../../components/Table';
import MenuBar from '../../components/MenuBar';
import Navigate from '../../components/Navigate';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useParams, Outlet, Link, useLocation } from 'react-router-dom';
import TitleTabs from '../../components/TitleTabs';
import { useUsers } from '../../contexts/useUsers';
import MapIcon from '@mui/icons-material/Map';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Grid, Avatar, IconButton, Chip, Button, Stack, Typography, TextField, Card, CardHeader, CardContent } from '@mui/material';
import { Box } from '@mui/system';
import { usePlaceGroups } from '../../contexts/usePlaceGroups';
import { usePlaces } from '../../contexts/usePlaces';
import { useCampaigns } from '../../contexts/useCampaigns';
import {
  PlaceGroupInterface,
  BreadcrumbType,
  CampaignFilter,
  UserFilter,
  ValueChange,
} from '../../components/common/types';
import { FormattedNumber } from 'react-intl';
import PhoneNumber from '../../components/PhoneNumber';
import { AttachMoneyOutlined, EditOutlined, MapOutlined, PaidOutlined, RemoveRedEyeOutlined } from '@mui/icons-material';
import CampaignOutlined from '@mui/icons-material/CampaignOutlined';

import QR_CODE_CLIENT_URL from '../../api/qrCodeClientUrl';
import numeral from 'numeral';
import { NumericFormat } from 'react-number-format';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useExportReports } from '../../contexts/useExportReports';

const initialPlaceGroup: PlaceGroupInterface = {
  id: null,
  name: null,
  pix: null,
  urlImage: null,
  updatedAt: null,
  splitPlaceGroup: null,
  splitPlace: null,
  nOfViewsLast30Days: 0,
  nOfViewsLast7Days: 0,
  totalPlacePendingAmount: 0,
  totalPlaceAmount: 0,
  totalPlaceGroupPendingAmount: 0,
  totalPlaceGroupAmount: 0,
};

const bignumbersCardsStyle = {
  p: 2, 
  m: 2,
  width: '250px', 
  height: 120, 
  backgroundColor: '#f7f7f7', 
  borderRadius: 1 
}

const NumberFormatCustom = (props: any) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NumericFormat {...props} />;
};

const PlaceGroupProfile = () => {
  const [value, setValue] = useState(0);
  const location = useLocation();

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    console.log('newValue', newValue)
    if (newValue === 0) {
      findPlacesList();
    }
    if (newValue === 1) {
      // findCampaignsList();
    }
    if (newValue === 2) {
      findUsersList();
    }
    setValue(newValue);
  };

  const numberFormatK = (value: number)=> value >= 1000 ? numeral(value).format('0.00a').toUpperCase() : value;

  const { placeGroups, getPlaceGroup, patchPlaceGroups } = usePlaceGroups();
  const [ loading, setLoading ] = useState<boolean>(false);
  const [placeGroup, setPlaceGroup] =
    useState<PlaceGroupInterface>(initialPlaceGroup);
  const { placeGroupId } = useParams();

  const handleInputChange = (event: any) => {
    const { name, value } = event?.target || event;
    setPlaceGroup({
      ...placeGroup,
      [name]: value,
    });
  };

  const getPlaceGroupInformation = () => {
    if (!placeGroup.id) {
      // console.log('getPlaceGroupInformation');
      getPlaceGroup(placeGroupId, { query: { includeBigNumbers: true } });
    }
  };

  const transformPlaceNumbers = (places: any) => {
    const newPlaces = places.map((place: any) => {
      return {
        ...place,
        nOfViewsLast30Days: Number(place.nOfViewsLast30Days || 0),
        nOfViewsLast7Days: Number(place.nOfViewsLast7Days || 0),
      }
    })
    setFormatedPlaces(newPlaces);
  }

  useEffect(() => {
    // console.log('useEffect getPlaceGroupInformation', placeGroupId);
    getPlaceGroupInformation();
  }, [placeGroupId]);

  useEffect(() => {
    const placeGroupProfile = placeGroups.find((adv: PlaceGroupInterface) => adv.id === Number(placeGroupId))

    if ((!placeGroup.id && placeGroupProfile?.id == placeGroupId) || (placeGroup.updatedAt != placeGroupProfile?.updatedAt) ) {
      // console.log('executando useEffect setPlaceGroup');
      setPlaceGroup(placeGroupProfile as PlaceGroupInterface);
    }
  }, [placeGroups]);

  const { findExportReports } = useExportReports();
  const { enqueueSnackbar } = useSnackbar();
  const { places, findPlaces } = usePlaces();
  const [ formatedPlaces, setFormatedPlaces ] = useState([]);
  const { campaigns, findCampaigns } = useCampaigns();
  const { users, findUsers } = useUsers();
  const [queryUser, setQueryUser] = useState('');
  const [queryPlace, setQueryPlace] = useState('');
  const [queryCampaign, setQueryCampaign] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [loadingSlugsRequestsByPlaceGroupResume, setLoadingSlugsRequestsByPlaceGroupResume] = useState(false);

  const filterDataUser = (query: string, data: any) => {
    if (!query) {
      return data;
    } else {
      return data.filter(({ name, email }: any) =>
        name.toLowerCase().includes(query.toLowerCase()) || email.toLowerCase().includes(query.toLowerCase())
      );
    }
  };

  const dataUsersFiltered = filterDataUser(queryUser, users);

  const filterDataPlace = (query: string, data: any) => {
    if (!query) {
      return data;
    } else {
      return data.filter(({ name }: any) =>
        name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };

  const dataPlacesFiltered = filterDataPlace(queryPlace, formatedPlaces);

  const filterDataCampaign = (query: string, data: any) => {
    if (!query) {
      return data;
    } else {
      return data.filter(({ name }: any) =>
        name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };

  const dataCampaignsFiltered = filterDataCampaign(queryCampaign, campaigns);

  const findPlacesList = () => {
    findPlaces({
      query: {
        includePlaceGroupBigNumbers: true, 
        placeGroupId
      }
    });
  };

  // const findCampaignsList = () => {
  //   findCampaigns({
  //     query: {
  //       includeBigNumbers: true,
  //       placeGroupId
  //     }
  //   });
  // };

  const findUsersList = () => {
    // if (!filteredUsers.length) {
      findUsers({
        query: {
          placeGroupId
        }
      });
    // }
  };

  useEffect(() => {
    findUsersList();
    findPlacesList();
  }, []);

  useEffect(() => {
    transformPlaceNumbers(places);
  }, [places]);

  const placeColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Estabelecimento',
      width: 450,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <Link to={`/places/${row?.id}/profile`} style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 2 }}>
              <Avatar
                alt=""
                src={row.urlImage}
                style={{ marginRight: '1.5rem' }}
              />
              {row?.name}
            </Box>
          </Link>
        </React.Fragment>

      ),
    },
    {
      field: 'isEnabled',
      headerName: 'Status',
      width: 330,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!row?.enabledCampaignsAt ? (
            <Chip label="Inativo" color="error" />
          ) : (
            <Chip label={`Ativado em ${dayjs(row?.enabledCampaignsAt).format('DD/MM/YY')}`} color="success" />
          )}
        </React.Fragment>
      ),
    },
    {
      field: 'acceptedMediaTypes',
      headerName: 'Tipos Midia',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>
          {row.acceptedMediaTypes?.includes('highlight') ? ' HL ' : null}
          {row.acceptedMediaTypes?.includes('listItemTop') ? ' LT ' : null}
          {row.acceptedMediaTypes?.includes('preRoll') ? ' PR ' : null}
        </span>
      ),
    },
    {
      field: 'nOfViewsLast30Days',
      headerName: '30 Dias',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{numeral(row.nOfViewsLast30Days).format('0,0')}</span>
      ),
    },
    {
      field: 'nOfViewsLast7Days',
      headerName: '7 Dias',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{numeral(row.nOfViewsLast7Days).format('0,0')}</span>
      ),
    },
    {
      field: 'avgViewsLast7Days',
      headerName: 'Media 7 Dias',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{numeral(row.nOfViewsLast7Days/7).format('0,0')}</span>
      ),
    },
    {
      field: 'totalPlacePendingAmount',
      headerName: 'À receber',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlacePendingAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'totalPayedAmount',
      headerName: 'Recebido',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlaceAmount - row?.totalPlacePendingAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'totalPlaceAmount',
      headerName: 'Total',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlaceAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
  ];

  const campaignColumns: GridColDef[] = [
    // columns field are different from Database Model places
    // {
    //   field: 'id',
    //   headerName: 'ID',
    //   width: 90,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'name',
      headerName: 'Nome',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/campaigns/${row?.id}/profile`}
          target='_blank'
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
        >
          {row?.name}
        </Link>
      ),
    },
    {
      field: 'nOfActivePlacesStatus',
      headerName: 'Status',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {(!!row?.nOfActivePlaces && row?.nOfActivePlaces > 0) ? (
            <Chip label="Rodando" color="success" />
          ) : (
            <Chip label="Parada" color="warning" />
          )}
        </React.Fragment>
      ),
    },
    {
      field: 'id',
      headerName: 'Preview',
      width: 80,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`${QR_CODE_CLIENT_URL}/preview/${row.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <RemoveRedEyeOutlined
            key={row?.id}
            id={row?.id}
            sx={{ color: '#FF4C51', width: '33px', height: '33px' }}
          />
        </Link>
      ),
    },
    {
      field: 'nOfContracts',
      headerName: 'Contratos',
      width: 100,
      // resizable: true,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfActiveContracts',
      headerName: 'Contratos Ativos',
      width: 150,
      // resizable: true,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'goal',
      headerName: 'Objetivo',
      width: 100,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'viewsGoal',
      headerName: 'Objetivo Impressões',
      width: 100,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfViews',
      headerName: 'Impressões',
      width: 120,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {
            row?.nOfViews && row?.viewsGoal 
              ? 
                <>
                  {Number(row?.nOfViews/row?.viewsGoal * 100).toFixed(2)}%
                  ({row?.nOfViews})
                </>
              : 
                '-'
          }
        </React.Fragment>
      ),
    },
    {
      field: 'viewsAvg',
      headerName: 'Exibição Média',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfCtas',
      headerName: 'Cliques',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'ctr',
      headerName: 'CTR',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
  ];

  const userColumns: GridColDef[] = [
    // {
    //   field: 'placeGroupId',
    //   headerName: 'ID',
    //   width: 90,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'name',
      headerName: 'Nome',
      width: 230,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/placeGroups/${placeGroupId}/users/edit/${row?.id}`}
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
          state={{ background: location }}
        >
          {row?.name}
        </Link>
      ),
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 330,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <PhoneNumber value={row.phone} />
      ),
    },
    {
      field: 'isEnabled',
      headerName: 'Status',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!row?.isEnabled ? (
            <Chip label="Inativo" color="error"/>
          ) : (
            <Chip label="Ativo" color="success" />
          )}
        </React.Fragment>
      ),
    },
    // {
    //   field: 'profile',
    //   headerName: 'Profile',
    //   width: 130,
    //   headerClassName: 'app-theme--header',
    // },
    // {
    //   field: 'permission',
    //   headerName: 'Permission',
    //   width: 130,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
  ];

  const tabNames = ['Estabelecimentos', 'Usuarios', 'Configurações'];

  const calculateTotalValue = () => {
    let total = 0;
    campaigns.forEach((cmp: CampaignFilter) => {
      const { viewsGoal = 0, viewCost = 0} = cmp;
      total += viewsGoal * viewCost;
    });
    return total
  }

  const sumPlaces = () => {
    let totalPlaces = 0;
    campaigns.forEach((cmp: CampaignFilter) => {
      const { nOfPlaces = 0 } = cmp;
      totalPlaces += Number(nOfPlaces);
    });

    return totalPlaces
  }

  const placeGroupInformation = [
    {
      title: 'Estabelecimentos',
      quantity: campaigns.length,
      icon: <MapIcon sx={{ width: 36, height: 36, color: 'white' }} />,
      color: '#0288D1',
    },
    // {
    //   title: 'Lugares',
    //   quantity: sumPlaces(),
    //   icon: (
    //     <InsertDriveFileIcon sx={{ width: 36, height: 36, color: 'white' }} />
    //   ),
    //   color: '#388E3C',
    // },
    {
      title: 'Valor Acumulado',
      quantity: calculateTotalValue(),
      icon: (
        <InsertDriveFileIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#F57C00',
    },
  ];

  const handleSubmit = async () => {
    // console.log('place: ', place);
    setLoading(true)
    // if (validatePlace(place)) {
      if (placeGroup?.id) {
        patchPlaceGroups(placeGroup.id, placeGroup)
          .then(() => {
            // console.log('Deu certo');
            enqueueSnackbar('ECD alterada com sucesso!', {
              variant: 'success',
            });
            setLoading(false)
          })
          .catch((error: any) => {
            // console.log('Erro ao alterar', error);
            enqueueSnackbar(error.message ? error.message : 'Erro ao alterar ECD!', {
              variant: 'error',
            });
            setLoading(false)
          });
      // }
    } else {
      setLoading(false)
    }
  };

  const switchMenuBarAccordinTab = (() => {
    switch (value) {
      case 0:
        return (
          <MenuBar
            // addUrl={`/placeGroups/${placeGroupId}/campaigns/create`}
            // AddButtonText="Novo Estabelecimento"
            query={queryPlace}
            setQuery={setQueryPlace}
          />
        );

      // case 1:
      //   return (
      //     <MenuBar
      //       addUrl={`/placeGroups/${placeGroupId}/places/create`}
      //       AddButtonText="Novo Contrato"
      //       query={queryPlace}
      //       setQuery={setQueryPlace}
      //     />
      //   );

      case 1:
        return (
          <MenuBar
            addUrl={`/placeGroups/${placeGroupId}/users/create`}
            AddButtonText="Novo Usuario"
            query={queryUser}
            setQuery={setQueryUser}
          />
        );

      case 2:
        return null;

      default:
        return (
          <MenuBar
            addUrl={`/placeGroups/${placeGroupId}/places/create`}
            AddButtonText="Novo Contrato"
            query={queryPlace}
            setQuery={setQueryPlace}
          />
        );
    }
  })();

  const switchTableAccordingTab = (() => {
    switch (value) {
      case 0:
        return ( 
          <>
            <Table height={550} rows={dataPlacesFiltered} columns={placeColumns} />
            <Typography
              variant='subtitle2' 
              style={{ color: 'gray' }}
            >
              * Dados atualizados diariamente às 00:01
            </Typography>
          </>
        );
      // case 1:
      //   return <Table height={550} rows={dataPlacesFiltered} columns={placeColumns} />;

      case 1:
        return <Table height={550} rows={dataUsersFiltered} columns={userColumns} />;

      case 2:
        return         <Grid container sx={{ bgcolor: 'background.paper', mt: 2 }}>
        <Grid item xs={6} sx={{ p: 2 }} rowSpacing={2}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ display: 'flex', flexDirection: 'column' }}
            // onSubmit={handleSubmit}
          >
            <TextField
              label="% ECD"
              value={placeGroup.splitPlaceGroup}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  thousandSeparator: '.',
                  decimalSeparator: ',',
                  valueIsNumericString: true,
                  decimalScale: 4,
                  variant: 'outlined',
                  onValueChange: (vals: ValueChange) =>
                    handleInputChange({
                      name: 'splitPlaceGroup',
                      value: vals.floatValue,
                    }),
                },
                disableUnderline: true,
              }}
              sx={{ mt: 2 }}
              InputLabelProps={{ shrink: true }}
            />  
            <TextField
              label="% Estabelecimento"
              value={placeGroup.splitPlace}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  thousandSeparator: '.',
                  decimalSeparator: ',',
                  valueIsNumericString: true,
                  decimalScale: 4,
                  variant: 'outlined',
                  onValueChange: (vals: ValueChange) =>
                    handleInputChange({
                      name: 'splitPlace',
                      value: vals.floatValue,
                    }),
                },
                disableUnderline: true,
              }}
              sx={{ mt: 2 }}
              InputLabelProps={{ shrink: true }}
            />   
              <TextField
                label="Pix"
                name="pix"
                id="pix"
                sx={{ mt: 2 }}
                value={placeGroup.pix || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <LoadingButton onClick={handleSubmit} loading={loading} variant="contained">
              Salvar
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>;

      default:
        return <Table height={550} rows={dataPlacesFiltered} columns={placeColumns} />;
    }
  })();

  const breadcrumbsList: BreadcrumbType[] = [
    {
      link: '/placeGroups',
      label: 'Lista de ECDs'
    },
    {
      link: `/placeGroups/${placeGroupId}`,
      label: placeGroup.name || '',
      disabled: true
    },
  ]

  const downloadSlugsRequestsByPlaceGroupResume = async () => {
    setLoadingSlugsRequestsByPlaceGroupResume(true)
    try {
      const views = await findExportReports({
        query: {
          reportType: 'slugs-requests-by-place-group-resume',
          placeGroupId,
        }
      });
      console.log('reportDatas', views);
      const viewsReport: any = [];
      views.forEach((view: any) => {
        const newView = {
          'Slug': view.slug,
          'ECD': view.ecdName,
          'Views 7 dias': view.views7Days,
          'Views 30 dias': view.views30Days,
        };
        viewsReport.push(newView);
      })
      const worksheet = XLSX.utils.json_to_sheet(viewsReport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Dados Campanha por data e local");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `Thirky-Slugs-${placeGroup?.name}_${dayjs().format('DD-MM-YYYY HHmm')}.xlsx`);
      setLoadingSlugsRequestsByPlaceGroupResume(false);
    } catch (error) {
      setLoadingSlugsRequestsByPlaceGroupResume(false);
      console.log('error', error);
    }
  }

  return (
    <Layout>
      <Navigate 
        pageTitle="Perfil do ECD" 
        breadcrumbs={breadcrumbsList}
      />
      <TitleTabs value={value} handleChange={handleChange} tabNames={tabNames}>
        <Stack
          // container
          sx={{ p: 1, width: '100%'}}
          // columns={10}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack 
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            // spacing={3}  
          >
            <Avatar sx={{ width: 90, height: 90, mr: 1 }} src={placeGroup.urlImage} />
            <Stack 
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              // spacing={3}
            >
              <Typography variant="h5"  sx={{ fontWeight: 'bold' }}>{placeGroup.name}</Typography>
              <Link
                to={`/placeGroups/${placeGroupId}/dashboard`}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="outlined">Ver Dash</Button>
              </Link>
            </Stack>
            <Link 
              state={{ background: location }} 
              to={`/placeGroups/edit/${placeGroupId}`}
            >
              <EditOutlined 
                fontSize="small" 
                sx={{ 
                  color: "#999999", 
                  marginLeft: 2, 
                  mt: '5px', 
                  borderColor: "#999999", 
                  borderStyle: 'solid', 
                  borderRadius: '2px', 
                  borderWidth: '1px',
                }} />
            </Link>
          </Stack>
          <Stack  flexDirection="row" justifyContent="space-around">
            <Stack justifyContent="space-between" sx={bignumbersCardsStyle}>
              <Box component="span" sx={{display: 'flex'}} >
                {/* <Speed /> */}
                <Typography sx={{fontWeight: 'bold'}} component="span">Acessos</Typography>
              </Box>
              <Stack flexDirection="row" >
                <Box sx={{ width: '50%' }}>
                  <Typography variant="h6">
                    {numberFormatK(placeGroup?.nOfViewsLast30Days || 0)}
                  </Typography>
                  <Typography color="#999999" variant="subtitle2">30 dias</Typography>
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Typography variant="h6">
                    {numberFormatK(placeGroup?.nOfViewsLast7Days || 0)}
                  </Typography>
                  <Typography color="#999999" variant="subtitle2">7 dias</Typography>
                </Box>
              </Stack>
            </Stack>
            <Stack justifyContent="space-between" sx={bignumbersCardsStyle}>
              <Box component="span" sx={{display: 'flex'}} >
                {/* <Speed /> */}
                <Typography sx={{fontWeight: 'bold'}} component="span">Á Pagar</Typography>
              </Box>
              <Stack flexDirection="row" >
                <Box sx={{ width: '50%' }}>
                  <Typography variant="h6">
                    <FormattedNumber
                      value={placeGroup?.totalPlacePendingAmount || 0} 
                      // style="currency" 
                      currency="BRL" 
                    />
                  </Typography>
                  <Typography color="#999999" variant="subtitle2">Places</Typography>
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Typography variant="h6">
                    <FormattedNumber
                      value={placeGroup?.totalPlaceGroupPendingAmount || 0} 
                      // style="currency" 

                      currency="BRL" 
                    />
                  </Typography>
                  <Typography color="#999999" variant="subtitle2">ECD</Typography>
                </Box>
              </Stack>
            </Stack>
            <Stack justifyContent="space-between" sx={bignumbersCardsStyle}>
              <Box component="span" sx={{display: 'flex'}} >
                {/* <Speed /> */}
                <Typography sx={{fontWeight: 'bold'}} component="span">Pago</Typography>
              </Box>
              <Stack flexDirection="row" >
                <Box sx={{ width: '50%' }}>
                  <Typography variant="h6">
                    <FormattedNumber
                      value={(placeGroup?.totalPlaceAmount || 0) - (placeGroup?.totalPlacePendingAmount || 0)} 
                      // style="currency" 
                      currency="BRL" 
                    />
                  </Typography>
                  <Typography color="#999999" variant="subtitle2">Places</Typography>
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Typography variant="h6">
                    <FormattedNumber
                      value={(placeGroup?.totalPlaceGroupAmount || 0) - (placeGroup?.totalPlaceGroupPendingAmount || 0)} 
                      // style="currency" 
                      currency="BRL" 
                    />
                  </Typography>
                  <Typography color="#999999" variant="subtitle2">ECD</Typography>
                </Box>
              </Stack>
            </Stack>
            <Stack justifyContent="space-between" sx={bignumbersCardsStyle}>
              <Box component="span" sx={{display: 'flex'}} >
                {/* <Speed /> */}
                <Typography sx={{fontWeight: 'bold'}} component="span">Total</Typography>
              </Box>
              <Stack flexDirection="row" >
                <Box sx={{ width: '50%' }}>
                  <Typography variant="h6">
                    <FormattedNumber
                      value={placeGroup?.totalPlaceAmount || 0} 
                      // style="currency" 
                      currency="BRL" 
                    />
                  </Typography>
                  <Typography color="#999999" variant="subtitle2">Places</Typography>
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Typography variant="h6">
                    <FormattedNumber
                      value={placeGroup?.totalPlaceGroupAmount || 0} 
                      // style="currency" 
                      currency="BRL" 
                    />
                  </Typography>
                  <Typography color="#999999" variant="subtitle2">ECD</Typography>
                </Box>
              </Stack>
            </Stack>
            {/* <Card style={{ width: '250px'}}>
              <CardHeader  sx={{ padding: '15px !important'}}
                avatar={
                  <MapOutlined 
                  sx={{ 
                    p: '5px', 
                    // mr: 1,
                    width: 25, 
                    height: 25, 
                    color: 'white', 
                    backgroundColor: '#388E3C',
                    borderRadius: '60px'
                  }} 
                />
                }
                title="Acessos"
              />
              <CardContent sx={{ display: 'flex', padding: '15px !important'}}>
                <Box sx={{ width: '50%'}}>
                  <Typography variant="h6">{placeGroup?.nOfViewsLast30Days}</Typography>
                  <Typography color="#999999" variant="subtitle2">30 dias</Typography>
                </Box>
                <Box sx={{ width: '50%'}}>
                  <Typography variant="h6">{placeGroup?.nOfViewsLast7Days}</Typography>
                  <Typography color="#999999" variant="subtitle2">7 dias</Typography>
                </Box>
              </CardContent>
            </Card> */}
            {/* <Stack flexDirection="row" sx={{ width: '200px'}}>
              <AttachMoneyOutlined
                sx={{ 
                  p: '10px', 
                  mr: 1,
                  width: 56, 
                  height: 56, 
                  color: 'white', 
                  backgroundColor: '#F57C00',
                  borderRadius: '60px'
                }} 
              />
              <Stack 
                justifyContent="center" 
                alignItems="center"
              >
                <Typography color="#999999" variant="subtitle2">Valor Acumulado</Typography>
                <Typography variant="h6">R$ {calculateTotalValue()}</Typography>
              </Stack>
            </Stack> */}
          </Stack>
        </Stack>
        <Stack 
          justifyContent='flex-end'
          sx={{ mb: '10px', mr: '20px'}}
        >
          <LoadingButton 
            onClick={() => downloadSlugsRequestsByPlaceGroupResume()} 
            variant="outlined"
            loading={loadingSlugsRequestsByPlaceGroupResume}
            style={{ maxWidth: '200px', alignSelf: 'flex-end' }}
          >
            Exportar Slugs
          </LoadingButton>
        </Stack>
      </TitleTabs>
      {switchMenuBarAccordinTab}
      {switchTableAccordingTab}
      <Outlet />
    </Layout>
  );
};

export default PlaceGroupProfile;
