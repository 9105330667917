import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useSnackbar } from 'notistack';
import { PhotoCamera } from '@mui/icons-material';
import { usePlaces } from '../../contexts/usePlaces';
import { usePlaceGroups } from '../../contexts/usePlaceGroups';
import { useNavigate, useParams } from 'react-router-dom';
import { PlaceGroupInterface } from '../common/types';

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '70vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

interface PlaceInterface {
  id: number | null;
  name: string | null;
  urlImage?: any | null;
  newFileImage?: string | null | unknown;
  placeGroupId?: number | null;
  isEnabled: boolean;
}

const initialPlace: PlaceInterface = {
  id: null,
  name: null,
  urlImage: null,
  newFileImage: null,
  placeGroupId: null,
  isEnabled: true,
};

const AddPlaceModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { placeId } = useParams();
  const {
    editPlace,
    getPlace,
    setEditPlace,
    getEditPlace,
    createPlaces,
    patchPlaces,
    findPlaces,
  } = usePlaces();
  const {
    placeGroups,
    findPlaceGroups,
  } = usePlaceGroups();
  const [place, setPlace] =
    useState<PlaceInterface>(initialPlace);

  const handleInputChange = (event: any) => {
    // console.log('event.target.value ', event.target.value);
    const { name, value } = event.target;
    // console.log('event.target.value ', { name, value });
    setPlace({
      ...place,
      [name]: value,
    });
  };

  useEffect(() => {
    findPlaceGroups();
  }, []);

  useEffect(() => {
    // console.log('placeId ', placeId);
    // console.log('place?.id ', place?.id);
    // console.log('editPlace?.id ', editPlace?.id);
    if (placeId && !place?.id) {
      // console.log('getEdirPlace');
      getEditPlace(placeId);
    }
  }, [placeId]);

  useEffect(() => {
    if (editPlace.id && editPlace?.id !== place?.id) {
      setPlace(editPlace);
    }
  }, [editPlace]);

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadFileProfile = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    // console.log('base64: ', base64)
    setPlace({
      ...place,
      newFileImage: base64 ? base64 : null
    })
  };

  const validatePlace = (place: any) => {
    if (!place?.name) {
      enqueueSnackbar('Informe o nome do estabelecimento!', { variant: 'error' });
      return false;
    }

    if (!place.id && !place?.placeGroupId) {
      enqueueSnackbar('Insira a ECD do estabelecimento!', { variant: 'error' });
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // console.log('place: ', place);
    if (validatePlace(place)) {
      if (place?.id) {
        patchPlaces(place.id, place)
          .then(() => {
            // console.log('Deu certo');
            enqueueSnackbar('Anunciante alterado com sucesso!', {
              variant: 'success',
            });
            getPlace(place.id);
            closeModal();
          })
          .catch((error: any) => {
            // console.log('Erro ao alterar', error);
            enqueueSnackbar('Erro ao alterar estabelecimento!', { variant: 'error' });
          });
      } else {
        createPlaces(place)
          .then((result: any) => {
            console.log('Deu certo', result);
            enqueueSnackbar('Anunciante criado com sucesso!', {
              variant: 'success',
            });
            closeModal(`/places/${result?.id}/profile`);
          })
          .catch((error: any) => {
            // console.log('Erro ao criar', error);
            enqueueSnackbar('Erro ao criar estabelecimento!', { variant: 'error' });
          });
      }
    }
  };

  const closeModal = (navigateTo?: string | null) => {
    if (navigateTo) {
      navigate(navigateTo);
    } else {
      navigate(-1);
    }
    setTimeout(() => {
      setPlace(initialPlace);
      setEditPlace(initialPlace);
    }, 500);
  };

  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CenterModalStyle} onClick={(e) => e.stopPropagation()}>
        <Stack
          sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }} 
          direction="row" 
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            { placeId ? 'Editar Estabelecimento' : 'Novo Estabelecimento'}
          </Typography>

          <IconButton className="closeIcon" onClick={() => closeModal()}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            label="Nome do estabelecimento"
            name="name"
            id="name"
            value={place.name || ''}
            onChange={handleInputChange}
            required
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          { !place.id && (
            <FormControl fullWidth variant="outlined" sx={{ mt: 3 }}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                shrink
                style={{ 
                  backgroundColor: '#fff', 
                  paddingLeft: 5, 
                  paddingRight: 5 
                }} 
              >
                ECD
              </InputLabel>
  
              <Select
                labelId="demo-simple-select-outlined-label"
                id="placeGroupId"
                name="placeGroupId"
                value={String(place.placeGroupId || '')}
                onChange={handleInputChange}
                label="ECD"
                input={<OutlinedInput />}
                required
              >
                <MenuItem value="">Selecione</MenuItem>
                {placeGroups.map((placeGroup : PlaceGroupInterface) => (
                  <MenuItem key={placeGroup.id} value={String(placeGroup.id)}>
                    {placeGroup.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <>
              {place?.newFileImage || place?.urlImage 
                ? <img 
                    src={ place?.newFileImage ? place?.newFileImage as string : place?.urlImage as string} 
                    alt="Perfil estabelecimento" 
                  /> 
                : null}
              <Button variant="contained" component="label"  startIcon={<PhotoCamera/>}>
                Selecionar Imagem
                <input hidden accept="image/*" onChange={(e) => uploadFileProfile(e)} multiple type="file" />
              </Button>
              {place?.newFileImage && (
                <Button 
                  color="warning" 
                  variant="contained" 
                  onClick={() => setPlace({...place, newFileImage: null})}
                >
                  Remover imagem
                </Button>
              )}
            </>
          </FormControl>
          <Button variant="contained" type="submit">
            {(!place?.id && 'Criar estabelecimento') || 'Editar estabelecimento'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPlaceModal;
