import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import NorthIcon from '@mui/icons-material/North';
import { LoadingButton } from '@mui/lab';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs, { Dayjs } from 'dayjs';
import * as XLSX from 'xlsx';
import uniqBy from 'lodash/uniqBy';
import sumBy from 'lodash/sumBy';

import Table from '../components/Table';
import { AdvertiserFilter } from '../components/common/types';
import { useAdvertisers } from '../contexts/useAdvertisers';
import { useCampaignMedias } from '../contexts/useCampaignMedias';
import { Link, useParams } from 'react-router-dom';
import { useCampaigns } from '../contexts/useCampaigns';
import { useContracts } from '../contexts/useContracts';
import { useAuth } from '../contexts/useAuth';
import { useExportReports } from '../contexts/useExportReports';
import Map from '../components/Map';
import ContractCard from '../components/ContractCard';
import '../styles/AdvertiserDashboard.css';
import { set } from 'lodash';
import { NotificationsPaused } from '@mui/icons-material';
import numeral from 'numeral';

import prerollIcon from '../components/img/img-preroll.png';
import highlightIcon from '../components/img/img-highlight.png';
import listIcon from '../components/img/img-list.png';

interface CampaignInterface {
  id: number | null;
  name: string | null;
  codPi: number | null;
  urlImage?: string | null;
  nOfViews: number | null;
  viewsAvg: number | null;
  nOfCtas: number | null;
  ctr: number | null;
  nOfPlaces: number | null;
  endDate: Dayjs | null;
  startDate: Dayjs | null;
  isFinished: boolean | null
}

const initialCampaign: CampaignInterface = {
  id: null,
  name: null,
  codPi: null,
  endDate: null,
  startDate: null,
  urlImage: null,
  nOfViews: 0,
  viewsAvg: 0,
  nOfCtas: 0,
  ctr: 0,
  nOfPlaces: 0,
  isFinished: false,
};

// Temporary function to await for an element until it exists in plain javascript
// function waitForElm(selector: any) {
//   return new Promise((resolve) => {
//     if (document.querySelector(selector)) {
//       return resolve(document.querySelector(selector));
//     }

//     const observer = new MutationObserver((mutations) => {
//       if (document.querySelector(selector)) {
//         resolve(document.querySelector(selector));
//         observer.disconnect();
//       }
//     });

//     observer.observe(document.body, {
//       childList: true,
//       subtree: true,
//     });
//   });
// }

const numberFormatK = (value: number)=> value >= 1000 ? numeral(value).format('0.00a').toUpperCase() : value;

const AdvertiserDashboard = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { campaigns, getCampaign } = useCampaigns();
  const { contracts, findContracts } = useContracts();
  const { campaignMedias, findCampaignMedias } = useCampaignMedias();
  const { findExportReports } = useExportReports();
  const [openExportMenu, setOpenExportMenu] = useState(false);
  const [uniquePlaces, setUniquePlaces] = useState<any>([]);
  const [loadingExportViewsDetails, setLoadingExportViewsDetails] = useState<boolean>(false);
  const [loadingCampaignDetailsGroupByDatePlaces, setLoadingCampaignDetailsGroupByDatePlaces] = useState<boolean>(false);

  const [campaign, setCampaign] =
    useState<CampaignInterface>(initialCampaign);
  const { advertiserId, campaignId } = useParams();
  const { user } = useAuth();

  const getInformations = () => {
      // console.log('getAdvertiserInformation');
      getCampaign(campaignId, { query: { includeBigNumbers: true } });
      findContracts({
        query: {
          includeCampaignPlaces: true,
          campaignId,
          includeBigNumbers: true,
        },
      });
      findCampaignMedias({ query: { campaignId } });
  };

  const downloadCampaignDetailsGroupByPlaces = () => {
    const reportData: any = [];
    uniquePlaces.forEach((place: any) => {
      const placeData = {
        'Local': place.name,
        'Endereço': place.address,
        'Cidade': place.addressCity,
        'Estado': place.addressState,
        'Total de Impressões': place?.nOfViews,
        'Total de Cliques': place?.nOfCtas,
        'CTR': place?.ctr,
      };
      reportData.push(placeData);
    })
    const worksheet = XLSX.utils.json_to_sheet(reportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Relatorio de Campanha");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `Thirky-Agrupado-Local-${campaign?.name} ${dayjs().format('DD-MM-YYYY HHmm')}.xlsx`);
  };

  const downloadCampaignDetailsGroupByDatePlaces = async () => {
    setLoadingCampaignDetailsGroupByDatePlaces(true)
    try {
      const views = await findExportReports({
        query: {
          reportType: 'campaign-details-group-by-date-places',
          campaignId,
        }
      });
      console.log('reportDatas', views);
      const viewsReport: any = [];
      views.forEach((view: any) => {
        const newView = {
          'Data': dayjs(view.date).format('DD/MM/YYYY'),
          'Nome Criativo': view.mediaName,
          'Tipo de Criativo': view.mediaType,
          'Id campanha': view.campaignId,
          'Nome da campanha': view.campaignName,
          'Nome Local':  view.placeName,
          'Endereço Local': view.placeAddress,
          'Cidade':  view.placeCity,
          'Estado':  view.placeState,
          'Impressões':  view.nOfViews,
          'Cliques':  view.nOfCtas,
          'CTR':  view.ctr,
          'CPM':  view.cpm,
          'Valor Gasto':  view.totalValue,
          'Sessões':  view.nOfSessions,
          'Usuarios Unicos':  view.nOfUsers
        };
        viewsReport.push(newView);
      })
      const worksheet = XLSX.utils.json_to_sheet(viewsReport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Dados Campanha por data e local");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `Thirky-Agrupado-Local-Data-${campaign?.name}_${dayjs().format('DD-MM-YYYY HHmm')}.xlsx`);
      setLoadingCampaignDetailsGroupByDatePlaces(false);
    } catch (error) {
      setLoadingCampaignDetailsGroupByDatePlaces(false);
      console.log('error', error);
    }
  }

  const processUniquePlaces = (contracts: any) => {
    const placesList = uniqBy(contracts, 'placeId').map((contract: any) => contract.place);
    const newUniquePlaces: any[] = [];
    placesList.forEach((place: any) => {
      const nOfViews: number = sumBy(
        contracts.filter((contract: any) => contract.placeId == place.id),
        (contract: any) => Number(contract.nOfViews)
      );
      const nOfCtas: number = sumBy(
        contracts.filter((contract: any) => contract.placeId == place.id),
        (contract: any) => Number(contract.nOfCtas)
      );
      const placeIsActive = contracts.filter((contract: any) => contract.placeId == place.id && !!contract.isActive);
      newUniquePlaces.push({
        ...place,
        nOfViews,
        nOfCtas,
        ctr: nOfViews > 0 ? (nOfCtas / nOfViews) * 100 : 0,
        isActive: placeIsActive.length > 0,
      });
    });
    setUniquePlaces(newUniquePlaces);
  }

  useEffect(() => {
    processUniquePlaces(contracts);
  }, [contracts]);



  useEffect(() => {
    getInformations()
  }, [campaignId]);

  useEffect(() => {
    setCampaign(campaigns[0] as CampaignInterface);
  }, [campaigns]);

  const placesByCampaign = contracts.map(({ place }: any) => place);
  const campaignMediaTypes = campaignMedias.map(({ type }: any) => type);
  // const filteredCampaigns = Object.values(campaigns).filter(
  //   (campaign: any) => campaign.advertiserId == advertiserId
  // );
 
  const campaignInformations = [
    {
      title: 'Estabelecimentos',
      quantity: campaign?.nOfPlaces,
      // percentage: advertiser?.nOfPlaces,
      icon: (
        <PlaceOutlinedIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#0288D1',
    },
    {
      title: 'Impressões',
      quantity: numeral(campaign?.nOfViews).format('0,0'),
      // percentage: advertiser?.nOfViews,
      icon: (
        <PreviewOutlinedIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#FFA726',
    },
    {
      title: 'Cliques',
      quantity: numeral(campaign?.nOfCtas).format('0,0'),
      // percentage: advertiser?.nOfCtas,
      icon: (
        <TouchAppOutlinedIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#FF4C51',
    },
  ];

  // const campaignColumns: GridColDef[] = [
  //   {
  //     field: 'name',
  //     headerName: 'Nome',
  //     width: 140,
  //     headerClassName: 'app-theme--header',
  //     renderCell: ({ row }: Partial<GridRowParams>) => (
  //       <Typography variant="button" gutterBottom>
  //         {row?.name}
  //       </Typography>
  //     ),
  //   },
  //   {
  //     field: 'options',
  //     headerName: '',
  //     width: 60,
  //     headerClassName: 'app-theme--header',
  //     renderCell: ({ row }: Partial<GridRowParams>) => (
  //       <OpenInNewIcon
  //         key={row?.id}
  //         id={row?.id}
  //         onClick={() => openCampaignContract(row?.id)}
  //         sx={{ color: '#FF4C51', width: '33px', height: '33px' }}
  //       />
  //     ),
  //   },
  //   {
  //     field: 'isDisabled',
  //     headerName: 'Status',
  //     width: 100,
  //     headerClassName: 'app-theme--header',
  //     renderCell: ({ row }: Partial<GridRowParams>) => (
  //       <React.Fragment>
  //         {row?.isDisabled ? (
  //           <Chip label="Encerrado" color="error" />
  //         ) : (
  //           <Chip label="Rodando" color="success" />
  //         )}
  //       </React.Fragment>
  //     ),
  //   },
  //   {
  //     field: 'startDate',
  //     headerName: 'Data de Inicio',
  //     width: 120,
  //     headerClassName: 'app-theme--header',
  //     renderCell: ({ row }: Partial<GridRowParams>) => (
  //       <span>{dayjs(row.createdAt).format('DD/MM/YYYY')}</span>
  //     ),
  //   },
  //   {
  //     field: 'endDate',
  //     headerName: 'Data de Enceramento',
  //     width: 160,
  //     headerClassName: 'app-theme--header',
  //     renderCell: ({ row }: Partial<GridRowParams>) => (
  //       <span>{dayjs(row.createdAt).format('DD/MM/YYYY  HH:mm')}</span>
  //     ),
  //   },
  //   {
  //     field: 'nOfPlaces',
  //     headerName: 'Locais',
  //     width: 160,
  //     headerClassName: 'app-theme--header',
  //     // renderCell: ({ row }: Partial<GridRowParams>) => (
  //     //   <span>{0}</span>
  //     // ),
  //   },
  //   {
  //     field: 'nOfViews',
  //     headerName: 'Impressões',
  //     width: 160,
  //     headerClassName: 'app-theme--header',
  //     // renderCell: ({ row }: Partial<GridRowParams>) => (
  //     //   <span>{0}</span>
  //     // ),
  //   },
  //   {
  //     field: 'viewsAvg',
  //     headerName: 'Exibição Média',
  //     width: 160,
  //     headerClassName: 'app-theme--header',
  //     // renderCell: ({ row }: Partial<GridRowParams>) => (
  //     //   <span>{0}</span>
  //     // ),
  //   },
  //   {
  //     field: 'nOfCtas',
  //     headerName: 'Cliques',
  //     width: 160,
  //     headerClassName: 'app-theme--header',
  //     // renderCell: ({ row }: Partial<GridRowParams>) => (
  //     //   <span>{0}</span>
  //     // ),
  //   },
  //   {
  //     field: 'ctr',
  //     headerName: 'CTR',
  //     width: 160,
  //     headerClassName: 'app-theme--header',
  //     // renderCell: ({ row }: Partial<GridRowParams>) => (
  //     //   <span>{1}</span>
  //     // ),
  //   },
  // ];

  const [gridView, setGridView] = useState(false);

  const changeGridView = () => {
    // console.log('changeGridView');
    setGridView(true);
  };

  const changeMapView = () => {
    // console.log('changeMapView');
    setGridView(false);
  };

  return (
    <React.Fragment>
      {(user.profile === 'advertiser' && advertiserId == user.advertiser_id) ||
      user.profile === 'admin' ? (
        <Layout removeLeftBar advertiserId={advertiserId}>
          <Box
            sx={{
              maxWidth: '1600px',
              margin: '0 auto',
            }}
          >
            <Typography 
              variant="subtitle2" 
              component={Link} 
              to={`/advertisercampaigns/${advertiserId}`}  
              style={{ 
                color: '#FF4C51', 
                textDecoration: 'inherit',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <ArrowBackIcon fontSize='small' />&nbsp;&nbsp;<span>Voltar para todas as campanhas</span>
            </Typography>
          </Box>
          <Stack 
            sx={{
              maxWidth: '1600px',
              margin: '0 auto',
            }}
            direction="row" 
            justifyContent="space-between"
          >
            <Stack>
              <Stack direction='row' justifyContent='space-between'>
                <Typography
                  variant="h4"
                  sx={{ justifyContent: 'left', paddingBottom: 3, mr: 2 }}
                >
                  {campaign?.name}           
                </Typography>
                {campaign?.isFinished 
                  ? 
                    <Chip sx={{ mr: 2 }} label="Encerrada" color="error"/> 
                  : 
                    <Chip sx={{ mr: 2 }} label="Rodando" color="success" />
                }
              </Stack>
              {campaign?.id && (
                <Box display='flex' justifyContent='flex-start'>
                  <Stack sx={{ mr: 4 }} justifyContent='flex-start'>
                    <Typography variant="subtitle1">Início:</Typography>
                    <Typography variant="subtitle1"><b>{dayjs(campaign.startDate).format('DD/MM/YYYY')}</b></Typography>
                  </Stack>
                  <Stack sx={{ mr: 4 }} justifyContent='flex-start'>
                    <Typography variant="subtitle1">Fim:</Typography>
                    <Typography variant="subtitle1"><b>{dayjs(campaign.endDate).format('DD/MM/YYYY')}</b></Typography>
                  </Stack>
                  <Stack sx={{ mr: 4 }} justifyContent='flex-start'>
                    <Typography variant="subtitle1">Formatos: </Typography>
                    <Stack direction='row'>
                      {campaignMediaTypes.includes('listItemTop') ? <img style={{ width: 25, height: 25 }} src={listIcon} alt='icone list'/> : null}
                      {campaignMediaTypes.includes('highlight') ? <img style={{ width: 25, height: 25 }} src={highlightIcon} alt='icone highlight'/> : null}
                      {campaignMediaTypes.includes('preRoll') ? <img style={{ width: 25, height: 25 }} src={prerollIcon} alt='icone preroll'/> : null}
                    </Stack>
                  </Stack>     
                  {campaign.codPi && (
                    <Stack sx={{ mr: 4 }} justifyContent='flex-start'>
                      <Typography variant="subtitle1">PI:</Typography>
                      <Typography variant="subtitle1"><b>{campaign.codPi}</b></Typography>
                    </Stack>             
                  )}           
                </Box>
              )}
            </Stack>
            <Stack direction="row" spacing={7} sx={{ paddingBottom: 4 }}>
              {campaignInformations.map((data, index) => {
                return (
                  <Card key={index} sx={{ minWidth: 240 }}>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={8}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{ color: '#999999', paddingLeft: '0.3rem' }}
                          >
                            {data.title}
                          </Typography>
                          <Typography
                            variant="h5"
                            sx={{ paddingLeft: '0.3rem' }}
                          >
                            {data.quantity}
                          </Typography>
                          {/* <Chip
                            icon={
                              <NorthIcon sx={{ '&&': { color: '#388E3C' } }} />
                            }
                            label={data.percentage}
                            sx={{
                              backgroundColor: '#81c78478',
                              color: '#388E3C',
                            }}
                          /> */}
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            sx={{ paddingRight: '1rem', paddingTop: '0.3rem' }}
                          >
                            <IconButton
                              // size="large"
                              sx={{
                                backgroundColor: data.color,
                                '&:hover': { backgroundColor: data.color },
                                cursor: 'default',
                              }}
                            >
                              <Badge>{data.icon}</Badge>
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              })}
            </Stack>
          </Stack>
          <Box
            id="campaign-places"
            sx={{
              backgroundColor: 'white',
              borderRadius: '8px',
              marginTop: 5,
              maxWidth: '1600px',
              margin: '0 auto',
            }}
          >
            <Stack 
              justifyContent='space-between'
              direction='row'
            >
              <Typography
                variant="h6"
                sx={{
                  paddingTop: '1.2rem',
                  paddingBottom: 3,
                  paddingLeft: '0.7rem',
                }}
              >
                Estabelecimento com a campanha
              </Typography>
              <Stack 
                direction='row'
                alignSelf='center'
              >

                <Button
                  sx={{ marginLeft: '1rem' }}
                  variant={!gridView ? 'contained' : 'outlined'}
                  onClick={changeMapView}
                >
                  <MapOutlinedIcon /> Mapa
                </Button>
                <Button
                  sx={{ marginLeft: '1rem' }}
                  variant={gridView ? 'contained' : 'outlined'}
                  onClick={changeGridView}
                >
                  <GridViewOutlinedIcon /> Lista
                </Button>
                {/* <Button 
                  sx={{ marginLeft: '1rem' }}
                  variant="outlined"
                  onClick={() => downloadCampaignDetailsGroupByPlaces()}
                >
                  <PublishIcon
                    style={{ fontSize: 'medium', marginRight: '0.5rem' }}
                  />
                  Exportar
                </Button> */}
                <Button 
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  sx={{ marginLeft: '1rem' }}
                  variant="outlined"
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {/* <PublishIcon
                    style={{ fontSize: 'medium', marginRight: '0.5rem' }}
                  /> */}
                  Exportar
                </Button>
                <Menu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  // anchorEl={anchorEl}
                  // open={openExportMenu}
                  // onClose={() => setOpenExportMenu(false)}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem disableRipple>
                    {/* <EditIcon /> */}
                    <LoadingButton onClick={downloadCampaignDetailsGroupByPlaces}>Relatorio Por Local</LoadingButton>
                  </MenuItem>
                  {/* <MenuItem  disableRipple>
                    <LoadingButton loading={loadingExportViewsDetails} onClick={downloadExportViewsDetails}>Exportar Entregas </LoadingButton>
                  </MenuItem> */}
                  <MenuItem  disableRipple>
                    {/* <FileCopyIcon /> */}
                    <LoadingButton loading={loadingCampaignDetailsGroupByDatePlaces} onClick={downloadCampaignDetailsGroupByDatePlaces}>Relatorio Por Local e Data </LoadingButton>
                  </MenuItem>
                </Menu>
              </Stack>
            </Stack>

            <Stack direction='row' sx={{ maxHeight: !!gridView ? 'initial' : 800}}>
              {!gridView ? 
                <Box sx={{ height: '700px', width: '70%' }}>
                  <div style={{ height: '700px' }} className="map">
                    <Map markers={placesByCampaign} />
                  </div>
                </Box> : null
              }
              <Box
                sx={{
                  width: !!gridView ? '100%' : '30%',
                  overflowY: 'scroll',
                  maxHeight: !!gridView ? 'initial' : 750,
                  display: !!gridView ? 'flex' : 'initial',
                  justifyContent: !!gridView ? 'center' : 'initial',
                  flexWrap: !!gridView ? 'wrap' : 'initial',
                }} 
              >
                {uniquePlaces.map((place: any, index: number) => (
                    <ContractCard
                      key={String(place.id)}
                      name={place.name}
                      urlImage={place.urlImage}
                      address={place.address}
                      nOfViews={place.nOfViews}
                      nOfCtas={place.nOfCtas}
                      ctr={place.ctr}
                      isActive={place.isActive}
                    />
                  // </Grid>
                ))}
              </Box>
            </Stack>
            <Typography
              variant='subtitle2' 
              style={{ color: 'gray' }}
            >
              * Dados atualizados em tempo real
            </Typography>
          </Box>
        </Layout>
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={8}
          divider={
            <Divider
              flexItem
              style={{ margin: 'auto', width: '50%', paddingTop: 9 }}
            />
          }
          sx={{ paddingTop: 5 }}
        >
          <Typography variant="h2" sx={{ color: 'red' }}>
            Acesso Negado
          </Typography>
          <React.Fragment>
            <Typography variant="h3" sx={{ color: 'black' }}>
              Você não tem permissão para visitar essa página!
            </Typography>

            <Typography variant="h3" sx={{ color: 'red' }}>
              🚫🚫🚫🚫
            </Typography>

            <Typography variant="h6" sx={{ color: 'red' }}>
              error code: 403 forbidden
            </Typography>
          </React.Fragment>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default AdvertiserDashboard;
