import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const CampaignMediasContext = createContext();
const initialCampaignMedia = {
  id: null,
  type: null,
  position: null,
  urlCover: null,
  urlVideo: null,
  videoDuration: null,
}

export function CampaignMediasProvider({ children }) {
  const [campaignMedias, setCampaignMedias] = useState([]);
  const [editCampaignMedia, setEditCampaignMedia] = useState(initialCampaignMedia);

  function getCampaignMedia(campaignMediaId) {
    services.campaignMedias.get(campaignMediaId).then((response) => {
      // console.log('getCampaignMedia', response);
      setCampaignMedias([response]);
    });
  }

  function getEditCampaignMedia(campaignMediaId) {
    services.campaignMedias.get(campaignMediaId).then((response) => {
      // console.log('getCampaignMedia', response);
      setEditCampaignMedia(response);
    });
  }

  function findCampaignMedias(query = {}) {
    services.campaignMedias.find(query).then((response) => {
      // console.log('findCampaignMedias', response);
      setCampaignMedias(response.data);
    });
  }

  function findHashCampaignMedias(hash) {
    services.campaignMedias.find({query: { hash }}).then((response) => {
      // console.log('findCampaignMedias', response);
      setCampaignMedias(response.data);
    });
  }


  async function createCampaignMedias(campaignMedia) {
    return services.campaignMedias.create(campaignMedia).then(() => {
      // findCampaignMedias({ query: { placeId: campaignMedia.placeId}});
    });
  }

  async function patchCampaignMedias(id, campaignMedia) {
    return services.campaignMedias.patch(id, campaignMedia).then(() => {
      setEditCampaignMedia(initialCampaignMedia);
      // findCampaignMedias({ query: { placeId: campaignMedia.placeId}});
    });
  }

  async function removeCampaignMedias(campaignMediaId) {
    return services.campaignMedias.remove(campaignMediaId).then(() => {
      // findCampaignMedias();
    });
  }
  return (
    <CampaignMediasContext.Provider
      value={{
        campaignMedias,
        editCampaignMedia,
        findHashCampaignMedias,
        setEditCampaignMedia,
        getEditCampaignMedia,
        findCampaignMedias,
        createCampaignMedias,
        patchCampaignMedias,
        getCampaignMedia,
        removeCampaignMedias,
      }}
    >
      {children}
    </CampaignMediasContext.Provider>
  );
}

export function useCampaignMedias() {
  const context = useContext(CampaignMediasContext);

  return context;
}
