import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { useSnackbar } from 'notistack';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { usePlaceResponsibles } from '../../contexts/usePlaceResponsibles';
import { useNavigate, useParams } from 'react-router-dom';

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '90vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

interface PlaceResponsibleInterface {
  id: number | null;
  name: string | null;
  email: string | null;
  phone: string | null;
  cpf?: string | null;
  position: string | null;
  isWhatsapp: boolean | null;
  placeId: number | null;
}

const initialPlaceResponsible: PlaceResponsibleInterface = {
  id: null,
  name: null,
  email: null,
  phone: null,
  cpf: null,
  position: null,
  isWhatsapp: null,
  placeId: null,
};

const AddPlaceResponsibleModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { placeId, placeResponsibleId } = useParams();
  const { 
    editPlaceResponsible, 
    setEditPlaceResponsible, 
    getEditPlaceResponsible, 
    createPlaceResponsibles, 
    patchPlaceResponsibles,
   } =
    usePlaceResponsibles();
  const [placeResponsible, setPlaceResponsible] = useState<PlaceResponsibleInterface>(initialPlaceResponsible);

  const handleInputChange = (event: any) => {
    // console.log('event.target.value ', event.target);
    const { name, value } = event.target;
    // console.log('event.target.value ', { name, value });
    setPlaceResponsible({
      ...placeResponsible,
      [name]: value,
    });
  };

  useEffect(() => {
    // console.log('placeResponsibleId ', placeResponsibleId);
    // console.log('placeResponsible?.id ', placeResponsible?.id);
    // console.log('editPlaceResponsible?.id ', editPlaceResponsible?.id);
    if (placeResponsibleId && !placeResponsible?.id) {
      // console.log('getEdirPlaceResponsible');
      getEditPlaceResponsible(placeResponsibleId);
    }
  }, [placeResponsibleId]);

  useEffect(() => {
    if (editPlaceResponsible.id && editPlaceResponsible?.id !== placeResponsible?.id) {
      setPlaceResponsible(editPlaceResponsible);
    }
  }, [editPlaceResponsible]);

  const validatePlaceResponsible = (placeResponsible: any) => {
    const { newPassword } = placeResponsible;

    if (!placeResponsible?.name) {
      enqueueSnackbar('Informe o nome do responsável!', { variant: 'error' });
      return false;
    }

    if (!placeResponsible?.email) {
      enqueueSnackbar('Informe o email do responsável!', { variant: 'error' });
      return false;
    }

    // if (!placeResponsible?.phone) {
    //   enqueueSnackbar('Informe o telefone do responsável!', { variant: 'error' });
    //   return false;
    // }

    if (!placeResponsible?.position) {
      enqueueSnackbar('Informe o cargo do responsável!', { variant: 'error' });
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // console.log('placeResponsible: ', placeResponsible);
    if (validatePlaceResponsible(placeResponsible)) {
      if (placeResponsible?.id) {
        patchPlaceResponsibles(placeResponsible.id, placeResponsible)
          .then(() => {
            // console.log('Deu certo');
            enqueueSnackbar('Responsável alterado com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            // console.log('Erro ao alterar', error);
            enqueueSnackbar('Erro ao alterar responsável!', { variant: 'error' });
          });
      } else {
        createPlaceResponsibles({ ...placeResponsible, placeId })
          .then(() => {
            // console.log('Deu certo');
            enqueueSnackbar('Responsável criado com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            // console.log('Erro ao criar', error);
            enqueueSnackbar('Erro ao criar responsável!', { variant: 'error' });
          });
      }
    }
  };

  const closeModal = () => {
    setPlaceResponsible(initialPlaceResponsible);
    setEditPlaceResponsible(initialPlaceResponsible);
    navigate(-1);
  };

  return (
    <Modal
      open={true}
      onClose={() => setPlaceResponsible(initialPlaceResponsible)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CenterModalStyle} onClick={(e) => e.stopPropagation()}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {placeResponsibleId ? 'Editar Responsável' : 'Adicionar Responsável'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton className="closeIcon" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '34ch' },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            label="Nome completo"
            name="name"
            id="name"
            value={placeResponsible.name || ''}
            onChange={handleInputChange}
            required
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            label="E-mail"
            name="email"
            id="email"
            value={placeResponsible.email || ''}
            onChange={handleInputChange}
            required
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Telefone"
            name="phone"
            id="phone"
            value={placeResponsible.phone || ''}
            onChange={handleInputChange}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel 
            control={
              <Checkbox 
                name="isWhatsapp" 
                id="isWhatsapp" 
                checked={!!placeResponsible.isWhatsapp}
                onChange={(event) => (setPlaceResponsible({...placeResponsible, isWhatsapp: event.target.checked}))}
              />
            } 
            label="WhatsApp" 
          />
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel 
              shrink 
              style={{ 
                backgroundColor: '#fff', 
                paddingLeft: 5, 
                paddingRight: 5 
              }} 
              id="demo-simple-select-outlined-label"
            >
              Cargo
            </InputLabel>
            <Select
              id="position"
              name="position"
              value={placeResponsible.position || ''}
              onChange={handleInputChange}
              label="Cargo"
              required
            >
              <MenuItem value={'Sócio'}>Sócio</MenuItem>
              <MenuItem value={'Gerente'}>Gerente</MenuItem>
              <MenuItem value={'Marketing'}>Marketing</MenuItem>
              <MenuItem value={'Financeiro'}>Financeiro</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" sx={{ width: '100% !important'}} type="submit">
            <AddIcon style={{ marginRight: '1rem' }} />
            {(!placeResponsible?.id && 'Adicionar novo') || 'Editar responsável'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPlaceResponsibleModal;
