import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useSnackbar } from 'notistack';
import { Download, UploadFile } from '@mui/icons-material';
import { usePlaces } from '../../contexts/usePlaces';
import { usePlaceGroups } from '../../contexts/usePlaceGroups';
import { useNavigate, useParams } from 'react-router-dom';
import { PlaceGroupInterface } from '../common/types';
import { LoadingButton } from '@mui/lab';
import { set } from 'lodash';

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '70vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

interface ImportPlaceInterface {
  placeGroupId?: number | null;
  placesFile?: string | null | unknown;
  placesSegmentFile?: string | null | unknown;
}

const initiaImportlPlace: ImportPlaceInterface = {
  placeGroupId: null,
  placesFile: null,
  placesSegmentFile: null,
};

const AddPlaceModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    importPlaces,
    findPlaces,
  } = usePlaces();
  const {
    placeGroups,
    findPlaceGroups,
  } = usePlaceGroups();
  
  const [place, setPlace] =
    useState<ImportPlaceInterface>(initiaImportlPlace);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ importType, setImportType ] = useState('importPlaces');
  const [ imprtPlacesResponse, setImportPlacesResponse ] = useState<any>(null);

  const handleInputChange = (event: any) => {
    // console.log('event.target.value ', event.target.value);
    const { name, value } = event.target;
    // console.log('event.target.value ', { name, value });
    setPlace({
      ...place,
      [name]: value,
    });
  };

  const handleImportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlace(initiaImportlPlace);
    setImportType((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    findPlaceGroups();
  }, []);

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadFile = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    // console.log('base64: ', base64)
    setPlace({
      ...place,
      placesFile: base64 ? base64 : null
    })
  };

  const uploadSegmentFile = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    // console.log('base64: ', base64)
    setPlace({
      ...place,
      placesSegmentFile: base64 ? base64 : null
    })
  };

  const validatePlace = (place: any) => {
    console.log('importType: ', importType);

    if (importType === 'importSegmentPlaces') {
      if (!place?.placesSegmentFile) {
        enqueueSnackbar('Insira a planilha de segmentação!', { variant: 'error' });
        return false;
      }
      return true;
    } else {
      if (!place?.placeGroupId) {
        enqueueSnackbar('Informe o nome da ECD!', { variant: 'error' });
        return false;
      }
  
      if (!place?.placesFile) {
        enqueueSnackbar('Insira a imagem do estabelecimento!', { variant: 'error' });
        return false;
      }
  
      return true;
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    // console.log('place: ', place);
    if (validatePlace(place)) {
      importPlaces(place)
        .then((result: any) => {
          enqueueSnackbar('Estabelecimentos importados com sucesso!', {
            variant: 'success',
          });
          setIsLoading(false);
          setImportPlacesResponse(result);
          // closeModal(`/places`);
        })
        .catch((error: any) => {
          enqueueSnackbar(error.message || 'Erro ao importar estabelecimentos!', { variant: 'error' });
          setIsLoading(false);
        });
    }
  };

  const handleSegmentSubmit = async (event: any) => {
    console.log('handleSegmentSubmit');
    event.preventDefault();
    setIsLoading(true);
    // console.log('place: ', place);
    if (validatePlace(place)) {
      importPlaces(place)
        .then((result: any) => {
          enqueueSnackbar('Segmentação de estabelecimentos importada com sucesso!', {
            variant: 'success',
          });
          setIsLoading(false);
          // closeModal(`/places`);
        })
        .catch((error: any) => {
          enqueueSnackbar(error.message || 'Erro ao importar segmentação de estabelecimentos!', { variant: 'error' });
          setIsLoading(false);
        });
    }
  };

  const reloadPlaces = () => {
    findPlaces({
      query: {
        $sort: {
          name: 1,
        },
        isEnabled: true,
      }
    });
  }

  const closeModal = (navigateTo?: string | null) => {
    if (navigateTo) {
      navigate(navigateTo);
      reloadPlaces()
    } else {
      navigate(-1);
    }
    setTimeout(() => {
      setPlace(initiaImportlPlace);
    }, 500);
  };

  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CenterModalStyle} onClick={(e) => e.stopPropagation()}>
        <Stack
          sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }} 
          direction="row" 
          justifyContent="space-between"
          alignItems="center"
        >
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Importar Estabelecimentos
          </Typography> */}
          <FormControl fullWidth variant="outlined" sx={{ mt: 3 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">Importar</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={importType}
              onChange={handleImportChange}
            >
              <FormControlLabel value="importPlaces" control={<Radio />} label="Estabecimentos" />
              <FormControlLabel value="importSegmentPlaces" control={<Radio />} label="Segmentação" />
            </RadioGroup>
          </FormControl>
          <IconButton className="closeIcon" onClick={() => closeModal()}>
            <CloseIcon />
          </IconButton>
        </Stack>
        {importType === 'importPlaces' && (  
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <FormControl fullWidth variant="outlined" sx={{ mt: 3 }}>
              <InputLabel 
                id="demo-simple-select-outlined-label"
                shrink
                style={{ 
                  backgroundColor: '#fff', 
                  paddingLeft: 5, 
                  paddingRight: 5,
                }} 
              >
                ECD
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="placeGroupId"
                name="placeGroupId"
                // value={String(place.placeGroupId || '')}
                onChange={handleInputChange}
                label="ECD"
                input={<OutlinedInput />}
                required
              >
                <MenuItem value="">Selecione</MenuItem>
                {placeGroups.map((placeGroup : PlaceGroupInterface) => (
                  <MenuItem key={placeGroup.id} value={String(placeGroup.id)}>
                    {placeGroup.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              {/* <Button variant="contained" component="label"  startIcon={<UploadFile/>}>
                Selecionar Imagem */}
                <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => uploadFile(e)} multiple type="file" />
              {/* </Button> */}
            </FormControl>
            <LoadingButton disabled={!place.placesFile || !place.placeGroupId} loading={isLoading} variant="contained" type="submit">
              { isLoading ? 'Importando...' : 'Importar estabelecimentos'}
            </LoadingButton>
            { imprtPlacesResponse && (
              <>
                <Typography variant="subtitle2" component="p" sx={{ marginTop: '20px' }}>
                  <>
                    <b>Dados da importação:</b>
                    <ul>
                      <li>{ imprtPlacesResponse?.totalPlaces } estabelecimentos importados</li>
                      <li>{ imprtPlacesResponse?.createdPlaces } novos cadastros</li>
                      <li>{ imprtPlacesResponse?.existsPlaces.length } previamente cadastrados</li>
                    </ul>
                  </>
                </Typography>
                <Typography variant="subtitle2" component="p" sx={{ marginTop: '20px' }}>
                  { imprtPlacesResponse?.existsPlaces.length > 0 && (
                    <>
                      <b>Estabelecimentos previamente cadastrados:</b>
                      <ul>
                        {imprtPlacesResponse?.existsPlaces.map((place: any) => (
                          <li key={place.id}>{place.name}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </Typography>
              </>
            )}
              {/* totalPlaces: dataJson.length,
              createdPlaces: createdPlaces.length,
              existsPlaces, */}
            <Typography variant="subtitle2" component="p" sx={{ marginTop: '20px' }}>
              Para que LATITUDE e LONGITUDE sejam preenchidos automaticamente é necessário inoformar o CEP do estabelecimento na planilha.
            </Typography>
            <Link  href='https://devimg.adsmenus.com/examples/exemplo-importacao-place-lote-v3.xlsx' target="_blank" rel="noopener" underline="hover">
              <Button sx={{ marginTop: '50px', width: '100%' }} variant="outlined" type="button">
              Download planilha exemplo de estabelecimentos <Download />
              </Button>
            </Link>
          </Box>
        )}
        {importType === 'importSegmentPlaces' && (
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSegmentSubmit}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              {/* <Button variant="contained" component="label"  startIcon={<UploadFile/>}>
                Selecionar Imagem */}
                <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => uploadSegmentFile(e)} multiple type="file" />
              {/* </Button> */}
            </FormControl>
            <LoadingButton  disabled={!place.placesSegmentFile}  loading={isLoading} variant="contained" type="submit">
              { isLoading ? 'Importando...' : 'Importar segmentação'}
            </LoadingButton>

            <Typography variant="subtitle2" component="p" sx={{ marginTop: '20px' }}>
              Para que  a segmentação seja importada corretamente, todos os CNPJ's dos estabelecimentos devem estar cadastrados.
            </Typography>
            <Link  href='https://devimg.adsmenus.com/examples/exemplo-importacao-segmentacao.xlsx' target="_blank" rel="noopener" underline="hover">
              <Button sx={{ marginTop: '50px', width: '100%' }} variant="outlined" type="button">
                Download planilha exemplo de segmentação<Download />
              </Button>
            </Link>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddPlaceModal;
