import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import {
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ViewIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import Table from '../components/Table';
import { useAdvertisers } from '../contexts/useAdvertisers';
import { Link, useParams } from 'react-router-dom';
import { useCampaigns } from '../contexts/useCampaigns';
import { useAuth } from '../contexts/useAuth';
import QR_CODE_CLIENT_URL from '../api/qrCodeClientUrl';
import '../styles/AdvertiserDashboard.css';
import numeral from 'numeral';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

interface AdvertiserInterface {
  id: number | null;
  name: string | null;
  urlImage?: any | null;
  nOfViews: number | null;
  viewsAvg: number | null;
  nOfCtas: number | null;
  ctr: number | null;
  nOfCampaigns: number | null;
  nOfPlaces: number | null;
}

const initialAdvertiser: AdvertiserInterface = {
  id: null,
  name: null,
  urlImage: null,
  nOfViews: 0,
  viewsAvg: 0,
  nOfCtas: 0,
  ctr: 0,
  nOfCampaigns: 0,
  nOfPlaces: 0,
};

const AdvertiserDashboard = () => {
  const { dashAdvertiser, getDashAdvertiser } = useAdvertisers();
  const [advertiser, setAdvertiser] =
    useState<AdvertiserInterface>(initialAdvertiser);
  const { advertiserId } = useParams();
  const { user } = useAuth();

  const getAdvertiserInformation = () => {
    getDashAdvertiser(advertiserId, { query: { includeBigNumbers: true } });
  };

  useEffect(() => {
    if (advertiserId && !advertiser.id) {
      getAdvertiserInformation();
    }
  }, [advertiserId]);

  useEffect(() => {
    if (!advertiser.id && dashAdvertiser?.id == advertiserId) {
      setAdvertiser(dashAdvertiser as AdvertiserInterface);
    }
  }, [dashAdvertiser]);

  const { campaigns, findCampaigns } = useCampaigns();

  const filteredCampaigns = Object.values(campaigns).filter(
    (campaign: any) => campaign.advertiserId == advertiserId
  );

  const findCampaignsList = () => {
    // if (!filteredCampaigns.length) {
      findCampaigns({ query: { advertiserId, includeBigNumbers: true, isTest: false } });
    // }
  };

  useEffect(() => {
    findCampaignsList();
  }, []);

  const advertiserInformation = [
    {
      title: 'Campanhas',
      quantity: campaigns?.length,
      // percentage: advertiser?.nOfCampaigns,
      icon: (
        <CampaignOutlinedIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#FE5390',
    },
    {
      title: 'Estabelecimentos',
      quantity: advertiser?.nOfPlaces,
      // percentage: advertiser?.nOfPlaces,
      icon: (
        <PlaceOutlinedIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#0288D1',
    },
    {
      title: 'Impressões',
      quantity: numeral(advertiser?.nOfViews).format('0,0'),
      // percentage: advertiser?.nOfViews,
      icon: (
        <PreviewOutlinedIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#FFA726',
    },
    {
      title: 'Cliques',
      quantity: numeral(advertiser?.nOfCtas).format('0,0'),
      // percentage: advertiser?.nOfCtas,
      icon: (
        <TouchAppOutlinedIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#FF4C51',
    },
  ];

  const campaignColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 270,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link 
        to={`/advertisercampaigns/${advertiserId}/campaign/${row?.id}`}
        target='_blank'
      >
        {row?.name}
      </Link>
      ),
    },
    {
      field: 'options',
      headerName: '',
      width: 60,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link 
          to={`/advertisercampaigns/${advertiserId}/campaign/${row?.id}`}
          target='_blank'
        >
          <OpenInNewIcon
            key={row?.id}
            id={row?.id}
            // onClick={() => openCampaignContract(row?.id)}
            sx={{ color: '#FF4C51', width: '33px', height: '33px' }}
          />
        </Link>
      ),
    },
    {
      field: 'preview',
      headerName: 'Preview',
      width: 80,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`${QR_CODE_CLIENT_URL}/preview/API/?campaignId=${row?.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ViewIcon
            key={row?.id}
            id={row?.id}
            sx={{ color: '#FF4C51', width: '33px', height: '33px' }}
          />
        </Link>
      ),
    },
    {
      field: 'isFinished',
      headerName: 'Status',
      width: 110,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.isFinished ? (
            <Chip label="Encerrada" color="error" />
          ) : (
            <Chip label="Rodando" color="success" />
          )}
        </React.Fragment>
      ),
    },
    {
      field: 'startDate',
      headerName: 'Data de Inicio',
      width: 140,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.startDate).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      field: 'endDate',
      headerName: 'Data de Enceramento',
      width: 140,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.endDate).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      field: 'nOfPlaces',
      headerName: 'Locais',
      width: 120,
      headerClassName: 'app-theme--header',
      // renderCell: ({ row }: Partial<GridRowParams>) => (
      //   <span>{0}</span>
      // ),
    },
    {
      field: 'nOfViews',
      headerName: 'Impressões',
      width: 120,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{numeral(row?.nOfViews).format('0,0')}</span>
      ),
    },
    // {
    //   field: 'viewsAvg',
    //   headerName: 'Exibição Média',
    //   width: 120,
    //   headerClassName: 'app-theme--header',
    //   // renderCell: ({ row }: Partial<GridRowParams>) => (
    //   //   <span>{0}</span>
    //   // ),
    // },
    {
      field: 'nOfCtas',
      headerName: 'Cliques',
      width: 120,
      headerClassName: 'app-theme--header',
      // renderCell: ({ row }: Partial<GridRowParams>) => (
      //   <span>{0}</span>
      // ),
    },
    {
      field: 'ctr',
      headerName: 'CTR',
      width: 120,
      headerClassName: 'app-theme--header',
      // renderCell: ({ row }: Partial<GridRowParams>) => (
      //   <span>{1}</span>
      // ),
    },
  ];

  return (
    <React.Fragment>
      {(user.profile === 'advertiser' && advertiserId == user.advertiser_id) ||
      user.profile === 'admin' ? (
        <Layout removeLeftBar advertiserId={advertiserId}>
          <Box display="grid" justifyContent="center">
            <Typography
              variant="h4"
              sx={{ justifyContent: 'left', paddingBottom: 3 }}
            >
              Olá, {advertiser?.name} 👋
            </Typography>
            <Stack direction="row" spacing={7} sx={{ paddingBottom: 4 }}>
              {advertiserInformation.map((data, index) => {
                return (
                  <Card key={index} sx={{ minWidth: 280 }}>
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={8}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{ color: '#999999', paddingLeft: '0.3rem' }}
                          >
                            {data.title}
                          </Typography>
                          <Typography
                            variant="h5"
                            sx={{ paddingLeft: '0.3rem' }}
                          >
                            {data.quantity}
                          </Typography>
                          {/* <Chip
                            icon={
                              <NorthIcon sx={{ '&&': { color: '#388E3C' } }} />
                            }
                            label={data.percentage}
                            sx={{
                              backgroundColor: '#81c78478',
                              color: '#388E3C',
                            }}
                          /> */}
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            sx={{ paddingRight: '1rem', paddingTop: '0.3rem' }}
                          >
                            <IconButton
                              // size="large"
                              sx={{
                                backgroundColor: data.color,
                                '&:hover': { backgroundColor: data.color },
                                cursor: 'default',
                              }}
                            >
                              <Badge>{data.icon}</Badge>
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              })}
            </Stack>
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: '8px',
                width: '100%',
                minWidth: 850,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  paddingTop: '1.2rem',
                  paddingBottom: 3,
                  paddingLeft: '0.7rem',
                }}
              >
                Ultimas Campanhas
              </Typography>
              <Table rows={filteredCampaigns} columns={campaignColumns} />
            </Box>
            <Typography
              variant='subtitle2' 
              style={{ color: 'gray' }}
            >
              * Dados atualizados em tempo real
            </Typography>
          </Box>
        </Layout>
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={8}
          divider={
            <Divider
              flexItem
              style={{ margin: 'auto', width: '50%', paddingTop: 9 }}
            />
          }
          sx={{ paddingTop: 5 }}
        >
          <Typography variant="h2" sx={{ color: 'red' }}>
            Acesso Negado
          </Typography>
          <React.Fragment>
            <Typography variant="h3" sx={{ color: 'black' }}>
              Você não tem permissão para visitar essa página!
            </Typography>

            <Typography variant="h3" sx={{ color: 'red' }}>
              🚫🚫🚫🚫
            </Typography>

            <Typography variant="h6" sx={{ color: 'red' }}>
              error code: 403 forbidden
            </Typography>
          </React.Fragment>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default AdvertiserDashboard;
