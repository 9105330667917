import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Layout from '../../components/Layout';
import Table from '../../components/Table';
import MenuBar from '../../components/MenuBar';
import Navigate from '../../components/Navigate';
import { useBatchPayments } from '../../contexts/useBatchPayments';
import { useBatchPaymentDeposits } from '../../contexts/useBatchPaymentDeposits';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { Avatar, Box, Chip, Typography } from '@mui/material';
import { BreadcrumbType } from '../../components/common/types';
import numeral from 'numeral';
import { FormattedNumber } from 'react-intl';

const menuBarStyle = {
  position: 'relative',
  padding: '20px',
  // flexDirection: 'column',
  // display: 'flex',
  justifyContent: 'space-between',
  bgcolor: 'background.paper',
  // height: '75px',
  zIndex: 4,
  alignItems: 'center',
  gridColumnEnd: 'auto',
  marginBottom: '10px',
  borderRadius: '10px',
};

const BatchPaymentView = () => {
  const location = useLocation();
  const { batchPaymentId } = useParams();
  const { dashBatchPayment, getDashBatchPayment } = useBatchPayments();
  const { batchPaymentDeposits, findBatchPaymentDeposits } = useBatchPaymentDeposits();
  const [ formatedBatchPayments, setFormatedBatchPayments ] = useState([]);

  const getBatchPaymentsList = () => {
    getDashBatchPayment(batchPaymentId, { query: { includeBigNumbers: true } });
  };
  const findBatchPaymentDepositsList = () => {
    findBatchPaymentDeposits({query: { batchPaymentId }});
  };

  useEffect(() => {
    getBatchPaymentsList();
    findBatchPaymentDepositsList();
  }, [batchPaymentId]);

  const [query, setQuery] = useState('');

  const filterData = (query: string, data: any) => {
    if (!query) {
      return data;
    } else {
      return data.filter(({ name }: any) =>
        name.toLowerCase().includes(query.toLowerCase())
      );
    }
  };

  const dataFiltered = filterData(query, formatedBatchPayments);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      // resizable: true,
      // headerClassName: 'app-theme--header',
    },   
    {
      field: 'recipientType',
      headerName: 'Tipo de Deposito',
      width: 150,
      // resizable: true,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.place ? 'Estabelecimento' : 'ECD'}
        </React.Fragment>
      ),
    },
    {
      field: 'depositName',
      headerName: 'Beneficiario',
      width: 250,
      // resizable: true,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row.place ? `${row.place?.name}    (${row.contracts[0].placeGroup.name})` : row.placeGroup?.name}
        </React.Fragment>
      ),
    },
    {
      field: 'nOfContracts',
      headerName: 'Contratos',
      width: 125,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'depositValue',
      headerName: 'Total Pago',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <FormattedNumber
            value={row?.depositValue} 
            style="currency" 
            currency="BRL" 
          />
        </React.Fragment>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 180,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
    {
      field: 'paymentDate',
      headerName: 'Status Pagamento',
      width: 180,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row.paymentDate ? `Pago em ${dayjs(row.paymentDate).format('DD/MM/YYYY HH:mm')}` : 'Pendente'}</span>
      ),
    },
  ];

  const breadcrumbsList: BreadcrumbType[] = [
    {
      link: '/batchPayments',
      label: 'Lista de Lotes de Pagamento'
    },
    {
      link: `/batchPayments/${batchPaymentId}/details`,
      label: `#${batchPaymentId}` || '',
      disabled: true
    },
  ]
  console.log('dashBatchPayment', dashBatchPayment)
  return (
    <Layout>
      <Navigate pageTitle={`Detalhes Lote #${batchPaymentId}`} breadcrumbs={breadcrumbsList} />
      <Box sx={menuBarStyle}>
        <Typography>Total de Contratos: {dashBatchPayment?.nOfContracts}</Typography> 
        <Typography>Total Depositos: {dashBatchPayment?.nOfDeposits}</Typography>
        <Typography>Valor Total: <FormattedNumber value={dashBatchPayment?.totalAmount} style="currency" currency="BRL" /></Typography>
      </Box>

      <Table height={700} rows={batchPaymentDeposits} columns={columns} />
      <Outlet />
    </Layout>
  );
};

export default BatchPaymentView;
