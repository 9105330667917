/*eslint-disable no-restricted-globals*/

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../contexts/useAuth';
import { Link, useNavigate } from 'react-router-dom';

const theme = createTheme();

const buttonPrimary = {
  mt: 3,
  mb: 2,
  color: '#FFFFFF',
  backgroundColor: '#FF4C51',
  '&:hover': {
    backgroundColor: '#df4247',
  },
};

function SignIn() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    login(data.get('email'), data.get('password')).then(navigate('/users'));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" className="signInPanel">
        <CssBaseline />
        <Box className="signInForm">
          <div>
            {/* <LockOutlinedIcon /> */}
            <h1 className="logo" >
              thirky
            </h1>
            <Typography component="h1" variant="h5">
              Esqueceu sua senha?
            </Typography>
            <Typography variant="body2">
              Insira seu e-mail e vamos enviar para voce as instrucoes para recuperar sua senha.
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={buttonPrimary}
              >
                Enviar link
              </Button>
              <Grid container>
                <Link to={'/'} className="linkStyle">
                  Voltar para o login
                </Link>
              </Grid>
            </Box>
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default SignIn;
