import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import { Container } from '@mui/system';

interface Props {
  center?: any;
  markers?: any;
  // markers?: {
  //   lat: string | number;
  //   lng: string | number;
  // }[];
}

const Map = ({ center, markers }: Props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCfl7eCykVACcCRR9iItO1Q4-jzIeTAsPk',
  });
  const [mapCenter, setMapCenter] = useState<any>(null);
  const [mapZoom, setMapZoom ] = useState(15);
  const [markerList, setMarkerList] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const getMapCenter = (markers: any) => {
    if (!center && (!markers || markers?.length === 0)) {
      console.log('no markers and center')
      return null;
    }

    if (center) {
      setMapCenter(center);
      setMapZoom(17);
      return;
    }

    let minLat = markers[0].lat;
    let maxLat = markers[0].lat;
    let minLng = markers[0].lng;
    let maxLng = markers[0].lng;
  
    for (let i = 0; i < markers.length; i++) {
      const { lat, lng } = markers[i];
      if (lat && lng && lat > -90 && lat < 90 && lng > -180 && lng < 180) {
        minLat = Math.min(minLat, Number(lat));
        maxLat = Math.max(maxLat, Number(lat));
        minLng = Math.min(minLng, Number(lng));
        maxLng = Math.max(maxLng, Number(lng));
      }
    }

    const latitudeMedia = (maxLat + minLat) / 2;
    const longitudeMedia = (maxLng + minLng) / 2;

    const latSpan = maxLat - minLat;
    const coaculatedZoom = Math.floor(Math.log2(360 / latSpan) + Math.log2(1.5));
    const zoom = coaculatedZoom < 10 ? coaculatedZoom : 10;

    setMapCenter({lat: latitudeMedia, lng: longitudeMedia})
    setMapZoom(zoom)
  }

  useEffect(() => {
    setMarkerList(markers);
    getMapCenter(markers);
  }, [center, markers]);

  useEffect(() => {
    if (markerList !== null && markerList?.length === markers?.length) {
      setLoading(true);
    }
  }, [markerList]);

  if (loading === false || !isLoaded) return <div>Loading...</div>;

  const noInfoAvailable = (
    <Container maxWidth="sm">
      <h2>Informe a Latitude e a Longitude</h2>
    </Container>
  );
 
  return (
    <GoogleMap
      zoom={mapZoom}
      center={mapCenter ? {
        lat: Number(mapCenter?.lat),
        lng: Number(mapCenter?.lng),
      } : { lat: -23.5505, lng: -46.6333 }}
      mapContainerStyle={{
        height: '100%',
        width: '100%',
      }}
      mapContainerClassName="map-container"
    >
      {markers ? (
        <React.Fragment>
          {markerList.map((marker: any, index: number) => (
            <MarkerF
              key={index}
              options={{ icon: `https://maps.google.com/mapfiles/ms/icons/${marker?.markerColor ? marker?.markerColor : 'red'}-dot.png` }}
              position={{
                lat: Number(marker.lat),
                lng: Number(marker.lng),
              }}
            />
          ))}
        </React.Fragment>
      ) : (
        <MarkerF position={mapCenter} />
      )}
    </GoogleMap>
  );
};

export default Map;
