import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './contexts/useAuth';

//Pages
import MainPage from './pages';
import UserView from './pages/UserView';
import SignIn from './pages/SignIn';
import ForgotPassword from './pages/ForgotPassword';
import NotFound from './pages/404';
import AdvertiserView from './pages/AdvertiserView';
import PlaceGroupView from './pages/placeGroups/PlaceGroupView';
import AdvertiserProfile from './pages/AdvertiserProfile';
import CampaignProfile from './pages/campaigns/CampaignProfile';
import CampaignView from './pages/CampaignView';
import PlaceProfile from './pages/PlaceProfile';
import PlaceView from './pages/PlaceView';
import AdvertiserUser from './pages/AdvertiserUser';
import PlaceGroupProfile from './pages/placeGroups/PlaceGroupProfile';
import PlaceGroupDashboard from './pages/placeGroups/PlaceGroupDashboard';
import BatchPaymentsView from './pages/batchPayments/BatchPaymentsView';
import BatchPaymentsDetails from './pages/batchPayments/BatchPaymentsDetails';

//Dash Admin
import AdvertiserDashboard from './pages/AdvertiserDashboard';
import CampaignDashboard from './pages/CampaignDashboard';

//Modals
import AddUserModal from './components/modals/AddUserModal';
import AddAdvertiserModal from './components/modals/AddAdvertiserModal';
import AddContractModal from './components/modals/AddContractModal';
import AddCampaignModal from './components/modals/AddCampaignModal';
import AddCampaignMediaModal from './components/modals/AddCampaignMediaModal';
import AddPlaceModal from './components/modals/AddPlaceModal';
import AddPlaceResponsibleModal from './components/modals/AddPlaceResponsibleModal';
import AddPlaceZoneModal from './components/modals/AddPlaceZoneModal';
import AddPointModal from './components/modals/AddPointModal';
import AddCampaignScheduleModal from './components/modals/AddCampaignScheduleModal';
import AddPlaceGroupModal from './components/modals/AddPlaceGroupModal';
import ImportPlacesModal from './components/modals/ImportPlacesModal';
import AddBatchPaymentModal from './components/modals/AddBatchPaymentModal';
import SegmentPlacesDashboard from './pages/segmentPlaces/SegmentPlacesDashboard';

function Navigation() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const background = location.state && location.state.background;

  const { token, validateToken, isValidated, user } = useAuth();

  const checkToken = async () => {
    if (!token) {
      await validateToken();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  if (!isValidated) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        {token && user.profile === 'admin' ? (
          <React.Fragment>
            <Routes location={background || location}>
              <Route path="/" element={<MainPage />} />
              <Route path="/users" element={<UserView />} />

              <Route path="/advertisers" element={<AdvertiserView />} />
              <Route
                path="/advertisers/:advertiserId/contracts/"
                element={<AdvertiserProfile />}
              />

              <Route path="/placeGroups" element={<PlaceGroupView />} />
              <Route
                path="/placeGroups/:placeGroupId/profile/"
                element={<PlaceGroupProfile />}
              />
              <Route
                path="placeGroups/:placeGroupId/dashboard/"
                element={<PlaceGroupDashboard />}
              />

              <Route
                path="advertisercampaigns/:advertiserId"
                element={<AdvertiserDashboard />}
              />
              <Route
                path="advertisercampaigns/:advertiserId/campaign/:campaignId"
                element={<CampaignDashboard />}
              />
              {/* <Route
                path="advertisercampaigns/:advertiserId/users"
                element={<AdvertiserUser />}
              /> */}

              <Route path="/places" element={<PlaceView />} />
              <Route
                path="/places/:placeId/profile"
                element={<PlaceProfile />}
              />
              <Route
                path="/campaigns/:campaignId/profile"
                element={<CampaignProfile />}
              />

              <Route path="/campaigns" element={<CampaignView />} />


              <Route path="/batchPayments" element={<BatchPaymentsView />} />
              <Route path="/batchPayments/:batchPaymentId/details" element={<BatchPaymentsDetails />} />

              <Route path="/segmentPlaces" element={<SegmentPlacesDashboard />} />

              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<Navigate replace to="/users" />} />
            </Routes>
            {background && (
              <Routes>
                <Route path="/users/create" element={<AddUserModal />} />
                <Route path="/users/edit/:userId" element={<AddUserModal />} />
                <Route
                  path="/advertisers/:advertiserId/users/create"
                  element={<AddUserModal />}
                />
                <Route
                  path="/advertisers/:advertiserId/users/edit/:userId"
                  element={<AddUserModal />}
                />

                <Route
                  path="/campaigns/create"
                  element={<AddCampaignModal />}
                />
                <Route
                  path="/campaigns/edit/:campaignId"
                  element={<AddCampaignModal />}
                />
                <Route
                  path="/campaigns/:campaignId/contract/create"
                  element={<AddContractModal />}
                />
                <Route
                  path="/campaigns/:campaignId/contract/edit/:contractId"
                  element={<AddContractModal />}
                />
                <Route
                  path="/campaigns/:campaignId/campaignMedia/create"
                  element={<AddCampaignMediaModal />}
                />
                <Route
                  path="/campaigns/:campaignId/campaignMedia/edit/:campaignMediaId"
                  element={<AddCampaignMediaModal />}
                />
                <Route
                  path="/campaigns/:campaignId/schedules/create"
                  element={<AddCampaignScheduleModal />}
                />
                <Route
                  path="/campaigns/:campaignId/schedules/edit/:campaignScheduleId"
                  element={<AddCampaignScheduleModal />}
                />
                <Route
                  path="/places/:placeId/contracts/edit/:contractId"
                  element={<AddContractModal />}
                />
                <Route
                  path="/advertisers/:advertiserId/campaigns/create"
                  element={<AddCampaignModal />}
                />
                <Route
                  path="/advertisers/:advertiserId/campaigns/edit/:campaignId"
                  element={<AddCampaignModal />}
                />

                <Route path="/places/create" element={<AddPlaceModal />} />
                <Route path="/places/import" element={<ImportPlacesModal />} />
                <Route
                  path="/places/edit/:placeId"
                  element={<AddPlaceModal />}
                />
                <Route
                  path="/places/:placeId/placeResponsible/create"
                  element={<AddPlaceResponsibleModal />}
                />
                <Route
                  path="/places/:placeId/placeResponsible/edit/:placeResponsibleId"
                  element={<AddPlaceResponsibleModal />}
                />

                <Route
                  path="/places/:placeId/placeZone/create"
                  element={<AddPlaceZoneModal />}
                />
                <Route
                  path="/places/:placeId/placeZone/edit/:placeZoneId"
                  element={<AddPlaceZoneModal />}
                />

                <Route
                  path="/places/:placeId/point/create"
                  element={<AddPointModal />}
                />
                <Route
                  path="/places/:placeId/point/edit/:pointId"
                  element={<AddPointModal />}
                />

                <Route
                  path="/advertisers/create"
                  element={<AddAdvertiserModal />}
                />
                <Route
                  path="/advertisers/edit/:advertiserId"
                  element={<AddAdvertiserModal />}
                />
                <Route
                  path="/advertisers/:advertiserId/contracts/create"
                  element={<AddContractModal />}
                />
                <Route
                  path="/advertisers/:advertiserId/contracts/edit/:contractId"
                  element={<AddContractModal />}
                />

                <Route
                  path="/contracts/create"
                  element={<AddContractModal />}
                />
                <Route
                  path="/contracts/edit/:contractId"
                  element={<AddContractModal />}
                />


                <Route
                  path="/placeGroups/create"
                  element={<AddPlaceGroupModal />}
                />
                <Route
                  path="/placeGroups/edit/:placeGroupId"
                  element={<AddPlaceGroupModal />}
                />
                <Route
                  path="/placeGroups/:placeGroupId/users/create"
                  element={<AddUserModal />}
                />
                <Route
                  path="/placeGroups/:placeGroupId/users/edit/:userId"
                  element={<AddUserModal />}
                />
                <Route
                  path="/batchPayments/create"
                  element={<AddBatchPaymentModal />}
                />

              </Routes>
            )}
          </React.Fragment>
        ) : null}
        {token && user.profile === 'advertiser' ? (
          <React.Fragment>
            <Routes location={background || location}>
              <Route
                path="advertisercampaigns/:advertiserId"
                element={<AdvertiserDashboard />}
              />
              <Route
                path="advertisercampaigns/:advertiserId/campaign/:campaignId"
                element={<CampaignDashboard />}
              />
              <Route
                path="*"
                element={
                  <Navigate
                    replace
                    to={`advertisercampaigns/${user.advertiser_id}`}
                  />
                }
              />
            </Routes>
          </React.Fragment>
        ) : null}
        {token && user.profile === 'ecd' ? (
          <React.Fragment>
            <Routes location={background || location}>
              <Route
                path="ecd/:placeGroupId/dashboard"
                element={<PlaceGroupDashboard />}
              />
              {/* <Route
                path="advertisercampaigns/:advertiserId/campaign/:campaignId"
                element={<CampaignDashboard />}
              /> */}
              <Route
                path="*"
                element={
                  <Navigate
                    replace
                    to={`ecd/${user.placeGroupId}/dashboard`}
                  />
                }
              />
            </Routes>
          </React.Fragment>
        ) : null}
        {!token ? (
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/passwordrecovery" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate replace to="/signin" />} />
          </Routes>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Navigation;
