import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const BatchPaymentDepositsContext = createContext();
const initialBatchPaymentDeposit = {
  id: null,
};

export function BatchPaymentDepositsProvider({ children }) {
  const [batchPaymentDeposits, setBatchPaymentDeposits] = useState([]);
  const [dashBatchPaymentDeposit, setDashBatchPaymentDeposit] = useState(initialBatchPaymentDeposit);
  const [editBatchPaymentDeposit, setEditBatchPaymentDeposit] = useState(initialBatchPaymentDeposit);

  function getBatchPaymentDeposit(batchPaymentDepositId, query = {}) {
    services.batchPaymentDeposits.get(batchPaymentDepositId, query).then((response) => {
      // console.log('getBatchPaymentDeposit', response);
      setBatchPaymentDeposits([response]);
    });
  }

  function getDashBatchPaymentDeposit(batchPaymentDepositId, query = {}) {
    services.batchPaymentDeposits.get(batchPaymentDepositId, query).then((response) => {
      // console.log('getBatchPaymentDeposit', response);
      setDashBatchPaymentDeposit(response);
    });
  }

  function getEditBatchPaymentDeposit(batchPaymentDepositId) {
    services.batchPaymentDeposits.get(batchPaymentDepositId).then((response) => {
      // console.log('getBatchPaymentDeposit', response);
      setEditBatchPaymentDeposit(response);
    });
  }

  function findBatchPaymentDeposits(query = {}) {
    return services.batchPaymentDeposits.find(query).then((response) => {
      // console.log('findBatchPaymentDeposits', response);
      setBatchPaymentDeposits(response.data);
      return response.data;
    });
  }

  async function createBatchPaymentDeposits(batchPaymentDeposit) {
    return services.batchPaymentDeposits.create(batchPaymentDeposit).then((result) => {
      return result;
    });
  }

  async function patchBatchPaymentDeposits(id, batchPaymentDeposit, query = {}) {
    return services.batchPaymentDeposits.patch(id, batchPaymentDeposit, query).then(() => {
      setEditBatchPaymentDeposit(initialBatchPaymentDeposit);
      getBatchPaymentDeposit(id);
    });
  }

  async function removeBatchPaymentDeposits(batchPaymentDepositId) {
    return services.batchPaymentDeposits.remove(batchPaymentDepositId).then(() => {
      findBatchPaymentDeposits();
    });
  }
  return (
    <BatchPaymentDepositsContext.Provider
      value={{
        batchPaymentDeposits,
        dashBatchPaymentDeposit,
        editBatchPaymentDeposit,
        setEditBatchPaymentDeposit,
        getEditBatchPaymentDeposit,
        getDashBatchPaymentDeposit,
        findBatchPaymentDeposits,
        createBatchPaymentDeposits,
        patchBatchPaymentDeposits,
        getBatchPaymentDeposit,
        removeBatchPaymentDeposits,
      }}
    >
      {children}
    </BatchPaymentDepositsContext.Provider>
  );
}

export function useBatchPaymentDeposits() {
  const context = useContext(BatchPaymentDepositsContext);

  return context;
}
