import React from 'react';
import { Card, Stack, Divider, CardContent, Typography, Avatar, Grid, CardActions, Button, Box } from '@mui/material';
import { ContractCardHandler } from './common/types';
import { FormattedNumber } from 'react-intl';
import numeral from 'numeral';

const ContractCard = ({ 
  isActive,
  name, 
  address, 
  nOfViews, 
  // viewsAvg, 
  nOfCtas, 
  ctr, 
  urlImage,
}: ContractCardHandler) => {
  return (
    <Card
    sx={{
      minWidth: 280,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.15)',
      margin: '10px',
    }}
  >
    <Stack
      divider={<Divider flexItem />}
      spacing={2}
      sx={{ width: 330 }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            alignSelf: 'flex-start',
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {!!isActive ? (
            <React.Fragment>
              <div
                className="campaignStatus"
                style={{ backgroundColor: '#66bb6a' }}
              ></div>
              <Typography
                variant="subtitle2"
                sx={{ paddingLeft: 1 }}
              >
                Ativo
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div
                className="campaignStatus"
                style={{ backgroundColor: '#C8C8C8' }}
              ></div>
              <Typography
                variant="subtitle2"
                sx={{ paddingLeft: 1 }}
              >
                Encerrada
              </Typography>
            </React.Fragment>
          )}
        </div>

        <Avatar src={urlImage}/>
        <Typography variant="h6">
          {name}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ color: '#999999' }}
        >
          {address}
        </Typography>
      </CardContent>      
      <Stack
        justifyContent='space-between'
        direction={'row'}
        padding={2}    
      >
        <Box sx={{ textAlign: 'center'}}>
          <Typography variant="subtitle1">{numeral(nOfViews).format('0,0')}</Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: '#999999' }}
          >
            Impressões
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center'}}>
          <Typography variant="subtitle1">{nOfCtas}</Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: '#999999' }}
          >
            Cliques
          </Typography>
          </Box>

        <Box sx={{ textAlign: 'center'}}>
          <Typography variant="subtitle1">
            <FormattedNumber value={ctr || 0} style='decimal' maximumFractionDigits={2}/> %
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: '#999999' }}
          >
            CTR
          </Typography>
          </Box>
      </Stack>

      {/* <CardActions
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button>Saiba mais</Button>
      </CardActions> */}
    </Stack>
  </Card>
  );
};

export default ContractCard;
