/*eslint-disable no-restricted-globals*/

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../contexts/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../components/img/logo.png';
import '../styles/SignIn.css';

const theme = createTheme();

const buttonPrimary = {
  mt: 3,
  mb: 2,
  color: '#FFFFFF',
  backgroundColor: '#FF4C51',
  '&:hover': {
    backgroundColor: '#df4247',
  },
};

function SignIn() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    login(data.get('email'), data.get('password')).then(navigate('/users'));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" className="signInPanel">
        <CssBaseline />
        <Box className="signInForm">
          <div>
            <div className="logo">

            <img
            src={logo}
            alt="Thirky logo"
            style={{ width: '155px', marginTop: '10px' }}
            id="thirky-logo"
          />
            </div>

            <Typography component="h1" variant="h4">
              Bem vindo
            </Typography>
            <Typography variant="body2">
              Faça seu login e comece sua aventura.
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={buttonPrimary}
              >
                Entrar
              </Button>
              {/* <Grid container>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Lembrar minha senha"
                  />
                </Grid>
                <Grid item>
                  <Link to={'/passwordrecovery'} className="linkStyle">
                    Esqueci minha senha
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default SignIn;
