import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useSnackbar } from 'notistack';
import { PhotoCamera } from '@mui/icons-material';
import { useAdvertisers } from '../../contexts/useAdvertisers';
import { useNavigate, useParams } from 'react-router-dom';

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '90vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

interface AdvertiserInterface {
  id: number | null;
  name: string | null;
  urlImage?: any | null;
  newFileImage?: string | null | unknown;
  isEnabled: boolean;
}

const initialAdvertiser: AdvertiserInterface = {
  id: null,
  name: null,
  urlImage: null,
  newFileImage: null,
  isEnabled: true,
};

const AddAdvertiserModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { advertiserId } = useParams();
  const {
    editAdvertiser,
    setEditAdvertiser,
    getEditAdvertiser,
    createAdvertisers,
    patchAdvertisers,
    findAdvertisers,
  } = useAdvertisers();
  const [advertiser, setAdvertiser] =
    useState<AdvertiserInterface>(initialAdvertiser);

  const handleInputChange = (event: any) => {
    // console.log('event.target.value ', event.target.value);
    const { name, value } = event.target;
    // console.log('event.target.value ', { name, value });
    setAdvertiser({
      ...advertiser,
      [name]: value,
    });
  };

  useEffect(() => {
    // console.log('advertiserId ', advertiserId);
    // console.log('advertiser?.id ', advertiser?.id);
    // console.log('editAdvertiser?.id ', editAdvertiser?.id);
    if (advertiserId && !advertiser?.id) {
      // console.log('getEdirAdvertiser');
      getEditAdvertiser(advertiserId);
    }
  }, [advertiserId]);

  useEffect(() => {
    if (editAdvertiser.id && editAdvertiser?.id !== advertiser?.id) {
      setAdvertiser(editAdvertiser);
    }
  }, [editAdvertiser]);

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const removeImage = () => {
    setAdvertiser({...advertiser, newFileImage: null})
  }

  const uploadFileProfile = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    // console.log('base64: ', base64)
    setAdvertiser({
      ...advertiser,
      newFileImage: base64 ? base64 : null
    })
  };

  const validateAdvertiser = (advertiser: any) => {
    if (!advertiser?.name) {
      enqueueSnackbar('Informe o nome do anunciante!', { variant: 'error' });
      return false;
    }

    // if (!advertiser?.image) {
    //   enqueueSnackbar('Insira a imagem do anunciante!', { variant: 'error' });
    //   return false;
    // }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // console.log('advertiser: ', advertiser);
    if (validateAdvertiser(advertiser)) {
      if (advertiser?.id) {
        patchAdvertisers(advertiser.id, advertiser)
          .then(() => {
            // console.log('Deu certo');
            enqueueSnackbar('Anunciante alterado com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            // console.log('Erro ao alterar', error);
            const { message } = error;
            enqueueSnackbar(message ? message : 'Erro ao alterar anunciante!', { variant: 'error' });
          });
      } else {
        createAdvertisers(advertiser)
          .then((result: any) => {
            console.log('Deu certo', result);
            enqueueSnackbar('Anunciante criado com sucesso!', {
              variant: 'success',
            });
            navigate(`/advertisers/${result.id}/contracts`);
            // closeModal();

          })
          .catch((error: any) => {
            // console.log('Erro ao criar', error);
            const { message } = error;
            enqueueSnackbar(message ? message : 'Erro ao criar anunciante!', { variant: 'error' });
          });
      }
    }
  };

  const reloadAdvertisers = () => {
    findAdvertisers({query: {
      includeAdvertiserNumberOfActivePlaces: true,
      $sort: {
        name: 1,
      },
    }});
  }

  const closeModal = () => {
    navigate(-1);
    reloadAdvertisers();
    setTimeout(() => {
      setAdvertiser(initialAdvertiser);
      setEditAdvertiser(initialAdvertiser);
    }, 1000)
  };

  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CenterModalStyle} onClick={(e) => e.stopPropagation()}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Novo Anunciante
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton className="closeIcon" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            label="Nome do anunciante"
            name="name"
            id="name"
            value={advertiser.name || ''}
            onChange={handleInputChange}
            required
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <>
              {advertiser?.newFileImage || advertiser?.urlImage 
                ? <img 
                    src={ advertiser?.newFileImage ? advertiser?.newFileImage as string : advertiser?.urlImage as string} 
                    alt="Perfil anunciante" 
                  /> 
                : null}
              <Button variant="contained" component="label" fullWidth  startIcon={<PhotoCamera/>}>
                Selecionar Imagem
                <input key={Math.random().toString(36)} hidden accept="image/*" onChange={(e) => uploadFileProfile(e)} multiple type="file" />
              </Button>
              {advertiser?.newFileImage && (
                <Button 
                  color="warning" 
                  variant="contained" 
                  onClick={removeImage}
                >
                  Remover imagem
                </Button>
              )}
            </>
          </FormControl>
          {advertiser.id &&
            <FormControlLabel
              control={
                <Checkbox
                  name="isEnabled"
                  id="isEnabled"
                  checked={!!advertiser.isEnabled}
                  onChange={(event) =>
                    setAdvertiser({
                      ...advertiser,
                      isEnabled: event.target.checked,
                    })
                  }
                />
              }
              label="Ativo"
            />
          }
          <Button variant="contained" type="submit" fullWidth>
            {(!advertiser?.id && 'Criar anunciante') || 'Editar anunciante'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddAdvertiserModal;
