import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import uniqBy from 'lodash/uniqBy';

import { useSnackbar } from 'notistack';
import { useContracts } from '../../contexts/useContracts';
import { useBatchPayments } from '../../contexts/useBatchPayments';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import numeral from 'numeral';
import { FormattedNumber } from 'react-intl';

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: '80%',
  height: '90vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

interface BatchPaymentInterface {
  id: number | null;
  recipientType: string | null;
}

const initiaBatchPayment: BatchPaymentInterface = {
  id: null,
  recipientType: 'place',
};

const reciptientTypes = [
  { value: 'place', label: 'Estabelecimento' },
  { value: 'placeGroup', label: 'ECD' },
];

const AddPlaceModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    contracts,
    findContracts,
  } = useContracts();

  const { createBatchPayments } = useBatchPayments();
  
  const [ batchPayment, setBatchPayment ] = useState<BatchPaymentInterface>(initiaBatchPayment);
  const [ placeGroupId, setPlaceGroupId ] = useState<number | null>(null);
  const [ campaignId, setCampaignId ] = useState<number | null>(null);
  const [ filtredContracts, setFiltredContracts ] = useState<any[]>([]);
  const [ selectedRows, setSelectedRows ] = useState<number[]>([]);

  const handleInputChange = (event: any) => {
    // console.log('event.target.value ', event.target.value);
    const { name, value } = event.target;
    // console.log('event.target.value ', { name, value });
    setBatchPayment({
      ...batchPayment,
      [name]: value,
    });
  };

  useEffect(() => {
    if (batchPayment.recipientType === 'place') {
      findContractsByPlaces();
    } else if (batchPayment.recipientType === 'placeGroup') {
      findContractsByPlaceGroups();
    }
    setCampaignId(null)
    setPlaceGroupId(null)
  }, [batchPayment.recipientType]);

  const findContractsByPlaces = () => {
    findContracts({ query: { 
      includeCampaignPlaceGroups: true,
      includeCampaignPlaces: true,
      placeBatchPaymentDepositId: 'null',
      totalPaidPlace: { $gt: 0 },
     }});
  }

  const findContractsByPlaceGroups = () => {
    findContracts({ query: { 
      includeCampaignPlaceGroups: true,
      includeCampaignPlaces: true,
      placeGroupBatchPaymentDepositId: 'null',
      totalPaidPlaceGroup: { $gt: 0 },
     }});
  }

  useEffect(() => {
    const newFiltredContractsCampaign = campaignId ? contracts.filter((cnt: any) => cnt?.campaign?.id === campaignId) : contracts;
    const newFiltredContractsPlaceGroup = placeGroupId ? newFiltredContractsCampaign.filter((cnt: any) => cnt?.placeGroup?.id === placeGroupId) : newFiltredContractsCampaign;
    setFiltredContracts(newFiltredContractsPlaceGroup)
  }, [placeGroupId, campaignId]);

  useEffect(() => {
    setFiltredContracts(contracts)
  }, [contracts]);

  const validateBatchPayment = (batchPayment: any) => {
    if (!batchPayment?.recipientType) {
      enqueueSnackbar('Informe o tipo de pagamento!', { variant: 'error' });
      return false;
    }

    // if (!place?.placesFile) {
    //   enqueueSnackbar('Insira a imagem do estabelecimento!', { variant: 'error' });
    //   return false;
    // }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // console.log('place: ', place);
    if (validateBatchPayment(batchPayment)) {
      createBatchPayments({...batchPayment, contracts: selectedRows})
        .then((result: any) => {
          enqueueSnackbar('Lote de pagamento criado com sucesso!', {
            variant: 'success',
          });
          closeModal(`/batchPayments`);
        })
        .catch((error: any) => {
          enqueueSnackbar(error.message || 'Erro ao criar lote de pagamento!', { variant: 'error' });
        });
    }
  };

  // const reloadBatchPayments = () => {
  //   find({
  //     query: {
  //       $sort: {
  //         name: 1,
  //       },
  //       isEnabled: true,
  //     }
  //   });
  // }

  const closeModal = (navigateTo?: string | null) => {
    if (navigateTo) {
      navigate(navigateTo);
      // reloadPlaces()
    } else {
      navigate(-1);
    }
    setTimeout(() => {
      setBatchPayment(initiaBatchPayment);
    }, 500);
  };

  const columnsContract: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'place',
      headerName: 'Local',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row?.place?.name}</span>
      ),
    },
    {
      field: 'campaign',
      headerName: 'Campanha',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row?.campaign?.name}</span>
      ),
    },
    {
      field: 'placeGroup',
      headerName: 'ECD',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row?.placeGroup?.name}</span>
      ),
    },
    { 
      field: 'totalPaidPlace', 
      headerName: 'Valor Estabelecimento', 
      hide: batchPayment.recipientType === 'placeGroup',
      width: 150,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPaidPlace} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    { 
      field: 'totalPaidPlaceGroup', 
      headerName: 'Valor ECD', 
      hide: batchPayment.recipientType === 'place',
      width: 150,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPaidPlaceGroup} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
  ];

  const handleSelectionChange = (newSelection: any) => {
    setSelectedRows(newSelection);
  };
  const filtredCampaigns = uniqBy(contracts, 'campaignId');
  const filtredPlaceGroups = uniqBy(contracts, 'placeGroupId');

  const selectedContracts = contracts.filter((cnt: any) => selectedRows.length && selectedRows.includes(cnt?.id));

  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CenterModalStyle} onClick={(e) => e.stopPropagation()}>
        <Stack
          sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }} 
          direction="row" 
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Criar Lote de Pagamento
          </Typography>

          <IconButton className="closeIcon" onClick={() => closeModal()}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '100%' },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <FormControl variant="outlined" sx={{ mt: 3, width: '30% !important' }}>
            <InputLabel 
              id="demo-simple-select-outlined-label"
              shrink
              style={{ 
                backgroundColor: '#fff', 
                paddingLeft: 5, 
                paddingRight: 5 
              }} 
            >
              Tipode de Pagamento
            </InputLabel>

            <Select
              labelId="demo-simple-select-outlined-label"
              id="recipientType"
              name="recipientType"
              value={String(batchPayment.recipientType || '')}
              onChange={handleInputChange}
              label="Tipode de Pagamento"
              input={<OutlinedInput />}
              required
            >
              <MenuItem value="">Selecione</MenuItem>
              {reciptientTypes.map((type : any) => (
                <MenuItem key={type.value} value={String(type.value)}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ mt: 3, width: '30% !important'  }}>
            <InputLabel 
              id="demo-simple-select-outlined-label"
              shrink
              style={{ 
                backgroundColor: '#fff', 
                paddingLeft: 5, 
                paddingRight: 5 
              }} 
            >
              ECD
            </InputLabel>

            <Select
              labelId="demo-simple-select-outlined-label"
              id="placeGroupId"
              name="placeGroupId"
              value={String(placeGroupId || '')}
              onChange={e => setPlaceGroupId(Number(e.target.value))}
              label="ECD"
              input={<OutlinedInput />}
              required
            >
              <MenuItem value="">Todos</MenuItem>
              {filtredPlaceGroups.length ? filtredPlaceGroups.map((contract : any) => (
                <MenuItem key={contract?.placeGroup?.id} value={String(contract?.placeGroup?.id)}>
                  {contract?.placeGroup?.name}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ mt: 3, width: '30% !important'  }}>
            <InputLabel 
              id="demo-simple-select-outlined-label"
              shrink
              style={{ 
                backgroundColor: '#fff', 
                paddingLeft: 5, 
                paddingRight: 5 
              }} 
            >
              Campanha
            </InputLabel>

            <Select
              labelId="demo-simple-select-outlined-label"
              id="placeGroupId"
              name="placeGroupId"
              value={String(campaignId || '')}
              onChange={e => setCampaignId(Number(e.target.value))}
              label="Campanha"
              input={<OutlinedInput />}
              required
            >
              <MenuItem value=''>Todos</MenuItem>
              {filtredCampaigns.length ? filtredCampaigns.map((contract : any) => (
                <MenuItem key={contract.campaign.id} value={String(contract.campaign.id)}>
                  {contract.campaign.name}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
          <Box>
            <Typography>Contratos selecionados: {selectedContracts.length}</Typography>
            { batchPayment.recipientType === 'place' && (
              <>
                <Typography>Total a pagar: 
                  <FormattedNumber
                    value={contracts.reduce((acc: any, row: any) => Number(acc) + Number(row?.totalPaidPlace), 0)} 
                    style="currency" 
                    currency="BRL" 
                  />
                </Typography>
                <Typography>Total selecionado: 
                  <FormattedNumber
                    value={selectedContracts.reduce((acc: any, row: any) => Number(acc) + Number(row?.totalPaidPlace), 0)} 
                    style="currency" 
                    currency="BRL" 
                  />
                </Typography>
              </>
            )}
            { batchPayment.recipientType === 'placeGroup' && (
              <>
                <Typography>Total a pagar: 
                  <FormattedNumber
                    value={contracts.reduce((acc: any, row: any) => Number(acc) + Number(row?.totalPaidPlaceGroup), 0)} 
                    style="currency" 
                    currency="BRL" 
                  />
                </Typography>
                <Typography>Total selecionado: 
                  <FormattedNumber
                    value={selectedContracts.reduce((acc: any, row: any) => Number(acc) + Number(row?.totalPaidPlaceGroup), 0)} 
                    style="currency" 
                    currency="BRL" 
                  />
                </Typography>
              </>
            )}
          </Box>

          <DataGrid
            rows={filtredContracts}
            columns={columnsContract}
            style={{ height: 500 }}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onSelectionModelChange={handleSelectionChange}
            // initialState={{
            //   pagination: {
            //     paginationModel: { page: 0, pageSize: 5 },
            //   },
            // }}
            // pageSizeOptions={[5, 10]}
            checkboxSelection
          />
          <Button variant="contained" type="submit" disabled={!selectedRows.length}>
            Criar Lote de Pagamento  {batchPayment.recipientType === 'placeGroup' ? 'ECD' : 'Estabelecimento'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPlaceModal;
