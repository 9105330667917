import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const CampaignsContext = createContext();
const initialCampaign = {
  id: null,
  name: null,
  taxonomyName: null,
  codPi: null,
  urlCover: null,
  urlVideo: null,
  prospectivePaymentDate: null,
  paidAt: null,
  approvedAt: null,
  viewCost: null,
  tagTrack: null,
  tagScript: null,
  viewsGoal: null,
  urlCta: null,
  textCta: null,
  startDate: null,
  endDate: null,
  colorStyle: null,
  backgroundStyle: null,
  waitTime: null,
  urlExample: null,
}

export function CampaignsProvider({ children }) {
  const [campaigns, setCampaigns] = useState([]);
  const [editCampaign, setEditCampaign] = useState(initialCampaign);

  function getCampaign(campaignId, query = {}) {
    services.campaigns.get(campaignId, query)
      .then((response) => {
        // console.log('getCampaign', response)
        setCampaigns([response])
      });
  }

  function getEditCampaign(campaignId, query = {}) {
    services.campaigns.get(campaignId, query)
      .then((response) => {
        // console.log('getCampaign', response)
        setEditCampaign(response)
      });
  }

  function findCampaigns({query = {}}) {
    query.$sort = query.$sort ? query.$sort : { createdAt: -1 }
    services.campaigns.find({query})
    .then((response) => {
      setCampaigns(response.data)
    });
  }

  async function createCampaigns(campaign) {
    return services.campaigns.create(campaign).then(result => {
      return result;
    })
  }

  async function createCampaignProcessPayments(campaignId) {
    return services.campaignProcessPayments.create({campaignId}).then(result => {
      return result;
    })
  }


  async function patchCampaigns(id, campaign) {
    return services.campaigns.patch(id, campaign)
      .then(() => {
        setEditCampaign(initialCampaign);
      });
  }

  function getCampaignStatus(status) {
    switch (status) {
      case 'preparing':
        return 'Nos Preparativos'
      case 'active':
        return 'Em Ativação'
      case 'started':
        return 'Em Andamento'
      case 'stoped':
        return 'Pausada'
      case 'inPayment':
        return 'Em Pagamento'
      case 'ended':
        return 'Encerrada'
      default:
        return ''
    }
  }

  async function removeCampaigns(campaignId) {
    return services.campaigns.remove(campaignId)
  }

  function clearCampaigns() {
    setCampaigns([])
  }
  return (
    <CampaignsContext.Provider
      value={{
        campaigns,
        editCampaign,
        setEditCampaign,
        getEditCampaign,
        findCampaigns,
        createCampaigns,
        patchCampaigns,
        getCampaign,
        removeCampaigns,
        clearCampaigns,
        createCampaignProcessPayments,
        getCampaignStatus,
      }}
    >
      { children }
    </CampaignsContext.Provider>
  );
}

export function useCampaigns() {
  const context = useContext(CampaignsContext);

  return context;
}
