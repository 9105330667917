import React, { Fragment, useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VerifiedIcon from '@mui/icons-material/Verified';
import ViewIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import numeral from 'numeral';
import * as XLSX from 'xlsx';

import Table from '../../components/Table';
import { usePlaceGroups } from '../../contexts/usePlaceGroups';
import { Link, useParams } from 'react-router-dom';
import { useCampaigns } from '../../contexts/useCampaigns';
import { usePlaces } from '../../contexts/usePlaces';
import { useContracts } from '../../contexts/useContracts';
import { useAuth } from '../../contexts/useAuth';
import { useExportReports } from '../../contexts/useExportReports';
import QR_CODE_CLIENT_URL from '../../api/qrCodeClientUrl';
import '../../styles/PlaceGroupDashboard.css';
import { FormattedNumber } from 'react-intl';

import { getMediaTypeIcon } from '../../utils/media-type-icons';

interface PlaceGroupInterface {
  id: number | null;
  name: string | null;
  urlImage?: any | null;
  // nOfViews: number | null;
  // viewsAvg: number | null;
  // nOfCtas: number | null;
  // ctr: number | null;
  // nOfCampaigns: number | null;
  // nOfPlaces: number | null;
}

const initialPlaceGroup: PlaceGroupInterface = {
  id: null,
  name: null,
  urlImage: null,
  // nOfViews: 0,
  // viewsAvg: 0,
  // nOfCtas: 0,
  // ctr: 0,
  // nOfCampaigns: 0,
  // nOfPlaces: 0,
};

const PlaceGroupDashboard = () => {
  const { dashPlaceGroup, getDashPlaceGroup } = usePlaceGroups();
  const { findExportReports } = useExportReports();
  const [placeGroup, setPlaceGroup] =
    useState<PlaceGroupInterface>(initialPlaceGroup);
  const { placeGroupId } = useParams();
  const { user } = useAuth();
  const { campaigns, findCampaigns, getCampaignStatus } = useCampaigns();
  const { contracts, findContracts } = useContracts();
  const { places, findPlaces, enablePlaceCampaigns } = usePlaces();
  const [ selectedList, setSelectedList ] = useState<string>('places');

  const [ queryPlace, setQueryPlace ] = useState<string>('');
  const [ queryPlacesEnabled, setQueryPlacesEnabled ] = useState<boolean>(true);
  const [ filtredPlaces, setFilteredPlaces ] = useState<any[]>([]);

  const [ queryCampaign, setQueryCampaign ] = useState<string>('');
  const [ filtredCampaigns, setFilteredCampaigns ] = useState<any[]>([]);

  const [ queryContract, setQueryContract] = useState<string>('');
  const [ filtredContracts, setFilteredContracts ] = useState<any[]>([]);

  const [ openEnableCampaignDialog, setOpenEnableCampaignDialog ] = useState<boolean>(false);
  const [ placeEnableCampaignId, setPlaceEnableCampaignId ] = useState<number | null>(null);

  const [ filterCampaignId, setFilterCampaignId ] = useState<number | null>(null);
  const [ loadingPlaceAccessesResume, setLoadingPlaceAccessesResume ] = useState<boolean>(false);

  const [ filterOnlyActiveContracts, setFilterOnlyActiveContracts] = useState<boolean>(true);

  const getPlaceGroupInformation = () => {
    getDashPlaceGroup(placeGroupId, { 
      query: { 
        // includeBigNumbers: true 
      } 
    });
  };

  useEffect(() => {
    if (placeGroupId && !placeGroup.id) {
      getPlaceGroupInformation();
    }
  }, [placeGroupId]);

  useEffect(() => {
    if (!placeGroup.id && dashPlaceGroup?.id == placeGroupId) {
      setPlaceGroup(dashPlaceGroup as PlaceGroupInterface);
    }
  }, [dashPlaceGroup]);

  useEffect(() => {
    if (filterCampaignId) {
      const contractsFiltred = contracts.filter((contract: any) => contract.campaignId == filterCampaignId);
      setFilteredContracts(contractsFiltred);
    } else {
      setFilteredContracts(contracts);
    }
  }, [filterCampaignId, contracts]);

  const filteredCampaigns = Object.values(campaigns).filter(
    (campaign: any) => campaign.placeGroupId == placeGroupId
  );

  const handleUpdateEnableCampaignPlace = () => {
    enablePlaceCampaigns(placeEnableCampaignId);
    handleCloseEnableCampaignDialog();
  }

  const placeColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Estabelecimento',
      width: 450,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 2 }}>
            <Avatar
              alt=""
              src={row.urlImage}
              style={{ marginRight: '1.5rem' }}
            />
            {row?.name}
          </Box>
        </React.Fragment>

      ),
    },
    {
      field: 'isEnabled',
      headerName: 'Status',
      width: 330,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!row?.enabledCampaignsAt ? (
            <>
              <Chip label="Inativo" color="error" style={{ marginRight: 15 }}/>
              <Button 
                onClick={() => handleOpenEnableCampaignDialog(row?.id as number)}
                variant="outlined" 
                color="success" 
                startIcon={<VerifiedIcon />}
              >
                Ativar
              </Button>
            </>
            
          ) : (
            <Chip label={`Ativado em ${dayjs(row?.enabledCampaignsAt).format('DD/MM/YY')}`} color="success" />
          )}
        </React.Fragment>
      ),
    },
    // {
    //   field: 'changeToActive',
    //   headerName: ' ',
    //   width: 130,
    //   headerClassName: 'app-theme--header',
    //   renderCell: ({ row }: Partial<GridRowParams>) => (
    //     <React.Fragment>
    //       {!row?.enabledCampaignsAt ? (

    //       ) : null}
    //     </React.Fragment>
    //   ),
    // },
    {
      field: 'acceptedMediaTypes',
      headerName: 'Tipos Midia',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>
          {row.acceptedMediaTypes?.includes('highlight') ? 
              <Tooltip title="Interstitial">
                <img style={{ width: 25, height: 25, marginRight: 10 }} src={getMediaTypeIcon('highlight'|| '')} alt={'highlight' || ''}/>
              </Tooltip>
            : null}
          {row.acceptedMediaTypes?.includes('listItemTop') ? 
            <Tooltip title="lista">
              <img style={{ width: 25, height: 25, marginRight: 10 }} src={getMediaTypeIcon('listItemTop'|| '')} alt={'listItemTop' || ''}/>
            </Tooltip>
            : null}
          {row.acceptedMediaTypes?.includes('preRoll') ? 
            <Tooltip title="Destaque">
              <img style={{ width: 25, height: 25, marginRight: 10 }} src={getMediaTypeIcon('preRoll'|| '')} alt={'preRoll' || ''}/>
            </Tooltip>
          : null}
        </span>
      ),
    },
    {
      field: 'nOfViewsLast30Days',
      headerName: '30 Dias',
      width: 125,
      type: "number",
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{numeral(row.nOfViewsLast30Days).format('0,0')}</span>
      ),
    },
    {
      field: 'nOfViewsLast7Days',
      headerName: '7 Dias',
      width: 125,
      type: "number",
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{numeral(row.nOfViewsLast7Days).format('0,0')}</span>
      ),
    },
    {
      field: 'avgViewsLast7Days',
      headerName: 'Media 7 Dias',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{numeral(row.nOfViewsLast7Days/7).format('0,0')}</span>
      ),
    },
    {
      field: 'totalPlacePendingAmount',
      headerName: 'À receber',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlacePendingAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'totalPayedAmount',
      headerName: 'Recebido',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlaceAmount - row?.totalPlacePendingAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'totalPlaceAmount',
      headerName: 'Total',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlaceAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'totalPlaceGroupPendingAmount',
      headerName: 'À receber Parceiro',
      width: 145,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlaceGroupPendingAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'totalPlaceGroupPayedAmount',
      headerName: 'Recebido Parceiro',
      width: 145,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlaceGroupAmount - row?.totalPlaceGroupPendingAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'totalPlaceGroupAmount',
      headerName: 'Total Parceiro',
      width: 145,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlaceGroupAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
  ];

  const campaignColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Campanha',
      width: 400,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 2 }}>
            <Avatar
              alt=""
              src={row.urlCover}
              style={{ marginRight: '1.5rem' }}
            />
            <Link to="#" onClick={() => handleFilterContracts(row.id)}>{row?.name}</Link>
          </Box>
        </React.Fragment>

      ),
    },
    {
      field: 'advertiser.name',
      headerName: 'Cliente',
      width: 120,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.advertiser?.name}
        </React.Fragment>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Fragment>
          { !!row?.status ? getCampaignStatus(row?.status) : ''}
        </Fragment>
      ),
    },
    {
      field: 'nOfPlaces',
      headerName: 'Lugares',
      width: 100,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'pariod',
      headerName: 'Periodo',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {dayjs(row?.startDate).format('DD/MM/YY')} - {dayjs(row?.endDate).format('DD/MM/YY')}
        </React.Fragment>
      ),
    },
    {
      field: 'nOfViews',
      headerName: 'Impressões',
      width: 100,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'totalPlaceAmount',
      headerName: 'Total Estabelecimento',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlaceAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'totalPlaceGroupAmount',
      headerName: 'Total Parceiro',
      width: 145,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlaceGroupAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Total Geral',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={Number(row?.totalPlaceAmount) + Number(row?.totalPlaceGroupAmount)} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'urlFilePi',
      headerName: 'Arquivo PI',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Fragment>
          {row?.urlFilePi ? (
            <Link to={row?.urlFilePi} target='_blank' rel="noreferrer">
              Baixar
            </Link>
          ) : null}
        </Fragment>
      ),
    },

  ];

  const contractColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Contrato',
      width: 100,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => `#${row?.id}`,
    },
    {
      field: 'campaign.name',
      headerName: 'Campanha',
      width: 320,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.campaign?.name}
        </React.Fragment>
      ),
    },
    {
      field: 'place.name',
      headerName: 'Local',
      width: 320,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.place?.name}
        </React.Fragment>
      ),
    },
    {
      field: 'pariod',
      headerName: 'Periodo',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {dayjs(row?.startDate).format('DD/MM/YY')} - {dayjs(row?.endDate).format('DD/MM/YY')}
        </React.Fragment>
      ),
    },
    {
      field: 'nOfViews',
      headerName: 'Impressões',
      width: 100,
      type: "number",
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{numeral(row.nOfViews || 0).format('0,0')}</span>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!!row?.campaign.isFinished ? 'Finalizado' : ''}
          {!row?.campaign.isFinished && (!!row.isPaused || !!row.isDisabled) ? 'Pausado' : ''}
          {!row?.campaign.isFinished && !row.isPaused && !row.isDisabled ? 'Ativo' : ''}
        </React.Fragment>
      ),
    },
    {
      field: 'totalPlaceAmount',
      headerName: 'Total Estabelecimento',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlaceAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'totalPlaceGroupAmount',
      headerName: 'Total Parceiro',
      width: 145,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={row?.totalPlaceGroupAmount} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Total Geral',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <FormattedNumber
          value={Number(row?.totalPlaceAmount) + Number(row?.totalPlaceGroupAmount)} 
          style="currency" 
          currency="BRL" 
        />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Criado Em',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {dayjs(row?.createdAt).format('DD/MM/YY')}
        </React.Fragment>
      ),
    },
    {
      field: 'paidAt',
      headerName: 'Pagamento',
      width: 100,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {row?.paidAt ? dayjs(row?.paidAt).format('DD/MM/YY') : ''}
        </React.Fragment>
      ),
    },
  ];

  const findPlacesList = () => {
    // if (!filteredCampaigns.length) {
      findPlaces({
        query: {
          includePlaceGroupBigNumbers: true,
          placeGroupId,
          // includeBigNumbers: true,
          // isTest: false,
        },
      });
    // }
  };

  const findCampaignsList = () => {
    // if (!filteredCampaigns.length) {
      findCampaigns({
        query: {
          placeGroupId,
          // includeBigNumbers: true,
          // isTest: false,
        },
      });
    // }
  };

  const findContractsList = () => {
    // if (!filteredCampaigns.length) {
      findContracts({
        query: {
          placeGroupId,
          $sort: {
            id: -1,
          }
          // includeBigNumbers: true,
          // isTest: false,
        },
      });
    // }
  };

  useEffect(() => {
    findPlacesList();
    findCampaignsList();
    findContractsList();
  }, []);

  const filterDataPlace = (query: string, isEnabled: boolean, data: any) => {
    const newFiltredStatusPlaces = data.filter((place: any) => 
      (!!place.enabledCampaignsAt && !!isEnabled) || (!place.enabledCampaignsAt && !isEnabled) 
    );
    console.log('newFiltredStatusPlaces', newFiltredStatusPlaces);
    if (!query) {
      setFilteredPlaces(newFiltredStatusPlaces);
    } else {
      const filtredQueryPlaces = newFiltredStatusPlaces.filter(({ name, enabledCampaignsAt }: any) =>
        name.toLowerCase().includes(query.toLowerCase())
      );
      console.log('filtredQueryPlaces', filtredQueryPlaces)
      setFilteredPlaces(filtredQueryPlaces);
    }
  };

  const filterDataCampaign = (query: string, dataArray: any) => {
    if (!query) {
      setFilteredCampaigns(dataArray);
    } else {
      const filtredQueryCampaigns= dataArray.filter(({ name }: any) =>
        name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredCampaigns(filtredQueryCampaigns);
    }
  };

  const filterDataContract = (query: string, dataArray: any, isActive: boolean) => {
    let constractsList = dataArray;
    if (query) {
      constractsList = constractsList.filter(({ place, campaign }: any) =>
        place?.name.toLowerCase().includes(query.toLowerCase()) ||
        campaign?.name.toLowerCase().includes(query.toLowerCase())
      );
    }

    if (isActive) {
      constractsList = constractsList.filter((contract: any) =>
        !contract?.campaign?.isFinished && !contract?.isPaused && !contract?.isDisabled
      );
    }

    setFilteredContracts(constractsList);
  };

  useEffect(() => {
    console.log('queryPlace', {queryPlace, queryPlacesEnabled});
    filterDataPlace(queryPlace, queryPlacesEnabled, places)
  }, [queryPlace, queryPlacesEnabled, places]);

  useEffect(() => {
    filterDataCampaign(queryCampaign, campaigns)
  }, [queryCampaign, campaigns]);

  useEffect(() => {
    filterDataContract(queryContract, contracts, filterOnlyActiveContracts)
  }, [queryContract, contracts, filterOnlyActiveContracts]);

  const handleCloseEnableCampaignDialog = () => {
    setOpenEnableCampaignDialog(false);
    setPlaceEnableCampaignId(null)
  }

  const handleOpenEnableCampaignDialog = (placeId: number) => {
    setOpenEnableCampaignDialog(true);
    setPlaceEnableCampaignId(placeId)
  }

  const handleFilterContracts = (campaignId: number) => {
    setFilterCampaignId(campaignId);
    setSelectedList('contracts');
  }

  const handleSelectList = (listType: string) => {
    setFilterCampaignId(null);
    setSelectedList(listType);
  }

  const placeGroupInformation = [
    {
      title: 'Estabelecimentos',
      listType: 'places',
      quantity: places.length,
      // percentage: placeGroup?.nOfPlaces,
      icon: (
        <PlaceOutlinedIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#0288D1',
    },
    {
      title: 'Campanhas',
      listType: 'campaigns',
      quantity: campaigns?.length,
      // percentage: placeGroup?.nOfCampaigns,
      icon: (
        <CampaignOutlinedIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#FE5390',
    },
    {
      title: 'Contratos',
      listType: 'contracts',
      quantity: filtredContracts?.length,
      // percentage: placeGroup?.nOfViews,
      icon: (
        <PreviewOutlinedIcon sx={{ width: 36, height: 36, color: 'white' }} />
      ),
      color: '#FFA726',
    },
    // {
    //   title: 'Cliques',
    //   quantity: 123, //placeGroup?.nOfCtas,
    //   // percentage: placeGroup?.nOfCtas,
    //   icon: (
    //     <TouchAppOutlinedIcon sx={{ width: 36, height: 36, color: 'white' }} />
    //   ),
    //   color: '#FF4C51',
    // },
  ];

  const downloadExportPlaceAccessesResume = async () => {
    setLoadingPlaceAccessesResume(true);
    if(!loadingPlaceAccessesResume) {
      const places = await findExportReports({
        query: {
          reportType: 'place-accesses-resume',
          placeGroupId: placeGroupId,
        }
      });
      setLoadingPlaceAccessesResume(false);
      console.log('reportDatas', places);
      const placesReport: any = [];
      places.forEach((place: any) => {
        const newPlace = {
          'id': place.id,
          'CNPJ': place.cnpj,
          'Nome Fantasia': place.name,
          'Razão Social': place.legalName,
          'Bairro': place.addressNeighborhood,
          'Cidade': place.addressCity,
          'Estado': place.addressState,
          'ECD': place.placeGroupName,
          'Slug': place.placeGroupKey,
          'Url do Menu': place?.urlMenu,
          
          'Media Impr. 7 Dias': Number(place?.avg7Days || 0),
          'Total Impr. 7 Dias': place?.sum7Days || 0,
          'Media Impr. 30 Dias': Number(place?.avg30Days || 0),
          'Total Impr. 30 Dias': place?.sum30Days || 0,

          'Media Sessoes 7 Dias': Number(place?.avgSessions7Days || 0),
          'Total Sessoes 7 Dias': place?.sumSessions7Days || 0,
          'Media Sessoes 30 Dias': Number(place?.avgSessions30Days || 0),
          'Total Sessoes 30 Dias': place?.sumSessions30Days || 0,

          'Media Usuarios 7 Dias': Number(place?.avgUsers7Days || 0),
          'Total Usuarios 7 Dias': place?.sumUsers7Days || 0,
          'Media Usuarios 30 Dias': Number(place?.avgUsers30Days || 0),
          'Total Usuarios 30 Dias': place?.sumUsers30Days || 0,

          'Ultima leitura': place.lastAccess ? dayjs(place.lastAccess).format('DD/MM/YYYY') : '',
          'Data Cadastro': dayjs(place.createdAt).format('DD/MM/YYYY HH:mm'),
        };
        placesReport.push(newPlace);
      })
      console.log(placesReport)
      const worksheet = XLSX.utils.json_to_sheet(placesReport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Relatorio de Dimensionamento");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `Thirky - Dimensionamento Resumido ${dayjs().format('DD-MM-YYYY HHmm')}.xlsx`);
    }
  };


  return (
    <React.Fragment>
      {(user.profile === 'ecd' && placeGroupId == user.placeGroupId) || user.profile === 'admin' ? (
        <Layout removeLeftBar placeGroupId={placeGroupId}>
          <Box display="grid" justifyContent="center">
            <Typography
              variant="h4"
              sx={{ justifyContent: 'left', paddingBottom: 3 }}
            >
              Olá, {placeGroup?.name}
            </Typography>
            <Stack direction="row" spacing={7} sx={{ paddingBottom: 4 }}>
              {placeGroupInformation.map((data, index) => {
                return (
                  <Card 
                    key={index} 
                    sx={{ minWidth: 350, cursor: 'pointer' }} 
                    onClick={() => handleSelectList(data.listType)}
                  >
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={8}>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{ color: '#999999', paddingLeft: '0.3rem', fontWeight: selectedList === data.listType ? 'bold' : 'normal' }}
                          >
                            {data.title}
                          </Typography>
                          <Typography
                            variant="h5"
                            sx={{ paddingLeft: '0.3rem' }}
                          >
                            {data.quantity}
                          </Typography>
                          {/* <Chip
                            icon={
                              <NorthIcon sx={{ '&&': { color: '#388E3C' } }} />
                            }
                            label={10}
                            sx={{
                              backgroundColor: '#81c78478',
                              color: '#388E3C',
                            }}
                          /> */}
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            sx={{ paddingRight: '1rem', paddingTop: '0.3rem' }}
                          >
                            <IconButton
                              // size="large"
                              sx={{
                                backgroundColor: data.color,
                                '&:hover': { backgroundColor: data.color },
                                cursor: 'default',
                              }}
                            >
                              <Badge>{data.icon}</Badge>
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              })}
            </Stack>
            <Typography 
              variant='subtitle2' 
              style={{ color: 'gray', paddingBottom: 10 }}
            >
              * Dados atualizados diariamente às 00:01
            </Typography>
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: '8px',
                width: '100%',
                minWidth: 850,
              }}
            >
              <Stack 
                direction='row' 
                alignItems='center' 
                justifyContent='space-between' 
                sx={{ padding: '1rem' }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    width: '30%',
                  }}
                >
                  { selectedList === 'places' ? 'Estabelecimentos' : null}
                  { selectedList === 'campaigns' ? 'Campanhas' : null}
                  { selectedList === 'contracts' ? 'Contratos' : null}
                </Typography>
                <Box>
                  { selectedList === 'places' ? (
                    <Fragment>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="placeIsEnabled"
                            id="placeIsEnabled"
                            checked={!!queryPlacesEnabled}
                            onChange={(event: any) =>
                              setQueryPlacesEnabled(event.target.checked)
                            }
                          />
                        }
                        label="Ativos"
                      />
                      <OutlinedInput
                        id="filled-adornment-password"
                        size= 'small'
                        onChange={(e) => setQueryPlace(e.target.value)}
                        value={queryPlace}
                        placeholder= 'Pesquisar'
                        autoFocus
                        autoComplete='off'
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                      {selectedList === 'places' ? (
                        <>
                          <Button 
                            variant="contained" 
                            onClick={downloadExportPlaceAccessesResume}
                            style={{ textDecoration: 'none', marginLeft: 10 }}
                          >
                            Relatorio de Leituras
                          </Button>
                          <Link
                            to={`https://thirky.com/sendPlaces/`}
                            target='_blank'
                            style={{ textDecoration: 'none', marginLeft: 10 }}
                          >
                            <Button  variant="contained">Enviar Lote</Button>
                          </Link>
                        </>
                      ) : null}
                    </Fragment>
                  ) : null}
                  { selectedList === 'campaigns' ? (
                    <Fragment>
                      <OutlinedInput
                        id="filled-adornment-password"
                        size= 'small'
                        onChange={(e) => setQueryCampaign(e.target.value)}
                        value={queryCampaign}
                        placeholder= 'Pesquisar'
                        autoFocus
                        autoComplete='off'
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </Fragment>
                  ) : null}
                  { selectedList === 'contracts' ? (
                    <Fragment>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="filterOnlyActiveContracts"
                            id="filterOnlyActiveContracts"
                            checked={!!filterOnlyActiveContracts}
                            onChange={(event: any) =>
                              setFilterOnlyActiveContracts(event.target.checked)
                            }
                          />
                        }
                        label="Listar Somente Ativos"
                      />
                      <OutlinedInput
                        id="filled-adornment-password"
                        size= 'small'
                        onChange={(e) => setQueryContract(e.target.value)}
                        value={queryContract}
                        placeholder= 'Pesquisar'
                        autoFocus
                        autoComplete='off'
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </Fragment>
                  ) : null}
                </Box>
              </Stack>
                { selectedList === 'places' ? <Table rows={filtredPlaces} columns={placeColumns} /> : null}
                { selectedList === 'campaigns' ? <Table rows={filtredCampaigns} columns={campaignColumns} /> : null}
                { selectedList === 'contracts' 
                  ? <Table 
                      rows={filtredContracts} 
                      columns={contractColumns} 
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'nOfViews', sort: 'desc' }],
                        },
                      }}
                    /> 
                  : null}
            </Box>
          </Box>
          <Dialog
            open={openEnableCampaignDialog}
            onClose={handleCloseEnableCampaignDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Ativar estabelecimento para receber campanhas?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deseja realmente ativas este estabelecimento para receber campanhas?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEnableCampaignDialog}>Cancelar</Button>
              <Button onClick={handleUpdateEnableCampaignPlace} autoFocus>
                Ativar
              </Button>
            </DialogActions>
          </Dialog>
        </Layout>
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={8}
          divider={
            <Divider
              flexItem
              style={{ margin: 'auto', width: '50%', paddingTop: 9 }}
            />
          }
          sx={{ paddingTop: 5 }}
        >
          <Typography variant="h2" sx={{ color: 'red' }}>
            Acesso Negado
          </Typography>
          <React.Fragment>
            <Typography variant="h3" sx={{ color: 'black' }}>
              Você não tem permissão para visitar essa página!
            </Typography>

            <Typography variant="h3" sx={{ color: 'red' }}>
              🚫🚫🚫🚫
            </Typography>

            <Typography variant="h6" sx={{ color: 'red' }}>
              error code: 403 forbidden
            </Typography>
          </React.Fragment>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default PlaceGroupDashboard;
