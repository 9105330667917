import React from 'react';
import '../styles/Navigate.css';
import {
  Box,
  Breadcrumbs,
  Stack,
  // Breadcrumbs,
  Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import { BreadcrumbType } from './common/types';

interface NavigateType {
  pageTitle: string;
  breadcrumbs: BreadcrumbType[];
}
const Navigate = (props: NavigateType) => {
  return (
    <Box className="navigate-bar" sx={{ p: 1 , mb: 1, borderRadius: 2}}>
      <Typography variant="h5" gutterBottom>
        {props.pageTitle}
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" sx={{ color: "#999999" }} />}
        aria-label="breadcrumb"
      >
        <Link underline="hover" key="1" color="#999999" href="/" >
          Pagina Inicial
        </Link>
        {props.breadcrumbs.map(bc =>     
          !bc.disabled 
            ? 
              <Link underline="hover" key="1" color="#999999" href={bc.link} >
                {bc.label}
              </Link>
            :
            <Typography color="#929292">
              {bc.label}
            </Typography>
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default Navigate;
