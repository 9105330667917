import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const QrcodeSizesContext = createContext();
const initialQrcodeSize = {
  id: null,
  name: null,
};

export function QrcodeSizesProvider({ children }) {
  const [qrcodeSizes, setQrcodeSizes] = useState([]);
  const [editQrcodeSize, setEditQrcodeSize] = useState(initialQrcodeSize);

  function getQrcodeSize(qrcodeSizeId) {
    services.qrcodeSizes.get(qrcodeSizeId).then((response) => {
      // console.log('getQrcodeSize', response);
      setQrcodeSizes([response]);
    });
  }

  function getEditQrcodeSize(qrcodeSizeId) {
    services.qrcodeSizes.get(qrcodeSizeId).then((response) => {
      // console.log('getQrcodeSize', response);
      setEditQrcodeSize(response);
    });
  }

  function findQrcodeSizes(query = {}) {
    services.qrcodeSizes.find(query).then((response) => {
      // console.log('findQrcodeSizes', response);
      setQrcodeSizes(response.data);
    });
  }

  async function createQrcodeSizes(qrcodeSize) {
    return services.qrcodeSizes.create(qrcodeSize).then(() => {
      findQrcodeSizes({ query: { placeId: qrcodeSize.placeId}});
    });
  }

  async function patchQrcodeSizes(id, qrcodeSize) {
    return services.qrcodeSizes.patch(id, qrcodeSize).then(() => {
      setEditQrcodeSize(initialQrcodeSize);
      findQrcodeSizes({ query: { placeId: qrcodeSize.placeId}});
    });
  }

  async function removeQrcodeSizes(qrcodeSizeId) {
    return services.qrcodeSizes.remove(qrcodeSizeId).then(() => {
      findQrcodeSizes();
    });
  }
  return (
    <QrcodeSizesContext.Provider
      value={{
        qrcodeSizes,
        editQrcodeSize,
        setEditQrcodeSize,
        getEditQrcodeSize,
        findQrcodeSizes,
        createQrcodeSizes,
        patchQrcodeSizes,
        getQrcodeSize,
        removeQrcodeSizes,
      }}
    >
      {children}
    </QrcodeSizesContext.Provider>
  );
}

export function useQrcodeSizes() {
  const context = useContext(QrcodeSizesContext);

  return context;
}
