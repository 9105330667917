import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';

const TableStyle = {
  // boxShadow: 2,
  // border: 2,
  // borderColor: 'primary.light',
  // minHeight: '59vh',
  '& .MuiDataGrid-cell:hover': {
    color: 'primary.main',
  },
  '& .app-theme--header': {
    color: 'grey',
    fontWeight: 'bold',
  },
};

const Table = (props: any) => {
  // const tableRef = useRef<HTMLDivElement>(null)
  // let tableOffsetTop = 0;
  // if (tableRef  instanceof HTMLElement) {
  //   const { offsetTop } = tableRef.current;
  //   tableOffsetTop = offsetTop;
  // }

  // console.log('tableRef', tableOffsetTop)
  const [ pageSize, setPageSize ] = useState(25)
  return (
    <Box style={{ width: '100%' }}>
      <DataGrid
        // autoHeight
        style={{ height: props.height || 600 }}
        rows={props.rows}
        columns={props.columns}
        initialState={props.initialState || {}}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // checkboxSelection 
        disableSelectionOnClick={true}
        isRowSelectable={() => false}
        // getRowHeight={() => 'auto'}
        // hideFooter
        sx={TableStyle}
      />
    </Box>
  );
};

export default Table;
