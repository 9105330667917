import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';
import superagent from 'superagent';

import SERVER_URL from './serverUrl';

const app = feathers();

app
  .configure(rest(SERVER_URL).superagent(superagent))
  .configure(auth({ storage: window.localStorage }));

const services = {
  users: app.service('users'),
  advertisers: app.service('advertisers'),
  campaigns: app.service('campaigns'),
  contracts: app.service('contracts'),
  places: app.service('places'),
  placeTypes: app.service('place-types'),
  points: app.service('points'),
  placeZones: app.service('place-zones'),
  placeResponsibles: app.service('place-responsibles'),
  tags: app.service('tags'),
  qrcodeSizes: app.service('qrcode-sizes'),
  placeGroups: app.service('place-groups'),
  campaignMedias: app.service('campaign-medias'),
  exportReports: app.service('export-reports'),
  importPlaces: app.service('import-places'),
  campaignProcessPayments: app.service('campaign-process-payments'),
  batchPayments: app.service('batch-payments'),
  batchPaymentDeposits: app.service('batch-payment-deposits'),
  userLogs: app.service('user-logs'),
  scrapingDatas: app.service('scraping-datas'),
};

app.hooks({
  error: {
    all(context) {
      // console.log('context.path', context.path)
      // console.log('context.error', context.error)
      if (context.path !== 'signin' && context.path !== 'authentication' && context.error && context.error.name === 'NotAuthenticated') {
        window.location.href = `${window.location.origin}/signin`;
      }
      return context;
    }
  }
});

export { app, services };
