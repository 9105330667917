import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const ScrapingDatasContext = createContext();

export function ScrapingDatasProvider({ children }) {

  function findScrapingDatas(data) {
    return services.scrapingDatas.create(data).then((response) => {
      return response;
    });
  }

  return (
    <ScrapingDatasContext.Provider
      value={{
        findScrapingDatas,
      }}
    >
      {children}
    </ScrapingDatasContext.Provider>
  );
}

export function useScrapingDatas() {
  const context = useContext(ScrapingDatasContext);

  return context;
}
