import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { TitleTabsHandler } from './common/types';
import { styled } from '@mui/material/styles';

interface StyledTabProps {
  label: string;
}

const menuBarStyle = {
  // position: 'relative',
  // display: 'grid',
  bgcolor: 'background.paper',
  borderRadius: 2,
  // height: '9.3rem',
  // zIndex: 4,
  // alignItems: 'center',
  // gridColumnEnd: 'auto',
  marginBottom: '1rem',
  // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
};

const tabsStyle = {
  // paddingTop: '0.5rem',
  '& div div': {
    borderTop: '1px double rgba(0, 0, 0, 0.15)',
  },
};

const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontSize: '1rem',
    color: "#999999"
  }))

const TitleTabs = ({ children, ...props }: TitleTabsHandler) => {
  return (
    <Box sx={menuBarStyle}>
      {children}

      <Tabs value={props.value} onChange={props.handleChange} sx={tabsStyle}>
        {props.tabNames.map((name, index) => {
          return <AntTab key={index} label={name}  />;
        })}
      </Tabs>
    </Box>
  );
};

export default TitleTabs;
