import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const PlaceResponsiblesContext = createContext();
const initialPlaceResponsible = {
  id: null,
  name: null,
  email: null,
  phone: null,
  cpf: null,
  position: null,
  isWhatsapp: null,
  placeId: null,
};

export function PlaceResponsiblesProvider({ children }) {
  const [placeResponsibles, setPlaceResponsibles] = useState([]);
  const [editPlaceResponsible, setEditPlaceResponsible] = useState(initialPlaceResponsible);

  function getPlaceResponsible(placeResponsibleId) {
    services.placeResponsibles.get(placeResponsibleId).then((response) => {
      // console.log('getPlaceResponsible', response);
      setPlaceResponsibles([response]);
    });
  }

  function getEditPlaceResponsible(placeResponsibleId) {
    services.placeResponsibles.get(placeResponsibleId).then((response) => {
      // console.log('getPlaceResponsible', response);
      setEditPlaceResponsible(response);
    });
  }

  function findPlaceResponsibles(query = {}) {
    services.placeResponsibles.find(query).then((response) => {
      // console.log('findPlaceResponsibles', response);
      setPlaceResponsibles(response.data);
    });
  }

  async function createPlaceResponsibles(placeResponsible) {
    return services.placeResponsibles.create(placeResponsible).then(() => {
      findPlaceResponsibles({ query: { placeId: placeResponsible.placeId}});
    });
  }

  async function patchPlaceResponsibles(id, placeResponsible) {
    return services.placeResponsibles.patch(id, placeResponsible).then(() => {
      setEditPlaceResponsible(initialPlaceResponsible);
      findPlaceResponsibles({ query: { placeId: placeResponsible.placeId}});
    });
  }

  async function removePlaceResponsibles(placeResponsibleId) {
    return services.placeResponsibles.remove(placeResponsibleId).then(() => {
      findPlaceResponsibles();
    });
  }
  return (
    <PlaceResponsiblesContext.Provider
      value={{
        placeResponsibles,
        editPlaceResponsible,
        setEditPlaceResponsible,
        getEditPlaceResponsible,
        findPlaceResponsibles,
        createPlaceResponsibles,
        patchPlaceResponsibles,
        getPlaceResponsible,
        removePlaceResponsibles,
      }}
    >
      {children}
    </PlaceResponsiblesContext.Provider>
  );
}

export function usePlaceResponsibles() {
  const context = useContext(PlaceResponsiblesContext);

  return context;
}
