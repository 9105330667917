import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const ExportReportsContext = createContext();

export function ExportReportsProvider({ children }) {

  function findExportReports(query = {}) {
    return services.exportReports.find(query).then((response) => {
      return response;
    });
  }

  return (
    <ExportReportsContext.Provider
      value={{
        findExportReports,
      }}
    >
      {children}
    </ExportReportsContext.Provider>
  );
}

export function useExportReports() {
  const context = useContext(ExportReportsContext);

  return context;
}
