import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const AdvertisersContext = createContext();
const initialAdvertiser = {
  id: null,
  name: null,
  email: null,
  password: null,
  phone: null,
  profile: null,
  permission: null,
};

export function AdvertisersProvider({ children }) {
  const [advertisers, setAdvertisers] = useState([]);
  const [dashAdvertiser, setDashAdvertiser] = useState(initialAdvertiser);
  const [editAdvertiser, setEditAdvertiser] = useState(initialAdvertiser);

  function getAdvertiser(advertiserId, query = {}) {
    services.advertisers.get(advertiserId, query).then((response) => {
      // console.log('getAdvertiser', response);
      setAdvertisers([response]);
    });
  }

  function getDashAdvertiser(advertiserId, query = {}) {
    services.advertisers.get(advertiserId, query).then((response) => {
      // console.log('getAdvertiser', response);
      setDashAdvertiser(response);
    });
  }

  function getEditAdvertiser(advertiserId) {
    services.advertisers.get(advertiserId).then((response) => {
      // console.log('getAdvertiser', response);
      setEditAdvertiser(response);
    });
  }

  function findAdvertisers(query = {}) {
    services.advertisers.find(query).then((response) => {
      // console.log('findAdvertisers', response);
      setAdvertisers(response.data);
    });
  }

  async function createAdvertisers(advertiser) {
    return services.advertisers.create(advertiser).then((result) => {
      return result;
    });
  }

  async function patchAdvertisers(id, advertiser) {
    return services.advertisers.patch(id, advertiser).then(() => {
      setEditAdvertiser(initialAdvertiser);
      getAdvertiser(id);
    });
  }

  async function removeAdvertisers(advertiserId) {
    return services.advertisers.remove(advertiserId).then(() => {
      findAdvertisers();
    });
  }
  return (
    <AdvertisersContext.Provider
      value={{
        advertisers,
        dashAdvertiser,
        editAdvertiser,
        setEditAdvertiser,
        getEditAdvertiser,
        getDashAdvertiser,
        findAdvertisers,
        createAdvertisers,
        patchAdvertisers,
        getAdvertiser,
        removeAdvertisers,
      }}
    >
      {children}
    </AdvertisersContext.Provider>
  );
}

export function useAdvertisers() {
  const context = useContext(AdvertisersContext);

  return context;
}
