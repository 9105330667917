import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import Table from '../components/Table';
import Navigate from '../components/Navigate';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useParams, Outlet, useLocation, Link } from 'react-router-dom';
import TitleTabs from '../components/TitleTabs';
import { v4 as uuidv4 } from 'uuid';
import Map from '../components/Map';
import {
  Grid,
  Avatar,
  TextField,
  FormControl,
  Button,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
  Stack,
  Chip,
  FormGroup,
  Input,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system';
import { encode } from 'base-64';
import DASH_QRCODE_URL from '../api/dashQrcodeUrl';
import { useSnackbar } from 'notistack';
import { usePlaces } from '../contexts/usePlaces';
import { usePlaceResponsibles } from '../contexts/usePlaceResponsibles';
import { usePlaceZones } from '../contexts/usePlaceZones';
import { usePlaceTypes } from '../contexts/usePlaceTypes';
import { usePoints } from '../contexts/usePoints';
import { useAuth } from '../contexts/useAuth';
import { useContracts } from '../contexts/useContracts';
import { usePlaceGroups } from '../contexts/usePlaceGroups';
import QRCode from 'qrcode.react';
import {
  BreadcrumbType,
  PlaceGroupInterface,
  PlaceInterface,
  PlaceTypeInterface,
  ValueChange,
} from '../components/common/types';
import PhoneNumber from '../components/PhoneNumber';
import { EditOutlined, OpenInBrowser, WhatsApp } from '@mui/icons-material';
import dayjs from 'dayjs';
import { FormattedNumber } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import { NumericFormat } from 'react-number-format';

const initialPlace: PlaceInterface = {
  id: null,
  name: null,
  urlImage: null,
  email: null,
  instagram: null,
  type: null,
  urlMenu: null,
  lat: null,
  lng: null,
  cnpj: null,
  legalName: null,
  bankName: null,
  bankAgency: null,
  bankAccount: null,
  address: null,
  addressNeighborhood: null,
  addressState: null,
  addressCity: null,
  addressCep: null,
  addressAdditional: null,
  pix: null,
  haveQrcode: false,
  placeTypeId: 1,
  hashAuth: null,
  acceptAllHighlight: false,
  acceptAllListItemTop: false,
  acceptAllListItemBottom: false,
  acceptAllPreRoll: false,
  isEnabled: true,
  enabledCampaigns: false,
  enabledCampaignsAt: null,
};

const initialPlaceGroup: PlaceGroupInterface = {
  id: null,
  name: null,
  groupKey: null,
  acceptPreRoll: false,
  acceptHighlight: false,
  acceptListItemTop: false,
  acceptListItemBottom: false,
};

const NumberFormatCustom = (props: any) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NumericFormat {...props} />;
};

const PlaceProfile = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  //Place Context
  const { enqueueSnackbar } = useSnackbar();
  const { places, getPlace, patchPlaces } = usePlaces();
  const { placeResponsibles, findPlaceResponsibles } = usePlaceResponsibles();
  const { placeZones, findPlaceZones } = usePlaceZones();
  const { contracts, findContracts } = useContracts();
  const { points, findPoints } = usePoints();
  const { token } = useAuth();
  const { placeGroups, findPlaceGroups } = usePlaceGroups();
  const { placeTypes, findPlaceTypes } = usePlaceTypes();
  const [ place, setPlace ] = useState<PlaceInterface>(initialPlace);
  const [ placeGroup, setPlaceGroup ] = useState<PlaceGroupInterface>(initialPlaceGroup);
  const location = useLocation();
  const { placeId } = useParams();
  const [ loading, setLoading ] = useState<boolean>(false);

  const getPlaceInformation = () => {
    getPlace(placeId);
  };

  const getContractInformations = () => {
    findContracts({
      query: {
        includeCampaignPlaces: true, 
        includeBigNumbers: true,
        placeId
      }
    })
  }

  useEffect(() => {
    // console.log('useEffect getPlaceInformation', placeId);
    getPlaceInformation();
    getContractInformations();
    findPlaceResponsibles({ query: { placeId } });
    findPlaceZones({ query: { placeId } });
    findPoints({ query: { placeId } });
    findPlaceTypes();
    findPlaceGroups();
  }, [placeId]);

  useEffect(() => {
    if (placeGroups?.length && place?.placeGroupId) {
      const newPlaceGroup = placeGroups.find(
        (pg: PlaceGroupInterface) => pg.id == place.placeGroupId 
      );
      const newPlace = {
        ...place,
        acceptAllPreRoll: false,
        acceptAllHighlight: false,
        acceptAllListItemTop: false,
        acceptAllListItemBottom: false,
      };
      setPlace(newPlace);
      setPlaceGroup(newPlaceGroup);
    }
  }, [place?.placeGroupId]);

  useEffect(() => {
    if(places[0]?.id == placeId) {
      setPlace(places[0] as PlaceInterface);
    }
  }, [places]);

  useEffect(() => {
    if (place.lat) {
      updateLatLng(place.lat)
    }
  }, [place.lat])

  const updateLatLng = (text: string) => {
    const latLng = text?.split(',');
    if (latLng?.length === 2){
      const newPlace = {
        ...place,
        lat: latLng[0],
        lng: latLng[1],
      };
      setPlace(newPlace);
    };
  }

  const searchPlace = (searchType: string) => {
    const name = place.name?.replaceAll(' ', '+') || '';
    const address = place.address?.replaceAll(' ', '+') || '';
    const addressNeighborhood = place.addressNeighborhood?.replaceAll(' ', '+') || '';
    const addressCity = place.addressCity?.replaceAll(' ', '+') || '';
    const addressState = place.addressState?.replaceAll(' ', '+') || '';
    const addressCep = place.addressCep?.replaceAll(' ', '+') || '';

    const query = `${name} ${address} ${addressNeighborhood} ${addressCity} ${addressState} ${addressCep}`;
    const urlBase = searchType === 'maps' ? 'https://www.google.com/maps/search/?q=' : 'https://www.bing.com/search?q=instagram+';
    const url = `${urlBase}${query.replaceAll(' ', '+')}`;
    window.open(url, '_blank');
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    // console.log('handleInputChange ', { name, value });
    setPlace({
      ...place,
      [name]: value,
    });
  };

  const handleExitCep = async (event: any) => {
    // const cep = place.addressCep?.replaceAll(/[^0-9]/g, '');

    const cepResponse = await fetch(
      `https://viacep.com.br/ws/${place.addressCep}/json/`
    )
      .then((response) => response.json())
      .catch(() => {
        enqueueSnackbar('Erro ao consultar CEP.', { variant: 'error' });
        return false;
      });

    setPlace({
      ...place,
      address: cepResponse.logradouro,
      addressNeighborhood: cepResponse.bairro,
      addressState: cepResponse.uf,
      addressCity: cepResponse.localidade,
    });
  };

  const validatePlace = (place: any) => {
    if (!place?.name) {
      enqueueSnackbar('Informe o nome do estabelecimento!', {
        variant: 'error',
      });
      return false;
    }

    if (!place?.placeGroupId) {
      enqueueSnackbar('Informe o ECD do estabelecimento!', {
        variant: 'error',
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    // console.log('place: ', place);
    setLoading(true)
    if (validatePlace(place)) {
      if (place?.id) {
        patchPlaces(place.id, place)
          .then(() => {
            // console.log('Deu certo');
            enqueueSnackbar('Estabelecimento alterado com sucesso!', {
              variant: 'success',
            });
            setLoading(false)
          })
          .catch((error: any) => {
            // console.log('Erro ao alterar', error);
            enqueueSnackbar(error.message ? error.message : 'Erro ao alterar estabelecimento!', {
              variant: 'error',
            });
            setLoading(false)
          });
      }
    } else {
      setLoading(false)
    }
  };

  const handleToggleDashQrcode = async () => {
    if (!place.hashAuth) {
      await patchPlaces(placeId, { hashAuth: uuidv4() });
    } else {
      await patchPlaces(placeId, { hashAuth: null });
    }
    getPlace(placeId);
  };

  const responsiblesColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nomde do Responsável',
      width: 200,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/places/${placeId}/placeResponsible/edit/${row?.id}`}
          state={{ background: location }}
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
        >
          {row?.name}
        </Link>
      ),
    },
    // {
    //   field: 'cpf',
    //   headerName: 'CPF',
    //   width: 150,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'position',
      headerName: 'Cargo',
      width: 100,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      width: 200,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <> 
          {row.isWhatsapp ? <Link to={`https://wa.me/${row.phone}`} target="_blank"><WhatsApp sx={{ color: '#25D366', mr: 2 }} /></Link> : null}
          <PhoneNumber value={row.phone} /> 
        </>
      ),
    },
    // {
    //   field: 'isWhatsapp',
    //   headerName: 'WhatsApp',
    //   width: 100,
    //   headerClassName: 'app-theme--header',
    //   renderCell: ({ row }: Partial<GridRowParams>) => (
    //     <div>{row?.isWhatsapp ? 'SIM' : 'NÃO'}</div>
    //   ),
    // },
  ];

  const zonesColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Zona',
      width: 200,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/places/${placeId}/placeZone/edit/${row?.id}`}
          state={{ background: location }}
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
        >
          {row?.name}
        </Link>
      ),
    },
  ];

  const pointsColumns: GridColDef[] = [
    {
      field: 'hash',
      headerName: 'Hash',
      width: 170,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/places/${placeId}/point/edit/${row?.id}`}
          state={{ background: location }}
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
        >
          {row?.hash}
        </Link>
      ),
    },
    {
      field: 'description',
      headerName: 'Descrição',
      width: 170,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'placeZone.name',
      headerName: 'Zona',
      width: 170,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row?.placeZone?.name}</span>
      ),
    },
    {
      field: 'qrcodeSize',
      headerName: 'Tamanho',
      width: 170,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'urlMenuTable',
      headerName: 'URL Menu',
      width: 170,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'tags',
      headerName: 'Tags',
      width: 170,
      headerClassName: 'app-theme--header',
    },
  ];

  const contractColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Contrato',
      width: 90,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`/places/${placeId}/contracts/edit/${row?.id}`}
          state={{ background: location }}
          style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
        >
          <React.Fragment>#{row?.id}</React.Fragment>
        </Link>
      ),
    },
    {
      field: 'campaignId',
      headerName: 'Campanha',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <Link
            to={`/campaigns/${row?.campaign?.id}/profile`}
            target='_blank'
          >
            {row?.campaign?.name}
          </Link>
      </React.Fragment>
      ),
    },
    {
      field: 'contractIsActive',
      headerName: 'Status',
      width: 125,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {!!row?.contractIsActive ? (
            <Chip label="Ativo" color="success" />
          ) : (
            <Chip label="Inativo" color="warning" />
          )}
        </React.Fragment>
      ),
    },
    {
      field: 'estimateViews',
      headerName: 'Impressões',
      width: 100,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'viewCost',
      headerName: 'Valor',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          <FormattedNumber minimumFractionDigits={4} value={row?.viewCost} style="decimal" currency="BRL" />
        </React.Fragment>
      ),
    },
    {
      field: 'nOfViews',
      headerName: 'Entrega',
      width: 220,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {Number(row?.nOfViews/row?.estimateViews * 100).toFixed(2)}%
           ({row?.nOfViews})
          
        </React.Fragment>
      ),
    },
    {
      field: 'viewsAvg',
      headerName: 'Exibição Média',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfCtas',
      headerName: 'Cliques',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'ctr',
      headerName: 'CTR',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },

  ];

  const tabNames = [
    'Estabelecimento',
    'Localização',
    'Dados da Empresa',
    'Responsáveis',
    'Zonas',
    'QrCode',
    'Contratos',
    'ECD',
  ];
  // console.log('place.placeType', place);
  if (!place) {
    return <p>Aguarde...</p>;
  }

  const breadcrumbsList: BreadcrumbType[] = [
    {
      link: '/places',
      label: 'Lista de Estabelecimentos'
    },
    {
      link: ``,
      label: place.name || '',
      disabled: true
    },
  ]
  
  return (
    <Layout>
      <Navigate pageTitle="Perfil do Estabelecimento" breadcrumbs={breadcrumbsList} />
      <TitleTabs
        value={tabIndex}
        handleChange={handleChange}
        tabNames={tabNames}
      >
        <Stack
          // container
          sx={{ p: 1, width: '100%'}}
          // columns={10}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack 
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            // spacing={3}  
          >
            <Avatar
              sx={{ width: 90, height: 90, mr: 1 }}
              src={place?.urlImage}
            />
            <Box>
              <Typography variant="h5">{place.name}</Typography>
              <Typography variant="subtitle2">
                {place.placeType?.name}
              </Typography>
            </Box>
            <Link 
              state={{ background: location }} 
              to={`/places/edit/${placeId}`}
            >
              <EditOutlined
                fontSize="small" 
                sx={{ 
                  color: "#999999", 
                  marginLeft: 2, 
                  mt: '5px', 
                  borderColor: "#999999", 
                  borderStyle: 'solid', 
                  borderRadius: '2px', 
                  borderWidth: '1px',
                }} />
            </Link>
          </Stack>
        </Stack>
      </TitleTabs>
      {tabIndex === 0 && (
        <Grid container sx={{ bgcolor: 'background.paper', mt: 2 }}>
          <Grid item xs={6} sx={{ p: 2 }} rowSpacing={2}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              // onSubmit={handleSubmit}
            >
              <TextField
                label="Nome do Estabelecimento"
                name="name"
                id="name"
                fullWidth
                sx={{ mt: 3 }}
                value={place.name || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <FormControl fullWidth variant="outlined" sx={{ mt: 3 }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Tipo de Estabelecimento
                </InputLabel>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="placeTypeId"
                  name="placeTypeId"
                  value={String(place.placeTypeId || '')}
                  onChange={handleInputChange}
                  label="Anunciante"
                  input={<OutlinedInput />}
                  required
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {placeTypes.map((placeType: PlaceInterface) => (
                    <MenuItem key={placeType.id} value={String(placeType.id)}>
                      {placeType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <TextField
                  label="Instagram"
                  name="instagram"
                  id="instagram"
                  fullWidth
                  sx={{ mt: 3, width: '90%' }}
                  value={place.instagram || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <IconButton aria-label="delete" size="large" sx={{ mt: 3 }} onClick={() => searchPlace('instagram')}>
                  <SearchIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <FormControlLabel
                sx={{ mt: 3 }}
                control={
                  <Checkbox
                    name="haveQrcode"
                    id="haveQrcode"
                    checked={!!place.haveQrcode}
                    onChange={(event) =>
                      setPlace({ ...place, haveQrcode: event.target.checked })
                    }
                  />
                }
                label="Já Possui QR Code"
              />
              <TextField
                label="URL Cardapio"
                name="urlMenu"
                id="urlMenu1"
                fullWidth
                sx={{ mt: 3 }}
                value={place.urlMenu || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Abrir">
                      <Link
                        to={place.urlMenu || '#'}
                        target="_blank"
                      >
                        <IconButton aria-label="abrir">
                            <OpenInBrowser />
                        </IconButton>
                      </Link>

                    </Tooltip>
                  )
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isEnabled"
                    id="isEnabled"
                    checked={!!place.isEnabled}
                    onChange={(event: any) =>
                      setPlace({
                        ...place,
                        isEnabled: event.target.checked,
                      })
                    }
                  />
                }
                label="Ativo"
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <LoadingButton onClick={handleSubmit} loading={loading} variant="contained">
                Salvar
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      )}
      {tabIndex === 1 && (
        <Grid container sx={{ bgcolor: 'background.paper', mt: 2, p: 2 }}>
          <Grid item xs={6}>
            <Box
              component="form"
              // sx={{ display: 'flex', flexWrap: 'wrap' }}
              noValidate
              autoComplete="off"
              // onSubmit={handleSubmit}
            >
              <TextField
                label="CEP"
                name="addressCep"
                id="addressCep"
                fullWidth
                sx={{ mt: 3 }}
                value={place.addressCep || ''}
                onChange={handleInputChange}
                onBlur={handleExitCep}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Endereço"
                name="address"
                id="address"
                fullWidth
                sx={{ mt: 3 }}
                value={place.address || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Numero/Complemento"
                name="addressAdditional"
                id="addressAdditional"
                fullWidth
                sx={{ mt: 3 }}
                value={place.addressAdditional || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <TextField
                  label="Bairro"
                  name="addressNeighborhood"
                  id="addressNeighborhood"
                  fullWidth
                  sx={{ mt: 3, width: '29%' }}
                  value={place.addressNeighborhood || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Cidade"
                  name="addressCity"
                  id="addressCity"
                  fullWidth
                  sx={{ mt: 3, width: '49%' }}
                  value={place.addressCity || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Estado"
                  name="addressState"
                  id="addressState"
                  fullWidth
                  sx={{ mt: 3, width: '19%' }}
                  value={place.addressState || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <TextField
                  label="Latitude"
                  name="lat"
                  id="lat"
                  sx={{ mt: 3, width: '43%' }}
                  value={place.lat || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Longitude"
                  name="lng"
                  id="lng"
                  sx={{ mt: 3, width: '43%' }}
                  value={place.lng || ''}
                  onChange={handleInputChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <IconButton aria-label="delete" size="large" sx={{ mt: 3 }} onClick={() => searchPlace('maps')}>
                  <SearchIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <LoadingButton onClick={handleSubmit} loading={loading} variant="contained">
                Salvar
              </LoadingButton>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ pt: 3, pl: 3 }}>
            {place.lat && place.lng ? (
              <div className="map" style={{ height: '100%', width: '100%' }} >
                <Map
                  center={{ lat: Number(place.lat), lng: Number(place.lng) }}
                  markers={[{ lat: Number(place.lat), lng: Number(place.lng) }]}
                />
              </div>
            ) : null}
          </Grid>
        </Grid>
      )}
      {tabIndex === 2 && (
        <Grid container sx={{ bgcolor: 'background.paper', mt: 2 }}>
          <Grid item xs={6} sx={{ p: 2 }} rowSpacing={2}>
            <Box
              component="form"
              // sx={{ display: 'flex', flexWrap: 'wrap' }}
              noValidate
              autoComplete="off"
              // onSubmit={handleSubmit}
            >
              <TextField
                label="Razão Social"
                name="legalName"
                id="legalName"
                fullWidth
                sx={{ mt: 3 }}
                value={place.legalName || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="CNPJ"
                name="cnpj"
                id="cnpj"
                fullWidth
                sx={{ mt: 3 }}
                value={place.cnpj || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Nome do Banco"
                name="bankName"
                id="bankName"
                fullWidth
                sx={{ mt: 3 }}
                value={place.bankName || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Agencia"
                name="bankAgency"
                id="bankAgency"
                fullWidth
                sx={{ mt: 3 }}
                value={place.bankAgency || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Conta"
                name="bankAccount"
                id="bankAccount"
                fullWidth
                sx={{ mt: 3 }}
                value={place.bankAccount || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Pix"
                name="pix"
                id="pix"
                fullWidth
                sx={{ mt: 3 }}
                value={place.pix || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <LoadingButton onClick={handleSubmit} loading={loading} variant="contained">
                Salvar
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      )}
      {tabIndex === 3 && (
        <Grid container sx={{ bgcolor: 'background.paper', mt: 2 }}>
          <Grid
            container
            sx={{ justifyContent: 'flex-end', m: 2, p: 1, bgcolor: '#f7f7f7' }}
            columns={12}
          >
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              {/* <TextField
                  label="Nome do Estabelecimento"
                  name="name"
                  id="name"
                  fullWidth
                  // sx={{ mt: 3 }}
                  // value={place.name || ''}
                  // onChange={handleInputChange}
                  // variant="outlined"
                  InputLabelProps={{ shrink: true }}
                /> */}
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
              xs={3}
            >
              <Link
                to={`/places/${placeId}/placeResponsible/create`}
                state={{ background: location }}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="contained">Novo Responsável</Button>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ p: 2 }} rowSpacing={2}>
            <Table rows={placeResponsibles} columns={responsiblesColumns} />
          </Grid>
        </Grid>
      )}
      {tabIndex === 4 && (
        <Grid container sx={{ bgcolor: 'background.paper', mt: 2 }}>
          <Grid
            container
            sx={{ justifyContent: 'flex-end', m: 2, p: 1, bgcolor: '#f7f7f7' }}
            columns={12}
          >
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              {/* <TextField
                  label="Nome do Estabelecimento"
                  name="name"
                  id="name"
                  fullWidth
                  // sx={{ mt: 3 }}
                  // value={place.name || ''}
                  // onChange={handleInputChange}
                  // variant="outlined"
                  InputLabelProps={{ shrink: true }}
                /> */}
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
              xs={3}
            >
              <Link
                to={`/places/${placeId}/placeZone/create`}
                state={{ background: location }}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="contained">Nova Zona</Button>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ p: 2 }} rowSpacing={2}>
            <Table rows={placeZones} columns={zonesColumns} />
          </Grid>
        </Grid>
      )}
      {tabIndex === 5 && (
        <Grid container sx={{ bgcolor: 'background.paper', mt: 2 }}>
          <Grid
            container
            sx={{ justifyContent: 'flex-end', m: 2, p: 1, bgcolor: '#f7f7f7' }}
            columns={12}
          >
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              {/* <TextField
                  label="Nome do Estabelecimento"
                  name="name"
                  id="name"
                  fullWidth
                  // sx={{ mt: 3 }}
                  // value={place.name || ''}
                  // onChange={handleInputChange}
                  // variant="outlined"
                  InputLabelProps={{ shrink: true }}
                /> */}
            </Grid>
            {/* <Grid item sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }} xs={3}>
                <Button variant="contained" >Gerar QrCode</Button>
              </Grid> */}
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
              xs={4}
            >
              <Link
                to={`/places/${placeId}/point/create`}
                state={{ background: location }}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="contained">Criar QrCode</Button>
              </Link>
              <Button
                onClick={handleToggleDashQrcode}
                variant="contained"
                sx={{ ml: 1 }}
              >
                {!place.hashAuth
                  ? 'Habilitar Dash QrCode'
                  : 'Desabilitar Dash QrCode'}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={place.hashAuth ? 9 : 12} sx={{ p: 2 }} rowSpacing={2}>
            <Table rows={points} columns={pointsColumns} />
          </Grid>
          {place.hashAuth && (
            <Grid item xs={3} sx={{ p: 2 }} rowSpacing={2}>
              <QRCode
                id="qrCode"
                value={`${DASH_QRCODE_URL}/singin/${place.hashAuth}/${encode(
                  token
                )}`}
                size={350}
                level={'L'}
                includeMargin={true}
              />
            </Grid>
          )}
        </Grid>
      )}
      {tabIndex === 6 && (
        <Grid container sx={{ bgcolor: 'background.paper', mt: 2 }}>
          <Grid item xs={place.hashAuth ? 9 : 12} sx={{ p: 2 }} rowSpacing={2}>
            <Table rows={contracts} columns={contractColumns} />
            <Typography 
              variant='subtitle2' 
              style={{ color: 'gray' }}
            >
              * Dados atualizados em tempo real
            </Typography>
          </Grid>
        </Grid>
      )}
      {tabIndex === 7 && (
        <Grid container sx={{ bgcolor: 'background.paper', mt: 2 }}>
          <Grid item xs={6} sx={{ p: 2 }} rowSpacing={2}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              // onSubmit={handleSubmit}
            >
              <FormControl fullWidth variant="outlined" sx={{ mt: 3 }}>
                <InputLabel 
                  id="demo-simple-select-outlined-label"
                  shrink
                  style={{ 
                    backgroundColor: '#fff', 
                    paddingLeft: 5, 
                    paddingRight: 5 
                  }} 
                >
                  ECD
                </InputLabel>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="placeGroupId"
                  name="placeGroupId"
                  value={String(place.placeGroupId || '')}
                  onChange={handleInputChange}
                  label="ECD"
                  input={<OutlinedInput />}
                  required
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {placeGroups.map((placeGroup : PlaceGroupInterface) => (
                    <MenuItem key={placeGroup.id} value={String(placeGroup.id)}>
                      {placeGroup.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Codigo no ECD (Slug)"
                name="placeGroupKey"
                id="placeGroupKey"
                fullWidth
                sx={{ mt: 3 }}
                value={place.placeGroupKey || ''}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <FormControl fullWidth variant="outlined" sx={{ mt: 3, flexDirection: 'row' }}>
                <TextField
                  label="% ECD"
                  value={place.splitPlaceGroup}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      thousandSeparator: '.',
                      decimalSeparator: ',',
                      valueIsNumericString: true,
                      decimalScale: 2,
                      variant: 'outlined',
                      onValueChange: (vals: ValueChange) =>
                      setPlace({
                        ...place,
                        splitPlaceGroup: vals.floatValue,
                      }),
                    },
                    disableUnderline: true,
                  }}
                  sx={{ width: '49%' }}
                  InputLabelProps={{ shrink: true }}
                /> 
                <TextField
                  label="% Estabelecimento"
                  value={place.splitPlace}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      thousandSeparator: '.',
                      decimalSeparator: ',',
                      valueIsNumericString: true,
                      decimalScale: 2,
                      variant: 'outlined',
                      onValueChange: (vals: ValueChange) =>
                        setPlace({
                          ...place,
                          splitPlace: vals.floatValue,
                        }),
                    },
                    disableUnderline: true,
                  }}
                  sx={{ ml: '2%', width: '49%' }}
                  InputLabelProps={{ shrink: true }}
                />  
              </FormControl>
              {!place.enabledCampaignsAt ? (
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Checkbox
                      name="enabledCampaigns"
                      id="enabledCampaigns"
                      checked={!!place.enabledCampaigns}
                      onChange={(event) =>
                        setPlace({ ...place, enabledCampaigns: event.target.checked })
                      }
                    />
                  }
                  label="Ativado"
                />
              ) : (
                <Chip 
                  sx={{ mt: 1 }}
                  label={`Ativado em ${dayjs(place.enabledCampaignsAt).format('DD/MM/YY')}`} 
                  color="success"
                />
              )}
            </Box>
            <FormGroup>
              <Typography variant="h6" sx={{ mt: 3 }}>Aceite Automático de Midias</Typography>
              {!!placeGroup?.acceptPreRoll && (
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Checkbox
                      name="acceptAllPreRoll"
                      id="acceptAllPreRoll"
                      checked={!!place.acceptAllPreRoll}
                      onChange={(event) =>
                        setPlace({ ...place, acceptAllPreRoll: event.target.checked })
                      }
                    />
                  }
                  label="Aceitar PreRoll"
                />
              )}

              {!!placeGroup?.acceptHighlight && (
                <FormControlLabel
                  sx={{ mt: 3 }}
                  control={
                    <Checkbox
                      name="acceptAllHighlight"
                      id="acceptAllHighlight"
                      checked={!!place.acceptAllHighlight}
                      onChange={(event) =>
                        setPlace({ ...place, acceptAllHighlight: event.target.checked })
                      }
                    />
                  }
                  label="Aceitar Destaque"
                />
              )}

              {!!placeGroup?.acceptListItemTop && (
                <FormControlLabel
                  sx={{ mt: 3 }}
                  control={
                    <Checkbox
                      name="acceptAllListItemTop"
                      id="acceptAllListItemTop"
                      checked={!!place.acceptAllListItemTop}
                      onChange={(event) =>
                        setPlace({ ...place, acceptAllListItemTop: event.target.checked })
                      }
                    />
                  }
                  label="Aceitar Item de Lista Topo"
                />
              )}

              {!!placeGroup?.acceptListItemBottom && (
                <FormControlLabel
                  sx={{ mt: 3 }}
                  control={
                    <Checkbox
                      name="acceptAllListItemBottom"
                      id="acceptAllListItemBottom"
                      checked={!!place.acceptAllListItemBottom}
                      onChange={(event) =>
                        setPlace({ ...place, acceptAllListItemBottom: event.target.checked })
                      }
                    />
                  }
                  label="Aceitar Item de Lista Rodapé"
                />
              )}
            </FormGroup>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <LoadingButton onClick={handleSubmit} loading={loading} variant="contained">
                Salvar
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      )}
      <Outlet />
    </Layout>
  );
};

export default PlaceProfile;
