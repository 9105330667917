import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useSnackbar } from 'notistack';
import { PhotoCamera } from '@mui/icons-material';
import { usePlaceGroups } from '../../contexts/usePlaceGroups';
import { useNavigate, useParams } from 'react-router-dom';

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '90vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

interface PlaceGroupInterface {
  id: number | null;
  name: string | null;
  groupKey: string | null;
  urlImage?: any | null;
  newFileImage?: string | null | unknown;
  acceptPreRoll: boolean;
  acceptHighlight: boolean;
  acceptListItemTop: boolean;
  acceptListItemBottom: boolean;
  isEnabled: boolean;
}

const initialPlaceGroup: PlaceGroupInterface = {
  id: null,
  name: null,
  groupKey: null,
  urlImage: null,
  newFileImage: null,
  acceptPreRoll: false,
  acceptHighlight: false,
  acceptListItemTop: false,
  acceptListItemBottom: false,
  isEnabled: true,
};

const AddPlaceGroupModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { placeGroupId } = useParams();
  const {
    editPlaceGroup,
    setEditPlaceGroup,
    getEditPlaceGroup,
    createPlaceGroups,
    patchPlaceGroups,
    findPlaceGroups,
  } = usePlaceGroups();
  const [placeGroup, setPlaceGroup] =
    useState<PlaceGroupInterface>(initialPlaceGroup);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setPlaceGroup({
      ...placeGroup,
      [name]: value,
    });
  };

  useEffect(() => {
    if (placeGroupId && !placeGroup?.id) {
      getEditPlaceGroup(placeGroupId);
    }
  }, [placeGroupId]);

  useEffect(() => {
    if (editPlaceGroup.id && editPlaceGroup?.id !== placeGroup?.id) {
      setPlaceGroup(editPlaceGroup);
    }
  }, [editPlaceGroup]);

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const removeImage = () => {
    setPlaceGroup({...placeGroup, newFileImage: null})
  }

  const uploadFileProfile = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setPlaceGroup({
      ...placeGroup,
      newFileImage: base64 ? base64 : null
    })
  };

  const validatePlaceGroup = (placeGroup: any) => {
    if (!placeGroup?.name) {
      enqueueSnackbar('Informe o nome da ECD!', { variant: 'error' });
      return false;
    }

    // if (!placeGroup?.image) {
    //   enqueueSnackbar('Insira a imagem da ECD!', { variant: 'error' });
    //   return false;
    // }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (validatePlaceGroup(placeGroup)) {
      if (placeGroup?.id) {
        patchPlaceGroups(placeGroup.id, placeGroup)
          .then(() => {
            enqueueSnackbar('ECD alteradA com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            const { message } = error;
            enqueueSnackbar(message ? message : 'Erro ao alterar ECD!', { variant: 'error' });
          });
      } else {
        createPlaceGroups(placeGroup)
          .then((result: any) => {
            enqueueSnackbar('ECD criadA com sucesso!', {
              variant: 'success',
            });
            navigate(`/placeGroups/${result.id}/profile`);
            // closeModal();

          })
          .catch((error: any) => {
            const { message } = error;
            enqueueSnackbar(message ? message : 'Erro ao criar ECD!', { variant: 'error' });
          });
      }
    }
  };

  const reloadPlaceGroups = () => {
    findPlaceGroups({query: {
      // includePlaceGroupNumberOfActivePlaces: true,
      $sort: {
        name: 1,
      },
    }});
  }

  const closeModal = () => {
    navigate(-1);
    reloadPlaceGroups();
    setTimeout(() => {
      setPlaceGroup(initialPlaceGroup);
      setEditPlaceGroup(initialPlaceGroup);
    }, 1000)
  };

  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CenterModalStyle} onClick={(e) => e.stopPropagation()}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Novo ECD
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton className="closeIcon" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            label="Nome da ECD"
            name="name"
            id="name"
            value={placeGroup.name || ''}
            onChange={handleInputChange}
            required
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="ECD Key"
            name="groupKey"
            id="groupKey"
            value={placeGroup.groupKey || ''}
            onChange={handleInputChange}
            disabled={!!placeGroup.id}
            required
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="acceptPreRoll"
                id="acceptPreRoll"
                checked={!!placeGroup.acceptPreRoll}
                onChange={(event) =>
                  setPlaceGroup({
                    ...placeGroup,
                    acceptPreRoll: event.target.checked,
                  })
                }
              />
            }
            label="Aceita PreRoll"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="acceptHighlight"
                id="acceptHighlight"
                checked={!!placeGroup.acceptHighlight}
                onChange={(event) =>
                  setPlaceGroup({
                    ...placeGroup,
                    acceptHighlight: event.target.checked,
                  })
                }
              />
            }
            label="Aceita Highlight"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="acceptListItemTop"
                id="acceptListItemTop"
                checked={!!placeGroup.acceptListItemTop}
                onChange={(event) =>
                  setPlaceGroup({
                    ...placeGroup,
                    acceptListItemTop: event.target.checked,
                  })
                }
              />
            }
            label="Aceita List Item Top"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="acceptListItemBottom"
                id="acceptListItemBottom"
                checked={!!placeGroup.acceptListItemBottom}
                onChange={(event) =>
                  setPlaceGroup({
                    ...placeGroup,
                    acceptListItemBottom: event.target.checked,
                  })
                }
              />
            }
            label="Aceita List Item"
          />
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <>
              {placeGroup?.newFileImage || placeGroup?.urlImage 
                ? <img 
                    src={ placeGroup?.newFileImage ? placeGroup?.newFileImage as string : placeGroup?.urlImage as string} 
                    alt="Perfil ECD" 
                  /> 
                : null}
              <Button variant="contained" component="label" fullWidth  startIcon={<PhotoCamera/>}>
                Selecionar Imagem
                <input key={Math.random().toString(36)} hidden accept="image/*" onChange={(e) => uploadFileProfile(e)} multiple type="file" />
              </Button>
              {placeGroup?.newFileImage && (
                <Button 
                  color="warning" 
                  variant="contained" 
                  onClick={removeImage}
                >
                  Remover imagem
                </Button>
              )}
            </>
          </FormControl>
          {placeGroup.id &&
            <FormControlLabel
              control={
                <Checkbox
                  name="isEnabled"
                  id="isEnabled"
                  checked={!!placeGroup.isEnabled}
                  onChange={(event) =>
                    setPlaceGroup({
                      ...placeGroup,
                      isEnabled: event.target.checked,
                    })
                  }
                />
              }
              label="Ativo"
            />
          }
          <Button variant="contained" type="submit" fullWidth>
            {(!placeGroup?.id && 'Criar ECD') || 'Editar ECD'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPlaceGroupModal;
