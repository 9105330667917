import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { useSnackbar } from 'notistack';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { usePlaceZones } from '../../contexts/usePlaceZones';
import { useNavigate, useParams } from 'react-router-dom';

const CenterModalStyle = {
  margin: '0 auto',
  marginTop: 5,
  width: 600,
  height: '90vh',
  transform: 'translate3d(0%, 0, 0)',
  bgcolor: 'background.paper',
  borderRadius: '12px;',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

interface PlaceZoneInterface {
  id: number | null;
  name: string | null;
}

const initialPlaceZone: PlaceZoneInterface = {
  id: null,
  name: null,
}

const AddPlaceZoneModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { placeId, placeZoneId } = useParams();
  const { 
    editPlaceZone, 
    setEditPlaceZone, 
    getEditPlaceZone, 
    createPlaceZones, 
    patchPlaceZones 
  } =  usePlaceZones();
  const [placeZone, setPlaceZone] = useState<PlaceZoneInterface>(initialPlaceZone);

  const handleInputChange = (event: any) => {
    // console.log('event.target.value ', event.target.value);
    const { name, value } = event.target;
    // console.log('event.target.value ', { name, value });
    setPlaceZone({
      ...placeZone,
      [name]: value,
    });
  };

  useEffect(() => {
    // console.log('placeZoneId ', placeZoneId);
    // console.log('placeZone?.id ', placeZone?.id);
    // console.log('editPlaceZone?.id ', editPlaceZone?.id);
    if (placeZoneId && !placeZone?.id) {
      // console.log('getEdirPlaceZone');
      getEditPlaceZone(placeZoneId);
    }
  }, [placeZoneId]);

  useEffect(() => {
    if (editPlaceZone.id && editPlaceZone?.id !== placeZone?.id) {
      setPlaceZone(editPlaceZone);
    }
  }, [editPlaceZone]);

  const validatePlaceZone = (placeZone: any) => {
    if (!placeZone?.name) {
      enqueueSnackbar('Informe o nome da zona!', { variant: 'error' });
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // console.log('placeZone: ', placeZone);
    if (validatePlaceZone(placeZone)) {
      if (placeZone?.id) {
        patchPlaceZones(placeZone.id, placeZone)
          .then(() => {
            // console.log('Deu certo');
            enqueueSnackbar('Zona alterada com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            // console.log('Erro ao alterar', error);
            enqueueSnackbar('Erro ao alterar zona!', { variant: 'error' });
          });
      } else {
        createPlaceZones({ ...placeZone, placeId })
          .then(() => {
            // console.log('Deu certo');
            enqueueSnackbar('Zona criada com sucesso!', {
              variant: 'success',
            });
            closeModal();
          })
          .catch((error: any) => {
            // console.log('Erro ao criar', error);
            enqueueSnackbar('Erro ao criar zona!', { variant: 'error' });
          });
      }
    }
  };

  const closeModal = () => {
    setPlaceZone(initialPlaceZone);
    setEditPlaceZone(initialPlaceZone);
    navigate(-1);
  };

  return (
    <Modal
      open={true}
      onClose={() => setPlaceZone(initialPlaceZone)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={CenterModalStyle} onClick={(e) => e.stopPropagation()}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Adicionar usuario
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton className="closeIcon" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '34ch' },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            label="Nome"
            name="name"
            id="name"
            value={placeZone.name || ''}
            onChange={handleInputChange}
            required
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <Button variant="contained" type="submit">
            <AddIcon style={{ marginRight: '1rem' }} />
            {(!placeZone?.id && 'Adicionar nova') || 'Editar zona'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPlaceZoneModal;
