import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const PlaceTypesContext = createContext();
const initialPlaceType = {
  id: null,
  name: null,
};

export function PlaceTypesProvider({ children }) {
  const [placeTypes, setPlaceTypes] = useState([]);
  const [placeTypeTypes, setPlaceTypeTypes] = useState([]);
  const [editPlaceType, setEditPlaceType] = useState(initialPlaceType);

  function getPlaceType(placeTypeId) {
    services.placeTypes.get(placeTypeId).then((response) => {
      // console.log('getPlaceType', response);
      setPlaceTypes([response]);
    });
  }

  function getEditPlaceType(placeTypeId) {
    services.placeTypes.get(placeTypeId).then((response) => {
      // console.log('getPlaceType', response);
      setEditPlaceType(response);
    });
  }

  function findPlaceTypes() {
    services.placeTypes.find().then((response) => {
      // console.log('findPlaceTypes', response);
      setPlaceTypes(response.data);
    });
  }

  function findPlaceTypeTypes() {
    services.placeTypeTypes.find().then((response) => {
      setPlaceTypeTypes(response.data);
    });
  }

  async function createPlaceTypes(placeType) {
    return services.placeTypes.create(placeType).then(() => {
      findPlaceTypes();
    });
  }

  async function patchPlaceTypes(id, placeType) {
    return services.placeTypes.patch(id, placeType).then(() => {
      setEditPlaceType(initialPlaceType);
      findPlaceTypes();
    });
  }

  async function removePlaceTypes(placeTypeId) {
    return services.placeTypes.remove(placeTypeId).then(() => {
      findPlaceTypes();
    });
  }
  return (
    <PlaceTypesContext.Provider
      value={{
        placeTypes,
        editPlaceType,
        setEditPlaceType,
        getEditPlaceType,
        findPlaceTypes,
        createPlaceTypes,
        patchPlaceTypes,
        getPlaceType,
        removePlaceTypes,

        placeTypeTypes,
        
      }}
    >
      {children}
    </PlaceTypesContext.Provider>
  );
}

export function usePlaceTypes() {
  const context = useContext(PlaceTypesContext);

  return context;
}
