import React, { SyntheticEvent, useEffect, useState } from 'react';

import { useCampaigns } from '../contexts/useCampaigns';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Avatar, Box, Chip, CircularProgress, Container, Stack, Typography } from '@mui/material';
import ViewIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import dayjs from 'dayjs';

import TitleTabs from '../components/TitleTabs';
import Layout from '../components/Layout';
import Table from '../components/Table';
import MenuBar from '../components/MenuBar';
import Navigate from '../components/Navigate';
import { BreadcrumbType } from '../components/common/types';

import QR_CODE_CLIENT_URL from '../api/qrCodeClientUrl';

const CampaignView = () => {
  const location = useLocation();
  const { campaigns, findCampaigns, clearCampaigns } = useCampaigns();
  const [ campaignsLoading, setCampaignsLoading ] = useState(false);
  const [ tabValue, setTabValue ] = useState(0);
  const [ columnsTable, setColumnsTable ] = useState<GridColDef[]>([])
  const [query, setQuery] = useState('');

  useEffect(() => {
    setCampaignsLoading(false)
  }, [campaigns]);

  const filterData = (query: string, data: any) => {
    return data.filter(({ name }: any) =>
      name.toLowerCase().includes(query.toLowerCase())
    );
  };

  const dataFiltered = filterData(query, campaigns);

  const renderAvatar = ({ row }: Partial<GridRowParams>) => (
    <React.Fragment>
      <Link
        to={`/campaigns/${row?.id}/profile`}
        style={{ textDecoration: 'none', color: '#1E1E1E', fontWeight: 'bold' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 2 }}>
          <Avatar
            alt=""
            src={row?.urlCover}
            style={{ marginRight: '1.5rem' }}
          />
          {row?.name}
        </Box>
        {/* <Link to={`/campaigns/edit/${row?.id}`} state={{ background: location }}> */}
        {/* </Link> */}
      </Link>
    </React.Fragment>
  );

  const tabNames = ['Rodando', 'Encerradas', 'Teste'];

  const baseColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Campanha',
      width: 350,
      headerClassName: 'app-theme--header',
      renderCell: renderAvatar,
    },
    {
      field: 'advertiser',
      headerName: 'Anunciante',
      width: 250,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{row?.advertiser?.name}</span>
      ),
    },
    {
      field: 'preview',
      headerName: 'Preview',
      width: 80,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Link
          to={`${QR_CODE_CLIENT_URL}/preview/${row.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ViewIcon
            key={row?.id}
            id={row?.id}
            sx={{ color: '#FF4C51', width: '33px', height: '33px' }}
          />
        </Link>
      ),
    },
  ]

  const bigNumbersColumns: GridColDef[] = [
    {
      field: 'nOfPlaces',
      headerName: 'Locais',
      width: 100,
      // resizable: true,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfActivePlaces',
      headerName: 'Locais Ativos',
      width: 100,
      // resizable: true,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfContracts',
      headerName: 'Contratos',
      width: 100,
      // resizable: true,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfActiveContracts',
      headerName: 'Contratos Ativos',
      width: 150,
      // resizable: true,
      headerClassName: 'app-theme--header',
    },
    // {
    //   field: 'goal',
    //   headerName: 'Objetivo',
    //   width: 100,
    //   headerClassName: 'app-theme--header',
    // },
    {
      field: 'viewsGoal',
      headerName: 'Impressões',
      width: 100,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfViews',
      headerName: 'Entrega',
      width: 220,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <React.Fragment>
          {
            row?.nOfViews && row?.viewsGoal 
              ? 
                <>
                  {Number(row?.nOfViews/row?.viewsGoal * 100).toFixed(2)}%
                  ({row?.nOfViews})
                </>
              : 
                '-'
          }
        </React.Fragment>
      ),
    },
    {
      field: 'viewsAvg',
      headerName: 'Exibição Média',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'nOfCtas',
      headerName: 'Cliques',
      width: 120,
      headerClassName: 'app-theme--header',
    },
    {
      field: 'ctr',
      headerName: 'CTR',
      width: 120,
      headerClassName: 'app-theme--header',
    },
  ];

  const endDateColumns: GridColDef[] = [
    {
      field: 'endDate',
      headerName: 'Finalizada Em',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.endDate).format('DD/MM/YYYY')}</span>
      ),
    },
  ];

  const createdAtColumns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Data do Cadastro',
      width: 130,
      headerClassName: 'app-theme--header',
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <span>{dayjs(row.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
  ];

  const findActiveCampaigns = () => {
    findCampaigns({ 
      query: { 
        includeAdvertisers: true,
        isTest: false,
        isFinished: false,
        includeBigNumbers: true,
      }
    });
    setColumnsTable([
      ...baseColumns, 
      ...bigNumbersColumns, 
      ...createdAtColumns
    ])
  }

  const handleFindCampaigns = (newValue: number) => {
    setCampaignsLoading(true);
    
    if (newValue === 0) {
      findActiveCampaigns();
    }
    if (newValue === 1) {
      findCampaigns({ 
        query: { 
          includeAdvertisers: true,
          isFinished: true,      
          $sort: {
            endDate: -1,
          },
        }
      });
      setColumnsTable([
        ...baseColumns, 
        ...endDateColumns,
        ...createdAtColumns
      ])
    }
    if (newValue === 2) {
      findCampaigns({ 
        query: { 
          includeAdvertisers: true,
          isTest: true,
        }
      });
      setColumnsTable([
        ...baseColumns, 
        ...createdAtColumns
      ])
    }

    setTabValue(newValue);
  }

  const handleChangeTab = (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    handleFindCampaigns(newValue)
  };

  const breadcrumbsList: BreadcrumbType[] = [
    {
      link: '/campaigns',
      label: 'Lista de Campanhas'
    },
    {
      link: '/campaigns',
      label: tabNames[tabValue],
      disabled: true
    },
  ]

  useEffect(() => {
    findActiveCampaigns();
  }, []);

  return (
    <Layout>
      <Navigate pageTitle="Lista de Campanhas" breadcrumbs={breadcrumbsList} />
      <MenuBar
        addUrl="/campaigns/create"
        AddButtonText="Adicionar"
        query={query}
        setQuery={setQuery}
      />
      <TitleTabs value={tabValue} handleChange={handleChangeTab} tabNames={tabNames} />
      {!!campaignsLoading 
        ? 
          <Stack 
            justifyContent="center" 
            alignItems="center"
            sx={{ height: 200 }}
          > 
            <CircularProgress /> 
          </Stack>
        :
          <>
            <Table rows={dataFiltered} columns={columnsTable} />
            <Typography
              variant='subtitle2' 
              style={{ color: 'gray', paddingBottom: 10 }}
            >
              * Dados atualizados em tempo real
            </Typography>
          </>
      }
      <Outlet />
    </Layout>
  );
};

export default CampaignView;
