import React from 'react';
import Layout from '../components/Layout';

const NotFound = () => {
  return (
    <Layout>
      <h1>Oops, Not Found!</h1>
    </Layout>
  );
};

export default NotFound;
