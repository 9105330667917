import React, { useState, useEffect, MouseEvent } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import VillaOutlinedIcon from '@mui/icons-material/VillaOutlined';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import packageJson from '../../package.json';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

// import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import logo from './img/logo.png';

import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/useAuth';
import { AccountCircle, AttachMoney } from '@mui/icons-material';
import { LayoutHandler, AdvertiserFilter } from './common/types';
import { useAdvertisers } from '../contexts/useAdvertisers';
import { Avatar, ListItemAvatar } from '@mui/material';

const { version } = packageJson;

const initialAdvertiser: AdvertiserFilter = {
  id: null,
  name: null,
  urlImage: null,
};

const drawerWidth = 240;

export default function Layout({
  children,
  window,
  advertiserId,
  placeGroupId,
  removeLeftBar,
}: LayoutHandler) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { advertisers, getAdvertiser } = useAdvertisers();
  const [advertiser, setAdvertiser] =
    useState<AdvertiserFilter>(initialAdvertiser);

  const getAdvertiserInformation = () => {
    if (!advertiser.id && advertiserId) {
      getAdvertiser(advertiserId);
    }
  };

  useEffect(() => {
    if (advertiserId) {
      getAdvertiserInformation();
    }
  }, [advertiserId]);

  useEffect(() => {
    if (!advertiser.id && advertisers[0]?.id == advertiserId && advertiserId) {
      setAdvertiser(advertisers[0] as AdvertiserFilter);
    }
  }, [advertisers]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogOut = async () => {
    handleMenuClose();
    await logout();
    navigate('/signin');
  };

  const handleMobileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      <MenuItem>
        <Link
          to="https://wa.me/5515996739466?text=Ola%20poderia%20me%20ajudar%20estou%20com%20duvidas"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
        >
          Ajuda
        </Link>
      </MenuItem>
      <MenuItem onClick={handleLogOut}>Sair</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleLogOut}>Sair</MenuItem>
    </Menu>
  );

  const advertiserMenuLinks = [
    {
      name: 'Campanhas',
      link: `/advertisercampaigns/${advertiserId}`,
      icon: <PanoramaOutlinedIcon />,
    },
    // {
    //   name: 'Usuários',
    //   link: `/advertisercampaigns/${advertiserId}/users`,
    //   icon: <PeopleOutlineIcon />,
    // },
  ];

  const menuLinks = [
    // {
    //   name: 'Dashboard',
    //   link: '/dashboard',
    //   icon: <GridViewOutlinedIcon />,
    // },
    {
      name: 'Anunciantes',
      link: '/advertisers',
      icon: <TvOutlinedIcon />,
    },
    {
      name: `ECDs`,
      link: '/placeGroups',
      icon: <ApartmentOutlinedIcon />,
    },
    {
      name: 'Campanhas',
      link: '/campaigns',
      icon: <PanoramaOutlinedIcon />,
    },
    {
      name: 'Estabelecimentos',
      link: '/places',
      icon: <VillaOutlinedIcon />,
    },
    {
      name: 'Financeiro',
      link: '/batchPayments',
      icon: <AttachMoney />,
    },
    {
      name: 'Segmentação',
      link: '/segmentPlaces',
      icon: <FilterAltIcon />,
    },
    {
      name: 'Usuários',
      link: '/users',
      icon: <PeopleOutlineIcon />,
    },
  ];

  const drawer = (
    <div style={{ height: '100%' }}>
      <Toolbar>
        <Link to="/users">
          <img
            src={logo}
            alt="Thirky logo"
            style={{ width: '135px', marginTop: '8px' }}
            id="thirky-logo"
          />
        </Link>
      </Toolbar>

      <List>
        {menuLinks.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            component={Link}
            to={item.link}
            style={{ color: 'inherit' }}
          >
            <ListItemButton>
              <ListItemIcon>
                {item.icon ? item.icon : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Typography 
        variant='subtitle2'
        style={{ 
          color: '#929292',
          position: 'absolute',
          bottom: '0',
          left: 16,
        }}
      >
        Versão: {version}
      </Typography>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box id="test" sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ backgroundColor: '#fff' }}
        sx={{
          height: '60px',
          width: {
            sm:
              removeLeftBar !== true ? `calc(100% - ${drawerWidth}px)` : `100%`,
          },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: 0,
        }}
      >
        <Toolbar>
          {removeLeftBar === true ? (
            <Link to="/users">
              <img
                src={logo}
                alt=""
                style={{
                  width: '110px',
                  height: '40px',
                  margin: '6px 0 0 1rem',
                }}
                id="thirky-logo"
              />
            </Link>
          ) : null}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {/* Responsive drawer */}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {/* <IconButton size="large" color="inherit">
              <NightlightRoundIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </IconButton>
            <IconButton size="large" color="inherit">
              <NotificationsIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </IconButton> */}

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {advertiser?.urlImage ? (
                <Avatar alt="Advertiser image" src={advertiser?.urlImage} />
              ) : (
                <AccountCircle style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
              )}
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {removeLeftBar !== true ? (
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                borderWidth: 0,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                borderWidth: 0,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      ) : null}
      <Box
        component="main"
        style={{ marginTop: 64, paddingRight: 50, backgroundColor: '#f2f2f2' }}
        sx={{
          flexGrow: 1,
          p: 3,
          // width: { sm: `calc(100% - ${drawerWidth}px)` },
          width: {
            sm:
              removeLeftBar !== true ? `calc(100% - ${drawerWidth}px)` : `100%`,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
